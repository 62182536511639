<template>
  <div class="mb-4 mt-1">
    <v-row no-gutters align="center">
      <v-col cols="9" md="7" lg="4">
        <v-text-field
          label="Search utility providers"
          append-icon="mdi-magnify"
          color="green"
          rounded
          solo
          hide-details
          v-model="filterFields.name_includes"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8" align="start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              rounded
              v-bind="attrs"
              v-on="on"
              @click="open = !open"
              class="ml-2"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
          <span>Toggle filter options</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="open"></div>
    </v-expand-transition>
  </div>
</template>
<script>
export default {
  props: ['filterParams'],
  data() {
    return {
      open: false,
      filterFields: {},
    };
  },
  watch: {
    filterFields: {
      handler: function (newVal) {
        this.$emit('update:filterParams', newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
</style>