<template>
  <div id="explore-challenges-page">
    <div id="explore-challenges-box" class="container">
      <h1>Explore Challenges</h1>
    </div>

    <div id="top" class="container">
      <p>
        Get excited about saving energy and money! Join a current or upcoming
        challenge, or take the lead to and create your own challenge. Make sure
        to carefully read the Challenge rules to make sure you are eligible to
        participate.
      </p>
    </div>

    <div id="explore-challenges-filter" class="container">
      <ul>
        <li :class="['activeFilter', { active: selection === 1 }]" @click="selection = 1">
          Current
        </li>
        <li id="upcoming" :class="['activeFilter', { active: selection === 2 }]" @click="selection = 2">
          Upcoming
        </li>
        <li id="right-filter" :class="['activeFilter', { active: selection === 0 }]" @click="selection = 0">
          Past
        </li>
      </ul>
    </div>

    <div id="challenges-content" class="container row justify-content-start">
      <Challenge
        v-for="challenge in challenges"
        :key="'challenge' + challenge.id"
        :challenge="challenge"
      />
    </div>
  </div>
</template>

<script>
import Challenge from '@/components/Challenge';
import { actionTypes } from '@/store/types';

export default {
  name: 'ExploreChallenges',
  components: { Challenge },
  async asyncData({ store }) {
    await store.dispatch(actionTypes.GET_CHALLENGES);
    let selection = null;

    if (store.state.challenges[1].length) {
      selection = 1
    } else if (store.state.challenges[2].length) {
      selection = 2;
    } else if (store.state.challenges[0].length) {
      selection = 0;
    }

    return {
      selection: selection
    }
  },
  computed: {
    challenges() {
      if (this.$store.state.challenges.length > 0) {
        if (process.client) {
          this.$nextTick(function() {
            $('.challenge').hover(
              function() {
                $(this)
                  .find('.overlay')
                  .css('display', 'block');
              },
              function() {
                $(this)
                  .find('.overlay')
                  .css('display', 'none');
              }
            );
          });
        }

        return this.$store.state.challenges[this.selection];
      }
      return [];
    },
  },
  head() {
    return {
      title: 'Explore Challenges | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Choose to participate in any current and upcoming MeterLeader energy saving Challenges. If you are interested in organizing a Challenge please contact us.',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/css/exploreChallenges';
</style>
