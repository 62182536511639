<template>
  <div>
    <NuxtLink :to="`/admind/users/${slotParams.item.id}`">{{
      slotParams.item.full_name
    }}</NuxtLink>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
};
</script>