<template>
  <div id="login-page">
    <div class="main-content">
      <h1>Resend Verification Email</h1>
      <div id="resend-verification" class="login-box">
        <p id="unknown-user" class="error">
          A user for this email address does not exist.
        </p>
        <p id="confirmed-error" class="error">
          This email address has already been confirmed.
        </p>
        <p id="server-error" class="error">A server error has occurred.</p>
        <p id="sent" class="success">New validation email sent.</p>
        <form class="login-form" method="post">
          <input
            v-model="email"
            class="email"
            type="email"
            name="email"
            placeholder="Email address"
            required
          /><br />
          <input
            class="login-button"
            type="button"
            value="Submit"
            @click="submit"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SendVerification',
  data() {
    return {
      email: '',
    };
  },
  mounted() {
    if (process.client) {
      this.userError = $('#unknown-user');
      this.confirmedError = $('#confirmed-error');
      this.serverError = $('#server-error');
      this.sent = $('#sent');
    }
  },
  methods: {
    async submit() {
      try {
        await this.$api.post('/user/verify_email_request', {
          email: this.email,
        });
        this.sent.show();
      } catch (error) {
        switch (error.response.data.msg) {
          case 'user_not_found':
            this.userError.show();
            break;
          case 'email_already_confirmed':
            this.confirmedError.show();
            break;
          default:
            this.serverError.show();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';
</style>
