<template>
  <div>
    <div class="d-flex ctabs align-items-center">
      <div
        class="ctab-item font-weight-bold px-2"
        @click="
          selectedTab = item;
          $emit('update:selected', item);
        "
        v-for="(item, iIndex) in items"
        :key="`item-${iIndex}`"
        :class="{ selected: selectedTab == item }"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ['Item1', 'Item2'],
    },
    selected: {
      type: String,
      default: 'Item1',
    },
  },
  data() {
    return {
      selectedTab: this.selected,
    };
  },
  watch: {
    selected(newVal) {
      this.selectedTab = newVal;
    },
  },
};
</script>

<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.ctabs
  overflow-x: auto
  border-radius: 18px !important
  margin-top: 1rem !important
  padding: 4px
  background-color: $grey

.ctab-item
  width: 100%
  border-radius: 18px !important
  text-align: center
  &.selected
    background-color: white
</style>