<template>
  <nav id="top-navbar" class="navbar fixed-top navbar-light navbar-expand-xl">
    <nuxt-link to="/" class="navbar-brand">
      <img id="logo" src="~/assets/images/logo.svg" />
    </nuxt-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div id="navbarNav" class="navbar-collapse collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active-loggedin">
          <nuxt-link class="nav-link activeLinks" to="/my-usage">
            My Usage
          </nuxt-link>
        </li>
        <li class="nav-item active-loggedin">
          <nuxt-link class="nav-link activeLinks" to="/challenges">
            Challenges
          </nuxt-link>
        </li>

        <li class="nav-item active-loggedin">
          <nuxt-link class="nav-link activeLinks" to="/actions">
            Actions
          </nuxt-link>
        </li>
        <li id="signup-button" class="nav-item">
          <a class="nav-link" @click="createChallenge"> Create Challenge </a>
        </li>
        <li class="nav-item mobile-nav-hide">
          <div class="dropdown">
            <nuxt-link
              id="profile-dropdown"
              class="nav-link dropdown-toggle"
              to="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img id="profile-pic" :src="userData.photo_url" />
              {{ navBarName }}
            </nuxt-link>
            <div
              id="profile-dropdown-container"
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="profile-dropdown"
            >
              <nuxt-link class="dropdown-item" to="/account-settings">
                Settings
              </nuxt-link>
              <nuxt-link class="dropdown-item" to="/faqs"> FAQs </nuxt-link>
              <nuxt-link class="dropdown-item" to="/how-it-works">
                How it Works
              </nuxt-link>
<!--              <a-->
<!--                class="dropdown-item"-->
<!--                href="https://s3.amazonaws.com/meterleader-assets/meterleader-privacy.pdf"-->
<!--              >-->
<!--                Privacy Policy-->
<!--              </a>-->
              <nuxt-link class="dropdown-item" to="/privacy-policy"> Privacy Policy </nuxt-link>

<!--              <a-->
<!--                class="dropdown-item"-->
<!--                href="https://s3.amazonaws.com/meterleader-assets/meterleader-terms.pdf"-->
<!--              >-->
<!--                Terms & Conditions-->
<!--              </a>-->
              <nuxt-link class="dropdown-item" to="/terms-conditions">
                Terms & Conditions
              </nuxt-link>

              <nuxt-link class="dropdown-item" to="/logout">
                Log Out
              </nuxt-link>
            </div>
          </div>
        </li>
        <!-- <li id="hamburger-menu" class="nav-item dropdown mobile-nav-hide">
          <a
            id="navbar-toggler-loggedin hamburger-dropdown"
            class="nav-link"
            data-toggle="dropdown"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <font-awesome-icon icon="bars" />
          </a>
          <div
            id="hamburger-dropdown"
            class="dropdown-menu dropdown-menu-right"
          >
            <nuxt-link class="dropdown-item" to="/how-it-works">
              How It Works
            </nuxt-link>

            <div class="nav-item dropdown-submenu">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Who It's For
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <nuxt-link to="/cities" class="dropdown-item"
                  >Cities
                </nuxt-link>
                <nuxt-link to="/companies" class="dropdown-item"
                  >Companies
                </nuxt-link>
              </div>
            </div>

            <nuxt-link class="dropdown-item" to="/explore-challenges">
              Explore Challenges
            </nuxt-link>
            <nuxt-link class="dropdown-item" to="/faqs"> FAQs </nuxt-link>
            <a class="dropdown-item" href="https://blog.meterleader.com/">
              Blog
            </a>
          </div>
        </li> -->
        <li class="nav-item mobile-nav-show">
          <nuxt-link
            class="nav-link activeLinks"
            to="/account-settings"
          >
            Account Settings
          </nuxt-link>
        </li>
        <li class="nav-item mobile-nav-show">
          <nuxt-link class="nav-link activeLinks" to="/logout">
            Log Out
          </nuxt-link>
        </li>
        <li class="nav-item mobile-nav-show">
          <nuxt-link class="nav-link activeLinks" to="/how-it-works">
            How it Works
          </nuxt-link>
        </li>
        <li class="nav-item mobile-nav-show">
          <nuxt-link class="nav-link activeLinks" to="/explore-challenges">
            Explore Challenges
          </nuxt-link>
        </li>
        <li class="nav-item mobile-nav-show">
          <nuxt-link class="nav-link activeLinks" to="/faqs"> FAQs </nuxt-link>
        </li>
<!--        <li class="nav-item mobile-nav-show">-->
<!--          <a class="nav-link activeLinks" href="https://blog.meterleader.com/"-->
<!--            >Blog</a-->
<!--          >-->
<!--        </li>-->
      </ul>
    </div>
    <BootstrapModal :showModal.sync="showCannotCreateChallengeAlert">
      <template v-slot:body>
        <div>
          In order to create a challenge you must have an Organization
          Subscription. Please see pricing <a href="/pricing">here</a>. To
          complete purchase, please send email to
          <a href="mailto: support@meterleader.com">support@meterleader.com</a>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="showCannotCreateChallengeAlert = false"
        >
          Close
        </button>
      </template>
    </BootstrapModal>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

import BootstrapModal from './BootstrapModal.vue';

import { actionTypes } from '@/store/types';

export default {
  name: 'LoggedInNavBar',

  data() {
    return {
      showCannotCreateChallengeAlert: false,
      canCreateChallenge: false,
    };
  },

  computed: mapState({
    // userData: (state) => state.auth.user ? state.auth.user : '',
    userData(state) {
      return state.auth.user ? state.auth.user : '';
    },
    navBarName(state) {
      // return `${state.auth.user.firstname} ${
      //   state.auth.user.lastname
      //     ? state.auth.user.lastname.slice(0, 1) + '.'
      //     : ''
      // }`;
      return state.auth.user ? `${state.auth.user.firstname} ${state.auth.user.lastname ? state.auth.user.lastname.slice(0, 1) + '.' : ''}` : '';
    },
  }),
  methods: {
    createChallenge() {
      if (!this.canCreateChallenge) {
        this.showCannotCreateChallengeAlert = true;
        return;
      }
      this.$router.push('/challenges/create');
    },
  },
  async mounted() {
    await this.$store.dispatch(actionTypes.GET_USER_PROFILE);
    this.canCreateChallenge =
      this.$store.state.user.profile.can_create_challenge;
    if (process.client) {
      $(document).ready(function () {
        const navMain = $('.navbar-collapse');
        navMain.on('click', 'a:not([data-toggle])', null, function () {
          navMain.collapse('hide');
        });
        $('.dropdown-submenu a.dropdown-toggle').on('click', function (e) {
          $(this).next('div').toggle();
          e.stopPropagation();
          e.preventDefault();
        });
      });
    }
  },
  components: { BootstrapModal },
};
</script>

<style lang="scss">
@import '~@/assets/css/navbar';

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 100%;
}
</style>
