<template>
  <div id="how-it-works-page">
    <div id="how-it-works-box" class="container">
      <h1>How It Works</h1>
      <h4 id="how-it-works-description">
        MeterLeader motivates people to save energy through online challenges
        that are integrated with real-time electricity data. Participate in a
        challenge by following these 4 easy steps:
      </h4>
      <FourSteps color="orange" />
    </div>

    <div id="how-we-calculate-box" class="container">
      <h2>How We Calculate Energy Savings & Carbon Emissions</h2>
      <h4>
        MeterLeader relies on a trusted third party to sync your hourly
        electricity and natural gas usage data from your utility account.
      </h4>
      <h4>
        The MeterLeader platform is designed so that only you can see and access
        your exact energy use data. However, our public facing Challenge pages
        do display some figures derived from your energy use data. Our Challenge
        pages display your percentage change in kWh electricity use, therms of
        natural gas usage, and pounds of total carbon emissions, while also
        aggregating the group’s total kilowatt-hour (kWh) and therms energy
        savings.
      </h4>
      <h4>
        We have chosen to display individual’s percent change in energy use
        because this allows for a more equal comparison and helps account for
        differences in home size, home type, number of occupants, and
        differences in equipment. The displayed percentage change in energy
        usage is calculated based on the individual’s electricity (kWh) and
        natural gas usage (therms) during the Challenge period as compared their
        previous year’s baseline usage during that same time period. Since
        Challenges are strictly focused on energy savings, we do not count
        negative values if participants increase their energy usage during the
        Challenge period.
      </h4>
    </div>
  </div>
</template>

<script>
import FourSteps from '@/components/FourSteps';

export default {
  name: 'HowItWorks',
  backButtonRoute: '/settings',
  components: { FourSteps },
  head() {
    return {
      title: 'How It Works | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Participate in a MeterLeader energy saving Challenge in four easy steps: 1. Sync Utility Data, 2. Join A Challenge, 3. Save Energy, 4. Make An Impact.',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/css/howItWorks';
</style>
