<template>
  <div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md" style="text-align: left">
            <h1>Mobilize your community to combat climate change together.</h1>
            <p style="margin-top: 40px">
              Engage your residents in measurably reducing carbon emissions at
              home.
            </p>
            <a
              href="https://calendly.com/meterleader/nzandt"
              target="_blank"
              class="btn px-5 py-3 my-4 font-weight-bold"
              style="background-color: #c200fb; color: #fff"
              >Schedule a 15 min call</a
            >
          </div>
          <div class="col-md img-right">
            <img src="~/assets/images/cities-1.png" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid background-grey">
        <div class="container" style="padding-top: 40px; padding-bottom: 40px">
          <div class="row">
            <div class="col-md">
              <h2 class="font-weight-bold">
                Cities are Tackling Climate Change Head On
              </h2>
              <p>
                The United Nations estimates that cities are responsible for
                over 70% of global greenhouse gas emissions. Luckily cities
                across the world have developed and are implementing
                comprehensive climate action plans. Increasing energy efficiency
                and electrification are central components of most city climate
                action plans and require the active engagement of all city
                residents...that’s where MeterLeader can help!
              </p>
            </div>
            <div class="col-md font-italic align-self-center blockquote">
              <h2 style="font-size: 1.6rem">
                “Will we go from summit to summit, or will the mayors fo the
                world summon the crowds to take action?”
              </h2>
              <br />
              <h3 style="font-size: 1.45rem">
                Mayor Gustavo Petro Urrego Bogota
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px">
        <div class="row">
          <div class="col-md img-left">
            <img style="width: 100%" src="~/assets/images/cities-2.png" />
          </div>
          <div class="col-md">
            <h1>Measurably achieve your climate action plan goals.</h1>
            <p>
              MeterLeader uses real-time electricity and natural gas data to
              measure REAL carbon emission reductions. We provide meaningful
              metrics on energy saved to help you track progress towards carbon
              emission reduction goals.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="container" style="margin-top: 25px">
          <div class="row">
            <div class="col-md">
              <h1>Promote Energy Efficiency & Electrification.</h1>
              <p>
                Educate residents on energy efficient behaviors, equipment, and
                electrification measures that they can take in their home or
                buildings to save money on utility bills and reduce carbon
                emissions. Customize weekly educational email content to fit to
                a certain building type or to align more specifically with your
                goals.
              </p>
            </div>
            <div class="col-md">
              <img src="~/assets/images/cities-3.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px">
        <div class="row">
          <div class="col-md img-left">
            <img src="~/assets/images/cities-4.png" />
          </div>
          <div class="col-md">
            <h1 class="font-weight-bold">
              Create Custom Energy Challenges tailored to each Stakeholder
            </h1>
            <p>
              Empower residents to work together to save energy and share best
              practices - all through the MeterLeader website. Help residents
              visualize their individual and collective progress toward carbon
              emission reduction goals.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div id="start-challenge" class="container-fluid background-green">
        <div class="container align-content-center">
          <h1>Start an energy savings challenge today!</h1>
          <a
            href="https://calendly.com/meterleader/nzandt"
            target="_blank"
            class="btn px-5 py-3 my-4 font-weight-bold"
            style="background-color: #c200fb; color: #fff"
            >Schedule a 15 min call</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import socialImage from '@/assets/images/ecogroups-1.jpg';

export default {
  name: 'ForCompanies',
  head() {
    return {
      title: 'For Cities | MeterLeader',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Inspire your community to combat climate change.',
        },
        {
          hid: 'og:image',
          name: 'image',
          property: 'og:image',
          content: `https://www.meterleader.com${socialImage}`,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/companies';
</style>
