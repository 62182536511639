<template>
  <div v-if="!hasUtilityAccounts" id="onboarding-reminder" class="container">
    <h5>Your Enrollment is Not Complete</h5>
    <p>
      You haven't connected your utility yet!
      <nuxt-link to="/syncdata?initial=true"> Click here </nuxt-link>
      to get started.
    </p>
  </div>
</template>

<script>
export default {
  name: 'OnboardingReminder',
  data() {
    return {
      hasUtilityAccounts: true,
    };
  },

  async mounted() {
    await this.getUserMeters();
  },

  methods: {
    async getUserMeters() {
      const res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      if (res.items.length <= 0) {
        this.hasUtilityAccounts = false;
      }
    },
  },
};
</script>

<style scoped lang="sass">
#onboarding-reminder
  padding: 20px
  // margin-top: 10px
  background-color: rgba(44, 109, 255, .14)
  border-radius: 5px

  p
    margin: 0

    a
      color: #007bff !important
</style>
