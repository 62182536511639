<template>
  <div id="utility-content" class="content">
    <div class="d-flex align-items-center">
      <h2 class="my-3">Utility Accounts</h2>
      <div :style="{ flexGrow: 1 }"></div>

      <button class="btn btn-c add" @click="addUtilityAccount">
        <span>Add Utility Account </span>
        <font-awesome-icon icon="plus" size="md" />
      </button>
    </div>

    <div
      v-for="(account, index) in utilityAccounts"
      :key="`utilityCard-${index}`"
    >
      <div class="card p-3 my-2">
        <div class="d-flex align-items-center">
          <h4>{{ account.utility_provider }}</h4>
          <div class="h5 px-2">
            <span
              class="badge badge-pill"
              :class="{
                'badge-success': account.status === 'Active',
                'badge-danger': account.status !== 'Active',
              }"
              >{{ account.status }}</span
            >
          </div>
          <div :style="{ flexGrow: 1 }"></div>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div class="dropdown-item">
                <a href="" @click="$router.push(`/syncdata/${account.id}`)"
                  >Reconnect</a
                >
              </div>
              <div class="dropdown-item">
                <a
                  class="text-danger"
                  data-toggle="modal"
                  data-target="#delete-modal"
                  @click="selectedUtilityAccount = account"
                  >Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(meter, meterIndex) in userMeters.filter(
            (o) => o.subscription_id === account.id
          )"
          :key="`meters-${meterIndex}`"
          class="d-flex align-items-center ml-3"
        >
          <div class="h6 mb-0">- {{ meter.address }}</div>

          <font-awesome-icon
            v-if="meter.available_meter_types.includes('GAS')"
            class="ml-5"
            icon="fire"
          />
          <font-awesome-icon
            v-if="meter.available_meter_types.includes('ELECTRIC')"
            icon="bolt"
            class="ml-3"
          />
        </div>
      </div>
    </div>

    <h5 v-if="utilityAccounts.length <= 0">No utilities accounts to show.</h5>

    <!--region Delete Utility Account Modal    -->
    <div
      id="delete-modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Utility Account</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure that you want to delete this utility account?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              id="delete-utility"
              type="button"
              data-dismiss="modal"
              class="btn btn-danger"
              @click="deleteUtility(selectedUtilityAccount.id)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--endregion Delete Utility Account Modal    -->
    <BootstrapModal :showModal.sync="showCannotAddPelmSubscription">
      <template v-slot:body>
        <div>
          Sorry! <br />
          In order to connect your utility account you must be associated with
          an Organization who has purchased a MeterLeader Subscription. Please
          see pricing <a href="/pricing"></a>. To complete purchase, please send
          email to
          <a href="mailto: support@meterleader.com">support@meterleader.com</a>.
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="showCannotAddPelmSubscription = false"
        >
          Close
        </button>
      </template>
    </BootstrapModal>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { actionTypes } from '@/store/types';
import BootstrapModal from '~/components/BootstrapModal.vue';

export default {
  async asyncData({ store }) {
    await store.dispatch(actionTypes.GET_USER_PROFILE);
    return {
      canSubscribePelm: store.state.user.profile.can_subscribe_pelm,
    };
  },
  data() {
    return {
      utilityAccounts: [],
      userMeters: [],
      selectedUtilityAccount: null,
      showCannotAddPelmSubscription: false,
    };
  },
  async mounted() {
    await this.loadUtilityAccounts();
    await this.getUserMeters();
  },
  methods: {
    addUtilityAccount() {
      if (!this.canSubscribePelm) {
        this.showCannotAddPelmSubscription = true;
        return;
      }
      this.$router.push('/syncdata');
    },
    async loadUtilityAccounts() {
      let res = await this.$api.get('pelm/pelm-subscriptions');
      this.utilityAccounts = res.data.subscriptions.map((o) => {
        o.status = 'Active';
        return o;
      });
    },
    async getUserMeters() {
      const res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      this.userMeters = res.items;
    },
    async deleteUtility(accountId) {
      await this.$api.delete(`pelm/pelm-subscriptions/${accountId}`);
      await this.loadUtilityAccounts();
    },
  },
  components: { BootstrapModal },
};
</script>
<style lang="scss" scoped>
@import '~/assets/css/variables';
.btn-c {
  font-weight: 500;
  color: #fff;
  border-radius: 0px;

  &.add {
    background-color: $green;
    border-color: $green;
  }

  &.delete {
    background-color: #dc3545;
    border-color: #dc3545;
  }
}
</style>
