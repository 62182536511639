<template>
  <div id="main">
    <!--    <Debug style="position: fixed; top: 200px"></Debug>-->
    <LoggedInNavBar v-if="$auth.loggedIn" class="d-none d-md-flex" />
    <NavBar v-else class="d-none d-md-flex" />
    <MobileHeader v-if="$auth.loggedIn" class="d-md-none" />
    <div class="main-content layout-top-padding">
      <!--      <div-->
      <!--        v-if="$auth.loggedIn && isProd && $route.path !== '/'"-->
      <!--        class="container alert alert-warning"-->
      <!--        role="alert"-->
      <!--        style="margin-top: 50px"-->
      <!--      >-->
      <!--        MeterLeader is undergoing maintenance. Challenges and Utility Accounts-->
      <!--        will be restored soon.-->
      <!--      </div>-->
      <nuxt />
    </div>
    <!-- <MobileNavBar v-if="$auth.loggedIn" class="d-md-none" /> -->
    <MobileNavBar v-if="$auth.loggedIn" class="d-md-none" />
    <div :style="{ height: '60px' }" class="d-md-none"></div>
    <Footer class="d-none d-lg-block" />
    <div :style="{ height: '50px' }" class="d-none"></div>
    <AlertModal></AlertModal>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar';
import MobileNavBar from '@/components/MobileNavBar.vue';
import Footer from '@/components/Footer';
import LoggedInNavBar from '@/components/LoggedInNavBar';
import Debug from '@/components/Debug';
import AlertModal from '~/components/AlertModal.vue';

import MobileHeader from '~/components/MobileHeader.vue';

export default {
  name: 'Default',
  components: {
    NavBar,
    LoggedInNavBar,
    Footer,
    Debug,
    MobileNavBar,
    AlertModal,
    MobileHeader,
  },
  computed: {
    // isProd() {
    //   return process.env.AUTH_BASE_URL === 'https://www.meterleader.com';
    // },
  },
  mounted() {
    console.log(this.$route.path);
  },
  watch: {
    $breakpoints: {
      handler: function () {
        if (this.$breakpoints.sm || this.$breakpoints.sSm) {
          if (!this.$auth.loggedIn) {
            // this.$router.push('/login');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="sass">
@import '@fortawesome/fontawesome-svg-core/styles.css'
@import 'bootstrap/dist/css/bootstrap.css'
@import '~@/assets/css/main'

.mobile-landing
  z-index: 10000
  top: 0px
  right: 0px
  bottom: 0px
  left: 0px
</style>
