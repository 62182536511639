<template>
  <div>
    <OrganizationForm @update="updateHandler($event)"></OrganizationForm>
  </div>
</template>
<script>
import OrganizationForm from '~/components/admind/organizations/OrganizationForm.vue';
export default {
  layout: 'admin_dashboard',
  components: { OrganizationForm },
  data() {
    return {};
  },
  methods: {
    async updateHandler(formData) {
      try {
        await this.$api.post('admind/organizations', {
          ...formData,
        });

        this.$router.push({ name: 'admind-organizations' });
      } catch (err) {
        this.$toast.error('Oops, something went wrong.', { duration: 5000 });
      }
    },
  },
};
</script>