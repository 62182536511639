<template>
  <div>
    <NuxtLink :to="`/admind/challenges/${slotParams.item.id}`">{{
      slotParams.item.name
    }}</NuxtLink>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
};
</script>