<template>
  <div>
    <v-row>
      <v-col sm="12" md="6" lg="4">
        <v-card v-if="Object.keys(data).length > 0">
          <v-card-title>{{ `${data.full_name}` }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>Email</v-col>
              <v-col>{{ data.email }}</v-col>
            </v-row>
            <v-row>
              <v-col>Status</v-col>
              <v-col>{{ data.status }}</v-col>
            </v-row>
            <v-row>
              <v-col>Organization</v-col>
              <v-col>
                <nuxt-link
                  :to="`/admind/organizations/${data.organization_id}/details`"
                  >{{ data.organization_name }}</nuxt-link
                ></v-col
              >
            </v-row>
            <v-row>
              <v-col>
                <nuxt-link
                  :to="`/admind/challenges?user_id=${$route.params.userId}`"
                  >Challenges</nuxt-link
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <nuxt-link
                  :to="`/admind/pelm_subscriptions?user_id=${$route.params.userId}`"
                  >Subscriptions</nuxt-link
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <nuxt-link
                  :to="`/admind/pelm_energy_accounts?user_id=${$route.params.userId}`"
                  >Energy Meters</nuxt-link
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <nuxt-link :to="`/admind/teams?user_id=${$route.params.userId}`"
                  >Properties</nuxt-link
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <a href="#" @click.prevent="syncMeter">Sync Meters</a>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <a href="#" @click.prevent="resetMeter">Reset Meters</a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/users/${this.$route.params.userId}`
      );
      this.data = res.data;
    },

    async syncMeter() {
      try {
        let res = await this.$api.$get(
          `pelm/sync-user-pelm-data/${this.$route.params.userId}`
        );

        this.$toast.success(
          `Successfully dispatched. Event Id# ${res.poll_event_id}`,
          { duration: 5000 }
        );
      } catch (error) {
        this.$toast.error('Failed to dispatch dispatched', { duration: 5000 });
      }
    },

    async resetMeter() {
      try {
        let res = await this.$api.$get(
          `pelm/reset-user-pelm-data/${this.$route.params.userId}`
        );

        this.$toast.success(
          `Successfully dispatched. Event Id# ${res.poll_event_id}`,
          { duration: 5000 }
        );
      } catch (error) {
        this.$toast.error('Failed to dispatch dispatched', { duration: 5000 });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
