// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/@fortawesome/fontawesome-svg-core/styles.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/bootstrap/dist/css/bootstrap.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a{outline:none}.page-enter-active,.page-leave-active{transition:opacity .4s}.page-enter,.page-leave-to{opacity:0}.layout-top-padding{padding-top:72px}@media(max-width: 720px){.layout-top-padding{padding-top:0px}}#main{display:flex;flex-direction:column;min-height:100vh}#main input,#main select,#main textarea{border:.5px #979797 solid;border-radius:0}#main .custom-select{background:#fff url(\"/assets/images/caret-down-solid.svg\") no-repeat right .75rem center/8px 10px;background-size:10px}#main .main-content{flex:1 1 auto;align-self:stretch;min-height:700px}#main .main-content #onboarding-reminder{padding:20px;margin-bottom:10px;background-color:rgba(44,109,255,.14);border-radius:5px}#main .main-content #onboarding-reminder p{margin:0}#main .main-content #onboarding-reminder a{color:#007bff !important}#main .main-content h1,#main .main-content h2,#main .main-content h5,#main .main-content h6{font-family:\"Raleway\",sans-serif}#main .main-content p{font-family:\"Open Sans\",sans-serif}.hide{display:none}.red{color:red !important}.mobile-landing{z-index:10000;top:0px;right:0px;bottom:0px;left:0px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
