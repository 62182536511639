<template>
  <div class="container">
    <h1
      data-toggle="collapse"
      data-target="#collapseStats"
      aria-expanded="true"
      aria-controls="collapseStats"
    >
      <font-awesome-icon icon="caret-down" /> Stats
    </h1>
    <div id="collapseStats" class="collapse show">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Stat</th>
            <th scope="col">24 Hours</th>
            <th scope="col">7 Days</th>
            <th scope="col">30 Days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Sign-ups</th>
            <td>{{ stats.signups['24h'] }}</td>
            <td>{{ stats.signups['1W'] }}</td>
            <td>{{ stats.signups['1M'] }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1
      data-toggle="collapse"
      data-target="#collapseChallenges"
      aria-expanded="true"
      aria-controls="collapseChallenges"
    >
      <font-awesome-icon icon="caret-down" /> Challenges
    </h1>
    <div id="collapseChallenges" class="collapse show">
      <h2>Unapproved Challenges</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Sponsor</th>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
            <!--        <th scope="col">Handle</th>-->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="challenge in unapprovedChallenges"
            :key="'unapproved' + challenge.id"
          >
            <th>{{ challenge.id }}</th>
            <td>{{ challenge.sponsor_name }}</td>
            <td>{{ challenge.name }}</td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                @click="showDeleteChallengeModal(challenge.id, challenge.name)"
              >
                Delete
              </button>
              <button
                type="button"
                class="btn btn-info"
                @click="
                  $router.push(`/challenges/create?id=${challenge.id}&admin=1`)
                "
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="preview(challenge.id)"
              >
                Preview
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="$repo.admin.returnChallenge(challenge.id)"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="$repo.admin.approveChallenge(challenge.id)"
              >
                Approve
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Returned Challenges</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Sponsor</th>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
            <!--        <th scope="col">Handle</th>-->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="challenge in returnedChallenges"
            :key="'returned' + challenge.id"
          >
            <th>{{ challenge.id }}</th>
            <td>{{ challenge.sponsor_name }}</td>
            <td>{{ challenge.name }}</td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                @click="showDeleteChallengeModal(challenge.id, challenge.name)"
              >
                Delete
              </button>
              <button
                type="button"
                class="btn btn-info"
                @click="
                  $router.push(`/challenges/create?id=${challenge.id}&admin=1`)
                "
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="preview(challenge.id)"
              >
                Preview
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="action(-1, challenge.id)"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="action(1, challenge.id)"
              >
                Approve
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Approved Challenges</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Sponsor</th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
            <!--        <th scope="col">Handle</th>-->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="challenge in approvedChallenges"
            :key="'approved' + challenge.id"
          >
            <th>{{ challenge.id }}</th>
            <td>{{ challenge.sponsor_name }}</td>
            <td>{{ challenge.name }}</td>
            <td>{{ getStatusText(challenge.status) }}</td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                @click="showDeleteChallengeModal(challenge.id, challenge.name)"
              >
                Delete
              </button>
              <button
                type="button"
                class="btn btn-info"
                @click="
                  $router.push(`/challenges/create?id=${challenge.id}&admin=1`)
                "
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="preview(challenge.id)"
              >
                Preview
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="action(-1, challenge.id)"
              >
                Reject
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="action(1, challenge.id)"
              >
                Approve
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1
      data-toggle="collapse"
      data-target="#collapseTasks"
      aria-expanded="true"
      aria-controls="collapseTasks"
    >
      <font-awesome-icon icon="caret-down" /> Tasks
    </h1>
    <div id="collapseTasks" class="collapse show">
      <div class="card-columns">
        <div class="card" style="width: 18rem">
          <div class="card-header">Challenges</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <button
                type="button"
                class="btn btn-info"
                @click="$repo.admin.updateChallenges()"
              >
                Update challenges
              </button>
            </li>
          </ul>
        </div>

        <div class="card" style="width: 18rem">
          <div class="card-header">Users</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <form class="form-inline">
                <input
                  v-model="id"
                  type="number"
                  class="form-control mb-2 mr-sm-2"
                  placeholder="Id"
                  style="width: 60px"
                />
                <button
                  type="button"
                  class="btn btn-info mb-2"
                  @click="$repo.admin.masquerade(id)"
                >
                  Masquerade
                </button>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <DialogModal
      id="delete-challenge-modal"
      title="Delete Challenge?"
      :message="`Delete '${selectedChallengeName}' ?`"
      @on-confirm="deleteChallenge"
    />
  </div>
</template>

<script>
import DialogModal from '@/components/DialogModal';
export default {
  name: 'Admin',
  components: { DialogModal },
  async asyncData({ app }) {
    const allChallenges = await app.$repo.admin.getAllChallenges();
    const stats = await app.$repo.admin.getStats();

    return {
      approvedChallenges: allChallenges.data.filter(
        (challenge) => challenge.status >= 3
      ),
      unapprovedChallenges: allChallenges.data.filter(
        (challenge) => challenge.status === 1
      ),
      returnedChallenges: allChallenges.data.filter(
        (challenge) => challenge.status === 2
      ),
      stats: stats.data,
    };
  },
  data() {
    return {
      approvedChallenges: null,
      unapprovedChallenges: null,
      returnedChallenges: null,
      stats: null,
      id: null,
      selectedChallengeId: '',
      selectedChallengeName: '',
    };
  },
  methods: {
    preview(id) {
      window.open(`/challenges/${id}?preview`);
    },
    getStatusText(status) {
      switch (status) {
        case 3:
          return 'Approved';
        case 4:
          return 'Launched';
        case 5:
          return 'Running';
        case 6:
          return 'Ended';
      }
    },
    async refresh() {
      console.log('refresh');
      const allChallenges = await this.$repo.admin.getAllChallenges();

      this.approvedChallenges = allChallenges.data.filter(
        (challenge) => challenge.status >= 3
      );
      this.unapprovedChallenges = allChallenges.data.filter(
        (challenge) => challenge.status === 1
      );
      this.returnedChallenges = allChallenges.data.filter(
        (challenge) => challenge.status === 2
      );
    },
    async action(what, id) {
      try {
        if (what === -1) {
          const result = await this.$repo.admin.returnChallenge(id);
          console.log('here', result);
          await this.refresh();
        } else if (what === 1) {
          await this.$repo.admin.approveChallenge(id);
          console.log('here');
          await this.refresh();
        }
      } catch (error) {
        console.log('error', error.response);
      }
      console.log('done');
    },

    showDeleteChallengeModal(id, name) {
      console.log('delete', id, name);
      this.selectedChallengeid = id;
      this.selectedChallengeName = name;
      $('#delete-challenge-modal').modal('show');
    },

    async deleteChallenge() {
      console.log('delete', this.selectedChallengeid);
      try {
        await this.$repo.challenges.delete(this.selectedChallengeid);
      } catch (error) {
        console.log(error);
      }
      this.refresh();
      console.log('here');
    },
  },
  head() {
    return {
      title: 'Admin | MeterLeader',
    };
  },
};
</script>

<style scoped>
h1 {
  padding: 25px 0;
}
</style>
