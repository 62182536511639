<template>
  <div id="challenge-form-page">
    <div id="main">
      <div v-if="isSubmitted" class="main-content container-fluid submitted">
        <div class="container">
          <h1>
            Congratulations, your Challenge has been submitted for review.
          </h1>
          <h5>
            We will review your challenge and notify you via email within 48
            hours. As soon as your challenge has been reviewed and approved you
            will be able to launch it live.
          </h5>
        </div>
      </div>
      <div v-else class="main-content">
        <form id="challengeForm" class="container needs-validation" novalidate>
          <h1 style="padding-bottom: 20px">
            {{
              $route.query.hasOwnProperty('admin')
                ? 'ADMIN - Review Challenge'
                : 'Create a Challenge'
            }}
          </h1>
          <div id="top-banner">
            {{ challengeStatusMessage }}
            <p style="color: red; display: inline">{{ errorMessage }}</p>
            <p v-if="isSaving" style="display: inline">
              Saving
              <font-awesome-icon icon="spinner" spin />
            </p>
            <p class="save-succeeded" style="display: none">Saved</p>
            <button
              v-if="challenge.status === 0 || challenge.status === 2"
              id="save"
              class="clear btn"
              type="button"
              @click="save(true)"
            >
              Save
            </button>
            <button
              id="preview"
              class="clear btn"
              type="button"
              @click="preview"
            >
              Preview
            </button>
            <button
              v-if="challenge.status < 3 || $store.state.auth.user.admin"
              class="btn btn-outline-danger"
              type="button"
              data-toggle="modal"
              data-target="#delete-challenge-modal"
            >
              Delete
            </button>
            <button
              v-if="challenge.status === 0 || challenge.status === 2"
              type="button"
              class="solid btn publish"
              @click="submit"
            >
              Submit for Review
            </button>
            <button
              v-if="challenge.status === 1 && $store.state.auth.user.admin"
              type="button"
              class="btn btn-danger"
              @click="$repo.admin.returnChallenge(challenge.id)"
            >
              Reject
            </button>
            <button
              v-if="challenge.status === 1 && $store.state.auth.user.admin"
              type="button"
              class="solid btn publish"
              @click="$repo.admin.approveChallenge(challenge.id)"
            >
              Approve
            </button>
            <button
              v-if="challenge.status === 3"
              type="button"
              class="solid btn publish"
              @click="launch"
            >
              Launch
            </button>
          </div>
          <!--region Section 1: Page Design -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">1</span>
              </div>
              Page Design
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col col-9 col-left no-gutters">
                  <div class="col col-9 col-left">
                    <div class="form-group">
                      <label for="challenge-name">
                        Challenge Name*<br />
                        <small
                          >Give your challenge a short and distinct name. <br />
                          EXAMPLE: "Company" Energy Savings Challenge,
                          "Neighborhood" Energy Challenge
                        </small>
                      </label>
                      <input
                        id="challenge-name"
                        v-model="challenge.name"
                        type="text"
                        name="challenge-name"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="banner"
                        >Banner Image*<br />
                        <small
                          >Decorate your challenge page with a fun image.</small
                        >
                      </label>
                      <img
                        v-if="previewBannerImage"
                        :src="previewBannerImage"
                        class="uploading-image banner-image-preview"
                      />

                      <div class="custom-file" style="margin-top: 10px">
                        <input
                          id="banner"
                          type="file"
                          name="banner"
                          accept="image/*"
                          class="custom-file-input"
                          :required="!previewBannerImage"
                          @change="uploadBannerImage"
                        />
                        <label class="custom-file-label" for="banner"
                          >Choose file</label
                        >
                      </div>

                      <small class="form-text text-muted"
                        >1200 x 628 pixels in size if you are doing an Electric
                        & Gas Challenge<br />
                        1000 x 400 pixels in size if you are doing an Electric
                        Only or Gas Only Challenge
                      </small>
                    </div>
                  </div>
                  <div class="col col-9 col-left">
                    <div class="form-group">
                      <label for="competition-description">
                        Challenge Description*<br />
                        <small
                          >Explain why you are putting on the challenge and the
                          goals of the challenge.</small
                        >
                      </label>
                      <textarea
                        id="competition-description"
                        v-model="challenge.description"
                        name="description"
                        rows="7"
                        maxLength="1000"
                        class="form-control"
                        required
                      />
                      <small id="description-len" class="form-text text-muted"
                        >max length: 1000 characters</small
                      >
                    </div>
                  </div>
                </div>
                <div class="col aside">
                  <p>
                    Challenge descriptions don't need to spell out every detail
                    because that will be listed in the Challenge Rules section.
                    <br /><br />
                    EXAMPLE: MeterLeader is challenging our members to reduce
                    carbon emissions stemming from their home energy usage from
                    July 1 through September 30, 2020 (it’s easy we - will show
                    you how). The top energy saver will win a $100 gift card.
                    And if the collective 1,000 pounds of CO2 emission
                    reductions is met, 10 additional winners will win $20 gift
                    cards.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 1: Page Design -->
          <!--region Section 2: Challenge Structure -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">2</span>
              </div>
              Challenge Structure
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label>
                      Challenge Type*<br />
                      <small
                        >Choose what type of challenge you would like to
                        host.</small
                      >
                    </label>
                    <div class="form-check">
                      <input
                        id="challenge-type-0"
                        class="form-check-input"
                        type="radio"
                        name="challenge-type"
                        v-model="challenge.participant_group"
                        value="No groups"
                        :checked="challenge.participant_group == 'No groups'"
                      />
                      <label class="form-check-label" for="challenge-type-0">
                        Individual Challenge: Individual residential utility
                        account holders compete against one another to win
                        prizes
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="challenge-type-1"
                        class="form-check-input"
                        type="radio"
                        name="challenge-type"
                        v-model="challenge.participant_group"
                        value="Creator specified groups"
                      />
                      <label class="form-check-label" for="challenge-type-1">
                        Team Challenge: Individual residential utility account
                        holders are grouped into teams; teams compete against
                        other teams
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="challenge.participant_group == 'Creator specified groups'"
              >
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label> Team Visibility </label>
                    <div class="form-check">
                      <input
                        id="team-visibility-1"
                        class="form-check-input"
                        type="radio"
                        name="display-groups"
                        v-model="challenge.display_groups"
                        :value="true"
                      />
                      <label class="form-check-label" for="team-visibility-1">
                        Teams are visible on leaderboard
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="team-visibility-0"
                        class="form-check-input"
                        type="radio"
                        name="display-groups"
                        v-model="challenge.display_groups"
                        :value="false"
                      />
                      <label class="form-check-label" for="team-visibility-0">
                        Teams are NOT visible on leaderboard
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-if="challenge.participant_group == 'Creator specified groups'"
              >
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label>
                      What are the names of the teams in the challenge?
                    </label>
                    <div
                      v-for="(item, pgIndex) in participantGroups"
                      :key="`pg-${pgIndex}`"
                      class="d-flex m-2"
                    >
                      <div :style="{ width: '100px' }">{{ item.name }}</div>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger ml-3"
                        @click="deleteParticipantGroup(item.id, pgIndex)"
                      >
                        Delete
                      </button>
                    </div>
                    <div class="d-flex">
                      <input type="text" v-model="participantGroupName" />
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        @click="addParticipantGroup"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col col-9 col-left no-gutters">
                  <div class="col col-9 col-left">
                    <div class="form-group">
                      <label>
                        Challenge Metric*<br />
                        <small
                          >All challenges are based on real energy data.</small
                        >
                      </label>
                      <div class="form-check">
                        <input
                          id="challenge-metric-2"
                          class="form-check-input"
                          type="radio"
                          name="challenge-metric"
                          v-model.number="challenge.metric"
                          :value="3"
                          checked
                        />
                        <label
                          class="form-check-label"
                          for="challenge-metric-2"
                        >
                          Both Electricity (kWh) & Natural Gas (therms)
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          id="challenge-metric-0"
                          class="form-check-input"
                          type="radio"
                          name="challenge-metric"
                          v-model.number="challenge.metric"
                          :value="1"
                        />
                        <label
                          class="form-check-label"
                          for="challenge-metric-0"
                        >
                          Electricity (kilowatt-hour)
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          id="challenge-metric-1"
                          class="form-check-input"
                          type="radio"
                          name="challenge-metric"
                          v-model.number="challenge.metric"
                          :value="2"
                        />
                        <label
                          class="form-check-label"
                          for="challenge-metric-1"
                        >
                          Natural gas (therms)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col col-9 col-left">
                    <div class="form-group">
                      <label>
                        Challenge Duration*<br />
                        <small
                          >All challenges must be at least 1 month long and the
                          maximum challenge length is 12 months. Keep in mind
                          you will also want to launch the challenge page live
                          at least 1 month before the challenge start date, and
                          it takes up to 48 hours for Challenges to be reviewed
                          and approved for launch.</small
                        >
                      </label>
                      <div class="form-row">
                        <div class="col-md-6 col-12">
                          <label> Starts </label>
                          <div class="form-row">
                            <div class="col-6">
                              <input
                                id="start-date"
                                v-model="challengeStartDate"
                                class="form-control"
                                type="date"
                                name="start-date"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-12">
                          <label> Ends </label>
                          <div class="form-row">
                            <div class="col-6">
                              <input
                                id="end-date"
                                v-model="challengeEndDate"
                                class="form-control"
                                type="date"
                                name="end-date"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col col-9 col-left">
                    <div class="form-group">
                      <label>
                        Challenge Educational Content*<br />
                        <small
                          >Challenge participants receive educational email
                          modules during the challenge encouraging them to
                          engage in a variety of energy saving
                          activities.</small
                        >
                      </label>
                      <div class="form-check">
                        <input
                          id="educational-content-0"
                          class="form-check-input"
                          type="radio"
                          name="educational-content"
                          value="0"
                          checked
                        />
                        <label
                          class="form-check-label"
                          for="educational-content-0"
                        >
                          12 email modules (cover topics related to saving
                          energy in the home)
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          id="educational-content-1"
                          class="form-check-input"
                          type="radio"
                          name="educational-content"
                          value="1"
                          disabled
                        />
                        <label
                          class="form-check-label"
                          for="educational-content-1"
                        >
                          24 email modules - Coming Soon!
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col aside">
                  <div class="col aside">
                    <p>
                      The recommended challenge duration is 3 months and begins
                      on the 1st of the month, and ends on the last day of the
                      month. Once the challenge has been approved by
                      MeterLeader, it is recommended to launch the challenge
                      page at least 1 month in advance of the challenge start
                      date, in order to give participants time to sign up and
                      prepare to save energy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 2: Challenge Structure -->
          <!--region Section 3:  Challenge Goals -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">3</span>
              </div>
              Challenge Goals
            </h4>
            <!-- Individual Goal -->
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label for="individual-goal-description">
                      Individual Goal Description*<br />
                      <small
                        >Describe the energy saving goal that you are setting
                        for each individual participant to achieve. This will be
                        the description that appears in the Challenge Rules
                        section of the Challenge Page. <br />
                        <template
                          v-if="
                            challenge.metric === 1 || challenge.metric === 2
                          "
                          >EXAMPLE: Reduce your home's kWh electricity usage by
                          as much as possible (as compared to your usage from
                          10/1/2019-12/30/2019). Try to aim for at least 15%
                          energy savings.</template
                        >
                        <template v-if="challenge.metric === 3">
                          EXAMPLE: Reduce carbon emissions stemming from your
                          home's energy use by as much as possible (as compared
                          to your home’s carbon emissions from
                          10/1/2019-12/30/2019). Try to aim for at least 15% in
                          CO2 emission reductions.
                        </template>
                      </small>
                    </label>
                    <textarea
                      id="individual-goal-description"
                      v-model="challenge.description_individual_goal"
                      name="individual-goal-description"
                      rows="4"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <!-- Collective Goal -->
              <div class="row" style="padding-top: 10px">
                <div class="col-9 col-left no-gutters">
                  <div class="col-9 col-left">
                    <div class="form-group">
                      <label
                        for="collective-description"
                        v-if="challenge.metric === 1"
                      >
                        Collective kWh Goal* <br />
                        <small>
                          Provide the numerical kilowatt-hour goal that the
                          collective group will aim to achieve. This will be the
                          number that the progress bar displays. <br />
                          EXAMPLE: 1000 (this represents the 1,000 kWh
                          collective goal)
                        </small>
                      </label>
                      <label
                        for="collective-description"
                        v-if="challenge.metric === 2"
                      >
                        Collective therms Goal* <br />
                        <small>
                          Provide the numerical therms goal that the collective
                          group will aim to achieve. This will be the number
                          that the progress bar displays. <br />
                          EXAMPLE: 1000 (this represents the 1,000 therms
                          collective goal)
                        </small>
                      </label>
                      <label
                        for="collective-description"
                        v-if="challenge.metric === 3"
                      >
                        Collective CO<sub>2</sub> Goal* <br />
                        <small>
                          Provide the numerical pounds of CO<sub>2</sub>
                          reduction goal that the collective group will aim to
                          achieve. This will be the number that the progress bar
                          displays. <br />
                          EXAMPLE: 1000 (this represents the 1,000 lb of CO<sub
                            >2</sub
                          >
                          reduction)
                        </small>
                      </label>
                      <input
                        id="kwh-goal"
                        v-model="challenge.savings_goal"
                        name="kwh-goal"
                        class="form-control"
                        type="number"
                        required
                      />
                      <p class="red">{{ collectiveKwhError }}</p>
                    </div>
                  </div>

                  <div class="col-9 col-left">
                    <div class="form-group">
                      <label for="collective-description">
                        Collective Goal Description* <br />
                        <small
                          v-if="
                            challenge.metric === 1 || challenge.metric === 2
                          "
                        >
                          Describe the energy savings goal that you are setting
                          for the collective group to achieve. This will be the
                          description that appears in the Challenge Rules
                          section of the Challenge Page.<br />
                          EXAMPLE: Achieve a total of 1,000 kWh in electricity
                          savings as a collective group.
                        </small>
                        <small v-if="challenge.metric === 3">
                          Describe the CO<sub>2</sub> emission reduction goal
                          (lbs) that you are setting for the collective group to
                          achieve. This will be the description that appears in
                          the Challenge Rules section of the Challenge Page.<br />
                          EXAMPLE: Achieve a total of 1,000 pounds of carbon
                          emission reductions as a collective group.
                        </small>
                      </label>
                      <textarea
                        id="collective-description"
                        v-model="challenge.description_collective_goal"
                        name="collective-description"
                        rows="4"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="col aside no-gutters">
                  <div class="col aside">
                    <p v-if="challenge.metric === 1 || challenge.metric === 2">
                      A realistic and achievable kWh energy savings goal is
                      based on the number of expected participants, the
                      individual savings goal, and the duration of the
                      Challenge. <br />
                      <br />
                      Use this equation to calculate your collective kWh goal:
                      316 kWh (avg. monthly usage in San Francisco) x % energy
                      savings x # of expected participants x # of months
                      challenge duration = kWh goal
                    </p>
                    <p v-if="challenge.metric === 3">
                      A realistic and achievable carbon emission reduction goal
                      is based on the number of expected participants, the
                      individual % carbon emissions reduction goal, and the
                      duration of the Challenge.
                      <br /><br />
                      Use this equation to calculate your collective CO2
                      emissions reduction goal: 316 kWh (avg. monthly usage in
                      San Francisco) x % carbon emissions goal x # of expected
                      participants x # of months challenge duration x 0.21 (kWh
                      carbon emission factor) = CO2 emission reduction goal
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 3:  Challenge Goals -->
          <!--region Section 4: Participant Eligibility -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">4</span>
              </div>
              Participant Eligibility
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label> Who is allowed to your Challenge?*<br /> </label>
                    <div class="form-check">
                      <input
                        id="join-eligibility-0"
                        class="form-check-input"
                        type="radio"
                        name="join-eligibility"
                        @input="changeChallengeType('publicVisible')"
                        required
                      />
                      <label class="form-check-label" for="join-eligibility-0">
                        Public Challenge: Anyone can view and join my challenge;
                        Challenge is displayed on MeterLeader Explore Challenges
                        page
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="join-eligibility-0"
                        class="form-check-input"
                        type="radio"
                        name="join-eligibility"
                        @input="changeChallengeType('publicHidden')"
                        required
                      />
                      <label class="form-check-label" for="join-eligibility-0">
                        Public - Invisible Challenge: Anyone can view and join
                        my challenge; Challenge is hidden from MeterLeader
                        Explore Challenges page
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="join-eligibility-1"
                        class="form-check-input"
                        type="radio"
                        name="join-eligibility"
                        @input="changeChallengeType('privateVisible')"
                        required
                      />
                      <label class="form-check-label" for="join-eligibility-1">
                        Private Challenge: Page is hidden from public and
                        password is required to see page
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>
                      Participant Utility Territory(s)*<br />
                      <small
                        >Choose a utility provider that your participants
                        have.</small
                      >
                    </label>

                    <div
                      class="form-check"
                      v-for="(
                        supportedUtility, supportedUtilityIndex
                      ) in supportedUtilities"
                      :key="`supportedUtility-${supportedUtilityIndex}`"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="utility-territory"
                        @change="
                          handleUtilitiesChange(
                            $event.target.checked,
                            supportedUtility.id
                          )
                        "
                        :checked="
                          challenge.pelm_utilities.includes(supportedUtility.id)
                        "
                      />
                      <label class="form-check-label" for="utility-territory-0">
                        {{
                          `${supportedUtility.name} (${supportedUtility.fullname})`
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label>
                      Participant Utility Rate*<br />
                      <small
                        >Indicate the utility rate that your participants
                        have.</small
                      >
                    </label>
                    <div class="form-check">
                      <input
                        id="utility-rate-0"
                        class="form-check-input"
                        type="radio"
                        name="utility-rate"
                        value="0"
                        checked
                      />
                      <label class="form-check-label" for="utility-rate-0">
                        Residential
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="utility-rate-1"
                        class="form-check-input"
                        type="radio"
                        name="utility-rate"
                        value="1"
                        disabled
                      />
                      <label class="form-check-label" for="utility-rate-1">
                        Commercial - Coming Soon!
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        id="utility-rate-2"
                        class="form-check-input"
                        type="radio"
                        name="utility-rate"
                        value="2"
                        disabled
                      />
                      <label class="form-check-label" for="utility-rate-2">
                        Both - Coming Soon!
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col aside">
                  <p>
                    At this time, MeterLeader does not support net metered
                    accounts. However we do hope to add these in the future.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label for="est-total-participants">
                      Estimated Total Participants*<br />
                      <small
                        >Give us an estimate for how many participants you hope
                        to have.</small
                      >
                    </label>
                    <select
                      id="est-total-participants"
                      v-model="challenge.est_total_participants"
                      name="est-total-participants"
                      class="custom-select"
                      required
                    >
                      <option value="0">1-9</option>
                      <option value="1">10-49</option>
                      <option value="2">50-99</option>
                      <option value="3">100-199</option>
                      <option value="4">200-299</option>
                      <option value="5">300-499</option>
                      <option value="6">500+</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 4: Participant Eligibility -->
          <!--region Section 5: Prizes  -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">5</span>
              </div>
              Prizes
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="prizes-description">
                        Reward Description* <br />
                        <small>
                          Describe what prizes you will give out, and how
                          winners will be selected. This will be the description
                          that appears in the Challenge Rules section of the
                          Challenge Page. <br />
                          EXAMPLE: The top energy saver will win a $100 Visa
                          gift card and will be featured on our blog.
                        </small>
                      </label>
                      <textarea
                        id="prizes-description"
                        v-model="challenge.description_prizes"
                        name="prizes-description"
                        rows="4"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col aside">
                  <p>
                    There are different ways to structure how rewards are given
                    out. You can offer: a collective reward for all participants
                    if the collective goal is achieved, an individual reward for
                    only the top energy savers, an individual reward for anyone
                    who achieves the individual goal, or an individual reward
                    that is lottery based (ie. lottery entry given for every 1%
                    of energy saved)
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 5: Prizes  -->
          <!--region Section 6: Organizer Information  -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">6</span>
              </div>
              Organizer Information
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <div class="form-group">
                    <label for="organizer-type">
                      Organizer Type*<br />
                      <small>What kind of organization are you?</small>
                    </label>
                    <select
                      id="organizer-type"
                      v-model="challenge.sponsor_type"
                      class="custom-select"
                      required
                    >
                      <option value="0">City</option>
                      <option value="1">Neighborhood</option>
                      <option value="2">School</option>
                      <option value="3">Company</option>
                      <option value="4">Professional Association</option>
                      <option value="5">Community Service Organization</option>
                      <option value="6">Church, Nonprofit Organization</option>
                      <option value="7">Property Management Group</option>
                      <option value="8">
                        Celebrity/Social Media Personality
                      </option>
                      <option value="9">Brand</option>
                      <option value="10">Other</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="organizer-name">
                      Organizer Name*<br />
                      <small
                        >What is the name of your organization? This is the name
                        that will be displayed as the Organizer on the Challenge
                        Page.</small
                      >
                    </label>
                    <input
                      id="organizer-name"
                      v-model="challenge.sponsor_name"
                      type="text"
                      name="organizer-name"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="organizer-banner">
                      Organizer Image*<br />
                      <small
                        >What Organizer image would you like displayed on the
                        Challenge page. We recommend using your organization's
                        logo.</small
                      >
                    </label>
                    <img
                      v-if="previewOrganizerImage"
                      :src="previewOrganizerImage"
                      class="uploading-image organizer-image-preview"
                    />

                    <div class="custom-file" style="margin-top: 10px">
                      <input
                        id="organizer-banner"
                        class="custom-file-input"
                        type="file"
                        name="organizer-banner"
                        accept="image/*"
                        :required="!previewOrganizerImage"
                        @change="uploadOrganizerImage"
                      />
                      <label class="custom-file-label" for="organizer-banner"
                        >Choose file</label
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>
                      Organizer Links*<br />
                      <small
                        >Choose up to 3 links (Website URL, Facebook, and
                        Twitter) to display in your organizer profile</small
                      >
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="url-0">Website</label>
                    <input
                      id="url-0"
                      v-model="challenge.sponsor_website"
                      type="text"
                      name="url-0"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="url-1">Facebook</label>
                    <input
                      id="url-1"
                      v-model="challenge.sponsor_facebook"
                      type="text"
                      name="url-1"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="url-2">Twitter</label>
                    <input
                      id="url-2"
                      v-model="challenge.sponsor_twitter"
                      type="text"
                      name="url-2"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--endregion Section 6 Organizer Information  -->
          <!--region Section 7: Challenge Organizer Agreement  -->
          <section>
            <h4 class="section-top">
              <div class="step-circle">
                <span class="step-number">7</span>
              </div>
              Challenge Organizer Agreement
            </h4>
            <div class="section-form container">
              <div class="row">
                <div class="col-9 col-left">
                  <label for="agreementCheck">
                    By creating and publishing a Challenge you are agreeing to
                    run the Challenge according to your approved design. If your
                    Challenge promises participants prizes you must deliver on
                    the rewards laid out in your Challenge Rules.
                  </label>
                  <div class="form-group form-check">
                    <input
                      id="agreementCheck"
                      type="checkbox"
                      class="form-check-input"
                      required
                    />
                    <label class="form-check-label" for="agreementCheck"
                      >I Agree</label
                    >
                    <div class="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="col-9 col-left" style="padding-top: 10px">
            <h1>Nice job! You're almost done.</h1>
          </div>
          <div id="bottom-banner">
            <button
              v-if="challenge.status === 0 || challenge.status === 2"
              class="clear btn"
              type="button"
              @click="save(true)"
            >
              Save
            </button>
            <button class="clear btn" type="button" @click="preview">
              Preview
            </button>
            <button
              v-if="challenge.status < 3 || $store.state.auth.user.admin"
              class="btn btn-outline-danger"
              type="button"
              data-toggle="modal"
              data-target="#delete-challenge-modal"
            >
              Delete
            </button>
            <button
              v-if="challenge.status === 0 || challenge.status === 2"
              type="button"
              class="solid btn publish"
              @click="submit"
            >
              Submit for Review
            </button>
            <button
              v-if="challenge.status === 1 && $store.state.auth.user.admin"
              type="button"
              class="btn btn-danger"
              @click="$repo.admin.returnChallenge(challenge.id)"
            >
              Reject
            </button>
            <button
              v-if="challenge.status === 1 && $store.state.auth.user.admin"
              type="button"
              class="solid btn publish"
              @click="$repo.admin.approveChallenge(challenge.id)"
            >
              Approve
            </button>
            <button
              v-if="challenge.status === 3"
              type="button"
              class="solid btn publish"
              @click="launch"
            >
              Launch
            </button>
            {{ challengeStatusMessage }}
            <p style="color: red; display: inline">{{ errorMessage }}</p>
            <p v-if="isSaving" style="display: inline">
              Saving
              <font-awesome-icon icon="spinner" spin />
            </p>
            <p class="save-succeeded" style="display: none">Saved</p>
          </div>
          <!--endregion Section 7: Challenge Organizer Agreement  -->
        </form>
      </div>
    </div>

    <DialogModal
      id="delete-challenge-modal"
      title="Delete Challenge?"
      :message="`Delete this challenge?`"
      @on-confirm="deleteChallenge"
    />
  </div>
</template>

<script>
import DialogModal from '@/components/DialogModal';
const moment = require('moment');

export default {
  name: 'Create',
  components: { DialogModal },
  middleware: ['auth', 'check_organization_admin'],
  async asyncData({ app, route }) {
    if (route.query.id) {
      try {
        const challenge = await app.$repo.challenges.get(
          parseInt(route.query.id),
          true
        );

        return {
          challenge: challenge.data,
          id: challenge.data.id,
          previewBannerImage: challenge.data.image_banner_url,
          previewOrganizerImage: challenge.data.image_creator_url,
          challengeStartDate: moment
            .utc(challenge.data.start_date)
            .format('YYYY-MM-DD'),
          // challengeStartTime: moment
          //   .utc(challenge.data.start_date)
          //   .format('HH:mm'),
          challengeEndDate: moment
            .utc(challenge.data.end_date)
            .format('YYYY-MM-DD'),
          // challengeEndTime: moment.utc(challenge.data.end_date).format('HH:mm'),
        };
      } catch (error) {
        console.log(error);
      }
    } else {
      return {
        challenge: {
          participant_group: 'No groups',
          display_groups: false,
          status: 0,
          id: null,
          name: null,
          image_banner_url: null,
          image_tile_url: null,
          image_creator_url: null,
          start_date: null,
          end_date: null,
          sponsor_name: null,
          sponsor_type: null,
          sponsor_website: null,
          sponsor_facebook: null,
          sponsor_twitter: null,
          savings_goal: null,
          description: null,
          description_prizes: null,
          description_eligibility: null,
          description_individual_goal: null,
          description_collective_goal: null,
          description_rules_url: null,
          max_participants: null,
          est_total_participants: null,
          metric: 3,
          pelm_utilities: [],
          is_private: false,
          password: null,
        },
      };
    }
  },
  data() {
    return {
      participantGroups: [],
      participantGroupName: '',
      supportedUtilities: [],
      errorMessage: '',
      isSubmitted: false,
      isSaving: false,
      isFormValid: false,
      previewBannerImage: null,
      previewOrganizerImage: null,
      bannerImageFile: null,
      organizerImageFile: null,
      id: null,
      challengeStartDate: null,
      // challengeStartTime: '00:00',
      challengeEndDate: null,
      // challengeEndTime: '23:59',
      challenge: {
        status: 0,
        id: null,
        name: null,
        image_banner_url: null,
        image_tile_url: null,
        image_creator_url: null,
        start_date: null,
        end_date: null,
        sponsor_name: null,
        sponsor_type: null,
        sponsor_website: null,
        sponsor_facebook: null,
        sponsor_twitter: null,
        savings_goal: 0,
        description: null,
        description_prizes: null,
        description_eligibility: null,
        description_individual_goal: null,
        description_collective_goal: null,
        description_rules_url: null,
        max_participants: null,
        est_total_participants: null,
        metric: 3,
        pelm_utilities: [],
        is_private: false,
        password: null,
      },
    };
  },
  computed: {
    challengeStatusMessage() {
      if (this.challenge.status === 1) {
        return 'Challenge has been submitted for review. Email us if you would like to make changes.';
      } else if (this.challenge.status === 2) {
        return 'Changes to this challenge have been requested. We have sent you an email with instructions.';
      } else if (this.challenge.status === 4) {
        return 'Challenge has launched. Contact us to make changes.';
      } else if (this.challenge.status === 5) {
        return 'Challenge has started. Contact us to make changes.';
      }
      return '';
    },
    collectiveKwhError() {
      if (
        isNaN(this.challenge.savings_goal) ||
        parseInt(this.challenge.savings_goal) <= 0
      ) {
        return 'Please enter a number greater than zero';
      }
    },
    durationError() {
      if (
        moment(this.challengeStartDate).isAfter(
          moment(`${this.challengeEndDate} 23:59`)
        )
      ) {
        return 'End date must be after Start date';
      }
    },
  },
  async mounted() {
    await this.getUtilities();
    await this.getParticipantGroups();
    if (process.client) {
      this.saveSucceeded = $('.save-succeeded');
      const bsCustomFileInput = require('bs-custom-file-input');
      $(document).ready(function () {
        bsCustomFileInput.init();
      });
    }

    console.log('date from server', this.challengeStartDate);
  },
  methods: {
    changeChallengeType(event) {
      if (event == 'publicVisible') {
        this.challenge.is_private = false;
        this.challenge.visibility = 0;
      }

      if (event == 'publicHidden') {
        this.challenge.is_private = false;
        this.challenge.visibility = -1;
      }
      if (event == 'privateVisible') {
        this.challenge.is_private = true;
        this.challenge.visibility = 0;
      }
    },
    async getParticipantGroups() {
      if (this.id) {
        let res = await this.$api.get(`/participant-groups/${this.id}/list`);
        this.participantGroups = res.data;
      }
    },
    async addParticipantGroup() {
      if (!this.id) {
        const { data } = await this.$api.post('/challenges/');
        this.id = data.id;
        console.log('id', this.id);
        this.$router.replace(`/challenges/create?id=${this.id}`);
      }

      const copy = Object.assign(
        {},
        {
          participant_group: this.challenge.participant_group,
          display_groups: this.challenge.display_groups,
        }
      );

      const r = await this.$repo.challenges.update(this.id, copy);

      if (this.participantGroupName) {
        let res = await this.$api.post(
          `/participant-groups/${this.id}/create`,
          {
            name: this.participantGroupName,
          }
        );

        this.participantGroups.push(res.data);
        this.participantGroupName = '';
      }
    },
    async deleteParticipantGroup(participantGroupId, index) {
      let res = await this.$api.delete(
        `participant-groups/${this.id}/${participantGroupId}`
      );

      this.participantGroups.splice(index, 1);
    },
    async getUtilities() {
      const res = await this.$api.$get(
        'admind/utilities?items_per_page=100&page=1'
      );
      this.supportedUtilities = res.results;
    },
    async handleUtilitiesChange(value, utiltyId) {
      console.log(value);
      if (value) {
        this.challenge.pelm_utilities.push(utiltyId);
      } else {
        const index = this.challenge.pelm_utilities.indexOf(utiltyId);
        if (index >= 0) {
          this.challenge.pelm_utilities.splice(index, 1);
        }
      }
    },
    async deleteChallenge() {
      try {
        await this.$repo.challenges.delete(this.challenge.id);
        localStorage.setItem('section', 'sponsoredChallenges');
        this.$router.replace('/challenges');
      } catch {
        this.errorMessage = "You can't delete this challenge";
      }
    },

    async save(isPreview = true) {
      this.isSaving = true;
      this.saveSucceeded.css('display', 'none');

      this.errorMessage = '';
      this.challenge.start_date = moment(this.challengeStartDate)
        .utc()
        .format('YYYY-MM-DD HH:mm');
      this.challenge.end_date = moment(`${this.challengeEndDate} 23:59`)
        .utc()
        .format('YYYY-MM-DD HH:mm');

      this.challenge.is_preview = isPreview;
      if (isNaN(this.challenge.savings_goal) || !this.challenge.savings_goal) {
        this.challenge.savings_goal = 0;
      }

      const copy = Object.assign({}, this.challenge);

      if (isPreview) {
        for (const key of Object.keys(this.challenge)) {
          if (!this.challenge[key] || this.challenge[key] === 'Invalid date') {
            delete copy[key];
          }
        }
      }

      // const data = {
      //   // Section 1: Page Design
      //   name: $('#challenge-name').val(),
      //   description: $('#competition-description').val(),
      //
      //   // Section 2: Challenge Structure
      //   challenge_type: $("input[name='challenge-type']:checked").val(),
      //   challenge_metric: $("input[name='challenge-metric']:checked").val(),
      //   // start_date: $("#start-date").val() + "T" + $("#start-time").val(), // TODO: convert to UTC?
      //   // live_date: $("#live-date").val() + "T" + $("#live-time").val(),
      //
      //   // Section 3: Challenge Goals
      //   description_individual_goal: $('#individual-goal-description').val(),
      //   description_collective_goal: $('#collective-description').val(),
      //
      //   // Section 4: Participant Eligibility
      //   join_eligibility: $("input[name='join-eligibility']:checked").val(),
      //   pelm_utility_id: $("input[name='utility-rate']:checked").val(),
      //   utility_rate: $("input[name='utility-rate']:checked").val(),
      //   participant_house_type: $('#participant-house-type').val(),
      //   est_total_participants: $('#est-total-participants').val(),
      //
      //   // Section 5: Prizes
      //   description_prizes: $('#prizes-description').val(),
      //
      //   // Section 6: Organizer Information
      //   organizer_type: $('#organizer-type').val(),
      //   organizer_name: $('#organizer-name').val(),
      //   sponsor_website: $('#url-0').val(),
      //   sponsor_facebook: $('#url-1').val(),
      //   sponsor_twitter: $('#url-2').val(),
      // };

      try {
        if (!this.id) {
          const { data } = await this.$api.post('/challenges/');
          this.id = data.id;
          console.log('id', this.id);
          this.$router.replace(`/challenges/create?id=${this.id}`);
        }

        const r = await this.$repo.challenges.update(this.id, copy);
        console.log('challenge update', r);
        this.challenge = r.data;

        try {
          const formData = new FormData();
          formData.append('banner', this.bannerImageFile);
          formData.append('creator', this.organizerImageFile);
          const r2 = await this.$repo.challenges.uploadImage(this.id, formData);
          console.log('challenge image', r2);
        } catch (error) {
          console.log('image upload error', error);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.log('error response', error.response);
          this.errorMessage = error.response.data.msg;
          this.isSaving = false;
          return false;
        }
      }

      setTimeout(() => {
        this.isSaving = false;
        this.saveSucceeded.css('display', 'inline');
        this.saveSucceeded.fadeOut(2000);
      }, 1000);

      return true;
    },

    checkForm() {
      this.errorMessage = '';
      // console.log(Object.keys(this.challenge))
      // e.preventDefault();

      // this.isFormValid = true;
      // return document.forms.challengeForm.reportValidity();
      const form = document.getElementById('challengeForm');
      const isFormValid = form.reportValidity();
      form.classList.add('was-validated');
      return isFormValid;

      const requiredFields = [
        'name',
        'image_banner_url',
        'image_tile_url',
        'image_creator_url',
        'start_date',
        'end_date',
        'sponsor_name',
        'sponsor_website',
        'sponsor_facebook',
        'sponsor_twitter',
        'savings_goal',
        'description',
        'description_prizes',
        'description_eligibility',
        'description_individual_goal',
        'description_collective_goal',
        'description_rules_url',
        'max_participants',
      ];
    },

    uploadBannerImage(e) {
      this.bannerImageFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.bannerImageFile);
      reader.onload = (e) => {
        this.previewBannerImage = e.target.result;
      };
    },

    uploadOrganizerImage(e) {
      this.organizerImageFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.organizerImageFile);
      reader.onload = (e) => {
        this.previewOrganizerImage = e.target.result;
      };
    },

    async preview() {
      const didSaveSucceed = await this.save();
      if (!didSaveSucceed) {
      } else {
        // this.$router.push(`/challenges/${this.id}?preview`)
        window.open(`/challenges/${this.id}?preview`);
      }
    },

    async launch() {
      const r = await this.$repo.challenges.launch(this.id);
      this.challenge.status = 4;
    },

    async submit() {
      if (!this.checkForm()) {
        this.errorMessage = 'Please fill out the required fields';
        return;
      }

      if (this.collectiveKwhError) {
        $('html, body').animate(
          {
            scrollTop: $('#kwh-goal').offset().top - 250,
          },
          1000
        );
        return;
      }

      try {
        const saveSucceeded = await this.save(false);
        if (!saveSucceeded) {
          return;
        }

        const r = await this.$repo.challenges.submitForReview(this.id);
        this.isSubmitted = true;
        $(document).scrollTop(0);
      } catch (error) {
        console.log('error', error.response);
      }
    },
  },
  head() {
    return {
      title: 'Create Challenge | MeterLeader',
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/challengeForm';

.submitted {
  background-color: $grey;
  .container {
    max-width: 650px;
    text-align: center;

    h5 {
      margin-top: 50px;
    }
  }
}

.form-group {
  padding-top: 15px;
}

.banner-image-preview {
  height: 400px;
  width: 100%;
}

.organizer-image-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0;
}
</style>
