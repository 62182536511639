<template>
  <div :style="{ position: 'relative', height: `${chartHeight}px` }">
    <canvas ref="myUsageBarChart"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

export default {
  props: {
    totalSamplesCount: {
      type: Number,
      default: 1,
    },
    dataSet: {
      type: Object,
      default: {
        labels: [],
        datasets: [
          {
            label: 'N/A',
            data: [],
            backgroundColor: '#C200FB',
            borderColor: '#C200FB',
            borderWidth: 1,
          },
        ],
      },
    },
  },
  chart: null,
  data() {
    return {
      currentBreakpoint: 'init',
    };
  },
  watch: {
    currentBreakpoint(newVal) {
      if (newVal == 'sm') {
        // this.$options.chart.data.labels = this.$options.chart.data.labels.map(
        //   (o) => o[0]
        // );
        // this.$options.chart.update();
      }
    },
    dataSet: {
      handler: function (newVal) {
        this.$options.chart.data.labels = newVal.labels;
        this.$options.chart.data.datasets = newVal.datasets;
        this.$options.chart.update();
      },
    },
  },
  computed: {
    chartHeight() {
      const mapping = {
        init: 400,
        xs: 300,
        sm: 440,
        md: 500,
        lg: 400,
      };
      return mapping[this.currentBreakpoint];
    },
  },
  mounted() {
    Chart.register(...registerables, annotationPlugin);
    const setCurrentBreakpoint = () => {
      if (0 < window.innerWidth && window.innerWidth < 576) {
        this.currentBreakpoint = 'xs';
      } else if (576 <= window.innerWidth && window.innerWidth < 768) {
        this.currentBreakpoint = 'sm';
      } else if (768 <= window.innerWidth && window.innerWidth < 992) {
        this.currentBreakpoint = 'md';
      } else if (992 <= window.innerWidth && window.innerWidth < 1200) {
        this.currentBreakpoint = 'lg';
      } else if (1200 <= window.innerWidth && window.innerWidth < 1400) {
        this.currentBreakpoint = 'xl';
      } else {
        this.currentBreakpoint = 'xxl';
      }
    };

    window.addEventListener('resize', setCurrentBreakpoint);
    const data = {
      labels: this.dataSet.labels,
      datasets: this.dataSet.datasets,
    };
    const annotation = {
      type: 'line',
      borderColor: 'black',
      borderDash: [6, 6],
      borderDashOffset: 0,
      borderWidth: 3,
      scaleID: 'yAxes',
      value: (ctx) => average(ctx),
    };

    const label1 = {
      type: 'label',
      xValue: (ctx) => ctx.chart.data.datasets[0].data.length / 2,
      yValue: (ctx) => average(ctx),
      backgroundColor: 'black',
      color: 'white',
      content: (ctx) => [`Average ${average(ctx).toFixed(2)}`],
      font: {
        size: 11,
      },
    };

    let average = (ctx) => {
      const values = ctx.chart.data.datasets[0].data;

      const avg =
        values.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
        this.totalSamplesCount;

      return avg;
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: {
            display: true,
            position: 'right',
            ticks: {
              beginAtZero: true,
            },
            grid: {
              display: true,
            },
          },

          xAxes: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
          annotation: {
            annotations: {
              annotation,
              label1,
            },
          },
        },
      },
    };
    this.$options.chart = new Chart(this.$refs.myUsageBarChart, config);
    setCurrentBreakpoint();
  },
};
</script>