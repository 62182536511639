<template>
  <div>
    <NuxtLink :to="`/admind/pelm_subscriptions/${slotParams.item.id}`">{{
      slotParams.value
    }}</NuxtLink>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
};
</script>