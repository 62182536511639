<template>
  <div>
    <v-row align justify>
      <v-col col="8">
        <v-tabs grow class="mb-3" v-model="taskStage">
          <v-tab>All</v-tab>
          <v-tab>Active</v-tab>
          <v-tab>Reserved</v-tab>
          <v-tab>Scheduled</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="d-flex align-center mb-3">
        <v-btn color="primary" @click="refresh = !refresh">Refresh</v-btn>
      </v-col>
    </v-row>
    <BackgroundTaskList
      :taskStage="taskStage"
      :refresh="refresh"
    ></BackgroundTaskList>
  </div>
</template>
<script>
import BackgroundTaskList from '@/components/admind/BackgroundTaskList.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    return {
      refresh: true,
      taskStage: 0,
    };
  },
  components: {
    BackgroundTaskList,
  },
};
</script>