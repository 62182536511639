var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('MobileLanding',{staticClass:"position-fixed mobile-landing d-block d-md-none"}),_vm._v(" "),_c('div',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"mbanner"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start justify-content-center px-4 pl-md-5 mt-5 mt-md-0"},[_vm._m(0),_vm._v(" "),_c('h5',{staticClass:"py-3 text-center text-md-left"},[_vm._v("\n              Track your home’s electric, gas, and carbon emissions - in one convenient place.\n")]),_vm._v(" "),_c('button',{staticClass:"cbtn py-3 font-weight-bold",on:{"click":function($event){return _vm.$router.push('/signup')}}},[_vm._v("\n              GET STARTED FREE\n            ")]),_vm._v(" "),_c('img',{staticClass:"mt-3 download_link",attrs:{"src":require("assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg")},on:{"click":_vm.downloadLink}})]),_vm._v(" "),_vm._m(1)])]),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',{staticClass:"bottom-banner d-flex align-items-center"},[_c('h2',{staticClass:"text-white font-weight-bold"},[_vm._v("\n          Save Energy And Combat Climate Change!\n        ")]),_vm._v(" "),_c('div',{style:({ flexGrow: 1 })}),_vm._v(" "),_c('button',{staticClass:"cbtn py-3 font-weight-bold",on:{"click":function($event){return _vm.$router.push('/signup')}}},[_vm._v("\n          GET STARTED FREE\n        ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-white font-weight-bold text-center text-md-left"},[_vm._v("\n              Save Energy ⚡ "),_c('br'),_vm._v("Fight Climate Change\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6"},[_c('img',{staticClass:"banner-image",attrs:{"src":require("assets/images/Banner_MeterLeader.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msponsor"},[_c('div',{staticClass:"featured-in d-flex flex-column flex-md-row justify-content-center align-items-center pb-4"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('a',{staticClass:"pr-md-4",attrs:{"href":"https://www.softeq.com/news/softeq-venture-studio-welcomes-new-cohort-of-14-startups-bringing-total-venture-fund-portfolio-of-startups-to-63"}},[_c('img',{staticClass:"featured-img",staticStyle:{"max-height":"100px","object-fit":"contain"},attrs:{"src":require("assets/images/Softeq_Venture_Studio_Logo.jpg")}})]),_vm._v(" "),_c('a',{staticClass:"pr-md-4",attrs:{"href":"https://laincubator.org/laci-welcomes-10-promising-cleantech-startups-for-its-fall-2021-incubation-cohort/"}},[_c('img',{staticClass:"featured-img",staticStyle:{"max-height":"100px","object-fit":"contain"},attrs:{"src":require("assets/images/laciLogo.png")}})]),_vm._v(" "),_c('a',{staticClass:"pr-md-4",attrs:{"href":"https://www.techstars.com/newsroom/announcing-the-stanley-techstars-2022-class"}},[_c('img',{staticClass:"featured-img",staticStyle:{"max-height":"100px"},attrs:{"src":require("assets/images/stanley-techstars-accelerator.png")}})]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.netzeroaccelerator.org/nza-22"}},[_c('img',{staticClass:"featured-img",staticStyle:{"max-height":"100px"},attrs:{"src":require("assets/images/net_zero.png")}})])])]),_vm._v(" "),_c('h1',{staticClass:"d-none d-md-block text-center font-weight-bold pt-3 pb-4"},[_vm._v("\n          Electricity and Heating Are Responsible For 1/3 Of "),_c('br'),_vm._v("\n          Global Carbon Emissions. We Can Change That!\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works"},[_c('h1',{staticClass:"text-center font-weight-bold"},[_vm._v("How It Works")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',[_c('h2',[_vm._v("⚡")]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Sync Data")]),_vm._v(" "),_c('p',[_vm._v("\n              Sync your electricity and natural gas meter data automatically\n              from your online utility account.\n            ")])]),_vm._v(" "),_c('div',[_c('h2',[_vm._v("🏆")]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Join Challenge")]),_vm._v(" "),_c('p',[_vm._v("\n              Engage in a fun competition to see who is the biggest energy\n              saver.\n            ")])]),_vm._v(" "),_c('div',[_c('h2',[_vm._v("🤩")]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Save Energy")]),_vm._v(" "),_c('p',[_vm._v("\n              Energy tips help achieve measureable savings you can actually see\n              via the real-time usage graph.\n            ")])]),_vm._v(" "),_c('div',[_c('h2',[_vm._v("⭐")]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Win Prizes")]),_vm._v(" "),_c('p',[_vm._v("\n              Households that save the most, win the most. Challenge winners\n              qualify for cool prizes.\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"text-center font-weight-bold py-2"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Learn More")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-meterleader"},[_c('h1',{staticClass:"text-center font-weight-bold"},[_vm._v("Why Use Meterleader")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-center"},[_c('div',{staticClass:"text-center mx-3"},[_c('h3',[_vm._v("✅")]),_vm._v(" "),_c('p',[_vm._v("Less energy usage = savings on your utility bills!")])]),_vm._v(" "),_c('div',{staticClass:"text-center mx-3"},[_c('h3',[_vm._v("✅")]),_vm._v(" "),_c('p',[_vm._v("Make positive environmental impacts that are close to home")])]),_vm._v(" "),_c('div',{staticClass:"text-center mx-3"},[_c('h3',[_vm._v("✅")]),_vm._v(" "),_c('p',[_vm._v("\n              Have Fun Participating In Our Community Driven Energy Saving\n              Challenges\n            ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-save-energy"},[_c('h1',{staticClass:"text-center font-weight-bold"},[_vm._v("Why Save Energy")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-center"},[_c('div',{staticClass:"text-md-center mx-3"},[_c('h3',[_vm._v("💸")]),_vm._v(" "),_c('h1',{staticClass:"text-p"},[_vm._v("$2060")]),_vm._v(" "),_c('p',{staticClass:"text-p"},[_vm._v("/Year")]),_vm._v(" "),_c('p',[_vm._v("\n              You can save upwards of 30% on your utility bills by adopting\n              energy saving habits and making affordable energy upgrades in your\n              home.\n            ")])]),_vm._v(" "),_c('div',{staticClass:"text-md-center mx-3"},[_c('h3',[_vm._v("🤒")]),_vm._v(" "),_c('h1',{staticClass:"text-p"},[_vm._v("230,000")]),_vm._v(" "),_c('p',{staticClass:"text-p"},[_vm._v("deaths/year")]),_vm._v(" "),_c('p',[_vm._v("\n              Reducing your electricity usage can help combat negative effects\n              of air pollution from power plants that contribute to respiratory\n              diseases.\n            ")])]),_vm._v(" "),_c('div',{staticClass:"text-md-center mx-3"},[_c('h3',[_vm._v("☁️")]),_vm._v(" "),_c('h1',{staticClass:"text-p"},[_vm._v("16.49")]),_vm._v(" "),_c('p',{staticClass:"text-p"},[_vm._v("metric tons CO2")]),_vm._v(" "),_c('p',[_vm._v("\n              Combat climate change by taking small tangible steps to reduce\n              your carbon footprint.\n            ")])])])])
}]

export { render, staticRenderFns }