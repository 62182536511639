<template>
  <SyncData :subscriptionIdProp="$route.params.subscriptionId" />
</template>
<script>
import SyncData from '~/components/SyncPelm.vue';

export default {
  middleware: 'check_pelm_cap',
  components: { SyncData },
  data() {},
};
</script>