import { render, staticRenderFns } from "./UtilityProvidersName.vue?vue&type=template&id=b4937048&"
import script from "./UtilityProvidersName.vue?vue&type=script&lang=js&"
export * from "./UtilityProvidersName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports