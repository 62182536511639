<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      multi-sort
      class="elevation-1"
      :server-items-length="serverItemsLength"
      :loading="loading"
    >
      <template
        v-for="slotName in Object.keys(dataTableSlotComponents)"
        v-slot:[slotName]="slotScope"
      >
        <component
          v-bind:is="dataTableSlotComponents[slotName]"
          :key="slotName"
          :slotParams="slotScope"
        ></component>
      </template>
    </v-data-table>
  </div>
</template>
<script>
/**
 * Generic Admin list based on admin list api.
 * @displayName Generic Admin List component
 */

export default {
  props: {
    /**
     * Endpoint url. Please refer admin list api.
     */
    endpoint: {
      type: String,
      required: true,
    },
    /**
     * An array of objects that each describe a header column.
     * See the example below for a definition of all properties
     * {
     *    text: string,
     *    value: string,
     *    align?: 'start' | 'center' | 'end',
     *    sortable?: boolean,
     *    filterable?: boolean,
     *    groupable?: boolean,
     *    divider?: boolean,
     *    class?: string | string[],
     *    cellClass?: string | string[],
     *    width?: string | number,
     *    filter?: (value: any, search: string, item: any) => boolean,
     *    sort?: (a: any, b: any) => number
     * }
     */
    headers: {
      type: Array,
      required: true,
    },
    /**
     * Filter fields from filter box component.
     */
    filterParams: {
      type: Object,
      required: true,
    },
    dataTableSlotComponents: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      loading: false,
      serverItemsLength: -1,
      page: 1,
      itemsPerPage: 15,
      sortBy: [],
      sortDesc: [],
      results: [],
    };
  },
  computed: {
    params() {
      let sort_fields = this.sortBy
        .map((field, index) => {
          if (this.sortDesc[index]) {
            return `-${field}`;
          }
          return field;
        })
        .join();
      let params = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        ...this.filterParams,
      };

      if (sort_fields) {
        params['sort_fields'] = sort_fields;
      }
      if (process.browser) {
        this.updateUrlParams(params);
      }
      return params;
    },
  },
  watch: {
    params: {
      handler: function () {
        this.fetchData();
      },
      deep: true,
    },
    filterParams: {
      handler: function (newVal) {
        this.page = 1;
        console.log(newVal);
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    updateUrlParams(params) {
      let queryString = '';
      for (let key in params) {
        if (['string', 'number', 'boolean'].includes(typeof params[key])) {
          if (queryString.startsWith('?')) {
            queryString = `${queryString}&${key}=${params[key]}`;
          } else {
            queryString = `?${key}=${params[key]}`;
          }
        }
        if (Array.isArray(params[key])) {
          if (
            params[key].every((i) => ['string', 'number'].includes(typeof i))
          ) {
            queryString = `${queryString}&${key}=${params[
              key
            ].toLocaleString()}`;
          }
        }
      }
      history.pushState({}, null, this.$route.path + queryString);
    },
    async fetchData() {
      this.loading = true;
      const res = await this.$api.$get(this.endpoint, {
        params: this.params,
      });
      this.loading = false;
      this.serverItemsLength = res.count;
      this.results = res.results;
    },
  },
};
</script>