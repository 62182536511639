<template>
  <div>
    <div>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-card v-if="Object.keys(data).length > 0">
            <v-card-title>{{ `${data.address}` }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>Utility Provider</v-col>
                <v-col>{{ data.pelm_utility_name }}</v-col>
              </v-row>
              <v-row>
                <v-col>Available Meter Types</v-col>
                <v-col>{{ data.available_meter_types.toString() }}</v-col>
              </v-row>
              <v-row>
                <v-col>Account Id</v-col>
                <v-col>{{ data.account_id }}</v-col>
              </v-row>
              <v-row>
                <v-col>Account Number</v-col>
                <v-col>{{ data.account_number }}</v-col>
              </v-row>
              <v-row>
                <v-col>Pelm User Id</v-col>
                <v-col>{{ data.pelm_user_id }}</v-col>
              </v-row>
              <v-row>
                <v-col>User</v-col>
                <v-col>{{ data.user_fullname }}</v-col>
              </v-row>
              <v-row>
                <v-col>Electric Usage Unit</v-col>
                <v-col>{{ data.electric_usage_unit || '-' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Gas Usage Unit</v-col>
                <v-col>{{ data.gas_usage_unit | '-' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Ghg Emission Unit</v-col>
                <v-col>{{ data.ghg_emissions_unit }}</v-col>
              </v-row>
              <v-row>
                <v-col>Last Sync Date Electric</v-col>
                <v-col>{{ data.last_sync_date_electric || '-' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Last Sync Date Gas</v-col>
                <v-col>{{ data.last_sync_date_gas || '-' }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/pelm-energy-accounts/${this.$route.params.energyAccountId}`
      );
      this.data = res.data;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>