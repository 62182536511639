<template>
  <div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md" style="text-align: left">
            <h1>
              This Earth Day, choose to be part of the solution.
            </h1>
            <p>Join the Earth Day Home Energy Challenge starting April 22, 2020, and start making REAL carbon emission reductions.</p>
            <nuxt-link class="btn-purple" to="/challenges/15/earth-day-home-energy-challenge">
              JOIN THE CHALLENGE
            </nuxt-link>
          </div>
          <div class="col-md img-right">
            <img
              src="~/assets/images/earthday1.png"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid background-grey">
        <div class="container" style="padding-top: 40px; padding-bottom: 40px">
          <div class="row">
            <div class="col-md">
              <h2>The fight against climate change starts with us.</h2>
              <p>
                Climate change is an existential threat to all of humanity that requires ALL of us to do our part. When our communities come together and motivate each other, we can achieve amazing impacts with our loved ones and neighbors.
              </p>
            </div>
            <div class="col-md font-italic align-self-center blockquote">
              <h2 style="font-size: 1.6rem;">
                “I am telling you there is hope. I have seen it. But it does not
                come from governments or corporations. It comes from the
                people...We do not have to wait. We can start the change right
                now. We the people.”
              </h2>
              <br />
              <h3 style="font-size: 1.45rem;">
                Greta Thunberg, Climate Change Activist
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px;">
        <div class="row">
          <div class="col-md img-left">
            <img
              src="~/assets/images/SampleMeterLeaderChallenge.webp"
            />
          </div>
          <div class="col-md">
            <h1>
              Fighting climate change from the comfort of home.
            </h1>
            <p>Knowing is half the battle, so MeterLeader helps you monitor your home carbon emissions by integrating real-time data from your utility provider. By knowing your usage, you can make a REAL impact without having to leave the house.</p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="container" style="margin-top: 25px;">
          <div class="row">
            <div class="col-md">
              <h1>
                Energy saving tips delivered weekly.
              </h1>
              <p>
                We’ll help you save energy and money by delivering energy saving activities to your inbox each week. You’ll be on your way to saving money, reducing carbon emissions, and saving the world.
              </p>
            </div>
            <div class="col-md">
              <img
                src="~/assets/images/activity5.webp"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px;">
        <div class="row">
          <div class="col-md img-left">
            <img
              src="~/assets/images/ecogroups-4.webp"
            />
          </div>
          <div class="col-md">
            <h1>
              Teamwork Empowers Change.
            </h1>
            <p>Get everyone in your community involved and watch your collective impact grow. Have a competitive streak? You can also see how your energy saving efforts rank against each other! Lead your community in shaping a greener future.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div id="start-challenge" class="container-fluid background-green">
        <div class="container align-content-center">
          <h1>Join the Earth Day Challenge today!</h1>
          <nuxt-link class="btn-white" to="/challenges/15/earth-day-home-energy-challenge">
            JOIN THE CHALLENGE
          </nuxt-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import socialImage from "@/assets/images/earthday1.png";

export default {
  name: 'ForCompanies',
  head() {
    return {
      title: 'For Environmental Groups | MeterLeader',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Join the Earth Day Home Energy Challenge starting April 22, 2020, and start making REAL carbon emission reductions.',
        },
        {
          hid: 'og:image',
          name: 'image',
          property: 'og:image',
          content: `https://www.meterleader.com${socialImage}`,
        },
      ]

    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/companies';
</style>
