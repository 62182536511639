<template>
  <div id="challenges-page">
    <OnboardingReminder v-if="$auth.loggedIn" />
    <div class="container d-none d-md-block">
      <h2 class="font-weight-bold">{{ $titleBar.data.title }}</h2>
    </div>
    <div id="top" class="container d-none d-md-block rounded">
      <p class="rounded">
        Here's your dashboard for viewing the challenges you are participating
        in, for exploring upcoming challenges, and for managing the challenges
        you create. Join a current or upcoming challenge and then invite your
        friends to join! Keep up the great work.
      </p>
    </div>

    <div class="container">
      <div class="d-flex ctabs">
        <div
          class="ctab-item"
          :class="{ selected: challengeSelection == 'myChallenges' }"
          @click="challengeSelection = 'myChallenges'"
        >
          My Challenges
        </div>
        <div
          class="ctab-item"
          :class="{
            selected: challengeSelection == 'exploreChallenges',
          }"
          @click="challengeSelection = 'exploreChallenges'"
        >
          Explore
        </div>
        <div
          class="ctab-item"
          :class="{
            selected: challengeSelection == 'sponsoredChallenges',
          }"
          @click="challengeSelection = 'sponsoredChallenges'"
        >
          Sponsored
        </div>
      </div>
    </div>

    <div
      v-if="challengeSelection === 'sponsoredChallenges'"
      id="challenges-time-filter"
      class="container noselect"
    >
      <div class="d-flex justify-content-between">
        <div>
          <p
            :class="activeSponsoredFilter(0)"
            @click="activeSponsoredSelection = 0"
          >
            Draft
          </p>
        </div>
        <div>
          <p
            :class="activeSponsoredFilter(1)"
            @click="activeSponsoredSelection = 1"
          >
            Submitted
          </p>
        </div>
        <div>
          <p
            :class="activeSponsoredFilter(3)"
            @click="activeSponsoredSelection = 3"
          >
            Approved
          </p>
        </div>
        <div>
          <p
            :class="activeSponsoredFilter(4)"
            @click="activeSponsoredSelection = 4"
          >
            Live
          </p>
        </div>
        <div>
          <p
            :class="activeSponsoredFilter(6)"
            @click="activeSponsoredSelection = 6"
          >
            Past
          </p>
        </div>
      </div>
    </div>
    <div v-else id="challenges-time-filter" class="container noselect">
      <div class="d-flex justify-content-center">
        <div class="m-1">
          <p :class="activeFilter(1)" @click="activeSelection = 1">Current</p>
        </div>
        <div class="m-1">
          <p :class="activeFilter(2)" @click="activeSelection = 2">Upcoming</p>
        </div>
        <div class="m-1">
          <p :class="activeFilter(0)" @click="activeSelection = 0">Past</p>
        </div>
      </div>
    </div>

    <div id="challenges-content" class="container row justify-content-start">
      <Challenge
        v-for="challenge in challenges"
        :key="'challenge' + challengeSelection + challenge.id"
        :challenge="challenge"
        :is-user-challenge="challengeSelection === 'sponsoredChallenges'"
        class="rounded"
      />
      <div v-if="activeSelection == 1 && !challenges.length">
        Currently there are no live challenges. Please check back again soon.
      </div>
      <div v-if="activeSelection == 2 && !challenges.length">
        Currently there are no upcoming challenges. Please check back again
        soon.
      </div>
    </div>
  </div>
</template>

  <script>
import OnboardingReminder from '@/components/OnboardingReminder';
import Challenge from '@/components/Challenge';
import CreateChallengeButton from '@/components/CreateChallengeButton.vue';
import { actionTypes } from '@/store/types';

export default {
  name: 'Index',
  pageTitle: 'Challenges',
  rightComponent: CreateChallengeButton,
  middleware: ['auth'],
  components: { Challenge, OnboardingReminder },
  async asyncData({ store, app }) {
    const [_0, myChallenges] = await Promise.all([
      store.dispatch(actionTypes.GET_CHALLENGES_CORPORATE),
      // store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
      app.$repo.challenges.getMyChallenges(),
    ]);

    let challengeSelection = null;
    let activeSelection = null;

    if (store.state.challengesCorporate[2].length) {
      challengeSelection = 'exploreChallenges';
      activeSelection = 2;
    } else if (store.state.challengesCorporate[1].length) {
      challengeSelection = 'myChallenges';
      activeSelection = 1;
    }

    if (challengeSelection == null) {
      challengeSelection = 'myChallenges';
      activeSelection = 1;
    }

    let min = 999;
    let activeSponsoredSelection;
    for (const challenge of myChallenges.data[3]) {
      min = Math.min(min, challenge.status);
    }

    if (min === 999) {
      activeSponsoredSelection = 0;
    } else {
      if (min === 0 || min === 2) {
        activeSponsoredSelection = 0;
      } else if (min === 4 || min === 5) {
        activeSponsoredSelection = 4;
      } else {
        activeSponsoredSelection = min;
      }
    }

    return {
      myChallenges: myChallenges.data,
      challengeSelection: challengeSelection,
      activeSelection: activeSelection,
      activeSponsoredSelection: activeSponsoredSelection,
    };
  },
  computed: {
    challenges() {
      if (process.client) {
        this.$nextTick(function () {
          $('.challenge').hover(
            function () {
              $(this).find('.overlay').css('display', 'block');
            },
            function () {
              $(this).find('.overlay').css('display', 'none');
            }
          );
        });
      }

      if (this.challengeSelection === 'myChallenges') {
        if (this.lastSection !== 'myChallenges') {
          this.lastSection = 'myChallenges';
          this.activeSelection = this.getChallengeSelection(this.myChallenges);
        }
        return this.myChallenges[this.activeSelection];
      } else if (this.challengeSelection === 'exploreChallenges') {
        if (this.lastSection !== 'exploreChallenges') {
          this.lastSection = 'exploreChallenges';
          this.activeSelection = this.getChallengeSelection(
            this.$store.state.challengesCorporate
          );
        }
        return this.$store.state.challengesCorporate[this.activeSelection];
      } else if (this.challengeSelection === 'sponsoredChallenges') {
        if (this.lastSection !== 'sponsoredChallenges') {
          this.lastSection = 'sponsoredChallenges';
          this.activeSponsoredSelection = this.getSponsoredChallengeSelection();
        }

        if (this.activeSponsoredSelection === 0) {
          return this.myChallenges[3].filter(
            (challenge) => challenge.status === 0 || challenge.status === 2
          );
        }

        if (this.activeSponsoredSelection === 4) {
          return this.myChallenges[3].filter(
            (challenge) => challenge.status === 4 || challenge.status === 5
          );
        }

        return this.myChallenges[3].filter(
          (challenge) => challenge.status === this.activeSponsoredSelection
        );
      }

      return [];
    },
  },
  mounted() {
    this.lastSection = null;

    try {
      const section = localStorage.getItem('section');
      if (section) {
        this.challengeSelection = section;
      }
      localStorage.removeItem('section');
    } catch (e) {}
  },
  methods: {
    getChallengeSelection(challenges) {
      if (challenges[1].length) {
        return 1;
      } else if (challenges[2].length) {
        return 2;
      } else if (challenges[0].length) {
        return 0;
      }
      return 1;
    },

    getSponsoredChallengeSelection() {
      let min = 999;
      for (const challenge of this.myChallenges[3]) {
        min = Math.min(min, challenge.status);
      }

      if (min === 999) {
        return 0;
      } else {
        if (min === 0 || min === 2) {
          return 0;
        } else if (min === 4 || min === 5) {
          return 4;
        } else {
          return min;
        }
      }
    },

    challengeFilter(which) {
      return {
        'ctab-sub': true,
        selected: this.challengeSelection === which,
      };
    },

    activeFilter(which) {
      return {
        'ctab-sub': true,
        selected: this.activeSelection === which,
      };
    },
    activeSponsoredFilter(which) {
      return {
        'ctab-sub': true,
        selected: this.activeSponsoredSelection === which,
      };
    },
  },
  head() {
    return {
      title: 'Challenges | MeterLeader',
    };
  },
};
</script>

<style lang="sass">
@use "sass:color"
@import '~@/assets/css/_variables.scss'
@import '~@/assets/css/challenges'
@import '~@/assets/css/exploreChallenges'

.noselect
  -webkit-touch-callout: none /* iOS Safari */
  -webkit-user-select: none /* Safari */
  -khtml-user-select: none /* Konqueror HTML */
  -moz-user-select: none /* Old versions of Firefox */
  -ms-user-select: none /* Internet Explorer/Edge */
  user-select: none

.selected-item
  background-color: #eaa000

.ctabs
  border-radius: 18px !important
  // margin-top: 1rem !important
  padding: 4px
  background-color: $grey

.ctab-item
  width: 100%
  border-radius: 18px !important
  text-align: center
  &.selected
    background-color: white
    font-weight: 600

.ctab-sub
  border: 1px solid $grey
  border-radius: 18px
  background-color: white
  padding: 4px 8px 4px 8px
  width: 100px
  text-align: center
  &.selected
    background-color: $grey
    font-weight: 600

.rounded
  border-radius: 18px !important

::-webkit-scrollbar
  display: none
</style>
