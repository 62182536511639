<template>
  <div>
    <div v-if="loading">
      <button
        class="px-2 py-1 rounded-0 m-bg-purple text-white"
        type="button"
        disabled
      >
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Loading...</span>
      </button>
      <button
        class="px-2 py-1 rounded-0 m-bg-purple text-white"
        type="button"
        disabled
      >
        <span
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/utilities.scss';
</style>