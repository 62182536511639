<template>
  <div id="about-page">
    <div id="main">
      <div class="main-content">
        <h1>About Us</h1>
        <div id="mission">
          <h2>Our Mission</h2>
          <h4>
            Use real-time data to empower individuals to come together to combat
            climate change.
          </h4>
        </div>

        <div id="story" class="container">
          <h2>Our Story</h2>
          <p>
            MeterLeader is a female founded company based in Orange County, CA
            supported by the
            <a
              href="https://www.techstars.com/newsroom/announcing-the-stanley-techstars-2022-class"
            >
              Stanley-Techstars Accelerator</a
            >,
            <a
              href="https://laincubator.org/laci-welcomes-10-promising-cleantech-startups-for-its-fall-2021-incubation-cohort/"
              >Los Angeles Cleantech Incubator</a
            >, and the
            <a href="https://www.netzeroaccelerator.org/nza-22"
              >USGBC-LA Net Zero Accelerator.</a
            >
          </p>
          <p>
            The seeds of MeterLeader were planted in 2009 when MeterLeader’s
            co-founders Natalie and Jolyon met as students at Cornell
            University. While members of the Sustainability Hub student club,
            Jolyon noticed that lights were being left on throughout the night
            in buildings across campus, so together they created Lights Off
            Cornell. Lights Off Cornell was a web application that mobilized
            over 200 student volunteers to turn off lights at night in 6
            buildings on campus, and measured the resulting carbon emission
            reductions.
          </p>
          <!-- <div class="d-flex justify-content-center my-3">
            <img
              :style="{ height: '350px' }"
              src="~/assets/images/lights_off_cornell.jpeg"
            />
          </div> -->
          <p>
            After graduation, Jolyon received his PhD in Theoretical Physics and
            worked for 6 years at MIT, before transitioning to a career in data
            science. Natalie went on to work as an energy efficiency consultant
            for multiple utility companies in Maryland and California. She
            helped design and implement a variety of residential energy
            efficiency programs.
          </p>
          <p>
            Then in 2018, Natalie had the idea to create MeterLeader, and
            approached Jolyon about working together again. “Let’s recreate
            Lights Off Cornell, but this time let’s empower everyone in the US
            to save energy and reduce carbon emissions.” And 9 months later
            MeterLeader was born.
          </p>
          <p>
            In 2019, MeterLeader went live and was integrated only with PG&E
            utility data. Natalie moved to San Francisco for 6 weeks, and had
            the “Silicon Valley” experience. She slept in a room with bunk beds
            and 3 strangers, and worked tirelessly to promote MeterLeader and
            understand how startups work.
          </p>
          <p>
            In 2020, Natalie ran out of money, and went back to work. She become
            a scrum master for a tech consulting company. There she learned how
            to work with large onshore and offshore dev teams, and how to build
            enterprise scale phone applications.
          </p>
          <p>
            At the end of 2021, MeterLeader was accepted into the Los Angeles
            Cleantech Incubator. And in 2022, Natalie lived in Baltimore for
            over 2 months to participate in the Stanley-Techstars Accelerator
            Program. During this time MeterLeader did a complete overhaul of its
            backend system and today is now integrated with hourly electricity
            and gas data from 12 utilities in 10 states.
          </p>

          <!-- <div class="d-flex justify-content-center my-3">
            <img
              :style="{ height: '350px' }"
              src="~/assets/images/natalie_at_booth.jpeg"
            />
          </div> -->

          <p>
            Most recently, MeterLeader was accepted into the USGBC-LA Net Zero
            Accelerator, attended and pitched at a number of conferences, and is
            now actively seeking
            <a href="https://www.meterleader.com/pilot">pilot partners</a> and
            investment.
          </p>
        </div>

        <div id="values-box" class="container-fluid">
          <div class="container">
            <h2>Our Values</h2>
            <div class="values row container-fluid">
              <div class="col-12 col-md-6 row container value">
                <div class="icon col-2">
                  <img src="~/assets/images/greenGroup.png" />
                </div>
                <div class="info col-10">
                  <h4>Community</h4>
                  <p>
                    When we act as part of a community we are stronger.
                    MeterLeader’s goal is to leverage community relationships to
                    achieve meaningful impact for the environment.
                  </p>
                </div>
              </div>
              <div class="row container value col-12 col-md-6">
                <div class="icon col-2">
                  <img src="~/assets/images/weight.png" />
                </div>
                <div class="info col-10">
                  <h4>Empowerment</h4>
                  <p>
                    We all have the power to make changes in our daily habits
                    and lifestyles to save energy. MeterLeader gives you the
                    tools and motivation to inspire this kind of change on a
                    large scale.
                  </p>
                </div>
              </div>
              <div class="row container value col-12 col-md-6">
                <div class="icon col-2">
                  <img src="~/assets/images/barGraph.png" />
                </div>
                <div class="info col-10">
                  <h4>Data-Driven</h4>
                  <p>
                    You can't improve upon something that you don’t measure.
                    That is why MeterLeader incorporates real-time meter data
                    into our energy saving Challenges.
                  </p>
                </div>
              </div>
              <div class="row container value col-12 col-md-6">
                <div class="icon col-2">
                  <img src="~/assets/images/greenSmiley.png" />
                </div>
                <div class="info col-10">
                  <h4>Fun</h4>
                  <p>
                    Just because saving energy is important doesn’t mean that we
                    can't have fun at the same time! MeterLeader challenges are
                    intended to inspire collaboration with your community.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  head() {
    return {
      title: 'About Us | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            "MeterLeader's mission is to empower individuals and communities to come together to save energy and collectively make a positive impact on the environment.",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/aboutUs';
</style>
