<template>
  <div>
    <div>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-card v-if="Object.keys(data).length > 0">
            <v-card-title>{{ `${data.name}` }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>Full name</v-col>
                <v-col>{{ data.fullname }}</v-col>
              </v-row>
              <v-row>
                <v-col>Timezone</v-col>
                <v-col>{{ data.timezone }}</v-col>
              </v-row>
              <v-row>
                <v-col>Subregion Emission Rate</v-col>
                <v-col>
                  <span>{{
                    data.subregion_emission_rate_acronym
                  }}</span>
                  
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import DynamicSelect from '~/components/admind/DynamicSelect.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/utilities/${this.$route.params.utilId}`
      );
      this.data = res.data;
    },
    async updateEmissionRate(emissionRate) {
      const res = await this.$api.patch(
        `admind/utilities/${this.$route.params.utilId}`,
        { subregion_emission_rate_id: emissionRate.id }
      );
      this.data.subregion_emission_rate_name = emissionRate.region_acronym
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    DynamicSelect,
  },
};
</script>