<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-12 mt-3">
        <div class="login p-4">
          <h1 class="font-weight-bold mb-1">Sign Up</h1>
          <small class="text-tip"
            >Already have a Meterleader account? <a href="#">Login</a>
          </small>
          <div class="form-field mt-3">
            <label class="label">Display Name</label><br />
            <input class="px-3" type="text" />
          </div>
          <!-- <div class="form-field mt-3">
            <label class="label">Last Name</label><br />
            <input class="px-3" type="text" />
          </div> -->

          <div class="form-field mt-3">
            <label class="label">Email</label><br />
            <input class="px-3" type="text" />
          </div>

          <div class="form-field mt-3">
            <label class="label">Password</label><br />
            <input class="px-3" type="password" />
          </div>

          <div class="form-field mt-3">
            <label class="label">Corporate Code (Optional)</label><br />
            <input class="px-3" type="text" />
          </div>

          <div class="d-flex justify-content-center">
            <hr class="flex-grow-1" />
            <p class="pt-1 px-2">OR</p>
            <hr class="flex-grow-1" />
          </div>

          <div>
            <button class="fb-bg text-white d-flex align-items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="mx-3"
              >
                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
              <p class="mb-0 text-center w-100 neg-margin">
                Continue with facebook
              </p>
            </button>

            <button class="accent-bg mb-3"><p class="mb-0">Login</p></button>
          </div>

          <small class="text-tip"
            >By creating an account you are accepting our
            <a href="#">Terms and Conditions and Privacy Policy</a>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {};
  },
};
</script>
  <style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

hr
  border-top: 1px solid black

a
  color: $accent

.accent-bg
  background-color: $accent

.fb-bg
  background-color: #3B579D

.form-field

  > .label
    margin-bottom: 0px
    font-size: 0.9rem
    font-weight: bold
    padding-bottom: 5px
  > input
    width: 100%
    height: 3rem
    border-radius: 10px !important

.login
  border-radius: 18px
  border: 2px solid $grey

.text-tip
  color: color.scale(#000, $lightness: 50%)
  font-size: 0.95rem

button
  width: 100%
  height: 3.8rem
  border-radius: 40px

  p
    font-weight: bold
    font-size: 1.09rem
  > .neg-margin
    margin-left: -62px
  > svg
    height: 30px
    width: 30px
    fill: white
</style>