<template>
  <div>
    <div>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-card v-if="Object.keys(data).length > 0">
            <v-card-title>{{ `${data.name}` }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>Leader</v-col>
                <v-col>{{ data.leader_fullname }}</v-col>
              </v-row>
              <v-row>
                <v-col>Ownership Type</v-col>
                <v-col>{{ data.home_ownership }}</v-col>
              </v-row>
              <v-row>
                <v-col>Home Type</v-col>
                <v-col>{{ data.home_type }}</v-col>
              </v-row>
              <v-row>
                <v-col>Square Footage</v-col>
                <v-col>{{ data.square_footage }}</v-col>
              </v-row>
              <v-row>
                <v-col>Primary Cooling Type</v-col>
                <v-col>{{ data.primary_cooling_type }}</v-col>
              </v-row>

              <v-row>
                <v-col>Primary Heating Type</v-col>
                <v-col>{{ data.primary_heating_type }}</v-col>
              </v-row>
              <v-row>
                <v-col>Total Electric Vehicles</v-col>
                <v-col>{{ data.no_of_electric_vehicles }}</v-col>
              </v-row>
              <v-row>
                <v-col>Total Gas Vehicles</v-col>
                <v-col>{{ data.no_of_gas_vehicles }}</v-col>
              </v-row>
              <v-row>
                <v-col>Total Gas Vehicles</v-col>
                <v-col>{{ data.no_of_gas_powered_tools }}</v-col>
              </v-row>
              <v-row>
                <v-col>Total Gas Vehicles</v-col>
                <v-col>{{ data.no_of_electric_powered_tools }}</v-col>
              </v-row>
              <v-row>
                <v-col>Occupants</v-col>
                <v-col>{{ data.number_of_occupants }}</v-col>
              </v-row>
              <v-row>
                <v-col>Has pool or spa</v-col>
                <v-col>{{ data.has_pool_or_spa ? 'Yes' : 'No' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Has battery storage</v-col>
                <v-col>{{ data.home_battery_storage ? 'Yes' : 'No' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Has rooftop solar</v-col>
                <v-col>{{ data.rooftop_solar ? 'Yes' : 'No' }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/teams/${this.$route.params.teamId}`
      );
      this.data = res.data;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>