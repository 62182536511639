<template>
  <div id="settings-page">
    <OnboardingReminder v-if="$auth.loggedIn" />
    <div class="container">
      <div class="d-none d-md-block">
        <h1 class="py-4 text-center">This page is only available on mobile</h1>
      </div>
      <div class="d-md-none">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="avatar d-flex justify-content-center align-content-center">
            <img id="profile-pic" :src="userData.photo_url" />
          </div>
          <h3 class="mt-3">{{ navBarName }}</h3>
        </div>
        <!-- <hr /> -->

        <div>
          <div
            class="link d-flex justify-content-between"
            @click="$router.push('/account-settings')"
          >
            <nuxt-link to="/account-settings"> Settings </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
          <div
            class="link d-flex justify-content-between"
            @click="$router.push('/faqs')"
          >
            <nuxt-link to="/faqs"> FAQ </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>

          <div
            class="link d-flex justify-content-between"
            @click="$router.push('/how-it-works')"
          >
            <nuxt-link to="/how-it-works"> How it Works </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>

          <div
            class="link d-flex justify-content-between"
            @click="$router.push('/terms-conditions')"
          >
            <nuxt-link to="/terms-conditions"> Terms & Conditions </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>

          <div
            class="link d-flex justify-content-between"
            @click="$router.push('/privacy-policy')"
          >
            <nuxt-link to="/privacy-policy"> Privacy Policy </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
        </div>

        <br />

        <div>
          <div class="link d-flex justify-content-between">
            <nuxt-link to="/logout"> Log Out </nuxt-link>
            <div>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  pageTitle: 'Settings',
  middleware: ['auth'],
  computed: mapState({
    userData: (state) => state.auth.user,
    navBarName(state) {
      return `${state.auth.user.firstname} ${state.auth.user.lastname}`;
    },
  }),
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'
.avatar
  height: 100px
  width: 100px
  background-color: gray
  border-radius: 100px
  & img
    height: 100%
    width: 100%

.link
  background-color: $grey
  border-radius: 18px
  padding: 10px 15px 10px 15px
  margin: 10px 0px 10px 0px
  width: 100%
  & a
    color: black
</style>
