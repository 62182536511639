<template>
  <div class="mb-1 mt-1">
    <v-row no-gutters align="center" class="mb-4">
      <v-col cols="9" md="7" lg="4">
        <v-text-field
          label="Search energy meters"
          append-icon="mdi-magnify"
          color="green"
          rounded
          solo
          hide-details
          v-model="filterFields.name_includes"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="6" lg="8" align="start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              rounded
              v-bind="attrs"
              v-on="on"
              @click="open = !open"
              class="ml-2"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
          <span>Toggle filter options</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card v-if="open">
        <v-card-title>Filter By</v-card-title>
        <v-card-text class="d-flex">
          <v-responsive max-width="300" height="50">
            <UserSelect
              :value="filterFields.user_id"
              @onSelect="$event = filterFields.user_id = $event"
            ></UserSelect>
          </v-responsive>
          <v-responsive max-width="280" height="50">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="dateRangeText"
                  label="Sync date range"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </template>

              <v-date-picker v-model="filterDateRange" range></v-date-picker>
            </v-menu>
          </v-responsive>
          <v-responsive max-width="280" height="50">
            <v-switch
            v-model="filterFields.is_never_synced"
            label="Is never synced"
          ></v-switch>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>
<script>
import UserSelect from '@/components/admind/UserSelect.vue';

export default {
  props: ['filterParams'],
  components: { UserSelect },
  data() {
    let filterDateRange = [];

    if (this.filterParams.sync_date_from) {
      filterDateRange.push(this.filterParams.sync_date_from);

      if (this.filterParams.sync_date_to) {
        filterDateRange.push(this.filterParams.sync_date_to);
      }
    }

    return {
      open: false,
      filterDateRange: filterDateRange,
      filterFields: this.filterParams,
    };
  },
  watch: {
    filterFields: {
      handler: function (newVal) {
        this.$emit('update:filterParams', newVal);
      },
      deep: true,
    },
    filterDateRange: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.$set(this.filterFields, 'sync_date_from', newVal[0]);
          if (newVal.length > 1) {
            this.$set(this.filterFields, 'sync_date_to', newVal[1]);
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    dateRangeText() {
      return this.filterDateRange.join(' ~ ');
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
</style>