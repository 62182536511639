<template>
  <div>
    <PropertyListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/teams'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import PropertyListFilterBox from '@/components/admind/listview_filter_boxes/PropertyListFilterBox.vue';
import TeamName from '@/components/admind/listview_column_overrides/TeamName.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    const defaultFilterParams = {
      user_id: null,
    };
    return {
      filterParams: {...defaultFilterParams, ...this.$route.query},
    };
  },
  dataTableSlotComponents: {
    'item.name': TeamName,
  },
  headers: [
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      align: 'start',
    },

    {
      text: 'Leader',
      align: 'center',
      sortable: false,
      value: 'leader_fullname',
    },
    {
      text: 'Home Ownership',
      value: 'home_ownership',
      sortable: false,
      align: 'center',
    },
    { text: 'Home Type', value: 'home_type', sortable: false, align: 'right' },
  ],
  components: {
    AdminList,
    PropertyListFilterBox,
  },
};
</script>