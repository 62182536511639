<template>
  <div
    class="component overflow-auto"
    :style="{ maxHeight: '300px' }"
    @scroll="onScroll"
  >
    <v-text-field
      class="item mx-3 mt-0 bg-white"
      @click.stop
      hide-details
      placeholder="Search..."
      v-model="query"
    ></v-text-field>
    <v-list class="pt-10">
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-title @click="$emit('onSelect', item)">{{
          item[labelKey]
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!items.length">
        <v-list-item-title>No Items</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  props: {
    endpoint: {
      type: String,
      default: null,
    },
    labelKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      query: null,
      count: 10,
      page: 1,
      size: 10,
      items: [],
    };
  },
  watch: {
    query: async function (value) {
      this.page = 1;
      let items = await this.getData();
      this.items = items;
    },
  },
  async mounted() {
    let items = await this.getData();
    this.items = items;
  },
  methods: {
    async getData() {
      const res = await this.$api.get(`${this.endpoint}`, {
        params: {
          page: this.page,
          items_per_page: this.size,
          search_query: this.query,
        },
      });
      this.count = res.data.count;
      return res.data.results;
    },

    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.page * this.size < this.count) {
          this.page = this.page + 1;
          let newItems = await this.getData();
          this.items = [...this.items, ...newItems];
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.component {
  .item {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 20;
  }
}
</style>