<template>
  <div id="notifications-content" class="content">
    <h2>Email Notifications</h2>
    <form id="notifications-form">
      <p id="notification-changed" class="success">
        Notification settings successfully changed
      </p>
      <p id="notification-error" class="error">
        Server Error. Please try again.
      </p>
      <div class="toggle">
        <h5>MeterLeader Newsletter</h5>
        <label class="switch">
          <input
            id="newsletter"
            v-model="emailNotifications"
            name="newsletter"
            type="checkbox"
          />
          <span class="slider" />
        </label>
      </div>
      <div class="toggle">
        <h5>Emails from Challenges</h5>
        <label class="switch">
          <input
            id="challenges"
            v-model="challengeNotifications"
            name="challenges"
            type="checkbox"
          />
          <span class="slider" />
        </label>
      </div>
      <button
        id="save-notification-changes"
        class="btn btn-green"
        type="button"
        @click="saveNotifications"
      >
        Save Changes
      </button>
    </form>
  </div>
</template>

<script>
import { actionTypes } from '@/store/types';

export default {
  name: 'Notifications',
  async asyncData({ store }) {
    await store.dispatch(actionTypes.GET_USER_PROFILE);
    return {
      challengeNotifications:
        store.state.user.profile.settings.challenge_notifications,
      emailNotifications: store.state.user.profile.settings.email_notifications,
    };
  },
  data() {
    return {
      challengeNotifications: false,
      emailNotifications: false,
    };
  },
  methods: {
    saveNotifications() {
      this.$repo.user.update({
        settings: {
          challenge_notifications: this.challengeNotifications,
          email_notifications: this.emailNotifications,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/accountSettings.scss';
</style>
