<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id + 'Title'"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-scrollable
        modal-dialog
        modal-dialog-scrollable-centered
      "
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 :id="id + 'Title'" class="modal-title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ cancelButtonLabel }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            @click="$emit('on-confirm')"
          >
            {{ confirmButtonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogModal',
  props: {
    id: String,
    title: {
      type: String,
      default: 'Title',
    },
    message: {
      type: String,
      default: 'Message',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonLabel: {
      type: String,
      default: 'Confirm',
    },
  },
};
</script>

<style scoped></style>
