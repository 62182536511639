<template>
  <div class="challenge">
    <v-row>
      <v-col cols="12">
        <v-card v-if="Object.keys(data).length > 0">
          <v-card-text>
            <v-row align="center">
              <v-col>
                <v-row align="start">
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="4"
                    :align="$vuetify.breakpoint.smAndUp ? 'center' : 'start'"
                  >
                    <v-img
                      class="mt-6 ml-4"
                      :lazy-src="
                        require('~/assets/images/image_thumbnail.jpeg')
                      "
                      :src="data.image_banner_url"
                      height="300"
                      width="300"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="8" lg="8">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="mx-4 my-3 h4">{{ data.name }}</div>
                      </v-col>
                      <v-divider />
                      <v-col cols="12">
                        <div class="mx-4">
                          <div class="d-flex align-center">
                            <div style="width: 100px" class="subtitle-2">
                              START:
                            </div>
                            <div class="subtitle-1">
                              {{ data.start_date | formatDate }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 100px" class="subtitle-2">
                              END:
                            </div>
                            <div class="subtitle-1">
                              {{ data.end_date | formatDate }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 100px" class="subtitle-2">
                              SPONSER:
                            </div>
                            <div class="subtitle-1">
                              {{ data.sponsor_name }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 100px" class="subtitle-2">
                              STATUS:
                            </div>
                            <div class="subtitle-1">
                              {{
                                $options.constants.statusMapping[
                                  `${data.status}_`
                                ]
                              }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <v-btn
                              @click.stop="emailDialog = true"
                              class="my-4 mr-1"
                              color="primary"
                            >
                              Email participants
                            </v-btn>
                            <v-btn
                              v-if="data.status == 1"
                              @click="approve"
                              class="my-4 mr-1"
                              color="primary"
                            >
                              Approve
                            </v-btn>
                            <v-btn
                              v-if="data.status == 1"
                              @click="reject"
                              class="my-4"
                              color="primary"
                            >
                              Reject
                            </v-btn>
                            <v-btn
                              @click="deleteChallenge"
                              class="my-4"
                              color="btn-danger"
                            >Delete</v-btn>
                          </div>

                          <v-divider class="my-6" />
                          <div class="h5">Participants</div>

                          <div class="d-flex align-center">
                            <div style="width: 200px" class="subtitle-2">
                              MAX PARTICIPANTS:
                            </div>
                            <div class="subtitle-1">
                              {{ data.max_participants }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 200px" class="subtitle-2">
                              ESTIMATED PARTICIPANTS:
                            </div>
                            <div class="subtitle-1">
                              {{ data.est_total_participants }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 200px" class="subtitle-2">
                              CURRENT PARTICIPANTS:
                            </div>
                            <div class="subtitle-1">
                              {{ data.current_participants }}
                            </div>
                          </div>
                          <v-btn
                            @click.stop="participantsDialog = true"
                            class="my-4"
                            color="primary"
                            >Participants</v-btn
                          >
                          <v-btn
                            @click="participantsResult"
                            class="my-4"
                            color="primary"
                          >Result</v-btn>
                          <v-btn
                            @click="participantsResultV2"
                            class="my-4"
                            color="primary"
                          >Result V2</v-btn>
                          <v-divider class="my-6" />
                          <div class="h5">Saving</div>
                          <div class="d-flex align-center">
                            <div style="width: 110px" class="subtitle-2">
                              SAVINGS GOAL:
                            </div>
                            <div class="subtitle-1">
                              {{ data.savings_goal }}
                            </div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 110px" class="subtitle-2">
                              SAVINGS KWH:
                            </div>
                            <div class="subtitle-1">{{ data.savings_kwh }}</div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 110px" class="subtitle-2">
                              SAVINGS CO<sub>2</sub>:
                            </div>
                            <div class="subtitle-1">{{ data.savings_co2 }}</div>
                          </div>
                          <div class="d-flex align-center">
                            <div style="width: 110px" class="subtitle-2">
                              SAVINGS THM:
                            </div>
                            <div class="subtitle-1">{{ data.savings_thm }}</div>
                          </div>
                          <v-divider class="my-6" />
                          <div class="h5">Description</div>
                          <div class="subtitle-1">{{ data.description }}</div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="participantsDialog" height="500px">
      <ChallengeUsers class=""></ChallengeUsers>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="emailDialog">
      <v-container>
        <div style="height: 700px" class="overflow-y-auto py-4">
          <ChallengeEmailSettings class="mt-2" />
        </div>
      </v-container>
    </v-bottom-sheet>
  </div>
</template>
<script>
import ChallengeUsers from '@/components/admind/ChallengeUsers.vue';
import ChallengeEmailSettings from '@/components/admind/ChallengeEmailSettings.vue';

import moment from 'moment-timezone';

export default {
  constants: {
    statusMapping: {
      '0_': 'Draft',
      '1_': 'Submitted',
      '2_': 'Returned',
      '3_': 'Approved',
      '4_': 'Launched',
      '5_': 'Started',
      '6_': 'Ended',
      '7_': 'Closed',
      '-1_': 'Removed',
    },
  },
  layout: 'admin_dashboard',
  components: {
    ChallengeUsers,
    ChallengeEmailSettings
  },
  data() {
    return {
      participantsDialog: false,
      emailDialog: false,
      loading: false,
      data: {},
    };
  },
  filters: {
    formatDate: function (value) {
      return moment
        .tz(value, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('MMM. D, YYYY. h:mmA z');
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    downloadCsv (data, version = 1) {
      // Making blob
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      // Making link and click
      a.setAttribute('href', url);
      if (version === 1) {
        a.setAttribute('download', this.data.name.replaceAll(' ', '_') + '_result.csv');
      } else {
        a.setAttribute('download', this.data.name.replaceAll(' ', '_') + '_result_V2.csv');
      }
      a.click();
    },
    csvMaker (results) {
      let csvRows = [];
      // Headers
      const headers = Object.values(results['headers']);
      csvRows.push(headers.join(','));
      // Row
      results['data'].forEach(function (item) {
        const values = Object.values(item).join(',');
        csvRows.push(values)
      });

      return csvRows.join('\n')
    },
    async participantsResult () {
      this.$nuxt.$loading.start();
      try {
        const csv_res = await this.$api.get(
          `challenges/result/${this.$route.params.challengeId}`
        );
        if (csv_res.data.status === 200) {
          const csvData = this.csvMaker(csv_res.data.results);
          this.downloadCsv(csvData);
        } else {
          this.$toast.error('This challenge doesn\'t have any participant.', { duration: 5000 });
        }
      } catch (e) {
        this.$toast.error('Something wrong.', { duration: 5000 });
      }
      this.$nuxt.$loading.finish();
    },
    async deleteChallenge () {
      this.$nuxt.$loading.start();
      try {
        const csv_res = await this.$api.delete(
          `admind/challenge/${this.$route.params.challengeId}`
        );
        if (csv_res.data.status === 200) {
          this.$nuxt.$loading.finish();
          await this.$router.push('/admind/challenges');
        } else {
          this.$toast.error('This challenge can not be deleted.', { duration: 5000 });
        }
      } catch (e) {
        this.$toast.error('Something wrong.', { duration: 5000 });
      }
      this.$nuxt.$loading.finish();
    },
    async participantsResultV2 () {
      this.$nuxt.$loading.start();
      try {
        const csv_res = await this.$api.get(
          `challenges/result/v2/${this.$route.params.challengeId}`
        );
        if (csv_res.data.status === 200) {
          const csvData = this.csvMaker(csv_res.data.results);
          this.downloadCsv(csvData, 2);
        } else {
          this.$toast.error('This challenge doesn\'t have any participant.', { duration: 5000 });
        }
      } catch (e) {
        this.$toast.error('Something wrong.', { duration: 5000 });
      }
      this.$nuxt.$loading.finish();
    },
    async approve() {
      try {
        await this.$repo.admin.approveChallenge(this.$route.params.challengeId);
        this.$toast.success('Challenge Approved.', { duration: 5000 });
        this.data.status = 3;
      } catch (error) {
        this.$toast.error('Something wrong.', { duration: 5000 });
      }
    },
    async reject() {
      try {
        await this.$repo.admin.returnChallenge(this.$route.params.challengeId);
        this.$toast.success('Challenge Rejected.', { duration: 5000 });
        this.data.status = 2;
      } catch (error) {
        this.$toast.error('Something wrong.', { duration: 5000 });
      }
    },
    async getData() {
      const res = await this.$api.get(
        `challenges/${this.$route.params.challengeId}`
      );
      this.data = res.data;
    },
  },
};
</script>
<style>
.flex-item-basis-full {
  flex-basis: 100%;
}
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>
