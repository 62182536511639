<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-12 mt-3">
        <div class="login p-4">
          <h1 class="font-weight-bold mb-1">Login</h1>
          <small class="text-tip"
            >Don't have a MeterLeader account.
            <a href="/signup">Sign up</a>
          </small>

          <p class="error mb-0" v-if="errorMessage">{{ errorMessage }}</p>
          <div class="form-field mt-3">
            <label class="label">Email</label><br />
            <input class="px-3" type="text" v-model="email" />
          </div>
          <div class="form-field mt-3">
            <label class="label">Password</label><br />
            <input class="px-3" type="password" v-model="password" />
          </div>
          <small class="text-tip mt-1">
            <a href="/reset_password" class="" id="forgot">Forgot password?</a>
          </small>
          <button class="accent-bg mt-3">
            <p class="mb-0" @click="login">Login</p>
          </button>
          <div class="mt-3">
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              v-model="rememberMe"
            />
            <label for="vehicle1">Remember me</label>
          </div>

          <div class="d-flex justify-content-center">
            <hr class="flex-grow-1" />
            <p class="pt-1 px-2">OR</p>
            <hr class="flex-grow-1" />
          </div>

          <div>
            <button
              class="fb-bg text-white d-flex align-items-center mb-3"
              @click="facebookLogin"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="mx-3"
              >
                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
              <p class="mb-0 text-center w-100 neg-margin">
                Continue with Facebook
              </p>
            </button>

            <div
              id="appleid-signin"
              class="mb-3 signin-button"
              data-mode="center-align"
              data-color="black"
              data-border="false"
              data-border-radius="40"
              data-type="continue"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: '',
    };
  },
  beforeCreate() {
    if (this.$auth.loggedIn) {
      return this.$router.push('/my-usage');
    }
  },
  mounted() {
    switch (this.$route.query.error) {
      case 'temporarily_unavailable':
      case 'apple_error':
        this.errorMessage =
          'Something went wrong with connecting to Apple. Please try again.';
        break;
      case 'apple_duplicate':
        this.errorMessage =
          'Email with this Apple account is already exists.';
        break;
      case 'server_error':
        this.errorMessage =
          'Something went wrong with connecting to Facebook. Please try again.';
        break;
      case 'access_denied':
        this.errorMessage = 'Facebook login declined.';
        break;
      case 'timeout':
      case 'our_bad':
        this.errorMessage =
          'Something went wrong on our end. Please try again.';
        break;
      default:
        this.errorMessage = '';
    }

    // if (this.errorMessage !== '') {
    //   $('#facebook-error').show();
    // }
  },
  methods: {
    facebookLogin() {
      this.$auth.$storage.setUniversal('retryCount', 0);
      window.location.href = process.env.API_ADDRESS + '/facebook/login';
    },
    async login() {
      if (DEMO) {
        console.log('login demo');
        await this.$auth.loginWith('local', {
          data: {
            email: this.email.trim(),
            password: this.password.trim(),
            remember_me: this.rememberMe,
          },
        });
        console.log('login success');
        // this.$router.push('/challenges');
      } else {
        try {
          try {
            const redirectUrl = this.$auth.$storage.getUniversal('redirect');
            if (!redirectUrl) {
              this.$auth.$storage.setUniversal('redirect', '/my-usage');
            }
          } catch {}

          const result = await this.$auth.loginWith('local', {
            data: {
              email: this.email.trim(),
              password: this.password.trim(),
              remember_me: this.rememberMe,
            },
          });
          this.$auth.$storage.setUniversal('user', result.data.user, true);

          if (this.$route.query.hasOwnProperty('redirect')) {
            window.location.href = this.$route.query['redirect'];
          }
        } catch (error) {
          if (error.response) {
            switch (error.response.data.msg) {
              case 'bad_login':
                this.errorMessage = 'Email and/or password is incorrect.';
                this.password = '';
                break;
              case 'waiting for password reset':
                this.errorMessage =
                  'This account has been locked an needs a password reset.';
                this.password = '';
                break;
              case 'email confirmation required':
                this.errorMessage =
                  'The email address for this account has not yet been verified.';
                break;
              case 'account closed':
                $('.login-form').trigger('reset');
                this.accountClosed.show();
                break;
              default:
                this.errorMessage = 'Server Error';
            }
          } else {
            this.errorMessage = 'Server Error';
          }
        }
      }
    },
  },

  head() {
    return {
      title: 'Login',
      meta: [
        {name: 'appleid-signin-client-id', content: process.env.APPLE_SIGNIN_CLIENT_ID},
        {name: 'appleid-signin-scope', content: 'name email'},
        {name: 'appleid-signin-redirect-uri', content: process.env.APPLEID_SIGNIN_REDIRECT_URI},
        {name: 'appleid-signin-state', content: 'mlapsi'},
        {name: 'appleid-signin-nonce', content: ''},
        {name: 'appleid-signin-use-popup', content: false},
      ],
      script: [
        {
          src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', body: true
        }
      ]
    };
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.error
  color: red

hr
  border-top: 1px solid black

a
  color: $accent

.accent-bg
  background-color: $accent

.fb-bg
  background-color: #3B579D

.form-field

  > .label
    margin-bottom: 0px
    font-size: 0.9rem
    font-weight: bold
    padding-bottom: 5px
  > input
    width: 100%
    height: 3rem
    border-radius: 10px !important

.login
  border-radius: 18px
  border: 2px solid $grey

.text-tip
  color: color.scale(#000, $lightness: 50%)
  font-size: 0.95rem

button
  width: 100%
  height: 3.3rem
  border-radius: 40px

  p
    font-weight: bold
    font-size: 1.09rem
  > .neg-margin
    margin-left: -62px
  > svg
    height: 30px
    width: 30px
    fill: white
</style>
<style>
.signin-button {
  width: 100%;
  height: 3.3rem;
}
.signin-button > div {
  max-width: 100% !important;
}
</style>
