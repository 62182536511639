<template>
  <div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md" style="text-align: left">
            <h1 class="font-weight-bold">
              Help your Employees Combat Climate Change <br />
              at Home
            </h1>
            <p style="margin-top: 40px">
              MeterLeader is a sustainability-focused employee engagement
              platform that uses real-time utility data to help your employees
              reduce carbon emissions at home.
            </p>
            <a
              href="https://calendly.com/meterleader/nzandt"
              target="_blank"
              class="btn px-5 py-3 my-4 font-weight-bold"
              style="background-color: #c200fb; color: #fff"
              >Schedule a 15 min call</a
            >
          </div>
          <div class="col-md img-right">
            <img src="~/assets/images/companies-1.jpg" />
          </div>
        </div>
      </div>
    </section>
    <!--region Sustainability -->

    <!--endregion -->
    <!--region Create a challenge in minutes -->
    <section>
      <div class="container" style="margin-top: 25px">
        <div class="row align-items-center">
          <div class="col-md">
            <img src="~/assets/images/ESG_image.png" />
          </div>
          <div class="col-md">
            <h1 class="font-weight-bold">Improve your E in ESG</h1>
            <p>
              Measure and reduce carbon emissions from WFH employees.
              MeterLeader is integrated with energy data from 12 utilities in 10
              states.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--endregion    -->
    <!--region Finally an easy way to make REAL carbon emission reductions. -->
    <section>
      <div class="container">
        <div
          class="container-fluid row align-items-center"
          style="margin-top: 25px"
        >
          <div class="col-md">
            <h1 class="font-weight-bold">Engage & Educate Employees</h1>
            <p>
              MeterLeader motivates employees to reduce carbon emissions, and
              makes it easy to learn about home electrification. Employees can
              better track their utilities, save money on bills, and win prizes.
            </p>
          </div>
          <div class="col-md img-left">
            <img src="~/assets/images/SampleMeterLeaderChallenge.webp" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div
          class="container-fluid row align-items-center"
          style="margin-top: 25px"
        >
          <div class="col-md img-left">
            <img src="~/assets/images/create-challenge.webp" />
          </div>
          <div class="col-md">
            <h1 class="font-weight-bold">
              Easy to Implement, Turnkey Solution
            </h1>
            <p>
              MeterLeader is an affordable and turnkey solution that takes very
              little time to set up and maintain. Plus no energy expertise is
              needed - we take care of all that!
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--endregion -->
    <!--region Start a challenge today -->
    <section>
      <div id="start-challenge" class="container-fluid background-green">
        <div class="container align-content-center">
          <h1>Start an energy savings challenge today!</h1>
          <a
            href="https://calendly.com/meterleader/nzandt"
            target="_blank"
            class="btn px-5 py-3 my-4 font-weight-bold"
            style="background-color: #c200fb; color: #fff"
            >Schedule a 15 min call</a
          >
        </div>
      </div>
    </section>
    <!--endregion -->
  </div>
</template>

<script>
import socialImage from '@/assets/images/companies-1.jpg';

export default {
  name: 'ForCompanies',
  head() {
    return {
      title: 'For Companies | MeterLeader',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Help your employees save energy and combat climate change.',
        },
        {
          hid: 'og:image',
          name: 'image',
          property: 'og:image',
          content: `https://www.meterleader.com${socialImage}`,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/companies';

#reason-box {
  text-align: left;
  background-color: $grey;
  padding: 70px 0;
  position: relative;

  .container {
    width: 80%;
  }

  h2 {
    padding-bottom: 20px;
  }

  h4 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .reason > * {
    display: inline-block;
  }

  .reason:not(:last-child) {
    padding-bottom: 10px;
  }

  .reason-image {
    max-width: 50px;
  }
}

.benefits {
  //margin: 0;
  //
  .row {
    //padding: 0;
    //margin-top: 50px;
  }

  .text {
    //  h4{
    //    font-family: $header_font;
    //    font-weight: 500;
    //    font-size: 1.2rem;
    //  }
    //
    p {
      font-family: $primary_font;
      font-weight: 400;
      font-size: 1.1rem;
    }
  }

  .text {
    text-align: center;
  }

  .icon {
    margin: auto;
    height: 70px;
    margin-top: 25px;
    padding-bottom: 10px;
  }

  .number {
    margin-top: 48px;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    p {
      font-family: $primary_font;
      width: 50%;
      margin: auto;
      font-size: 15pt;
      font-weight: bold;
      color: white;
    }
  }

  .number-container {
    padding: 0;
  }
}
</style>
