import { render, staticRenderFns } from "./ChallengeMobile.vue?vue&type=template&id=525dae5a&scoped=true&"
import script from "./ChallengeMobile.vue?vue&type=script&lang=js&"
export * from "./ChallengeMobile.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeMobile.vue?vue&type=style&index=0&id=525dae5a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525dae5a",
  null
  
)

export default component.exports