<template>
  <SyncData :initial="!!$route.query.initial" />
</template>
<script>
import SyncData from '~/components/SyncPelmData.vue';
export default {
  middleware: 'check_pelm_cap',
  components: { SyncData },
  data() {
    return {};
  },
};
</script>
<style>
.fixed-top {
  z-index: 0 !important;
}
</style>