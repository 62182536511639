<template>
  <div
    id="createTeamModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createTeamModalTitle"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-scrollable
        modal-dialog
        modal-dialog-scrollable-centered
      "
      role="document"
      style="min-width: 400px; max-width: 400px"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="createTeamModalTitle" class="modal-title">
            Create a New Team
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form id="createTeamForm" novalidate>
          <div class="modal-body" style="max-height: 800px">
            <div class="form-group">
              <label for="teamName">Property Name</label>
              <input
                id="teamName"
                v-model="teamName"
                class="form-control"
                required
              />
              <div class="invalid-feedback">Please enter a property name.</div>
            </div>

            <label>Select which Meter(s) to add to your team</label>
            <div class="card" style="position: relative">
              <div style="max-height: 250px; overflow: auto">
                <VJstree :data="data" show-checkbox multiple allow-batch />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-green" @click="createTeam">
              Create Team
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VJstree from '@/components/tree/tree';
import { actionTypes } from '@/store/types';

export default {
  name: 'CreateTeam',
  components: { VJstree },
  async fetch({ store }) {
    await store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS);
  },
  data() {
    return {
      teamName: '',
      checkedMeters: [],
      data: [],
    };
  },
  computed: {
    utilityAccounts() {
      return this.$store.state.user.utilityAccounts;
    },
    teams() {
      return this.$store.state.user.teams;
    },
  },
  async mounted() {
    function createName(utility) {
      let result = utility.utility_name;
      if (utility.account_number) {
        const temp = String(utility.account_number);
        result += ' - ' + temp.slice(temp.length - 5, temp.length - 1);
      }

      if (utility.fuel_type) {
        result += ' - ' + utility.fuel_type[0];
      }

      if (utility.rate) {
        result += ' - ' + utility.rate[0];
      }

      if (utility.address) {
        result += ' - ' + utility.address;
      }

      return result;
    }

    const isUnavailable = await this.unavailableMeters();
    const result = [];

    this.utilityAccounts.forEach((utility) => {
      const parent = {
        text: createName(utility),
        children: [],
        opened: true,
      };

      utility.meters.forEach((meter) => {
        parent.children.push({
          text: `${meter.name || 'unnamed'}${
            !meter.is_active ? ' - Not active' : ''
          }${isUnavailable[meter.id] ? ' - ' + isUnavailable[meter.id] : ''}`,
          value: meter.id,
          disabled: !meter.is_active || isUnavailable[meter.id],
        });
      });

      result.push(parent);
    });

    this.data = result;
  },

  methods: {
    // TODO: handle this server side
    // Find which meters are already on a team
    async unavailableMeters() {
      const metersInTeams = {};
      const promiseResults = await Promise.all(
        this.teams.map((team) => {
          return this.$repo.teams.get(team.id).then((result) => {
            const teamMeters = [];
            result.data.meters.forEach((meter) => {
              teamMeters.push({
                id: meter.id,
                msg: 'Already on team: ' + team.name,
              });
            });
            return teamMeters;
          });
        })
      );

      promiseResults.forEach((promiseResult) => {
        promiseResult.forEach((x) => {
          metersInTeams[x.id] = x.msg;
        });
      });
      return metersInTeams;
    },
    getSelectedNodes() {
      const selectedNodes = new Set();
      this.data.forEach((parent) => {
        parent.children.forEach((child) => {
          if (typeof child.value === 'number' && child.selected) {
            selectedNodes.add(child.value);
          }
        });
      });

      return selectedNodes;
    },
    async createTeam() {
      try {
        const form = document.getElementById('createTeamForm');
        form.checkValidity();
        form.classList.add('was-validated');

        const selectedMeters = this.getSelectedNodes();

        // Empty arrays and strings are falsy
        if (!(selectedMeters || this.teamName)) {
          return;
        }

        // Create a team
        const createTeamResult = await this.$repo.teams.create();
        const createdTeamId = createTeamResult.data.id;

        // Rename newly created team
        const renameTeamResult = await this.$repo.teams.update(createdTeamId, {
          name: this.teamName,
        });

        // Add meters to team
        const self = this;
        const addMeter = async function (meter) {
          try {
            const response = await self.$repo.teams.addMeter(
              createdTeamId,
              meter
            );
            return 0;
          } catch (error) {
            $('#meterError' + meter).text(error.response.data.msg);
            return 1;
          }
        };

        const addMetersResult = await Promise.all(
          [...selectedMeters].map((meter) => {
            return addMeter(meter);
          })
        );

        if (addMetersResult.every((result) => result === 0)) {
          $('#createTeamModal').modal('hide');
          this.$emit('team-created');
        }
      } catch (error) {
        // TODO: better error handling
        console.log('create team error', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-green {
  @import '~@/assets/css/variables';

  color: white;
  background-color: $green;
}
</style>
