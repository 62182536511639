<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-center">Leaderboard</h4>
        <div class="row border-bottom overflow-hidden">
          <div class="col-width">
            <h5 class="font-weight-bold">Rank</h5>
          </div>
          <div class="col-width">
            <h5 class="font-weight-bold text-left">Participant</h5>
          </div>
          <div
            class="col-width text-center"
            v-if="[1, 3].includes(challengeMetric)"
          >
            <h5 class="font-weight-bold">
              Electricity
              <span>
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="<p>% kWh reduced is the change in electricity use (in kilowatt-hours) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more electricity during the challenge period.</p><p>Data is updated daily.</p>"
                  ><font-awesome-icon icon="question-circle" size="sm"
                /></a>
              </span>
            </h5>

            <p>(% kWh Reduced)</p>
          </div>
          <div
            class="col-width text-center"
            v-if="[2, 3].includes(challengeMetric)"
          >
            <h5 class="font-weight-bold">
              Natural Gas
              <span
                ><a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="<p>% therms reduced is the change in natural gas use (in therms) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more natural gas during the challenge period.</p><p>Data is updated daily.</p>"
                  ><font-awesome-icon icon="question-circle" size="sm" /></a
              ></span>
            </h5>
            <p>(% therms Reduced)</p>
          </div>
          <div class="col-width text-center emission-column">
            <h5 class="font-weight-bold">
              Total CO<sub>2</sub> Reduced
              <span
                ><a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="% Total CO2 reduced is the change in CO2 emitted (in pounds) during the challenge duration period as compared to the same time period in the previous year.
For CA utilities, pounds of CO2 for electricity is calculated by applying hourly CAISO emissions data per kWh. For non-CA utilities, we apply the region specific EPA e-grid electricity emission factor per kWh. For all utilities, pounds of CO2 for natural gas is calculated by applying the natural gas emission factor (11.7 pounds/therm).
0% will be displayed if participants are emitted more CO2 during the challenge period. Data is updated daily."
                  ><font-awesome-icon icon="question-circle" size="sm" /></a
              ></span>
            </h5>
            <p>(% lbs Reduced)</p>
          </div>
          <div class="col-caret"></div>
        </div>
        <template v-for="(participantGroup, pgIndex) in participantGroups">
          <div
            :id="`participant-group-${pgIndex}`"
            data-toggle="collapse"
            :data-target="`#group-participants-${pgIndex}`"
            class="row"
            :class="{
              'border-bottom overflow-hidden':
                pgIndex < participantGroups.length - 1,
            }"
            @click="participantGroup.collapse = !participantGroup.collapse"
            :key="`participant-group-${pgIndex}`"
          >
            <div class="col-width rank">{{ pgIndex + 1 }}</div>
            <div
              class="col-width d-flex justify-content-center align-items-center"
            >
              <h5 class="pt-2">{{ participantGroup.name }}</h5>
            </div>
            <div
              class="col-width text-center mt-2"
              v-if="[1, 3].includes(challengeMetric)"
            >
              {{ participantGroup.savings_kwh | stat }}
            </div>
            <div
              class="col-width text-center mt-2"
              v-if="[2, 3].includes(challengeMetric)"
            >
              {{ participantGroup.savings_thm | stat }}
            </div>
            <div class="col-width text-center emission-column">
              <div class="mt-2">
                {{ participantGroup.savings_co2 | stat }}
              </div>
            </div>
            <div class="col-caret text-center">
              <v-icon
                class="pt-2"
                :class="{ 'mdi-rotate-180': !participantGroup.collapse }"
                >mdi-arrow-down-drop-circle-outline</v-icon
              >
            </div>
          </div>
          <div
            class="collapse my-2 py-3"
            :aria-labelledby="`participant-group-${pgIndex}`"
            :id="`group-participants-${pgIndex}`"
            :key="`participant-group-${pgIndex}`"
          >
            <div
              v-if="!groupedParticipatingTeams[participantGroup.id]"
              class="row"
            >
              <div class="text-center py-3" :style="{ flexGrow: 1 }">
                <h5>No participants in this group</h5>
              </div>
              <div class="no-participant-col emission-column"></div>
            </div>
            <div
              v-else
              v-for="(
                participant, participantIndex
              ) in groupedParticipatingTeams[participantGroup.id]"
              :key="`participant-${participantIndex}`"
              class="row"
            >
              <div class="col-width rank text-center">
                {{ participantIndex + 1 }}
              </div>
              <div class="col-width">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="participant-image">
                    <img
                      :src="
                        participant.image_url ||
                        `https://ui-avatars.com/api/?name=${participant.user_name}`
                      "
                    />
                  </div>
                  <h5>{{ participant.user_name }}</h5>
                </div>
              </div>
              <div
                class="col-width text-center mt-2"
                v-if="[1, 3].includes(challengeMetric)"
              >
                {{ participant.usage_kwh_relative | stat }}
              </div>
              <div
                class="col-width text-center mt-2"
                v-if="[2, 3].includes(challengeMetric)"
              >
                {{ participant.usage_thm_relative | stat }}
              </div>
              <div class="col-width text-center emission-column">
                <div class="mt-2">
                  {{ participant.co2_emissions_relative | stat }}
                </div>
              </div>
              <div class="col-caret"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],

  async fetch() {
    const groupStatResponse = await this.$api.get(
      `/participant-groups/${this.$route.params.challenge}/list`
    );

    this.participantGroups = groupStatResponse.data;
    this.participantGroups.forEach((element) => {
      element.collapse = true;
    });
  },
  data() {
    return {
      participantGroups: [],
    };
  },

  filters: {
    stat(value) {
      if (value) {
        let result = Math.round((value + Number.EPSILON) * 100);
        if (result < 0) {
          result = 0;
        }
        return `${result}%`;
      } else {
        return '-';
      }
    },
  },

  mounted() {
    if (process.client) {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    }
  },

  methods: {
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
  computed: {
    groupedParticipatingTeams() {
      const result = this.groupBy(this.participatingTeams, 'group_id');
      return result;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/assets/css/variables.scss';

// .bg-grey {
//   background-color: $grey;
// }

.rank {
  padding-left: 20px;
  font-weight: 600;
  color: $blue;
  font-size: 20pt;
}

.emission-column {
  background-color: #b0f1db;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.participant-image {
  height: 50x;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: $grey;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

.col-width {
  width: 18%;
  padding: 10px;
}

.col-caret {
  width: 10%;
  padding: 10px;
  background-color: #b0f1db;
}

.no-participant-col {
  width: 28%;
}
</style>