<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:item.scheduled_at="{ item }">
      <div>{{ item.scheduled_at | formatDate }}</div>
    </template>
    <template v-slot:item.is_scheduled="{ item }">
      <div class="d-flex justify-center">
        <v-switch
          v-model="item.is_scheduled"
          :loading="item.loading"
          :disabled="item.loading || item.disableSendNowButton"
          @click.native.stop
          @change="toggleEmailSetting(item)"
        >
        </v-switch>
      </div>
    </template>
    <template v-slot:item.email_sent_dtm="{ item }">
      <div v-if="item.email_sent_dtm">
        {{ item.email_sent_dtm | formatDate }}
      </div>
      <div v-else>N/A</div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        @click="sendEmail(item)"
        :disabled="item.disableSendNowButton"
        >Send Now</v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: 'Template Name',
          align: 'start',
          sortable: false,
          value: 'template_name',
        },
        {
          text: 'Scheduled At',
          align: 'center',
          sortable: false,
          value: 'scheduled_at',
        },
        {
          text: 'Sent At',
          align: 'center',
          sortable: false,
          value: 'email_sent_dtm',
        },
        {
          text: 'Schedule Control',
          align: 'center',
          sortable: false,
          value: 'is_scheduled',
        },
        {
          text: 'Actions',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const data = await this.$api.$get(
        `admind/challenges/${this.$route.params.challengeId}/email-settings`
      );
      this.items = data.settings
        .map((obj) => {
          obj.loading = false;
          obj.disableSendNowButton = obj.email_sent_dtm ? true : false;
          return obj;
        })
        .sort((a, b) => {
          let aDate = new Date(a.scheduled_at);
          let bDate = new Date(b.scheduled_at);
          return aDate - bDate;
        });
      this.loading = false;
    },
    async toggleEmailSetting(emailSetting) {
      emailSetting.loading = true;
      await this.$api.post(
        `admind/challenges/${this.$route.params.challengeId}/toggle-email-settings`,
        {
          template_name: emailSetting.template_name,
          is_scheduled: emailSetting.is_scheduled,
          task_id: emailSetting.task_id,
        }
      );
      emailSetting.loading = false;
    },
    async sendEmail(scheduleItem) {
      try {
        let res = await this.$api.get(
          `admind/challenges/${this.$route.params.challengeId}/sendmail`,
          {
            params: {
              template_name: scheduleItem.template_name,
            },
          }
        );

        scheduleItem.disableSendNowButton = true;
        scheduleItem.email_sent_dtm = new Date();
      } catch (error) {
        this.$toast.error('Oops! Something went wrong.', { duration: 5000 });
      }
    },
  },
};
</script>
