<template>
  <div id="login-page">
    <div class="main-content">
      <template v-if="!token">
        <div class="login-box login-box-first">
          <div class="back-button">
            <div class="d-flex align-items-center">
              <font-awesome-icon
                class="icon h2 mb-0"
                icon="angle-left"
                @click="$router.push('login')"
              />
              <div class="flex-grow-1"></div>
            </div>
          </div>
          <h1 class="page-title">Request Password Reset</h1>
        </div>
        <div id="request-reset" class="login-box">
          <p id="unverified" class="error">
            Email for this account hasn't been verified.
            <a href="/send-verification">Resend verification email</a>
          </p>
          <p id="social-account" class="error">
            This account was linked through facebook. Please change password
            through Facebook.
          </p>
          <p id="server-error" class="error">A server error has occurred.</p>
          <p id="no-associated-account" class="error">
            This email is not associated with an existing MeterLeader account
            please create a new account.
          </p>
          <p id="sent" class="success">
            Password reset email sent to email address.
          </p>
          <form v-on:submit.prevent="submitRequest" class="login-form">
            <input
              v-model="email"
              class="email"
              type="email"
              name="email"
              placeholder="Email address"
              required
            /><br />
            <input class="login-button" type="submit" value="Submit" />
          </form>
        </div>
      </template>

      <template v-else>
        <div class="login-box login-box-first">
          <div class="back-button">
            <div class="d-flex align-items-center">
              <font-awesome-icon
                class="icon h2 mb-0"
                icon="angle-left"
                @click="$router.push('login')"
              />
              <div class="flex-grow-1"></div>
            </div>
          </div>
          <h1 class="page-title">Reset Password</h1>
        </div>
        <div id="reset-password" class="login-box">
          <p id="bad-token" class="error">Invalid password reset token</p>
          <p id="unknown-user" class="error">
            No user exists for that email address
          </p>
          <p id="token-used" class="error">Token has already been used</p>
          <ul id="password-error">
            <li id="password-length">
              Password must be at least 8 characters long
            </li>
            <li id="password-case">
              Password must contain an upper case character
            </li>
            <li id="password-lower">
              Password must contain a lower case character
            </li>
            <li id="password-num">Password must contain a number</li>
          </ul>
          <p id="social-account" class="error">
            This account was linked through facebook. Please change password
            through Facebook.
          </p>
          <p id="server-error" class="error">A server error has occurred.</p>
          <p id="sent" class="success">Password successfully reset!</p>
          <form v-on:submit.prevent="submitNewPassword" class="login-form">
            <input
              v-model="newPassword"
              class="password"
              type="password"
              name="password"
              placeholder="New Password"
              required
            /><br />
            <input class="login-button" type="submit" value="Submit" />
          </form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  backButtonRoute: '/login',
  name: 'ResetPassword',
  asyncData({ route }) {
    return {
      token: route.query.token,
    };
  },
  data() {
    return {
      email: '',
      newPassword: '',
    };
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      this.userError = $('#unknown-user');
      this.serverError = $('#server-error');
      this.noAssociatedAccount = $('#no-associated-account');
      this.unverifiedError = $('#unverified');
      this.socialError = $('#social-account');
      this.tokenError = $('#bad-token');
      this.usedTokenError = $('#used-token');
      this.passwordError = $('#password-error');
      this.passwordLength = $('#password-length');
      this.passwordCase = $('#password-case');
      this.passwordLower = $('#password-lower');
      this.passwordNum = $('#password-num');
      this.passwordInput = $('.password');
      this.sent = $('#sent');
    }
  },
  methods: {
    submitRequest() {
      this.unverifiedError.hide();
      this.socialError.hide();
      this.serverError.hide();

      this.$sentry.captureMessage(`Reset password for: ${this.email}`);

      this.$api
        .post('/user/reset_request', { email: this.email })
        .then(() => {
          this.sent.show();
        })
        .catch((error) => {
          switch (error.response.data.msg) {
            case 'email_not_confirmed':
              this.unverifiedError.show();
              break;
            case 'social_account':
              this.socialError.show();
              break;
            case 'no-associated-account':
              this.noAssociatedAccount.show();
              break;
            default:
              this.serverError.show();
          }
        });
    },
    submitNewPassword() {
      this.tokenError.hide();
      this.userError.hide();
      this.usedTokenError.hide();
      this.passwordError.hide();
      this.socialError.hide();
      this.serverError.hide();
      this.passwordNum.removeClass('bold');
      this.passwordCase.removeClass('bold');
      this.passwordLower.removeClass('bold');
      this.passwordLength.removeClass('bold');

      this.$api
        .post('/user/reset', {
          newpassword: this.newPassword,
          token: this.token,
        })
        .then(() => {
          this.sent.show();
        })
        .catch((error) => {
          const response = error.response.data;
          switch (response.msg) {
            case 'bad_token':
              this.tokenError.show();
              break;
            case 'user_not_found':
              this.userError.show();
              break;
            case 'password_already_reset':
              this.usedTokenError.show();
              break;
            case 'weak_password':
              this.passwordError.show();
              this.passwordInput.val('');
              for (let i = 0; i < response.warning.length; i++) {
                switch (response.warning[i]) {
                  case 'Password must be at least 8 characters long':
                    this.passwordLength.addClass('bold');
                    break;
                  case 'Password must contain an upper case character':
                    this.passwordCase.addClass('bold');
                    break;
                  case 'Password must contain a lower case character':
                    this.passwordLower.addClass('bold');
                    break;
                  case 'Password must contain a number':
                    this.passwordNum.addClass('bold');
                    break;
                }
              }
              break;
            case 'social_account':
              this.socialError.show();
              break;
            default:
              this.serverError.show();
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';
.login-box-first {
  background-color: #E8E8E8 !important;
  padding: 5% 0 !important;
}
.back-button {
  width: 15px;
  float: left;
  padding-top: 10px;
}
.page-title {
  width: 95%;
  float: left;
}
@media (max-width: 320px){
  #login-page #request-reset {
    margin-top: 90px;
  }
}

@media (max-width: 480px) {
  #login-page #request-reset {
    margin-top: 90px;
  }
}
</style>
