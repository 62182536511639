<template>
  <div>
    <UtilityProviderListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/utilities'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import UtilityProviderListFilterBox from '@/components/admind/listview_filter_boxes/UtilityProviderListFilterBox.vue';
import UtilityProvidersName from '@/components/admind/listview_column_overrides/UtilityProvidersName.vue';
import EmptyPlaceholder from '@/components/admind/listview_column_overrides/EmptyPlaceholder.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    return {
      filterParams: {},
    };
  },
  dataTableSlotComponents: {
    'item.name': UtilityProvidersName,
  },
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    {
      text: 'Full Name',
      value: 'fullname',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Pelm Utility Id',
      value: 'id',
      sortable: false,
      align: 'center',
    },

    {
      text: 'Emission Subregion',
      value: 'region_acronym',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Emission Subregion Rate(lbs/kwh)',
      value: 'pound_per_kwh_rate',
      sortable: false,
      align: 'center',
    },

    { text: 'Timezone', value: 'timezone', sortable: false, align: 'right' },
  ],
  components: {
    AdminList,
    UtilityProviderListFilterBox,
  },
};
</script>