<template>
  <div id="comments" class="card">
    <div v-if="$auth.loggedIn">
      <form>
        <div class="row row justify-content-around">
          <textarea type="text" class="textarea form-control col-10 mb-2 mr-sm-2" id="inlineFormInputName2"
                     placeholder="" v-model="message"></textarea>
          <button type="button" class="btn btn-primary col mb-2 align-self-center"
                  style="height: 38px; max-width: 60px;" @click="submit">Post
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <a href="/login">Log in to comment</a>
    </div>
    <Comment v-for="comment in $store.state.comments.comments.filter(c => c.parent_id == 0)"
             :comment="comment"
             :key="comment.id"
             :challenge-id="challengeId"
             :level="0"
             style="margin-top: 15px"
    />

<!--    <DialogModal-->
<!--      id="delete-comment-modal"-->
<!--      title="Delete Comment?"-->
<!--      :message="`Delete '${commentToDelete.message}' ?`"-->
<!--      @on-confirm="deleteComment"-->
<!--    />-->

  </div>
</template>

<script>
  import Comment from "@/components/Comment";
  import DialogModal from "@/components/DialogModal";
  import {actionTypes} from "@/store/types";

  export default {
    name: "Comments",
    components: {Comment, DialogModal},
    props: ['challengeId'],
    data() {
      return {
        message: '',
        commentToDelete: {
          message: ''
        },
        comments: [],
      }
    },
    async asyncData({ app }) {
    },
    async mounted() {
      $('.textarea').on("input", function () {
        $(this).css("height", ""); //reset the height
        $(this).css("height", Math.min(jQuery(this).prop('scrollHeight'), 800) + "px");
      });
      require('linkifyjs/jquery')($, document);
      $('.comment-message').linkify();
    },
    methods: {
      async getComments() {
        try {
          await this.$store.dispatch(actionTypes.GET_COMMENTS, this.challengeId);
        } catch (e) {
        }
      },
      async submit() {
        try {
          await this.$api.post(`/challenges/${this.challengeId}/comments`, {message: this.message, parent_id: 0});
          this.message = '';
          await this.getComments();
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
