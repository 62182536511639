<template>
  <div id="footer-box" class="container-fluid">
    <div class="row">
      <div class="col">
        <div id="footer-logo-container">
          <a href="/"
            ><img id="footer-logo" src="~/assets/images/abbreviatedLogo.png"
          /></a>
        </div>
      </div>
      <div class="col">
        <h4>ABOUT</h4>
        <ul>
          <li>
            <nuxt-link to="/about-us"> About Us </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/pilot"> Become a Pilot Partner </nuxt-link>
          </li>

          <li>
            <nuxt-link to="/companies"> For Companies </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/building-owners"> For Building Owners </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/cities"> For Cities </nuxt-link>
          </li>
          <!-- <li>
            <nuxt-link to="/ecogroups"> For Environmental Groups </nuxt-link>
          </li> -->
          <!--          <li>-->
          <!--            <nuxt-link to="/organizers">-->
          <!--              For Organizers-->
          <!--            </nuxt-link>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <nuxt-link to="/earthday">-->
          <!--              Join our Earth Day Challenge-->
          <!--            </nuxt-link>-->
          <!--          </li>-->
          <li>
            <nuxt-link to="/privacy-policy"> Privacy Policy </nuxt-link>
          </li>
          <li>
<!--            <a-->
<!--              href="https://s3.amazonaws.com/meterleader-assets/meterleader-terms.pdf"-->
<!--              >Terms & Conditions</a-->
            <nuxt-link to="/terms-conditions"> Terms & Conditions </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="col">
        <h4>EXPLORE</h4>
        <ul>
          <li>
            <nuxt-link to="/how-it-works"> How it Works </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/pricing"> Pricing </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/explore-challenges"> Explore Challenges </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/faqs"> FAQs </nuxt-link>
          </li>
<!--          <li><a href="http://blog.meterleader.com/">Blog</a></li>-->
        </ul>
      </div>
      <div class="col">
        <h4>CONTACT</h4>
        <ul>
          <li>
            For questions email: <br />
            <a href="mailto:support@meterleader.com" target="_top"
              >support@meterleader.com</a
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <h4>CONNECT</h4>
        <ul id="logos">
          <li>
            <a href="https://www.facebook.com/MeterLeader-135817010607241/"
              ><img src="~/assets/images/whiteFacebookLogo.png"
            /></a>
          </li>
          <li>
            <a href="https://www.twitter.com/meterleader"
              ><img src="~/assets/images/whiteTwitterLogo.png"
            /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/meterleader"
              ><img src="~/assets/images/whiteInstagramLogo.png"
            /></a>
          </li>
        </ul>
        <p id="copyright">&copy;2023 MeterLeader Co.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/footer';
</style>
