<template>
  <div>
    <NuxtLink :to="`/admind/utility_providers/${slotParams.item.id}`">{{
      slotParams.item.name
    }}</NuxtLink>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
};
</script>