<template>
  <div>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md" style="text-align: left">
            <h1>
              Inspire your community to combat climate change.
            </h1>
            <p style="margin-top: 40px">
              MeterLeader lets you create energy savings challenges integrated with real-time PG&E, SDG&E, and SCE energy data.
            </p>
            <nuxt-link class="btn-purple" to="/challenges/create">
              Create A Challenge FREE
            </nuxt-link>
          </div>
          <div class="col-md img-right">
            <img
              src="~/assets/images/ecogroups-1.jpg"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid background-grey">
        <div class="container" style="padding-top: 40px; padding-bottom: 40px">
          <div class="row">
            <div class="col-md">
              <h2>The way we fight climate change needs to change.</h2>
              <p>
                Climate change is an existential threat to all of humanity. We
                can't wait for government to save the day. We ALL need to be
                part of the solution. As individuals our impacts might be small, but
                when we act collectively our impacts become outsized.
              </p>
            </div>
            <div class="col-md font-italic align-self-center blockquote">
              <h2 style="font-size: 1.6rem;">
                “I am telling you there is hope. I have seen it. But it does not
                come from governments or corporations. It comes from the
                people... We do not have to wait. We can start the change right
                now. We the people.”
              </h2>
              <br />
              <h3 style="font-size: 1.45rem;">
                Greta Thunberg, Climate Change Activist
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px;">
        <div class="row">
          <div class="col-md img-left">
            <img
              src="~/assets/images/SampleMeterLeaderChallenge.webp"
            />
          </div>
          <div class="col-md">
            <h1>
              Finally an easy way to reduce carbon emissions IRL.
            </h1>
            <p>
              MeterLeader uses real-time energy data to measure REAL carbon
              emission reductions. Don't settle for wishful pledges, meaningless
              self-reported actions, or abstract carbon emission estimates.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="container" style="margin-top: 25px;">
          <div class="row">
            <div class="col-md">
              <h1>
                Create a challenge in minutes and invite your community to join.
              </h1>
              <p>
                MeterLeader allows you to easily create energy saving
                challenges. Then spread the word and invite others to join you
                on social media. You don’t need to know anything about energy to
                create a challenge – we take care of all that!
              </p>
            </div>
            <div class="col-md">
              <img
                src="https://meterleaderblog.files.wordpress.com/2020/03/create-challenge2.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" style="margin-top: 25px;">
        <div class="row">
          <div class="col-md img-left">
            <img
              src="~/assets/images/ecogroups-4.webp"
            />
          </div>
          <div class="col-md">
            <h1>
              See the collective impact of your community.
            </h1>
            <p>
              Empower everyone in your community to get involved in combating
              climate change. Then see the amount of carbon emissions you've reduced together as a group.<br />
              #SaveEnergyTogether
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div id="start-challenge" class="container-fluid background-green">
        <div class="container align-content-center">
          <h1>Start an energy savings challenge today!</h1>
          <nuxt-link class="btn-white" to="/challenges/create">
            Create A Challenge FREE
          </nuxt-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import socialImage from "@/assets/images/ecogroups-1.jpg";

export default {
  name: 'ForCompanies',
  head() {
    return {
      title: 'For Environmental Groups | MeterLeader',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Inspire your community to combat climate change.',
        },
        {
          hid: 'og:image',
          name: 'image',
          property: 'og:image',
          content: `https://www.meterleader.com${socialImage}`,
        },
      ]

    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/companies';
</style>
