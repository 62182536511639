<template>
  <div>
    <NuxtLink :to="`/admind/organizations/${slotParams.item.id}/details`">{{
      slotParams.item.name
    }}</NuxtLink>
  </div>
</template>
  <script>
export default {
  props: ['slotParams'],
};
</script>