<template>
  <div id="participants" class="card">
    <h6>Leaderboard</h6>
    <template v-if="participatingTeams.length === 0">
      This challenge doesn't have any participants yet
    </template>
    <table v-else id="leaderboard">
      <colgroup>
        <col span="4">
        <col style="background-color: #b0f1db; text-align: center">
      </colgroup>
      <tr>
        <th id="ranking" style="width: 15%">
          Rank
        </th>
        <th style="width: 25%">Participant</th>
        <th id="reduced" style="text-align: center; width: 20%">
          Electricity
          <a data-toggle="tooltip" data-placement="right" data-html="true" title="<p>% kWh reduced is the change in electricity use (in kilowatt-hours) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more electricity during the challenge period.</p><p>Data is updated daily.</p>"><font-awesome-icon icon="question-circle" size="sm" /></a>
          <br /><small>(% kWh Reduced)</small>
        </th>
        <th style="text-align: center; width: 20%">Natural Gas
          <a data-toggle="tooltip" data-placement="right" data-html="true" title="<p>% therms reduced is the change in natural gas use (in therms) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more natural gas during the challenge period.</p><p>Data is updated daily.</p>"><font-awesome-icon icon="question-circle" size="sm" /></a>
          <br /><small>(% therms Reduced)</small></th>
        <th style="text-align: center; width: 20%">Total CO<sub>2</sub> Reduced
          <a data-toggle="tooltip" data-placement="right" data-html="true" title="<p>% Total CO2 reduced is the change in CO2 emitted (in pounds) during the challenge duration period as compared to the same time period in the previous year.</p><p>Pounds of CO2 are calculated by applying the electricity emission factor per kWh (0.21) and the natural gas emission factor per therm (1.17e-11).</p><p>0% will be displayed if participants are emitting more CO2 during the challenge period. Data is updated daily.</p>"><font-awesome-icon icon="question-circle" size="sm" /></a>
          <br /><small>(% lbs Reduced)</small></th>
      </tr>
      <tbody>
        <template v-for="(team, tindex) in participatingTeams">
          <tr class="team" :data-team="team.id">
            <td class="rank">{{ tindex + 1 }}</td>
            <td>
              <div class="participant-image">
                <img :src="team.members[0].photo_url" />
              </div>
              <!-- prettier-ignore -->
              <p>{{ team.members[0].firstname }} {{ team.members[0].lastname.substr(0, 1) }}</p>
            </td>
            <td style="text-align: center">{{ getKwhUsage(team) }}</td>
            <td style="text-align: center">{{ getThmUsage(team) }}</td>
            <td style="text-align: center">{{ getCo2Reduction(team) }}</td>
          </tr>
          <!--          <tr-->
          <!--            v-for="(member, mindex) in team.members"-->
          <!--            :class="['team-' + team.id, 'hide']"-->
          <!--          >-->
          <!--            <td class="rank" />-->
          <!--            <td>-->
          <!--              {{ mindex + 1 }}.-->
          <!--              <div class="participant-image">-->
          <!--                <img :src="team.image" />-->
          <!--              </div>-->
          <!--              <p>{{ member.firstname }} {{ member.lastname.substr(0, 1) }}</p>-->
          <!--            </td>-->
          <!--            <td />-->
          <!--          </tr>-->
        </template>
      </tbody>
    </table>
    <p id="view-more">
      View More
    </p>
  </div>
</template>

<script>
const moment = require('moment');
export default {
  name: 'LeaderBoardWithGas',
  props: ['participatingTeams', 'hasChallengeStarted'],
  mounted() {
    if (process.client) {

      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })

      const caret = $('.caret');
      caret.click(function(e) {
        const teamCaret = $(this);
        const teamId = teamCaret
          .parent()
          .parent()
          .attr('data-team');
        const members = $('.team-' + teamId);
        if (teamCaret.hasClass('fa-caret-right')) {
          teamCaret.removeClass('fa-caret-right').addClass('fa-caret-down');
          members.removeClass('hide');
        } else {
          teamCaret.addClass('fa-caret-right').removeClass('fa-caret-down');
          members.addClass('hide');
        }
      });
    }
  },
  methods: {
    getKwhUsage(team) {
      if (!this.hasChallengeStarted || team.account_status < 1) {
        return '-';
      }
      return `${team.usage_kwh_relative > 0 ? (team.usage_kwh_relative * 100).toFixed(1) : 0} %`;
    },
    getThmUsage(team) {
      if (!this.hasChallengeStarted || team.account_status < 1) {
        return '-';
      }
      return `${team.usage_thm_relative > 0 ? (team.usage_thm_relative * 100).toFixed(1) : 0} %`;
    },
    getCo2Reduction(team) {
      if (!this.hasChallengeStarted || team.account_status < 1) {
        return '-';
      }
      return `${team.co2_emissions_relative > 0 ? (team.co2_emissions_relative * 100).toFixed(1) : 0} %`;
    },
  },
};
</script>

<style lang="scss">
  .tooltip-inner {
    p {
      text-align: left;
    }
  }
</style>

<style scoped lang="scss">
  @import '~@/assets/css/variables.scss';

  table { table-layout: fixed; }
  table th, table td { overflow: hidden; }

  #participants {
    text-align: center;
    font-weight: bold;

    .user {
      background-color: $orange;
    }

    #user-top:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    #view-more {
      padding-top: 30px;
      cursor: pointer;
      display: none;
    }

    table {
      text-align: left;
      width: 100%;
      font-weight: 400;

      .caret {
        margin-right: 5px;
        cursor: pointer;
        width: 20px;
      }

      th {
        font-weight: 400;
        padding: 5px;
      }

      tr {
        border-bottom: 1px $grey solid;
      }

      p {
        display: inline-block;
      }

      .rank {
        padding-left: 20px;
        font-weight: 600;
        color: $blue;
        font-size: 20pt;
      }

      .participant-image {
        height: 50px;
        width: 50px;
        margin: 10px;
        border-radius: 50%;
        background-color: $grey;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;

        img {
          height: 100%;
        }
      }
    }
  }
</style>
