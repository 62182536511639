<template>
  <div :style="{ backgroundColor: '#e8e8e8', minHeight: '90vh' }">
    <div class="container">
      <h1 class="my-5 text-center">Utility Registration</h1>
      <div class="d-flex justify-content-center">
        <template v-for="step in totalSteps">
          <div
            :key="`step-${step}`"
            class="
              stepper-counter
              d-flex
              justify-content-center
              align-items-center
            "
            :class="{
              'disable-stepper': step > currentStep,
              'active-stepper ': step <= currentStep,
            }"
          >
            <h5 class="mb-1">{{ step }}</h5>
          </div>
          <svg
            v-if="step < totalSteps"
            height="20"
            width="90"
            :key="`step-line-${step}`"
          >
            <line
              x1="0"
              y1="15"
              x2="90"
              y2="15"
              style="stroke: rgb(200, 200, 200); stroke-width: 3"
            />
          </svg>
        </template>
      </div>
      <div class="mt-5">
        <div
          v-if="currentStep == 1"
          class="d-flex justify-content-center text-center"
        >
          <div
            :style="{ width: '450px', backgroundColor: '#fafafa' }"
            class="p-4"
          >
            <p id="utility-message" class="description">
              Connect your electric and/or gas utility account to your
              MeterLeader account.
              <a href="/faqs#1" target="_blank">Questions about this step?</a>
            </p>
            <button
              class="add-utility-button p-3 mt-3"
              @click="initPelm(pelmUserId)"
            >
              Add Utility Account
            </button>
          </div>
        </div>
        <div
          v-else-if="currentStep == 2 && initial"
          class="d-flex justify-content-center"
        >
          <div
            :style="{ width: '300px', backgroundColor: '#fafafa' }"
            class="p-4"
          >
            <h4>Home Information</h4>
            <hr />
            <br />
            <form @submit.prevent="teamSubmit">
              <div class="form-group">
                <label for="formGroupExampleInput">Property Name *</label>
                <input
                  type="text"
                  class="form-control"
                  id="team-name"
                  placeholder="Property Name"
                  required
                  v-model="teamFormData.name"
                />
              </div>
              <div class="form-group">
                <label for="homeType">Home Type</label>
                <select
                  class="form-control"
                  id="homeType"
                  v-model="teamFormData.home_type"
                >
                  <option disabled selected>Please select Home Type</option>
                  <option value="Single Family Home">Single Family Home</option>
                  <option value="Townhouse">Townhouse</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Condo">Condo</option>
                  <option value="Duplex">Duplex</option>
                  <option value="Mobile Home">Mobile Home</option>
                  <option value="Other">Other</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>
              <div class="form-group">
                <label for="homeType">Home Ownership</label>
                <select
                  class="form-control"
                  id="homeOwnershio"
                  v-model="teamFormData.home_ownership"
                >
                  <option disabled selected>
                    Please select Home Ownership
                  </option>
                  <option value="Renter">I am a renter.</option>
                  <option value="Home Owner">I am a home owner.</option>
                </select>
              </div>
              <div class="form-group">
                <label for="numberOfOccupants">Number of Occupants</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="numberOfOccupants"
                  aria-describedby="numberOfOccupants"
                  placeholder="Enter number of occupants"
                  v-model="teamFormData.number_of_occupants"
                />
              </div>

              <div class="form-group">
                <label for="squareFootage">Square Footage</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="squareFootage"
                  aria-describedby="squareFootage"
                  placeholder="Enter square footage"
                  v-model="teamFormData.square_footage"
                />
              </div>

              <div class="form-group">
                <label for="primaryHeatingType">Primary Heating Type</label>
                <select
                  class="form-control"
                  id="primaryHeatingType"
                  v-model="teamFormData.primary_heating_type"
                >
                  <option disabled selected>Please select Heating Type</option>
                  <option value="Natural Gas Furnace">
                    Natural Gas Furnace
                  </option>
                  <option value="Propane Furnace">Propane Furnace</option>
                  <option value="Oil Furnace">Oil Furnace</option>
                  <option value="Electric Furnace">Electric Furnace</option>
                  <option value="Electric Heat Pump">Electric Heat Pump</option>
                  <option value="Electric Baseboard Heating">
                    Electric Baseboard Heating
                  </option>
                  <option value="Electric Space Heater">
                    Electric Space Heater
                  </option>
                  <option value="Boiler">Boiler</option>
                  <option value="Other">Other</option>
                  <option value="No Heating">No Heating</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>
              <div class="form-group">
                <label for="primaryCoolingType">Primary Cooling Type</label>
                <select
                  class="form-control"
                  id="primaryCoolingType"
                  v-model="teamFormData.primary_cooling_type"
                >
                  <option disabled selected>Please select Cooling Type</option>
                  <option value="Central Cooling">Central Cooling</option>
                  <option value="Through Wall (Split System)">
                    Through Wall (Split System)
                  </option>
                  <option value="Window/Room Air Conditioner">
                    Window/Room Air Conditioner
                  </option>
                  <option value="Portable Air Conditioner">
                    Portable Air Conditioner
                  </option>
                  <option value="No AC">No AC</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>

              <div class="form-group">
                <label for="poolAndSpa">Has Pool or Spa</label>
                <select
                  class="form-control"
                  id="poolAndSpa"
                  v-model="teamFormData.has_pool_or_spa"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label for="rooftopSolar">Rooftop Solar</label>
                <select
                  class="form-control"
                  id="rooftopSolar"
                  v-model="teamFormData.rooftop_solar"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label for="homeBatteryStorage">Home Battery Storage</label>
                <select
                  class="form-control"
                  id="homeBatteryStorage"
                  v-model="teamFormData.home_battery_storage"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label for="numberOfElectricVehicles"
                  >Number of Electric Vehicles</label
                >
                <input
                  type="number"
                  min="0"
                  max="10"
                  class="form-control"
                  id="numberOfElectricVehicles"
                  aria-describedby="numberOfElectricVehicles"
                  placeholder="Enter number of electric vehicles"
                  v-model="teamFormData.no_of_electric_vehicles"
                />
              </div>

              <div class="form-group">
                <label for="numberOfGasVehicles">Number of Gas Vehicles</label>
                <input
                  type="number"
                  min="0"
                  max="10"
                  class="form-control"
                  id="numberOfGasVehicles"
                  aria-describedby="numberOfGasVehicles"
                  placeholder="Enter number of gas vehicles"
                  v-model="teamFormData.no_of_gas_vehicles"
                />
              </div>

              <div class="form-group">
                <label for="numberOfElectricPoweredTools">
                  Number of Electric Powered Tools <br />
                  <small class="label-small"
                    >(lawn mower, leaf blower, edge trimmer, chainsaw, tractor,
                    snow blower)</small
                  >
                </label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="numberOfElectricPoweredTools"
                  aria-describedby="numberOfElectricPoweredTools"
                  placeholder="Enter number of electric powered tools"
                  v-model="teamFormData.no_of_electric_powered_tools"
                />
              </div>
              <div class="form-group">
                <label for="numberOfGasPoweredTools"
                  >Number of Gas Powered Tools <br />
                  <small class="label-small"
                    >(lawn mower, leaf blower, edge trimmer, chainsaw, tractor,
                    snow blower)</small
                  ></label
                >
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="numberOfGasPoweredTools"
                  aria-describedby="numberOfGasPoweredTools"
                  placeholder="Enter number of gas powered tools"
                  v-model="teamFormData.no_of_gas_powered_tools"
                />
              </div>

              <input
                :style="{ width: '100%' }"
                type="submit"
                value="Next"
                class="add-utility-button p-3 mt-3"
              />
            </form>
          </div>
        </div>
        <div
          v-else-if="(currentStep == 2 && !initial) || currentStep == 3"
          class="d-flex justify-content-center text-center"
        >
          <div
            :style="{ width: '450px', backgroundColor: '#fafafa' }"
            class="p-4"
          >
            <template v-if="initial">
              <h5>Congratulations!</h5>
              <br />
              <p>
                Congratulations your utility has been added. Please note, it may
                take up to 20 minutes for your energy data to be displayed.

                <br /><br />Now go join an available challenge. Or if you need
                to - go add a second utility account to your property.
              </p>

              <button
                type="button"
                class="btn btn-primary"
                @click="$router.push({ path: '/challenges' })"
              >
                Join A Challenge
              </button>

              <button
                type="button"
                class="btn btn-primary"
                @click="$router.push({ path: '/account-settings?tab=Utilities' })"
              >
                Add Another Utility
              </button>
            </template>
            <template v-else>
              <h5>Congratulations!</h5>
              <br />
              <p>
                Congratulations your utility has been added. Please note, it may
                take up to 20 minutes for your energy data to be displayed.

                <br /><br />Now make sure it is associated with an existing
                property or create a new property.
              </p>

              <button
                type="button"
                class="btn btn-primary"
                @click="$router.push({ path: '/account-settings?tab=Properties' })"
              >
                Go to My Properties
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    subscriptionIdProp: { type: String, required: false },
    initial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      totalSteps: this.initial ? 3 : 2,
      currentStep: 1,
      pelmSdkLoaded: false,
      pelmUserId: null,
      subscriptionId: null,
      defaultPelmEnergyAccount: {},
      teamFormData: {},
      initialDataSynced: false,
    };
  },
  async mounted() {
    if (this.subscriptionIdProp) {
      let utilityAccout = await this.$api.get(
        `pelm/pelm-subscriptions/${this.subscriptionIdProp}`
      );
      this.pelmUserId = utilityAccout.data.pelm_user_id;
      this.subscriptionId = this.subscriptionIdProp;
    }

    let pelmCdnScript = document.createElement('script');
    pelmCdnScript.onload = () => {
      this.pelmSdkLoaded = true;
    };
    pelmCdnScript.setAttribute(
      'src',
      'https://api.pelm.com/connect/pelm-connect.js'
    );
    document.head.appendChild(pelmCdnScript);
  },
  watch: {
    async currentStep(newVal) {
      if (newVal == 2) {
        let res = await this.$api.$get('pelm/sync-user-pelm-data');

        let intervalId;

        intervalId = setInterval(async () => {
          let pollEventState = await this.$api.get(
            `poll-events/${res.poll_event_id}`
          );

          if (!pollEventState.data.data) {
            return;
          }
          this.initialDataSynced = true;
          this.$nuxt.$loading.finish();
          clearInterval(intervalId);
          await this.$api.delete(`poll-events/${res.poll_event_id}`);
        }, 5000);
      }
    },
  },
  methods: {
    directToMyUsage() {
      if (!this.initialDataSynced) {
        this.$nuxt.$loading.start();
      }

      this.$router.push({ path: '/challenges' });
    },
    async teamSubmit() {
      console.log(this.defaultPelmEnergyAccount);
      console.log(this.teamFormData);

      if (!this.defaultPelmEnergyAccount.team_id) {
        let teamRes = await this.$api.post('team/', {
          ...this.teamFormData,
        });

        let pelmMeterRes = await this.$api.patch(
          `pelm/pelm-energy-accounts/${this.defaultPelmEnergyAccount.id}`,
          { team_id: teamRes.data.id }
        );
      } else {
        // TODO dispatch a message
      }

      this.currentStep = 3;
    },
    async initPelm(pelmUserId) {
      const { connect_token, pelm_user_id } = await this.getConnectToken(
        pelmUserId
      );
      this.launchPelm(connect_token, pelm_user_id);
    },
    async getConnectToken(pelmUserId) {
      let params = {};
      if (pelmUserId) {
        params = {
          pelm_user_id: pelmUserId,
        };
      }
      this.$nuxt.$loading.start();
      const { connect_token, pelm_user_id } = await this.$api.$get(
        'pelm/get-pelm-connect-token',
        {
          params,
        }
      );
      this.$nuxt.$loading.finish();
      return { connect_token, pelm_user_id };
    },
    async launchPelm(connectToken, pelmUserId) {
      const onSuccess = async (authorizationCode) => {
        this.$nuxt.$loading.start();
        let res;
        if (this.pelmUserId && this.subscriptionId) {
          res = await this.$api.patch(
            `pelm/pelm-subscriptions/${this.subscriptionId}`,
            {
              authorization_code: authorizationCode,
              pelm_user_id: pelmUserId,
            }
          );
        } else {
          try {
            res = await this.$api.post('pelm/pelm-subscriptions', {
              authorization_code: authorizationCode,
              pelm_user_id: pelmUserId,
            });
          } catch (err) {
            this.$nuxt.$loading.finish();
            this.$toast.error(
              'Requested utility provider is not supported by meterleader.',
              { duration: 5000 }
            );
          }
        }

        let intervalId;

        intervalId = setInterval(async () => {
          let pollEventState = await this.$api.get(
            `poll-events/${res.data.poll_event_id}`
          );

          if (!pollEventState.data.data) {
            return;
          }

          if (pollEventState.data.data.error) {
            this.$toast.error(
              'This utility account is already registered with current user.',
              { duration: 5000 }
            );
            this.$nuxt.$loading.finish();
            clearInterval(intervalId);
            await this.$api.delete(`poll-events/${res.data.poll_event_id}`);
            this.currentStep = 1;
            return;
          }

          this.$nuxt.$loading.finish();

          if (this.initial) {
            this.defaultPelmEnergyAccount =
              pollEventState.data.data.energy_accounts[0];
            if (this.defaultPelmEnergyAccount.team_id) {
              this.currentStep = 3;
            } else {
              this.currentStep = 2;
            }
          } else {
            this.currentStep = 2;
          }

          clearInterval(intervalId);
          await this.$api.delete(`poll-events/${res.data.poll_event_id}`);
        }, 5000);
      };
      const onExit = () => {};

      const config = {
        connectToken,
        onSuccess,
        onExit,
      };

      if (!this.pelmSdkLoaded) {
        throw 'Pelm sdk is not loaded yet.';
      }
      const pelm = await window.PelmConnect.create(config);
      pelm.open();
    },
  },
};
</script>
<style scoped>
.stepper-counter {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  color: #fff;
}

.disable-stepper {
  background-color: gray;
}

.active-stepper {
  background-color: #1ad596;
}

.add-utility-button {
  background-color: #1ad596;
  font-weight: 700;
  box-shadow: 0 8px 8px -4px #b1a9a9;
}

.label-small {
  font-size: small;
}
</style>
