<template>
  <iframe
    src="https://blog.meterleader.com/"
    style="position: absolute; border: none; height: 70%; width: 100%"
  ></iframe>
</template>

<script>
export default {
  name: 'Blog',
};
</script>

<style scoped></style>
