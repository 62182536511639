<template>
  <div>
    <v-row>
      <v-col sm="12" md="6" lg="4">
        <v-card v-if="Object.keys(data).length > 0">
          <v-card-title>{{ `${data.id} - ${data.home_type}` }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>Home Type</v-col>
              <v-col>{{ data.home_type }}</v-col>
            </v-row>
            <v-row>
              <v-col>No of occupants</v-col>
              <v-col>{{ data.number_of_occupants }}</v-col>
            </v-row>
            <v-row>
              <v-col>Square Footage</v-col>
              <v-col>{{ data.square_footage }}</v-col>
            </v-row>
            <v-row>
              <v-col>Primary Heating Type</v-col>
              <v-col>{{ data.primary_heating_type || 'N/A' }}</v-col>
            </v-row>
            <v-row>
              <v-col>Primary Cooling Type</v-col>
              <v-col>{{ data.primary_cooling_type || 'N/A' }}</v-col>
            </v-row>
            <v-row>
              <v-col>Has pool or spa</v-col>
              <v-col>{{ data.has_pool_or_spa }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/properties/${this.$route.params.propertyId}`
      );
      this.data = res.data;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>