<template>
  <div>
    <OrganizationListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/organizations'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
  <script>
import AdminList from '@/components/admind/AdminList.vue';
import OrganizationListFilterBox from '@/components/admind/listview_filter_boxes/OrganizationListFilterBox.vue';
import LocalDateTime from '~/components/admind/listview_column_overrides/LocalDateTime.vue';
import EmptyPlaceholder from '~/components/admind/listview_column_overrides/EmptyPlaceholder.vue';
import OrganizationName from '~/components/admind/listview_column_overrides/OrganizationName.vue';

export default {
  data() {
    return {
      filterParams: {},
    };
  },
  dataTableSlotComponents: {
    'item.subscription_paln_expiry_datetime': LocalDateTime,
    'item.name': OrganizationName,
    'item.subscription_plan_type': EmptyPlaceholder,
    'item.admin_name': EmptyPlaceholder,
  },
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    {
      text: 'Corporate Code',
      align: 'center',
      sortable: false,
      value: 'corporate_code',
    },
    { text: 'Admin', value: 'admin_name', align: 'center' },
    {
      text: 'Subscription Plan',
      value: 'subscription_plan_type',
      align: 'center',
    },

    {
      text: 'Pelm subscription cap',
      value: 'maximum_pelm_subscription_allowed',
      align: 'center',
    },

    {
      text: 'Plan Expire Datetime',
      value: 'subscription_paln_expiry_datetime',
      align: 'right',
    },
  ],
  layout: 'admin_dashboard',
  components: {
    OrganizationListFilterBox,
    AdminList,
  },
};
</script>