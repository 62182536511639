<template>
  <div class="container">Logging out ...</div>
</template>

<script>
export default {
  name: 'Logout',
  async mounted() {
    await this.$auth.$storage.removeUniversal('user');
    await this.$auth.logout();
  },
};
</script>

<style scoped></style>
