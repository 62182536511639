var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","label":_vm.label || 'User',"value":_vm.selectedUser ? _vm.selectedUser.full_name : null,"append-icon":_vm.selectedUser ? 'mdi-close' : null},on:{"click:append":() => {
            _vm.selectedUser = null;
            _vm.$emit('onSelect', null);
          }}},'v-text-field',attrs,false),on))]}}])},[_vm._v(" "),_c('DynamicSelect',{attrs:{"endpoint":'admind/users',"labelKey":'full_name'},on:{"onSelect":($event) => {
          _vm.$emit('onSelect', $event.id);
          _vm.selectedUser = $event;
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }