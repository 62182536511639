<template>
  <div>
    <div class="d-flex flex-column ctabs align-items-center">
      <div
        class="ctab-item mt-2 mb-1 py-2"
        @click="
          selectedTab = item;
          $emit('update:selected', item);
        "
        v-for="(item, iIndex) in items"
        :key="`item-${iIndex}`"
        :class="{ selected: selectedTab == item }"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ['Item1', 'Item2'],
    },
    selected: {
      type: String,
      default: 'Item1',
    },
  },
  data() {
    return {
      selectedTab: this.selected,
    };
  },
  watch: {
    selected(newVal) {
      this.selectedTab = newVal;
    },
  },
};
</script>
  
  <style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.ctabs
  border-radius: 18px !important
  border: 2px solid $grey
  margin-top: 1rem !important
  padding: 10px

.ctab-item
  width: 100%
  border-radius: 18px !important
  text-align: center
  background-color: $grey
  font-weight: 400
  &.selected
    background-color: $accent
    color: #fff
    font-weight: 600
</style>