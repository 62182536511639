<template>
  <div id="home">
    <!--region Panel 1 -->
    <div id="lightning-box">
      <div class="container" style="height: 100%;">
        <div class="row no-gutters" style="height: 100%;">
          <div class="col align-self-center left">
            <h1>
              Challenge your <span id="text-cycle" class="green">team</span>
            </h1>
            <h1>to save energy</h1>
            <h5 id="banner-tagline">
              Combat climate change, save money, and have fun doing it together
            </h5>
            <div class="row no-gutters" style="max-width: 580px">
              <div class="col">
                <nuxt-link id="create-challenge" to="/challenges/create">
                  Create A Challenge
                </nuxt-link>
              </div>
              <div class="col">
                <nuxt-link
                  id="join-challenge"
                  :to="$auth.loggedIn ? '/challenges' : '/explore-challenges'"
                >
                  Join A Challenge
                </nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-5 pl-lg-3 align-self-center">
            <img
              id="lightning-img"
              src="~/assets/images/SampleMeterLeaderChallenge.webp"
            />
          </div>
        </div>
      </div>
    </div>
    <!--endregion Panel 1 -->
    <!--region Panel 2: Explore Challenges -->
    <div class="container-fluid" style="background-color: #E8E8E8">
      <div id="description-box" class="container">
        <h4 style="max-width: 700px; margin-left: 20px; margin-right: 20px;">
          Create fun energy saving challenges integrated with real-time
          electricity data
        </h4>
      </div>
    </div>
    <div id="explore-box" class="container">
      <h2>Explore Challenges</h2>
      <div id="challenges" class="container-fluid row">
        <Challenge
          v-for="challenge in challenges"
          :key="'challenge' + challenge.id"
          :challenge="challenge"
        />
      </div>
    </div>
    <!--endregion Panel 2: Explore Challenges -->
    <!--region Panel 3: How It Works -->
    <div id="homepage-how-it-works-box" class="container-fluid">
      <div class="container">
        <h2>How It Works</h2>
        <FourSteps color="purple" />
        <div id="learn-more">
          <nuxt-link to="/how-it-works">Learn More <span>></span></nuxt-link>
        </div>
      </div>
    </div>
    <!--endregion 3: How It Works -->
    <!--region Panel 4: Features -->
    <div id="features-box" class="container">
      <h2>Features</h2>
      <div class="container-fluid row justify-content-around">
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenMeter.png" />
          </div>
          <div class="col-10 right">
            <h4>Real-time Meter Data</h4>
            <p>
              Real-time residential electricity data is securely integrated into
              challenges.
            </p>
          </div>
        </div>
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenSmiley.png" />
          </div>
          <div class="col-10 right">
            <h4>Rewarding Experience</h4>
            <p>
              Save energy, win prizes, have fun, and feel good about making a
              positive impact.
            </p>
          </div>
        </div>
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenBolt.png" />
          </div>
          <div class="col-10 right">
            <h4>Energy Saving Resources</h4>
            <p>
              Receive energy saving learning modules and activities directly to
              your inbox.
            </p>
          </div>
        </div>
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenShare.png" />
          </div>
          <div class="col-10 right">
            <h4>Infused with Social Science</h4>
            <p>
              Designed with social science principles shown to achieve 1-27%
              energy savings.
            </p>
          </div>
        </div>
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenFootprint.png" />
          </div>
          <div class="col-10 right">
            <h4>Collective Impact</h4>
            <p>
              Be inspired by the aggregate energy savings achieved by your
              community.
            </p>
          </div>
        </div>
        <div class="feature col-12 col-md-5 row">
          <div class="col-2 image">
            <img src="~/assets/images/greenGroup.png" />
          </div>
          <div class="col-10 right">
            <h4>Community Engagement</h4>
            <p>
              Use MeterLeader to start a conversation about saving energy with
              your community.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--endregion Panel 4: Features -->
    <!--region Panel 5: Why Use MeterLeader -->
    <div id="reason-box" class="container-fluid">
      <div class="container">
        <h2>Why Use MeterLeader?</h2>
        <div class="reason row">
          <div class="image col-2 col-md-auto">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10 col-md-auto">
            Achieve measurable energy & carbon emission reductions
          </h4>
        </div>
        <div class="reason row">
          <div class="image col-2 col-md-auto">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10 col-md-auto">
            Engage and reward your team in a fun and meaningful activity
          </h4>
        </div>
        <div class="reason row">
          <div class="image col-2 col-md-auto">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10 col-md-auto">
            It’s easy and takes minutes to set up and run - we do all the work
            for you!
          </h4>
        </div>
      </div>
    </div>
    <!--endregion Panel 5: Why Use MeterLeader -->
    <!--region Panel 6: Why Save Energy -->
    <div id="why-save-energy-box" class="container">
      <h2>Why Save Energy?</h2>
      <div class="container-fluid row">
        <div class="col-12 col-md-4 reason">
          <h1>$2,060</h1>
          <h4>/year</h4>
          <p>
            The average US household spends
            <a href="https://www.energystar.gov/products/where_does_my_money_go"
            >$2,060 every year</a
            >
            on energy utility bills. By adopting energy saving habits and making
            energy efficiency upgrades in your home you could save big on your
            utility bills.
          </p>
        </div>
        <div class="col-12 col-md-4 reason">
          <h1>13,000</h1>
          <h4>deaths/year</h4>
          <p>
            Air pollution from power plants contributes to respiratory diseases,
            and is attributable to over
            <a
              href="https://www.huffingtonpost.com/2011/03/14/power-plant-air-pollution-coal-kills_n_833385.html"
            >13,000 premature deaths per year in the US</a
            >. By saving energy we can reduce air pollution and improve public
            health.
          </p>
        </div>
        <div class="col-12 col-md-4 reason">
          <h1>16.49</h1>
          <h4>metric tons CO<span>2</span></h4>
          <p>
            The average American produces
            <a
              href="https://data.worldbank.org/indicator/EN.ATM.CO2E.PC?end=2014&start=1960&view=chart"
            >16.49 metric tons</a
            >
            of carbon dioxide (CO2) per year. CO<span>2</span> is the primary
            driver of climate change, which threatens species and people's
            livelihoods. By saving energy we help mitigate against climate
            change.
          </p>
        </div>
      </div>
    </div>
    <!--endregion Panel 6: Why Save Energy -->
    <JoinFooter />
  </div>
</template>

<script>
  import FourSteps from '@/components/FourSteps';
  import JoinFooter from '@/components/JoinFooter';
  import Challenge from '@/components/Challenge';
  import { actionTypes } from '@/store/types';

  export default {
    name: 'Home',
    components: {
      Challenge,
      JoinFooter,
      FourSteps,
    },

    async asyncData({ store, app }) {
      try {
        await store.dispatch(actionTypes.GET_CHALLENGES);
      } catch (error) {
        console.log('error', error.response.data);
      }
    },
    data() {
      return {};
    },
    computed: {
      challenges() {
        if (process.client) {
          if (this.$store.state.challenges.length > 0) {
            this.$nextTick(function() {
              $('.challenge').hover(
                function() {
                  $(this)
                    .find('.overlay')
                    .css('display', 'block');
                },
                function() {
                  $(this)
                    .find('.overlay')
                    .css('display', 'none');
                }
              );
            });
            // TODO: do this properly
            return this.$store.state.challenges[1]
              .concat(this.$store.state.challenges[2])
              .slice(0, 3);
          }
        }
        return [];
      },
    },
    mounted() {
      const cycleElt = $('#text-cycle');
      let cycleIndex = 0;
      const cycleTexts = [
        'team',
        'community',
        'company',
        'building',
        'office',
        'school',
        'family',
        'friends',
        'team',
      ];
      setInterval(function() {
        cycleElt.text(cycleTexts[cycleIndex]);
        cycleIndex++;
        if (cycleIndex === cycleTexts.length) {
          cycleIndex = 0;
        }
      }, 4000);
    },
    head() {
      return {
        title: "Let's Save Energy Together | MeterLeader",
        meta: [
          {
            hid: 'description',
            name: 'description',
            content:
              'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: 'http://s3.amazonaws.com/meterleader-assets/fbimage.png',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content:
              'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
          },
          {
            hid: 'og:image:width',
            property: 'og:image:width',
            content: '876',
          },
          {
            hid: 'og:image:height',
            property: 'og:image:height',
            content: '451',
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '~@/assets/css/homepage';

  #homepage-how-it-works-box {
    background-color: $green;
    color: white;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 0;
    position: relative;

    h2 {
      padding-bottom: 40px;
    }

    .number {
      background-color: $purple;
    }

    #learn-more {
      width: 100%;
      text-align: right;
      padding-bottom: 40px;
      padding-top: 70px;
      padding-right: 40px;

      a {
        color: white;
        display: inline-block;
        font-size: 25px;
      }

      a:hover {
        text-decoration: none;
      }

      span {
        font-size: 22pt;
      }
    }
  }

</style>
