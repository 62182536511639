<template>
  <div>
    <ChallengeListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/challenges'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    >
    </AdminList>
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import ChallengeListFilterBox from '@/components/admind/listview_filter_boxes/ChallengeListFilterBox.vue';
import ChallengeName from '@/components/admind/listview_column_overrides/ChallengeName.vue';
import ChallengeStatus from '@/components/admind/listview_column_overrides/ChallengeStatus.vue';
import LocalDateTime from '@/components/admind/listview_column_overrides/LocalDateTime.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    const defaultFilterParams = {
      user_id: null,
      status: null,
    };
    return {
      filterParams:{...defaultFilterParams, ...this.$route.query},
    };
  },
  dataTableSlotComponents: {
    'item.name': ChallengeName,
    'item.start_date': LocalDateTime,
    'item.end_date': LocalDateTime,
    'item.status': ChallengeStatus,
  },
  mounted() {
    console.log(this.$options.dataTableSlotComponents);
  },
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
    },
    {
      text: 'Start date',
      value: 'start_date',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Sponsor name',
      value: 'sponsor_name',
      align: 'center',
      sortable: false,
    },
    { text: 'Status', value: 'status', align: 'center', sortable: false },
  ],
  components: {
    AdminList,
    ChallengeListFilterBox,
  },
};
</script>