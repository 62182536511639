<template>
  <div class="container d-flex">
    <!--    <h2 class="d-md-flex font-weight-bold">-->
    <!--      {{ $titleBar.data.title }}-->
    <!--    </h2>-->
    <div class="row">
      <div class="tc-div">
        <div class="d-none d-lg-block">
          <div class="d-flex align-items-center">
            <font-awesome-icon
              class="icon h2 mb-0"
              icon="angle-left"
              @click="$router.back()"
            />
            <div class="flex-grow-1"></div>
          </div>
        </div>
        <div class="d-lg-none" v-if="!$auth.loggedIn">
          <div class="d-flex align-items-center">
            <font-awesome-icon
              class="icon h2 mb-0"
              icon="angle-left"
              @click="$router.back()"
            />
            <div class="flex-grow-1"></div>
          </div>
        </div>
        <div id="terms-conditions" class="fit-obj">
          <h3 class="title text-center">METERLEADER<br><br>
            WEBSITE PRIVACY POLICY</h3>

          <p class="context mt-5">Last Updated: May 8, 2023</p>
          <p class="context">This Privacy Policy explains how MeterLeader Inc. or any of our corporate subsidiaries, parents,
            or affiliates (referred to as “<strong>we,</strong>” “<strong>us,</strong>” and “<strong>our</strong>”) collects, uses, and discloses information about
            any person, including any organization, in connection with the use of any website, application,
            API, or service (collectively, our “<strong>Services</strong>” or the “<strong>Site</strong>”) which links to this Privacy Policy or
            which is offered by us to any person using our Site, including any organization or person using the
            Site on an organization’s behalf (referred to as “<strong>you</strong>” or “<strong>your</strong>”). The terms of MeterLeader’s
            Website Terms of Service, available at <a href="https://s3.amazonaws.com/meterleader-assets/
            meterleader-terms.pdf">https://s3.amazonaws.com/meterleader-assets/
              meterleader-terms.pdf</a> are additionally incorporated into this Privacy Policy by reference.
            Capitalized terms used herein and not otherwise defined have the meaning ascribed to them in
            the Terms of Service.</p>

          <p class="content">This Privacy Policy does NOT apply to any information that you may provide to third-parties in
            connection with the Site; the sharing of that information is governed by the privacy policy or other
            relationship between you and each third-party with which you provide information about yourself.</p>

          <p class="content">If you do not agree with our policies and practices regarding your privacy as set out here, your
            choice is not to use our Site or access our Services. <strong>BY ACCESSING OR USING THE SITE, YOU
              AGREE TO THIS PRIVACY POLICY. THIS POLICY MAY CHANGE FROM TIME TO TIME.
              YOUR CONTINUED USE OF THE SITE AFTER WE MAKE CHANGES IS DEEMED TO BE
              ACCEPTANCE OF THOSE CHANGES, SO PLEASE CHECK THIS POLICY PERIODICALLY
              FOR UPDATES.</strong></p>

          <p class="content insider">2. <strong class="title">CHILDREN USING THE SITE</strong>. Our Site is not intended for nor directed at children under 18
            years of age. No one under age 18 may access the Site nor provide any personal information
            to or on the Site. We do not knowingly collect personal information from children under 18. If
            you are under 18, do not use or provide any information on the Site or on or through any of its
            features. If we learn we have collected or received personal information from a child under 18,
            we will delete that information. If you believe we might have any information from or about a
            child under 13, please contact us at support@meterleader.com</p>

          <p class="content insider">3. <strong class="title">INFORMATION WE COLLECT ABOUT YOU, HOW WE COLLECT AND RETAIN IT</strong>.</p>

          <p class="content">We collect several types of information from and about users of the Site, including information:</p>

          <p class="content insider-div">a. By which you may be personally identified, such as name, postal address, e-mail address,
            location (city and state), Utility Account Credentials, Utility Data, and any other
            information the Site collects that is defined as personal or personally identifiable
            information under applicable law (“<strong>personal information</strong>”);</p>

          <p class="content insider-div">b. That is about you but individually does not identify you; and</p>

          <p class="content insider-div">c. About your internet connection, the equipment you use to access the Site and usage
            details.</p>

          <p class="content">We collect this information:</p>

          <p class="content insider-div">i. Directly from you when you provide it to us through your Account on the Site or through
            your participation in a Challenge;</p>
          <p class="content insider-div">ii. Automatically as you navigate through the Site. Information collected automatically may
            include usage details, IP addresses, and information collected through cookies, web
            beacons, and other tracking technologies; and</p>
          <p class="content insider-div">iii. From third-parties to whom you have provided personal information and have authorized
            to share such information with us, for example, your Utility and our licensors and partners
            providing content on the Site.</p>

          <p class="content">We will retain any information we collect from you only for as long as is necessary for the
            purposes set out in this Privacy Policy. We will retain and use this information only to the extent
            necessary to keep the data in your Account during its activation or deactivation, or to comply with
            our legal obligations (for example, if we are required to retain your data to comply with applicable
            laws), resolve disputes, and enforce our legal agreements and policies.</p>

          <p class="content">We will also retain your usage details for internal analysis purposes. Usage details may be retained
            for a shorter period of time at our discretion, except when this data is used to strengthen the
            security or to improve the functionality of our Services, or we are legally obligated to retain this
            data for longer time periods.</p>

          <p class="content insider">4. <strong class="title">INFORMATION YOU PROVIDE TO US</strong>. The information we collect that you provide to us on or
            through the Site may include:</p>

          <p class="content insider-div">a. Information that you choose to provide us through the Site. This includes information
            provided at the time of registering to use the Site or used to request services from us or
            from third-parties via the Site (including identifiers such as your name, location, email,
            Utility Account Credentials, and Utility Data). Certain of this information is optional to
            provide. We may also ask you for information when you report a problem with the Site.</p>

          <p class="content insider-div">b. Records and copies of your correspondence (including email addresses), if you contact
            us.</p>

          <p class="content insider-div">c. Your responses to surveys that we might ask you to complete.</p>

          <p class="content insider-div">d. Your search queries or activity logs on the Site. This “log data” may include information
            such as your device Internet Protocol (“<strong>IP</strong>”) address, device name, operating system
            version, the configuration of the app when utilizing the Site, the time and date of your use
            of the Site, and other statistics.</p>

          <p class="content">5. <strong class="title">INFORMATION WE COLLECT THROUGH THIRD-PARTIES & AUTOMATIC DATA
            COLLECTION TECHNOLOGIES</strong>. As you navigate through and interact with the Site, we may
            use automatic data collection technologies to collect certain information about your
            equipment, browsing actions, and patterns, including details of your use of the Site and device
            and internet connection, including your IP address, operating system, and browser type.</p>

          <p class="content">The information we collect automatically may include personal information, or we may maintain it
            or associate it with personal information we collect in other ways or receive from third-parties. It
            helps us to improve the Site and to deliver a better and more personalized service, including by
            enabling us to:</p>

          <p class="content insider-div">a. Estimate our audience size and usage patterns;</p>

          <p class="content insider-div">b. Store information about your preferences, allowing us to customize the Site according to
            your individual interests;</p>

          <p class="content insider-div">c. Speed up your searches; and</p>

          <p class="content insider-div">d. Recognize you when you return to the Site.</p>

          <p class="content">The technologies we use for this automatic data collection may include:</p>
          <p class="content insider-div">i. Google Analytics (Google LLC). Google Analytics is a web analysis service provided by
            Google LLC. Google utilizes personal information collected to track and examine the use
            of the Site, to prepare reports on its activities, and to share them with other Google
            services. Google may use the personal information collected to contextualize and
            personalize the ads of its own advertising network.</p>
          <p class="content insider-div">ii. Platform Services and Hosting. These services have the purpose of hosting and running
            key components of the Site, therefore allowing the provision of the Site from within a
            unified platform. Such platforms provide a wide range of tools to the Owner (such as
            analytics, user registration, commenting, database management, e-commerce, payment
            processing) that imply the collection and handling of personal information.</p>
          <p class="content insider-div">iii. Displays of Content from Third-Party Platforms. This service allows you to view content
            hosted on external platforms directly from the pages of the Site and interact with them.
            This type of service might still collect web traffic data for the pages where the service is
            installed, even when users do not use it.</p>
          <p class="content insider-div">iv. Tag Management. This service helps us manage the tags or scripts needed on the Site in a
            centralized fashion. This results in your personal information collected flowing through
            these services, potentially resulting in the retention of personal information by Google Tag
            Manager, a service provided by Google LLC, the tag management service we use.</p>

          <p class="content">We also may rely on certain third-party services to help us collect and analyze this information. The
            collection and use of this information is governed by the privacy policies of the relevant thirdparty. To view the privacy policies applicable to the collection and use of information from you by
            these third-parties, please see our Terms of Service.</p>

          <p class="content">The Site may also contain links to other sites. If you click on a third-party link, you will be directed
            to that site. Note that these external sites are not operated by us. Therefore, we strongly advise
            you to review the privacy policy of these websites. We have no control over and assume no
            responsibility for the content, privacy policies, or practices of any third-party sites or services
            presented to you via the Site.</p>

          <p class="content">6. <strong class="title">HOW WE USE YOUR INFORMATION</strong>. We use information that we collect about you or that
            you provide to us, including any personal information:</p>

          <p class="content insider-div">a. To present the Site and its contents (including content generated by algorithms we use on
            the Site) to you;</p>
          <p class="content insider-div">b. To provide you with information, products, or services that you request from us;</p>
          <p class="content insider-div">c. To fulfill any other purpose for which you provide it, including in connection with the
            creation of Challenge Data or for other purposes related to your participation in a
            Challenge as set forth in our Terms of Service;</p>
          <p class="content insider-div">d. To provide you with notices about your Account;</p>
          <p class="content insider-div">e. To carry out our obligations and enforce our rights arising from any contracts entered into
            between you and us;</p>
          <p class="content insider-div">f. To notify you about changes to the Site or any products or services we offer or provide
            through it (in compliance with applicable law);</p>
          <p class="content insider-div">g. To personalize your experience on the Site, for example, to suggest content in which we
            think you may be interested;</p>
          <p class="content insider-div">h. To allow you to participate in interactive features on the Site, including to allow you to
            share information about your use of the Site through third-party social media services, and
            to provide you with better recommendations;</p>
          <p class="content insider-div">i. To detect, investigate, and prevent fraudulent transactions, abuse, and other illegal
            activities on the Site; to protect the rights, property, or safety of Users and others; to
            enforce compliance with our policies; and to comply with applicable law and government
            requests or enforce or manage legal claims or accounting and administrative tasks;</p>
          <p class="content insider-div">j. In any other way we may describe when you provide the information; and</p>
          <p class="content insider-div">k. For any other purpose with your consent.</p>

          <p class="content">7. <strong class="title">DISCLOSURE OF YOUR INFORMATION</strong>. We share or disclose certain personal information of
            Users, as follows:</p>

          <p class="content insider-div">a. To our personnel, subsidiaries, and affiliates in order to provide you with our Services.</p>
          <p class="content insider-div">b. To third-parties we use to support the Site, including Stripe, Apple and Google.</p>
          <p class="content insider-div">c. To any third-parties who offer integrated products or services on the Site, if you request
            that such third-party provides you with their products or services using the Site. The use
            of your personal information by such third-parties will be governed by the terms of their
            applicable privacy policies.</p>
          <p class="content insider-div">d. To a buyer or other successor in the event of a sale or transfer of some or all of our
            assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by us about Users and Visitors is among
            the assets transferred.</p>
          <p class="content insider-div">e. To fulfill the purpose for which you provide it, including with respect to your participation
            in a Challenge and the publication of Challenge Data related to a Challenge, as set forth in
            out Terms of Service and any applicable Challenge Rules.</p>
          <p class="content insider-div">f. For any other purpose disclosed by us when you provide the information.</p>
          <p class="content insider-div">g. With your consent.</p>

          <p class="content">We may also disclose your personal information:</p>

          <p class="content insider-div">i. To comply with any court order, law, or legal process, including to respond to any
            government or regulatory request;</p>
          <p class="content insider-div">ii. To enforce or apply our terms of service or other agreements between you and us; or</p>
          <p class="content insider-div">iii. If we believe disclosure is necessary or appropriate to protect the rights, property, or
            safety of us, our Visitors, Users, Utility Connectors, or others.</p>

          <p class="content">Finally, we may also disclose aggregated information about Users and Visitors, and information
            that does not identify any individual, without restriction.</p>

          <p class="content">8. <strong class="title">DISCLOSURE OF YOUR INFORMATION</strong>. We strive to
            provide you with choices regarding the personal information you provide to us. We have
            created mechanisms to provide you with control over the visibility of your personal information,
            which you can access by emailing us at support@meterleader.com.</p>

          <p class="content">Please note that certain functions or features of the Site may not operate properly if you change
            your information sharing settings.</p>

          <p class="content">You may also send us an email to request access to, correct or delete any personal information
            that you have provided to us. We cannot delete your personal information except by also deleting
            your Account. If your Account is deleted, Challenge Data from any Challenge you participated in
            may not be deleted immediately. We may not accommodate a request to change information if we
            believe the change would violate any law or legal requirement or cause the information to be
            incorrect.</p>

          <p class="content">Finally, you may use your mobile device, computer, or other internet-connected device’s settings
            to change how your device retains and uses information provided or collected by applications
            such as the Site operating on that device. We encourage you to familiarize yourself with your
            device’s settings before contacting us with a request to modify or delete any of your personal
            information.</p>

          <p class="content">9. <strong class="title">YOUR DATA PRIVACY RIGHTS</strong>. Depending on your location of use of the Site and the
            location of your primary residence, you may have the right to exercise certain data protections,
            including the right to request that we delete, amend or provide you with access to your
            personal information. For example, California Civil Code Section § 1798.83 permits users of
            the Site that are California residents to request certain information regarding our disclosure of
            personal information to third-parties for their direct marketing purposes. To make such a
            request, please send an email to support@meterleader.com.</p>

          <p class="content">We respond to all requests that we receive from individuals who wish to exercise their data
            protection rights in accordance with applicable data protection laws. You can contact us with your
            request by sending an email to support@meterleader.com. You may also file a complaint with your
            local data protection authority about the use of your information on the Site.</p>

          <p class="content">The rights that you have, depending on the jurisdiction in which you live, may include:</p>

          <p class="content insider-div">a. Accessing, correcting, updating, or requesting deletion of your information.</p>
          <p class="content insider-div">b. Objecting to processing of your information, asking us to restrict processing of your
            information, or requesting the portability of your information.</p>
          <p class="content insider-div">c. Opting out from receiving marketing communications that we send you at any time. You
            can exercise this right by selecting the “unsubscribe” or “opt-out” link in the marketing
            emails we send you. Additionally, you may update your email preferences by changing
            the settings in your Account.</p>
          <p class="content insider-div">d. Withdrawing your consent at any time if we have collected and processed your
            information with your consent. Withdrawing your consent will not affect the lawfulness of
            any processing that we conducted prior to your withdrawal, nor will it affect processing of
            your information conducted in reliance on lawful processing grounds other than consent.</p>

          <p class="content">We are a controller of your information for data privacy purposes. Note that third-parties using or
            providing services to the Site may also be controllers of your information; you should contact
            these third-parties and review their privacy policies if you have questions regarding how they use
            the information you provide to them.</p>

          <p class="content">10. <strong class="title">CALIFORNIA PRIVACY RIGHTS</strong>. During the preceding 12 months, we have collected, used,
            and shared the categories of personal information described in this Privacy Policy. We do not
            consider the ways we share your information as a “sale” for purposes of California law.
            California residents have certain rights to access and delete their personal information and to
            request transparency about our privacy practices. If you would like to access or delete your
            information, or otherwise exercise your rights under the California Consumer Privacy Act,
            please see the section of this Privacy Policy titled YOUR DATA PRIVACY RIGHTS. Once we
            receive your request, we may verify it by requesting information sufficient to confirm your
            identity, including by asking you for additional information. Consistent with California law, if
            you choose to exercise your rights, we will not charge you different prices, provide different
            quality of services, or discriminate against you in any way for exercising your rights, unless
            those differences are permitted by law. If you would like to use an agent registered with the
            California Secretary of State to exercise your rights, we may request evidence that you have
            provided such agent with power of attorney or that the agent otherwise has valid written
            authority to submit requests to exercise rights on your behalf.</p>

          <p class="content">11. <strong class="title">DATA SECURITY & TRANSFERS OF INFORMATION</strong>. We have implemented measures
            designed to secure your personal information from accidental loss and from unauthorized
            access, use, alteration, and disclosure. The safety and security of your information also
            depends on you. Where we have given you (or where you have chosen) a password for
            access to certain parts of the Site, you are responsible for keeping this password confidential.
            We ask you not to share your password with anyone.</p>

          <p class="content">Unfortunately, the transmission of information via the internet is not completely secure. Although
            we do our best to protect your personal information, we cannot guarantee the security of your
            personal information transmitted to or via the Site. Any transmission of personal information is at
            your own risk. We are not responsible for circumvention of any privacy settings or security
            measures contained on the Site or Services.</p>

          <p class="content">We are based in the United States, and our Site is offered to persons located in the United States.
            As such, information about you will be transferred to, and processed in, the United States, which
            may not be your country of residence. Your country of residence may have data protection laws
            that are different to the laws of the United States and, in some cases, may not be as protective.</p>

          <p class="content">12. <strong class="title">CHANGES TO OUR PRIVACY POLICY</strong>. It is our policy to post any changes we make to our
            privacy policy on this page. If we make material changes to how we treat our users’ personal
            information, we will notify you by email to the primary email address specified in your
            Account or through a notice on the Site. The date the Privacy Policy was last revised is
            identified at the top of the page. You are responsible for ensuring we have an up-to-date
            active and deliverable email address for you, and for periodically visiting the Site and this
            Privacy Policy to check for any changes.</p>

          <p class="content">13. <strong class="title">CONTACT INFORMATION</strong>. To ask questions or comment about this Privacy Policy and our
            privacy practices, contact us at:</p>

          <p class="content insider"><br>MeterLeader Inc.<br>
            Attn: Legal<br>
            6942 Lydia Dr<br>
            Huntington Beach, CA 92647<br>
            support@meterleader.com</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  pageTitle: 'Privacy Policy',
  backButtonRoute: '/account-settings',
  components: {
  },
  data() {
    return {
    };
  }
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.tc-div
  width: 100%
  margin-top: 2%
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #212529
  text-align: left
  background-color: #fff

.fit-obj
  width: 90%
  height: 99.87%
  margin: 0 auto

.content
  width: 100%
  float: left

.title
  font-size: 18px
  font-weight: bold

.bolder
  font-weight: bold

.insider
  padding-left: 1.2em
  text-indent: -1.2em

.insider-div
  width: 98%
  float: right
  padding-left: 1.2em
  text-indent: -1.2em

#terms-conditions
  padding: 3% 3% 0 3%
</style>
