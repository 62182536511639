<template>
  <div>
    <OrganizationForm
      :loading="loading"
      :initial="initial"
      @update="updateHandler($event)"
    ></OrganizationForm>
  </div>
</template>
  <script>
import OrganizationForm from '~/components/admind/organizations/OrganizationForm.vue';
export default {
  layout: 'admin_dashboard',
  components: { OrganizationForm },
  data() {
    return {
      initial: {},
      loading: false,
    };
  },
  mounted() {
    this.getInitial();
  },
  methods: {
    async getInitial() {
      this.loading = true;
      let res = await this.$api.get(
        `admind/organizations/${this.$route.params.organizationId}`
      );
      this.initial = res.data;
      if (this.initial.subscription_paln_expiry_datetime) {
        this.initial.subscription_paln_expiry_datetime = new Date(
          this.initial.subscription_paln_expiry_datetime
        );
      }
      this.loading = false;
    },
    async updateHandler(formData) {
      delete formData.id;
      try {
        await this.$api.patch(
          `admind/organizations/${this.$route.params.organizationId}`,
          {
            ...formData,
          }
        );

        this.$router.push({ name: 'admind-organizations' });
      } catch (err) {
        this.$toast.error('Oops, something went wrong.', { duration: 5000 });
      }
    },
  },
};
</script>