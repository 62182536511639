<template>
  <div id="notifications-content" class="content ml-4">
    <h3 class="font-weight-bold">Notifications</h3>
    <form id="notifications-form">
      <p id="notification-changed" class="success">
        Notification settings successfully changed
      </p>
      <p id="notification-error" class="error">
        Server Error. Please try again.
      </p>
      <div class="toggle">
        <h5>MeterLeader Newsletter</h5>
        <label class="switch">
          <input
            id="newsletter"
            v-model="emailNotifications"
            name="newsletter"
            type="checkbox"
          />
          <span class="slider" />
        </label>
      </div>
      <div class="toggle">
        <h5>Emails from Challenges</h5>
        <label class="switch">
          <input
            id="challenges"
            v-model="challengeNotifications"
            name="challenges"
            type="checkbox"
          />
          <span class="slider" />
        </label>
      </div>
      <button
        id="save-notification-changes"
        class="btn btn-green mt-3 w-100"
        type="button"
        @click="saveNotifications"
      >
        Save Changes
      </button>
    </form>
  </div>
</template>

  <script>
import { actionTypes } from '@/store/types';

export default {
  name: 'Notifications',
  pageTitle: 'Settings',
  async mounted() {
    await this.$store.dispatch(actionTypes.GET_USER_PROFILE);

    this.challengeNotifications =
      this.$store.state.user.profile.settings.challenge_notifications;
    this.emailNotifications =
      this.$store.state.user.profile.settings.email_notifications;

    const notificationChanged = $('#notification-changed');
    const notificationError = $('#notification-error');

    notificationChanged.hide();
    notificationError.hide();
  },
  data() {
    return {
      challengeNotifications: false,
      emailNotifications: false,
    };
  },
  methods: {
    async saveNotifications() {
      this.$nuxt.$loading.start();
      const notificationChanged = $('#notification-changed');
      const notificationError = $('#notification-error');
      try {
        await this.$repo.user.update({
          settings: {
            challenge_notifications: this.challengeNotifications,
            email_notifications: this.emailNotifications,
          },
        });
        notificationChanged.show();
        this.$nuxt.$loading.finish();
      } catch (error) {
        notificationError.show();
        this.$nuxt.$loading.finish();
      }
    },
  },
};
</script>

  <style lang="sass" scoped>
@import '~@/assets/css/accountSettings.scss'
button
  border-radius: 15px

//#save-notification-changes
//  width: 100%
</style>
