<template>
  <div>
    <div>
      <GroupedChallengeLeaderboard
        v-if="supportsGroupedParticipants"
        :participating-teams="participatingTeams"
        :has-challenge-started="hasChallengeStarted"
        :challenge-metric="challengeMetric"
      ></GroupedChallengeLeaderboard>
      <UngroupedChallengeLeaderboard
        v-if="!supportsGroupedParticipants"
        :participating-teams="participatingTeams"
        :has-challenge-started="hasChallengeStarted"
        :challenge-metric="challengeMetric"
      ></UngroupedChallengeLeaderboard>
    </div>
  </div>
</template>
  <script>
import GroupedChallengeLeaderboard from './NewGroupedChallengeLeaderboard.vue';
import UngroupedChallengeLeaderboard from './NewUngroupedChallengeLeaderboard.vue';

export default {
  components: {
    GroupedChallengeLeaderboard,
    UngroupedChallengeLeaderboard,
  },
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],

  computed: {
    hasParticipants() {
      return this.participatingTeams.length > 0;
    },
    supportsGroupedParticipants() {
      if (this.hasParticipants) {
        const firstParticipant = this.participatingTeams[0];
        return firstParticipant.hasOwnProperty('group_id');
      }
      return false;
    },
  },
};
</script>