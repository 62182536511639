<template>
  <div class="container">
    <div class="row mt-3">
      <div class="d-none d-lg-block" style="padding-top: 8px;">
        <div class="d-flex align-items-center">
          <font-awesome-icon
            v-if="$titleBar.data.backButtonRoute"
            class="icon h2 mb-0"
            icon="angle-left"
            @click="$router.back()"
          />
          <div class="flex-grow-1"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="detail-section">
          <div class="d-flex align-items-end title">
            <div class="img">
              <img :src="categories[tip.category].image" />
            </div>
            <h3 class="pl-3 font-weight-bold">{{ tip.name }}</h3>
          </div>
          <p class="mt-3" v-html="tip.description"></p>
          <!-- <div class="d-flex">
            <button class="action-btn-white rounded mr-3">
              Add to my plan
            </button>
            <button class="action-btn rounded">Completed</button>
          </div> -->
        </div>
      </div>
      <!-- <div class="col-12 col-md-6">
        <div class="comments-section">
          <h4>Comments</h4>
          <input
            class="rounded px-3 py-2 w-100"
            placeholder="Add a comment"
            type="text"
          />
          <div class="comment">
            <p class="mb-0">Name</p>
            <p class="font-weight-bold mb-0">Comment Title</p>
            <p class="mb-0">
              Take inventory of all the light bulbs in your home, and replace
              any halogen or incandescent light bulbs you have in the house with
              LED bulbs. LED stands for light emitting diode, which uses a
              semiconductor to convert electricity into light
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'TipName',
  // backButtonRoute: { name: 'actions' },
  backButtonRoute: '/actions',
  async asyncData({ app, params }) {
    if (params.tipName) {
      const tip = await app.$api.get(`/tips/name/${params.tipName}`);
      return {
        tip: tip.data,
      };
    }
  },
  data() {
    return {
      tip: {},
      categories: [
        { name: 'All Actions', image: '' },
        {
          name: 'Lighting',

          image: '/assets/images/lighting.png',
        },
        {
          name: 'Appliances',
          image: '/assets/images/lighting.png',
        },
        {
          image: '/assets/images/electronics.png',

          name: 'Electronics',
        },
        {
          image: '/assets/images/heating.png',

          name: 'Heating',
        },

        {
          image: '/assets/images/cooling.png',

          name: 'Cooling',
        },

        {
          image: '/assets/images/heatingAndCooling.png',

          name: 'Heating and Cooling',
        },

        {
          image: '/assets/images/hotWater.png',

          name: 'Hot Water',
        },
        { image: '/assets/images/pool.png', name: 'Pool' },
        { image: '/assets/images/other.png', name: 'Other' },
      ],
    };
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.rounded
  border-radius: 18px !important

.comment
  margin-top: 10px
  border: 2px solid $grey
  border-radius: 18px
  padding: 20px

.comments-section
  background-color: color.scale($grey, $lightness: 80%)
  border-radius: 18px
  padding: 20px

.action-btn
  padding: 5px 10px 5px 10px
  text-align: center
  border: 2px solid black

.action-btn-white
  padding: 5px 10px 5px 10px
  text-align: center
  background-color: #fff

.detail-section
  background-color: $grey
  padding: 20px
  border-radius: 18px
  > .title
    > .img
      border-radius: 15px
      background-color: $grey
      padding: 10px

      > img
        height: 100px
        width: 100px
</style>
