<template>
  <div class="container">
    <h1 v-if="error.statusCode === 404">Page not found</h1>
    <h1 v-else>An error occurred</h1>
    <nuxt-link to="/">Home page</nuxt-link>
  </div>
</template>

<script>
export default {
  props: ['error'],
  async mounted() {
    console.log(this.error);
    if (process.client && this.error.status === 422) {
      await this.$auth.logout();
    }
  },
};
</script>

<style>
@import '@fortawesome/fontawesome-svg-core/styles.css';
@import 'bootstrap/dist/css/bootstrap.css';
</style>
