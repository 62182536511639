<template>
  <div id="participants" class="card">
    <h6>Participants</h6>
    <template v-if="participatingTeams.length === 0">
      This challenge doesn't have any participants yet
    </template>
    <table v-else id="leaderboard">
      <tr>
        <th id="ranking">
          Rank
        </th>
        <th>Participant</th>
        <th id="reduced" v-if="challengeMetric === 1">
          % kWh Reduced
          <a data-toggle="tooltip" data-placement="right" data-html="true" title="<p>% kWh reduced is the change in energy use during the challenge duration period as compared to the same time period in the previous year.</p><p>If a user is consuming more energy during the challenge period as compared to the prior year, 0% will be displayed.</p><p>Data is typically updated once per day.</p>"><font-awesome-icon icon="question-circle" size="sm" /></a>
        </th>
        <th id="reduced" v-if="challengeMetric === 2">
          % therms Reduced
          <a data-toggle="tooltip" data-placement="right" data-html="true" title="<p>% therms reduced is the change in energy use during the challenge duration period as compared to the same time period in the previous year.</p><p>If a user is consuming more energy during the challenge period as compared to the prior year, 0% will be displayed.</p><p>Data is typically updated once per day.</p>"><font-awesome-icon icon="question-circle" size="sm" /></a>
        </th>
      </tr>
      <tbody>
        <template v-for="(team, tindex) in participatingTeams">
          <tr class="team" :data-team="team.id">
            <td class="rank">{{ tindex + 1 }}</td>
            <td>
              <div class="participant-image">
                <img :src="team.members[0].photo_url" />
              </div>
              <!-- prettier-ignore -->
              <p>{{ team.members[0].firstname }} {{ team.members[0].lastname.substr(0, 1) }}</p>
            </td>
            <td style="padding-left: 35px">{{ getUsage(team) }}</td>
          </tr>
          <!--          <tr-->
          <!--            v-for="(member, mindex) in team.members"-->
          <!--            :class="['team-' + team.id, 'hide']"-->
          <!--          >-->
          <!--            <td class="rank" />-->
          <!--            <td>-->
          <!--              {{ mindex + 1 }}.-->
          <!--              <div class="participant-image">-->
          <!--                <img :src="team.image" />-->
          <!--              </div>-->
          <!--              <p>{{ member.firstname }} {{ member.lastname.substr(0, 1) }}</p>-->
          <!--            </td>-->
          <!--            <td />-->
          <!--          </tr>-->
        </template>
      </tbody>
    </table>
    <p id="view-more">
      View More
    </p>
  </div>
</template>

<script>
const moment = require('moment');
export default {
  name: 'LeaderBoard',
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],
  mounted() {
    if (process.client) {

      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })

      const caret = $('.caret');
      caret.click(function(e) {
        const teamCaret = $(this);
        const teamId = teamCaret
          .parent()
          .parent()
          .attr('data-team');
        const members = $('.team-' + teamId);
        if (teamCaret.hasClass('fa-caret-right')) {
          teamCaret.removeClass('fa-caret-right').addClass('fa-caret-down');
          members.removeClass('hide');
        } else {
          teamCaret.addClass('fa-caret-right').removeClass('fa-caret-down');
          members.addClass('hide');
        }
      });
    }
  },
  methods: {
    getUsage(team) {
      if (!this.hasChallengeStarted || team.account_status < 1) {
        return '-';
      }
      if (this.challengeMetric === 1) {
        return `${team.usage_kwh_relative > 0 ? (team.usage_kwh_relative * 100).toFixed(1) : 0} %`;
      }
      if (this.challengeMetric === 2) {
        return `${team.usage_thm_relative > 0 ? (team.usage_thm_relative * 100).toFixed(1) : 0} %`;
      }
      return '-'
    },
  },
};
</script>

<style lang="scss">
  @import '~@/assets/css/variables.scss';

  #participants {
    text-align: center;
    font-weight: bold;

    .user {
      background-color: $orange;
    }

    #user-top:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    #view-more {
      padding-top: 30px;
      cursor: pointer;
      display: none;
    }

    table {
      text-align: left;
      width: 100%;
      font-weight: 400;

      .caret {
        margin-right: 5px;
        cursor: pointer;
        width: 20px;
      }

      th {
        font-weight: 400;
        padding: 5px;
      }

      tr {
        border-bottom: 1px $grey solid;
      }

      p {
        display: inline-block;
      }

      .rank {
        padding-left: 20px;
        font-weight: 600;
        color: $blue;
        font-size: 20pt;
      }

      .participant-image {
        height: 50px;
        width: 50px;
        margin: 10px;
        border-radius: 50%;
        background-color: $grey;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;

        img {
          height: 100%;
        }
      }
    }
  }

  .tooltip-inner {
    p {
      text-align: left;
    }
  }
</style>
