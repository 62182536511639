<template>
  <div>
    <AdminList
      :endpoint="'admind/users'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import UserName from '@/components/admind/listview_column_overrides/UserName.vue'

export default {
  data() {
    return {
      filterParams: {
        challenge_id: this.$route.params.challengeId
      },
    };
  },
  dataTableSlotComponents: {
    'item.full_name': UserName,
  },
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'full_name',
    },
    { text: 'Kwh savings', value: 'total_kwh_savings', align: 'center' },
    { text: 'Thermal savings', value: 'total_thm_savings', align: 'center' },
    { text: 'Co2 savings', value: 'total_co2_savings', align: 'center' },
    { text: 'Status', value: 'status', align: 'center' }
  ],
  layout: 'admin_dashboard',
  components: {
    AdminList,
  },
};
</script>