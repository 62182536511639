<template>
  <div>
    <PelmEnergyAccountFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/pelm-energy-accounts'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import PelmEnergyAccountFilterBox from '@/components/admind/listview_filter_boxes/PelmEnergyAccountFilterBox.vue';
import PelmEnergyAccountAvailableTypes from '@/components/admind/listview_column_overrides/PelmEnergyAccountAvailableTypes.vue';
import PlemEnergyAccountAddress from '@/components/admind/listview_column_overrides/PlemEnergyAccountAddress.vue';
import EmptyPlaceholder from '@/components/admind/listview_column_overrides/EmptyPlaceholder.vue';


export default {
  layout: 'admin_dashboard',
  data() {
    const defaultFilterParams = {
      user_id: null,
    };
    return {
      filterParams: {...defaultFilterParams, ...this.$route.query},
    };
  },
  dataTableSlotComponents: {
    'item.address': PlemEnergyAccountAddress,
    'item.available_meter_types': PelmEnergyAccountAvailableTypes,
    'item.last_sync_date_electric': EmptyPlaceholder,
    'item.last_sync_date_gas': EmptyPlaceholder,
  },
  headers: [
    {
      text: 'Address',
      value: 'address',
      sortable: false,
      align: 'start',
    },
    {
      text: 'Energy Types',
      align: 'center',
      sortable: false,
      value: 'available_meter_types',
    },
    {
      text: 'User',
      align: 'center',
      sortable: false,
      value: 'user_fullname',
    },
    {
      text: 'Utility Provider',
      value: 'pelm_utility_name',
      sortable: false,
      align: 'center',
    },

    {
      text: 'Last sync date (electric)',
      value: 'last_sync_date_electric',
      sortable: true,
      align: 'center',
    },
    {
      text: 'Last sync date (gas)',
      value: 'last_sync_date_gas',
      sortable: true,
      align: 'right',
    }
  ],
  components: {
    AdminList,
    PelmEnergyAccountFilterBox,
  },
};
</script>