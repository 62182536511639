<template>
  <div id="login-page">
    <div id="main">
      <div class="main-content">
        <h1>Sign Up</h1>
        <p>Already have a MeterLeader account? <a href="/login">Log In</a></p>
        <div class="login-box">
          <h3>Account sign up</h3>
          <div class="login-facebook-button">
            <img src="~/assets/images/blueFacebookLogo.png" />
            <h4>Continue with Facebook</h4>
          </div>
          <form v-on:submit.prevent="signUp" class="login-form">
            <p id="or">or</p>
            <div id="success">
              <p>
                You have successfully signed up! Please check your email for a
                verification email.
              </p>
            </div>
            <p id="email-taken" class="error">
              A user for that email already exists.
            </p>
            <p id="email-invalid" class="error">Not a valid email address</p>
            <p id="corporate-code-error" class="error">
              Invalid corporate code
            </p>
            <p id="server-error" class="error">A server error has occurred.</p>
            <ul id="password-error">
              <li id="password-length">
                Password must be at least 8 characters long
              </li>
              <li id="password-case">
                Password must contain an upper case character
              </li>
              <li id="password-lower">
                Password must contain a lower case character
              </li>
              <li id="password-num">Password must contain a number</li>
            </ul>
            <input
              v-model="firstname"
              class="fName"
              type="text"
              name="firstname"
              placeholder="First Name"
              required
            /><br />
            <input
              v-model="lastname"
              class="lName"
              type="text"
              name="lastname"
              placeholder="Last Name"
            /><br />
            <input
              v-model="email"
              class="email"
              type="email"
              name="email"
              placeholder="Email address"
              required
            /><br />
            <input
              v-model="password"
              class="password"
              type="password"
              name="password"
              placeholder="Password"
              required
            /><br />

            <input
              v-model="corporate_code"
              class="corporate-code"
              type="lName"
              name="corporate_code"
              placeholder="Corporate code (Optional)"
            /><br />

            <input
              id="signup"
              class="login-button"
              type="submit"
              value="Sign Up"
            />
          </form>
          <div id="bottom-signup">
            <p>
              By creating an account you are accepting our
              <a
                href="https://s3.amazonaws.com/meterleader-assets/meterleader-terms.pdf"
                >Terms and Conditions</a
              >
              and
              <a
                href="https://s3.amazonaws.com/meterleader-assets/meterleader-privacy.pdf"
                >Privacy Policy</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signup',
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      corporate_code: '',
    };
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      $('.login-facebook-button').click(function (e) {
        window.location.href = process.env.API_ADDRESS + '/facebook/login';
      });

      this.loginForm = $('.login-form');
      this.emailTaken = $('#email-taken');
      this.success = $('#success');
      this.passwordError = $('#password-error');
      this.passwordLength = $('#password-length');
      this.passwordCase = $('#password-case');
      this.passwordLower = $('#password-lower');
      this.passwordNum = $('#password-num');
      this.emailError = $('#email-invalid');
      this.serverError = $('#server-error');
      this.emailInput = $('.email');
      this.corporateCodeInput = $('.corporate-code');
      this.passwordInput = $('.password');
      this.nameLength = $('#name-length');
      this.corporateCodeError = $('#corporate-code-error');
    }
  },
  methods: {
    signUp() {
      const data = {
        email: this.email.trim(),
        password: this.password.trim(),
        firstname: this.firstname.trim(),
        lastname: this.lastname.trim(),
        corporate_code: this.corporate_code.trim(),
      };

      try {
        const joinChallengeId = localStorage.getItem('joinChallengeId');
        if (joinChallengeId) {
          data['join_challenge_id'] = joinChallengeId;
        }
        localStorage.removeItem('joinChallengeId');
      } catch (e) {}

      this.$api
        .post('/user/create_user', data)
        .then(() => {
          this.$router.replace('/signup/confirm');
        })
        .catch((error) => {
          this.passwordError.hide();
          this.emailTaken.hide();
          this.success.hide();
          this.emailError.hide();
          this.serverError.hide();

          this.passwordNum.removeClass('bold');
          this.passwordCase.removeClass('bold');
          this.passwordLower.removeClass('bold');
          this.passwordLength.removeClass('bold');

          switch (error.response.data.msg) {
            case 'weak_password':
              this.passwordError.show();
              this.passwordInput.val('');
              for (let i = 0; i < error.response.data.warning.length; i++) {
                switch (error.response.data.warning[i]) {
                  case 'Password must be at least 8 characters long':
                    this.passwordLength.addClass('bold');
                    break;
                  case 'Password must contain an upper case character':
                    this.passwordCase.addClass('bold');
                    break;
                  case 'Password must contain a lower case character':
                    this.passwordLower.addClass('bold');
                    break;
                  case 'Password must contain a number':
                    this.passwordNum.addClass('bold');
                    break;
                }
              }
              break;
            case 'bad_email':
              this.emailError.show();
              this.emailInput.addClass('warning');
              break;
            case 'bad_corporate_code':
              this.corporateCodeError.show();
              this.corporateCodeInput.addClass('warning');
              break;
            case 'user_exists':
              this.emailTaken.show();
              this.loginForm.trigger('reset');
              break;
            case 'bad_firstname':
              this.nameLength.show();
              this.passwordInput.val('');
              break;
            default:
              this.serverError.show();
              this.loginForm.trigger('reset');
              break;
          }
        });
    },
  },
  head() {
    return {
      title: 'Sign up',
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';
</style>
