<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-center">Leaderboard</h4>

        <div class="row border-bottom flex-nowrap overflow-hidden">
          <div class="col-width">
            <h5 class="font-weight-bold">Rank</h5>
          </div>
          <div class="col-width">
            <h5 class="font-weight-bold text-left">Participant</h5>
          </div>
          <div
            class="col-width text-center"
            v-if="[1, 3].includes(challengeMetric)"
          >
            <h5 class="font-weight-bold">
              Electricity
              <span>
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="<p>% kWh reduced is the change in electricity use (in kilowatt-hours) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more electricity during the challenge period.</p><p>Data is updated daily.</p>"
                  ><font-awesome-icon icon="question-circle" size="sm"
                /></a>
              </span>
            </h5>

            <p>(% kWh Reduced)</p>
          </div>
          <div
            class="col-width text-center"
            v-if="[2, 3].includes(challengeMetric)"
          >
            <h5 class="font-weight-bold">
              Natural Gas
              <span
                ><a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="<p>% therms reduced is the change in natural gas use (in therms) during the challenge duration period as compared to the same time period in the previous year.</p><p>0% will be displayed if participants are using more natural gas during the challenge period.</p><p>Data is updated daily.</p>"
                  ><font-awesome-icon icon="question-circle" size="sm" /></a
              ></span>
            </h5>
            <p>(% therms Reduced)</p>
          </div>
          <div class="col-width text-center emission-column">
            <h5 class="font-weight-bold">
              Total CO<sub>2</sub> Reduced
              <span
                ><a
                  data-toggle="tooltip"
                  data-placement="right"
                  data-html="true"
                  title="% Total CO2 reduced is the change in CO2 emitted (in pounds) during the challenge duration period as compared to the same time period in the previous year.
For CA utilities, pounds of CO2 for electricity is calculated by applying hourly CAISO emissions data per kWh. For non-CA utilities, we apply the region specific EPA e-grid electricity emission factor per kWh. For all utilities, pounds of CO2 for natural gas is calculated by applying the natural gas emission factor (11.7 pounds/therm).
0% will be displayed if participants are emitted more CO2 during the challenge period. Data is updated daily."
                  ><font-awesome-icon icon="question-circle" size="sm" /></a
              ></span>
            </h5>
            <p>(% lbs Reduced)</p>
          </div>
        </div>

        <div v-if="participatingTeams.length <= 0" class="text-center my-5">
          <div class="h5">
            No participants have yet joined the challenge.
          </div>
        </div>

        <div
          v-for="(item, index) in participatingTeams"
          :key="`lb-${index}`"
          class="row flex-nowrap"
          :class="{
            'border-bottom': index < participatingTeams.length - 1,
          }"
        >
          <div class="col-width rank">{{ index + 1 }}</div>
          <div
            class="col-width d-flex align-items-center justify-content-start"
          >
            <div class="d-flex align-items-center">
              <div class="participant-image d-none d-md-flex">
                <img
                  :src="
                    item.image_url ||
                    `https://ui-avatars.com/api/?name=${item.user_name}`
                  "
                />
              </div>
              <h5 class="mb-0">{{ item.user_name }}</h5>
            </div>
          </div>
          <div
            class="
              col-width
              text-center
              d-flex
              align-items-center
              justify-content-center
            "
            v-if="[1, 3].includes(challengeMetric)"
          >
            {{ item.usage_kwh_relative | stat }}
          </div>
          <div
            class="
              col-width
              text-center
              d-flex
              align-items-center
              justify-content-center
            "
            v-if="[2, 3].includes(challengeMetric)"
          >
            {{ item.usage_thm_relative | stat }}
          </div>
          <div
            class="
              col-width
              text-center
              emission-column
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <div>{{ item.co2_emissions_relative | stat }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],
  filters: {
    stat(value) {
      if (value) {
        let result = Math.round((value + Number.EPSILON) * 100);
        if (result < 0) {
          result = 0;
        }
        return `${result}%`;
      } else {
        return '-';
      }
    },
  },
  mounted() {
    if (process.client) {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    }
  },
};
</script>
<style scoped lang="scss">
@import '~@/assets/css/variables.scss';
.rank {
  padding-left: 20px;
  font-weight: 600;
  color: $blue;
  font-size: 20pt;
}

.emission-column {
  background-color: #b0f1db;
}

.participant-image {
  height: 50x;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: $grey;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

.col-width {
  width: 20%;
  padding: 10px;
}
</style>
