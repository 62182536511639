<template>
  <div class="container">
    <div class="d-none d-lg-block" style="padding-top: 6px;">
      <div class="d-flex align-items-center">
        <font-awesome-icon
          v-if="$titleBar.data.backButtonRoute"
          class="icon h2 mb-0"
          icon="angle-left"
          @click="$router.back()"
        />
        <div class="flex-grow-1"></div>
      </div>
    </div>
    <div id="challenge-rules" v-html="challenge.description_rules_url"></div>
  </div>
</template>
<script>
export default {
  pageTitle: 'Challenge Rules',
  backButtonRoute: '/account-settings',
  data() {
  },
  async asyncData({ params, app }) {
    let challenge;
    try {
      challenge = await app.$repo.challenges.get(params.rule);
      challenge = challenge.data;

      return {
        challenge: challenge
      };
    } catch (e) {
      console.log(e);
    }
  },
}
</script>
<style lang="sass">
@use "sass:color"
@import '~@/assets/css/challengeRule'

//.fit-obj
//  width: 90%
//  height: 99.87%
//  margin: 0 auto
//
//.content
//  width: 100%
//  float: left
//
//.title
//  font-size: 18px
//  font-weight: bold
//
//.bolder
//  font-weight: bold
//
//.insider
//  padding-left: 1.2em
//  text-indent: -1.2em
//
//.insider-div
//  width: 98%
//  float: right
//  padding-left: 1.2em
//  text-indent: -1.2em

</style>

