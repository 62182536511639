<template>
  <div>
    <UserListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/users'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import UserListFilterBox from '@/components/admind/listview_filter_boxes/UserListFilterBox.vue';
import UserName from '@/components/admind/listview_column_overrides/UserName.vue';
import UserAction from '@/components/admind/listview_column_overrides/UserAction.vue';

export default {
  data() {
    return {
      filterParams: {},
    };
  },
  dataTableSlotComponents: {
    'item.full_name': UserName,
    'item.actions': UserAction,
  },
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'full_name',
    },
    { text: 'Email', value: 'email', align: 'center' },
    { text: 'Total challenges', value: 'total_challenges', align: 'center' },
    // { text: 'Kwh savings', value: 'total_kwh_savings', align: 'center' },
    // { text: 'Thermal savings', value: 'total_thm_savings', align: 'center' },
    // { text: 'Co2 savings', value: 'total_co2_savings', align: 'center' },
    { text: 'Status', value: 'status', align: 'center' },
    { text: 'Actions', value: 'actions', align: 'right', sortable: false },
  ],
  layout: 'admin_dashboard',
  components: {
    UserListFilterBox,
    AdminList,
  },
};
</script>