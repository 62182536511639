<template>
  <div id="login-page">
    <div class="main-content">
      <h1>Thank you for signing up!</h1>
      <p>Check your email to complete registration.</p>
      <h4>
        Didn't receive an email?
        <nuxt-link to="/signup/send-verification"
          >Resend verification email.</nuxt-link
        >
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';
</style>
