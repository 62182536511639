<template>
  <div
    id="editMetersModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editMetersModalTitle"
    aria-hidden="true"
  >
    <!--  <div class="" id="createTeamModal" tabindex="-1" aria-labelledby="createTeamModalTitle"-->
    <!--       aria-hidden="false">-->
    <div
      class="
        modal-dialog
        modal-dialog-scrollable
        modal-dialog
        modal-dialog-scrollable-centered
      "
      role="document"
      style="max-width: 400px"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="editMetersTitle" class="modal-title">Edit meters</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form id="editMetersForm" novalidate>
          <div class="modal-body" style="max-height: 800px">
            <label>Select which Meter(s) to add or remove</label>
            <div class="card" style="position: relative">
              <div style="max-height: 250px; overflow: auto">
                <VJstree :data="data" show-checkbox multiple allow-batch />
              </div>
            </div>
            <p class="error-message">{{ errorMessage }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-green" @click="save">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VJstree from '@/components/tree/tree';
import { actionTypes } from '@/store/types';

export default {
  name: 'EditMeters',
  components: { VJstree },
  props: {
    selectedTeamDetails: Object,
  },
  async fetch({ store }) {
    await store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS);
  },
  data() {
    return {
      teamName: '',
      checkedMeters: [],
      data: [],
      errorMessage: '',
    };
  },
  computed: {
    utilityAccounts() {
      return this.$store.state.user.utilityAccounts;
    },
    teams() {
      return this.$store.state.user.teams;
    },
  },
  async mounted() {
    function createName(utility) {
      let result = utility.utility_name;
      if (utility.account_number) {
        const temp = String(utility.account_number);
        result += ' - ' + temp.slice(temp.length - 5, temp.length - 1);
      }

      if (utility.fuel_type) {
        result += ' - ' + utility.fuel_type[0];
      }

      if (utility.rate) {
        result += ' - ' + utility.rate[0];
      }

      if (utility.address) {
        result += ' - ' + utility.address;
      }

      return result;
    }

    const isUnavailable = await this.unavailableMeters();

    const teamMetersSet = new Set(
      this.selectedTeamDetails.meters.map((meter) => meter.id)
    );

    this.utilityAccounts.forEach((utility) => {
      const parent = {
        text: createName(utility),
        children: [],
        opened: true,
      };

      utility.meters.forEach((meter) => {
        parent.children.push({
          text: `${meter.name || 'unnamed'}${
            !meter.is_active ? ' - Not active' : ''
          }${isUnavailable[meter.id] ? ' - ' + isUnavailable[meter.id] : ''}`,
          value: meter.id,
          disabled: !meter.is_active || isUnavailable[meter.id],
          selected: teamMetersSet.has(meter.id),
        });
      });

      this.data.push(parent);
    });
  },

  methods: {
    // TODO: handle this server side
    async unavailableMeters() {
      const teamMetersSet = new Set(
        this.selectedTeamDetails.meters.map((meter) => meter.id)
      );
      const metersInTeams = {};
      const promiseResults = await Promise.all(
        this.teams.map((team) => {
          return this.$repo.teams.get(team.id).then((result) => {
            const d = [];
            result.data.meters.forEach((meter) => {
              d.push({ id: meter.id, msg: 'Already on team: ' + team.name });
            });
            return d;
          });
        })
      );
      promiseResults.forEach((o) => {
        o.forEach((x) => {
          metersInTeams[x.id] = x.msg;
        });
      });

      for (const teamMeter of teamMetersSet) {
        delete metersInTeams[teamMeter];
      }

      return metersInTeams;
    },
    getSelectedNodes() {
      const selectedNodes = new Set();
      this.data.forEach((parent) => {
        parent.children.forEach((child) => {
          if (typeof child.value === 'number' && child.selected) {
            selectedNodes.add(child.value);
          }
        });
      });

      return selectedNodes;
    },
    async save() {
      this.errorMessage = '';
      this.$nuxt.$loading.start();
      // Meter ids which are currently on the team
      const teamMetersSet = new Set(
        this.selectedTeamDetails.meters.map((meter) => meter.id)
      );
      // Meter ids which are selected
      const selectedMeters = this.getSelectedNodes();

      // Meter ids which will be newly added to the team
      const addedMeters = new Set(
        [...selectedMeters].filter((x) => !teamMetersSet.has(x))
      );

      // Meter ids which were removed from the team
      const removedMeters = new Set(
        [...teamMetersSet].filter((x) => !selectedMeters.has(x))
      );

      try {
        for (const addedMeter of addedMeters) {
          await this.$repo.teams.addMeter(
            this.selectedTeamDetails.id,
            addedMeter
          );
        }
      } catch (error) {
        this.$nuxt.$loading.finish();
        this.errorMessage = error.response.data.msg;
      }

      try {
        for (const removedMeter of removedMeters) {
          await this.$repo.teams.removeMeter(
            this.selectedTeamDetails.id,
            removedMeter
          );
        }
      } catch (error) {
        this.$nuxt.$loading.finish();
        this.errorMessage = error.response.data.msg;
      }
      this.$nuxt.$loading.finish();

      if (this.errorMessage === '') {
        $('#editMetersModal').modal('hide');
        this.$emit('meters-updated');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-green {
  @import '~@/assets/css/variables';

  color: white;
  background-color: $green;
}

.error-message {
  color: red;
  margin-top: 15px !important;
}
</style>
