<template>
  <div>
    <PropertyListFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/properties'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import PropertyListFilterBox from '@/components/admind/listview_filter_boxes/PropertyListFilterBox.vue';
import PropertyId from '@/components/admind/listview_column_overrides/PropertyId.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    const defaultFilterParams = {
      user_id: null,
    };
    return {
      filterParams: {...defaultFilterParams, ...this.$route.query},
    };
  },
  dataTableSlotComponents: {
    'item.id': PropertyId,
  },
  headers: [
    {
      text: '#ID',
      align: 'start',
      sortable: false,
      value: 'id',
    },
    {
      text: 'Home Type',
      value: 'home_type',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Number of occupants',
      value: 'number_of_occupants',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Square Footage',
      value: 'square_footage',
      sortable: false,
      align: 'center',
    },
  ],
  components: {
    AdminList,
    PropertyListFilterBox,
  },
};
</script>