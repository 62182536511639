<template>
  <div class="mb-1 mt-1">
    <v-row no-gutters align="center" class="mb-4">
      <v-col cols="9" md="7" lg="4">
        <v-text-field
          label="Search challenges"
          append-icon="mdi-magnify"
          color="green"
          rounded
          solo
          hide-details
          v-model="filterFields.name_includes"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="5" lg="8" align="start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              rounded
              v-bind="attrs"
              v-on="on"
              @click="open = !open"
              class="ml-2"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
          <span>Toggle filter options</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card v-if="open" elevation="0">
        <v-card-title>Filter By</v-card-title>
        <v-card-text class="d-flex">
          <v-responsive max-width="300" height="50">
            <UserSelect class="pr-2"
              :value="filterFields.user_id"
              @onSelect="$event = filterFields.user_id = $event"
            ></UserSelect>
          </v-responsive>
          <v-responsive max-width="300" height="50">
            <v-select
              v-model="filterFields.status"
              :items="challengeStatuses"
              label="Status"
              clearable
              hide-details
            ></v-select>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>
<script>
import UserSelect from '@/components/admind/UserSelect.vue';

export default {
  props: ['filterParams'],
  components: { UserSelect },
  data() {
    return {
      open: false,
      filterFields: this.filterParams,
      challengeStatuses: [
        {
          text: 'Draft',
          value: "0",
        },
        {
          text: 'Submitted',
          value: "1",
        },
        {
          text: 'Returned',
          value: "2",
        },
        {
          text: 'Approved',
          value: "3",
        },
        {
          text: 'Launched',
          value: "4",
        },
        {
          text: 'Started',
          value: "5",
        },
        {
          text: 'Ended',
          value: "6",
        },
        {
          text: 'Closed',
          value: "7",
        },
        {
          text: 'Removed',
          value: "-1",
        },
      ],
    };
  },
  watch: {
    filterFields: {
      handler: function (newVal) {
        this.$emit('update:filterParams', newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
</style>