<template>
  <div class="leaderboard px-md-5 pb-4">
<!--    <h3 class="py-3">Leaderboard</h3>-->
    <div class="group-label d-flex align-items-center">
      <div style="flex: 6">Participants</div>
      <div style="flex: 2">Electricity</div>
      <div style="flex: 2">Gas</div>
      <div style="flex: 2">CO2</div>
      <div style="flex: 1"></div>
    </div>
    <div
      class="participant-group"
      v-for="(participantGroup, pgIndex) in participantGroups"
      :key="`participant-group-${pgIndex}`"
    >
      <div class="group-detail d-flex align-items-center py-4">
        <div class="group-rank text-left pl-2" style="flex: 1">
          {{ pgIndex + 1 }}
        </div>
        <div class="font-weight-bold" style="flex: 5">
          {{ participantGroup.name }}
        </div>
        <div style="flex: 2"><span class="pl-3">{{ participantGroup.savings_kwh | stat }}</span></div>
        <div style="flex: 2"><span class="pl-2">{{ participantGroup.savings_thm | stat }}</span></div>
        <div style="flex: 2" class="group-co2">
          <span class="c-spacer-0">{{ participantGroup.savings_co2 | stat }}</span>
        </div>
        <div style="flex: 1" class="text-right pr-2" @click="toggleCaret(pgIndex)">
          <font-awesome-icon :icon="participantToggle[pgIndex].icon" size="lg"/>
        </div>
      </div>
      <div
        class="participant-detail d-flex align-items-center" :class="participantToggle[pgIndex].class"
        v-if="!groupedParticipatingTeams[participantGroup.id]"
      >
        <div class="py-3 text-center">No participants in this group</div>
      </div>
      <div
        v-else
        class="participant-detail d-flex align-items-center" :class="participantToggle[pgIndex].class"
        v-for="(participant, participantIndex) in groupedParticipatingTeams[
          participantGroup.id
        ]"
        :key="`participant-${participantIndex}`"
      >
        <div class="text-center font-weight-bold" style="flex: 1">
          {{ participantIndex + 1 }}
        </div>
        <div style="flex: 5">
          <img
            class="avatar"
            :src="
              participant.image_url ||
              `https://ui-avatars.com/api/?name=${participant.user_name}`
            "
          />
          {{ participant.user_name }}
        </div>
        <div style="flex: 2"><span class="c-spacer-1">{{ participant.usage_kwh_relative | stat }}</span></div>
        <div style="flex: 2"><span class="c-spacer-2">{{ participant.usage_thm_relative | stat }}</span></div>
        <div style="flex: 2" class="group-co2">
          <span>{{ participant.usage_thm_relative | stat }}</span>
        </div>
        <div style="flex: 1" class="text-right"></div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],

  async fetch() {
    const groupStatResponse = await this.$api.get(
      `/participant-groups/${this.$route.params.challenge}/list`
    );

    this.participantGroups = groupStatResponse.data;
    // this.participantGroups.forEach((element) => {
    //   element.collapse = true;
    // });
    this.participantGroups.forEach(obj => {
      this.participantToggle.push({class: 'd-none', icon: 'caret-right'});
    });
  },
  data() {
    return {
      participantGroups: [],
      participantToggle: [],
    };
  },

  filters: {
    stat(value) {
      if (value) {
        let result = Math.round((value + Number.EPSILON) * 100);
        if (result < 0) {
          result = 0;
        }
        return `${result}%`;
      } else {
        return '-';
      }
    },
  },

  mounted() {
    if (process.client) {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    }
  },

  methods: {
    toggleCaret(key, event) {
      if (this.participantToggle[key].icon === 'caret-right') {
        this.participantToggle[key].icon = 'caret-down';
        this.participantToggle[key].class = '';
      } else {
        this.participantToggle[key].icon = 'caret-right';
        this.participantToggle[key].class = 'd-none';
      }
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
  computed: {
    groupedParticipatingTeams() {
      const result = this.groupBy(this.participatingTeams, 'group_id');
      return result;
    },
  },
};
</script>
<style scoped lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/variables.scss'

.avatar
  width: 100%
  height: 50px
  width: 50px
  border-radius: 50px
  margin-right: 5px

.leaderboard
  margin-top: 15px
  background-color: #fff
  border-radius: 10px

.group-rank
  font-size: 1.5rem
  color: $accent

.group-label
  > div
    font-size: 0.7rem
    color: $medium-grey

.participant-group
  background-color: $light-grey

  border-radius: 10px
  margin-top: 5px

  > .participant-detail
    border-top: 0.3rem solid #fff
    padding: 15px
    > .group-co2
      background-color: rgba(26, 213, 150, 0.3)
      text-align: center
      padding: 15px 5px 15px 5px
      border-radius: 15px

    > .group-detail
      padding: 15px
      font-weight: 600
      > .group-co2
        background-color: #1AD596
        text-align: center
        padding: 15px 8px 15px 8px
        border-radius: 15px

.c-spacer-0
  padding-left: 0.85rem

.c-spacer-1
  padding-left: 1.27rem

.c-spacer-2
  padding-left: 1rem

.d-none
  display: none !important
</style>
