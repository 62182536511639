<template>
  <div>
    <PelmSubscriptionFilterBox :filterParams.sync="filterParams" />
    <AdminList
      :endpoint="'admind/pelm-subscriptions'"
      :headers="$options.headers"
      :filterParams="filterParams"
      :dataTableSlotComponents="$options.dataTableSlotComponents"
    />
  </div>
</template>
<script>
import AdminList from '@/components/admind/AdminList.vue';
import PelmSubscriptionFilterBox from '@/components/admind/listview_filter_boxes/PelmSubscriptionFilterBox.vue';
import PelmSubscriptionDetailLink from '@/components/admind/listview_column_overrides/PelmSubscriptionDetailLink.vue';

export default {
  layout: 'admin_dashboard',
  data() {
    const defaultFilterParams = {
      user_id: null,
    };
    return {
      filterParams: {...defaultFilterParams, ...this.$route.query},
    };
  },
  dataTableSlotComponents: {
    'item.pelm_user_id': PelmSubscriptionDetailLink,
  },
  headers: [
    {
      text: 'Pelm User Id',
      value: 'pelm_user_id',
      sortable: false,
      align: 'start',
    },
    {
      text: 'User',
      align: 'center',
      sortable: false,
      value: 'user_fullname',
    },
    {
      text: 'Utility Provider',
      value: 'pelm_utility_name',
      sortable: false,
      align: 'center',
    },
    { text: 'Is active', value: 'is_active', sortable: false, align: 'center' },
  ],
  components: {
    AdminList,
    PelmSubscriptionFilterBox,
  },
};
</script>