<template>
  <div v-if="show">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-body m-4">
                <div class="text-center">
                  <h3 class="py-4">Which team are you affiliated with?</h3>
                  <div class="form-group">
                    <select
                      class="form-control-lg p-2 mb-2 custom-select"
                      v-model="selectedParticipantGroup"
                      :style="{ width: '100%' }"
                    >
                      <option :value="null" selected>Select Team</option>
                      <option
                        v-for="(participantGroup, index) in participantGroups"
                        :key="`team-${index}`"
                        :value="participantGroup"
                      >
                        {{ participantGroup.name }}
                      </option>
                    </select>
                  </div>
                  <h5 class="text-left py-2">
                    Don't see your team? Please send us an email at
                    support@meterleader.com
                  </h5>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="
                      $emit(
                        'selectedParticipantGroup',
                        selectedParticipantGroup
                      )
                    "
                    :disabled="!selectedParticipantGroup"
                  >
                    Join Challenge
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="show = false"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    challengeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: this.showModal,
      selectedParticipantGroup: null,
      participantGroups: [],
    };
  },
  watch: {
    showModal(val) {
      this.show = val;
    },
    show(val) {
      this.$emit('update:showModal', val);
    },
  },

  async mounted() {
    await this.getParticipantGroups();
  },
  methods: {
    async getParticipantGroups() {
      let res = await this.$api.get(
        `/participant-groups/${this.challengeId}/list`
      );
      this.participantGroups = res.data;
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.item {
  cursor: pointer;
  padding: 5px;
}

.item:hover {
  background-color: rgb(241, 241, 241);
}
</style>