<template>
  <div :challengeId="challenge.id">
    <!--region Top -->
    <div
      v-if="isPreview"
      class="container"
      style="text-align: center; padding: 25px"
    >
      <a href="#" style="font-weight: 800; font-size: 15pt" @click="close"
        >Close Preview</a
      >
    </div>

    <div id="challenge-image" class="container">
      <div>
        <img :src="challenge.image_banner_url" />
        <!-- prettier-ignore -->
        <p v-if="challenge.image_banner_url" id="top-days-left">{{ timeLeft }}</p>
      </div>
    </div>
    <!--endregion Top -->

    <!-- prettier-ignore -->
    <div class="container">
      <div id="middle-box">
        <div class="org-image-box">
          <img :src="challenge.image_creator_url"/>
        </div>
        <div class="row">
          <div class="col-auto">
            <h3 id="challenge-name">
              {{ challenge.name }}
            </h3>
            <p id="already-in" class="error">You are already participating in this challenge</p>
            <p id="full" class="error">This challenge is full</p>
            <p id="not-authorized" class="error">This challenge hasn't been authorized by an admin yet</p>
            <!-- prettier-ignore -->
            <p id="no-active-accounts" class="error">You have no active utility accounts connected.
              Please check settings <nuxt-link to="/account-settings?tab=Utilities">here</nuxt-link> to see if your utility account failed to connect.
            </p>
            <p id="join-error" class="error">A server error has occured. Please try again later.</p>
          </div>
          <div class="col ml-auto align-self-center">
            <button
              v-if="!isUserInChallenge"
              id="join"
              class="btn right"
              @click="joinClick"
            >
              Join Challenge<img src="~/assets/images/add.png"/>
            </button>
            <button
              v-else-if="$auth.loggedIn"
              id="participating"
              class="btn right"
              data-toggle="modal"
              data-target="#leaveModal"
            >
              Participating<img src="~/assets/images/whiteCheckmark.png"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--region Challenge Info -->
    <div id="challenge-info" class="container">
      <div class="row">
        <div class="col-md-7">
          <ul class="nav">
            <li class="nav-item" @click="setSection('home')">
              <a :class="['nav-link', { 'active-section': section === 'home' }]"
                >Home</a
              >
            </li>
            <li class="nav-item" @click="setSection('discussion')">
              <a
                :class="[
                  'nav-link',
                  { 'active-section': section === 'discussion' },
                ]"
                >Discussion
                <sup v-if="$store.state.comments.new_comment_count">{{
                  $store.state.comments.new_comment_count
                }}</sup></a
              >
            </li>
          </ul>
          <div v-show="section === 'home'">
            <div id="overview" class="card">
              <p>{{ challenge.description }}</p>
              <!-- prettier-ignore -->
              <p>
                Starts: <span class="start">{{ startDate }}</span>
                Ends: <span class="end"> {{ endDate }} </span>
              </p>
              <p id="days-left">
                {{ timeLeft }}
              </p>
            </div>

            <!--region Leaderboard -->
            <!-- TODO: Shouldn't be client only-->
            <client-only>
              <LeaderBoard
                :participating-teams="participatingTeams"
                :has-challenge-started="hasChallengeStarted"
                :challenge-metric="challenge.metric"
              />
            </client-only>
            <!--endregion Leaderboard -->
          </div>
          <Comments
            v-show="section === 'discussion'"
            :challenge-id="challenge.id"
          />
        </div>
        <!--region Right column -->
        <div class="col-md-5">
          <div id="share" class="row" v-if="challenge.is_private == false">
            <div class="col-3" style="margin: auto 0">
              <b>Share:</b>
            </div>
            <div class="col-3">
              <img
                id="fb-share"
                class="share"
                src="~/assets/images/blueFacebookLogo.png"
              />
            </div>
            <div class="col-3">
              <img
                id="twitter"
                class="share"
                src="~/assets/images/blueTwitterLogo.png"
              />
            </div>
            <div class="col-3">
              <!--              <%-->
              <!--              const challengeNameWords = challenge.name.split(' ');-->
              <!--              const challengeName = (challengeNameWords[challengeNameWords.length - 1].toLowerCase() !== 'challenge') ? `${challenge.name} Challenge` : challenge.name;-->
              <!--              %>-->
              <a
                :href="
                  encodeURI(
                    `mailto:?subject=Join the ${challenge.name} challenge. Save Energy. Win Prizes.&body=${challengeUri}`
                  )
                "
                title="Share Challenge by Email"
              >
                <img src="~/assets/images/message.png" />
              </a>
            </div>
          </div>
          <div id="summary" class="card">
            <div class="row">
              <div class="col-6">
                <img src="~/assets/images/conferenceCall.png" />
                <p>Participants<br />{{ challenge.current_participants }}</p>
              </div>
              <div class="col-6">
                <img src="~/assets/images/barGraph.png" />
                <!-- prettier-ignore -->
                <p>Energy Savings*<br />{{ Math.round(challenge.savings_thm) }} therms</p>
              </div>
              <div class="col-6">
                <img src="~/assets/images/percent.png" />
                <p>
                  Average Savings Rate<br /><span id="average" />{{
                    averageSavingsRate
                  }}%
                </p>
              </div>
              <div class="col-6">
                <img src="~/assets/images/biohazard.png" />
                <!-- prettier-ignore -->
                <p>CO<sub>2</sub> Reductions*<br />{{Math.round(challenge.savings_thm * 13.446)}} lb
                </p>
              </div>
            </div>
            <div id="progress-bar">
              <div
                id="current-progress"
                :style="{
                  width:
                    Math.round(
                      (challenge.savings_thm / challenge.savings_goal) * 100
                    ) + '%',
                }"
              />
            </div>
            <p>
              {{
                Math.round(
                  (challenge.savings_thm / challenge.savings_goal) * 100
                ) || 0
              }}% of the collective goal has been met!
            </p>
            <div class="row">
              <div class="col-6">
                <p>Current: {{ Math.round(challenge.savings_thm) }} therms</p>
              </div>
              <div class="col-6">
                <p>Goal: {{ challenge.savings_goal }} therms</p>
              </div>
            </div>
          </div>
          <div id="rules" class="card">
            <h6 class="title">Rules</h6>
            <h6>Duration</h6>
            <!-- prettier-ignore -->
            <p>Starts: <span class="start">{{ startDate }}</span>
              Ends: <span class="end">{{ endDate }}</span>
            </p>
            <h6>Participant Eligibility</h6>
            <p v-if="challenge.description_eligibility">
              {{ challenge.description_eligibility }}
            </p>
            <!-- prettier-ignore -->
            <p v-else>1) Must be a primary account holder of an active residential {{ requiredUtilitiesText }} account.
              2) Only participants who have been living (and currently are living) at the home address of the associated {{ requiredUtilitiesText }} account for at least 1 year and therefore have at least 1 year’s worth of electricity data, are eligible to win the reward. Home address must be primary location of residence.
              3) Participants with less than 1 year’s worth of electricity data are still eligible to participate in the challenge, but will not be eligible to win the reward.</p>
            <h6>Individual Goal</h6>
            <p>{{ challenge.description_individual_goal }}</p>
            <h6>Collective Goal</h6>
            <p>{{ challenge.description_collective_goal }}</p>
            <h6>Reward(s)</h6>
            <p>{{ challenge.description_prizes }}</p>
            <p>Questions? Check our <a href="/faqs">FAQ page</a></p>
            <p>
              For details read the complete
              <a :href="challenge.description_rules_url"
                >{{ challenge.name }} Rules</a
              >
            </p>
          </div>

          <div id="organizer" class="card">
            <div class="row">
              <div class="col-3">
                <div class="org-image-box">
                  <img :src="challenge.image_creator_url" />
                </div>
              </div>
              <div class="col-9">
                <h6>Organizer</h6>
                <p class="organizer">
                  {{ challenge.sponsor_name }}
                </p>
                <p>Created on {{ createdOn }}</p>
                <a
                  v-if="challenge.sponsor_website"
                  :href="makeUrl(challenge.sponsor_website)"
                  >Website</a
                ><br />
                <a
                  v-if="challenge.sponsor_facebook"
                  :href="makeUrl(challenge.sponsor_facebook)"
                  >Facebook</a
                ><br />
                <a
                  v-if="challenge.sponsor_twitter"
                  :href="makeUrl(challenge.sponsor_twitter)"
                  >Twitter</a
                >
              </div>
            </div>
          </div>
        </div>
        <!--endregion Right column -->
      </div>
    </div>
    <!--endregion Challenge Info -->
    <SelectTeam
      :showModal.sync="showTeamSelect"
      @selectedTeam="($event) => selectedTeamPromiseResolve($event)"
    />

    <SelectParticipantGroup
      :showModal.sync="showParticipantGroupSelect"
      :challengeId="$route.params.challenge"
      @selectedParticipantGroup="
        ($event) => selectedParticipantGroupResolve($event)
      "
    />
  </div>
</template>

<script>
import Comments from '@/components/Comments';
import LeaderBoard from '@/components/LeaderBoard';
import { actionTypes, mutationTypes } from '@/store/types';

import SelectTeam from '@/components/challengeDetail/SelectTeam.vue';
import SelectParticipantGroup from '@/components/challengeDetail/SelectParticipantGroup.vue';

const moment = require('moment-timezone');

const je = function ($) {
  $.expander = {
    version: '2.0.1',
    defaults: {
      // the number of characters at which the contents will be sliced into two parts.
      slicePoint: 100,

      // a string of characters at which to slice the contents into two parts,
      // but only if the string appears before slicePoint
      // Useful for slicing at the first line break, e.g. {sliceOn: '<br'}
      sliceOn: null,

      // whether to keep the last word of the summary whole (true) or let it slice in the middle of a word (false)
      preserveWords: true,

      // whether to normalize the whitespace in the data to display (true) or not (false)
      normalizeWhitespace: true,

      // whether to count and display the number of words inside the collapsed text
      showWordCount: false,

      // text to include between summary and detail. Default ' ' prevents appearance of
      // collapsing two words into one.
      // Was hard-coded in script; now exposed as an option to fix issue #106.
      detailPrefix: ' ',

      // What to display around the counted number of words, set to '{{count}}' to show only the number
      wordCountText: ' ({{count}} words)',

      // a threshold of sorts for whether to initially hide/collapse part of the element's contents.
      // If after slicing the contents in two there are fewer words in the second part than
      // the value set by widow, we won't bother hiding/collapsing anything.
      widow: 4,

      // text displayed in a link instead of the hidden part of the element.
      // clicking this will expand/show the hidden/collapsed text
      expandText: 'read more',
      expandPrefix: '&hellip; ',

      expandAfterSummary: false,

      // Possible word endings to test against for when preserveWords: true
      wordEnd: /(&(?:[^;]+;)?|[0-9a-zA-Z\u00C0-\u0100]+|[^\u0000-\u007F]+)$/,

      // class names for summary element and detail element
      summaryClass: 'summary',
      detailClass: 'details',

      // class names for <span> around "read-more" link and "read-less" link
      moreClass: 'read-more',
      lessClass: 'read-less',

      // class names for <a> around "read-more" link and "read-less" link
      moreLinkClass: 'more-link',
      lessLinkClass: 'less-link',

      // number of milliseconds after text has been expanded at which to collapse the text again.
      // when 0, no auto-collapsing
      collapseTimer: 0,

      // effects for expanding and collapsing
      expandEffect: 'slideDown',
      expandSpeed: 250,
      collapseEffect: 'slideUp',
      collapseSpeed: 200,

      // allow the user to re-collapse the expanded text.
      userCollapse: true,

      // text to use for the link to re-collapse the text
      userCollapseText: 'read less',
      userCollapsePrefix: ' ',

      // all callback functions have the this keyword mapped to the element in the jQuery set when .expander() is called
      onSlice: null, // function() {}
      beforeExpand: null, // function() {},
      afterExpand: null, // function() {},
      onCollapse: null, // function(byUser) {}
      afterCollapse: null, // function() {}
    },
  };

  $.fn.expander = function (options) {
    var meth = 'init';

    if (typeof options === 'string') {
      meth = options;
      options = {};
    }

    var opts = $.extend({}, $.expander.defaults, options);
    var rSelfClose =
      /^<(?:area|br|col|embed|hr|img|input|link|meta|param).*>$/i;
    var rAmpWordEnd = opts.wordEnd;
    var rOpenCloseTag = /<\/?(\w+)[^>]*>/g;
    var rOpenTag = /<(\w+)[^>]*>/g;
    var rCloseTag = /<\/(\w+)>/g;
    var rLastCloseTag = /(<\/([^>]+)>)\s*$/;
    var rTagPlus = /^(<[^>]+>)+.?/;
    var rMultiSpace = /\s\s+/g;
    var delayedCollapse;

    var removeSpaces = function (str) {
      return opts.normalizeWhitespace
        ? $.trim(str || '').replace(rMultiSpace, ' ')
        : str;
    };

    var methods = {
      init: function () {
        this.each(function () {
          var i,
            l,
            tmp,
            newChar,
            summTagless,
            summOpens,
            summCloses,
            lastCloseTag,
            detailText,
            detailTagless,
            html,
            expand;
          var $thisDetails, $readMore;
          var slicePointChanged;
          var openTagsForDetails = [];
          var closeTagsForsummaryText = [];
          var strayChars = '';
          var defined = {};
          var thisEl = this;
          var $this = $(this);
          var $summEl = $([]);
          var o = $.extend(
            {},
            opts,
            $this.data('expander') || ($.meta && $this.data()) || {}
          );
          var hasDetails = !!$this.find('.' + o.detailClass).length;
          var hasBlocks = !!$this.find('*').filter(function () {
            var display = $(this).css('display');

            return /^block|table|list/.test(display);
          }).length;
          var el = hasBlocks ? 'div' : 'span';
          var detailSelector = el + '.' + o.detailClass;
          var moreClass = o.moreClass + '';
          var lessClass = o.lessClass + '';
          var expandSpeed = o.expandSpeed || 0;
          var allHtml = removeSpaces($this.html());
          var summaryText = allHtml.slice(0, o.slicePoint);

          // allow multiple classes for more/less links
          o.moreSelector = 'span.' + moreClass.split(' ').join('.');
          o.lessSelector = 'span.' + lessClass.split(' ').join('.');
          // bail out if we've already set up the expander on this element
          if ($.data(this, 'expanderInit')) {
            return;
          }

          $.data(this, 'expanderInit', true);
          $.data(this, 'expander', o);
          // determine which callback functions are defined
          $.each(
            [
              'onSlice',
              'beforeExpand',
              'afterExpand',
              'onCollapse',
              'afterCollapse',
            ],
            function (index, val) {
              defined[val] = $.isFunction(o[val]);
            }
          );

          // back up if we're in the middle of a tag or word
          summaryText = backup(summaryText);

          // summary text sans tags length
          summTagless = summaryText.replace(rOpenCloseTag, '').length;

          // add more characters to the summary, one for each character in the tags
          while (summTagless < o.slicePoint) {
            newChar = allHtml.charAt(summaryText.length);

            if (newChar === '<') {
              newChar = allHtml.slice(summaryText.length).match(rTagPlus)[0];
            }
            summaryText += newChar;
            summTagless++;
          }

          // SliceOn script, Closes #16, resolves #59
          // Original SliceEarlierAt code (since modfied): Sascha Peilicke @saschpe
          if (o.sliceOn) {
            slicePointChanged = changeSlicePoint({
              sliceOn: o.sliceOn,
              slicePoint: o.slicePoint,
              allHtml: allHtml,
              summaryText: summaryText,
            });

            summaryText = slicePointChanged.summaryText;
          }

          summaryText = backup(
            summaryText,
            o.preserveWords && allHtml.slice(summaryText.length).length
          );

          // separate open tags from close tags and clean up the lists
          summOpens = summaryText.match(rOpenTag) || [];
          summCloses = summaryText.match(rCloseTag) || [];

          // filter out self-closing tags
          tmp = [];
          $.each(summOpens, function (index, val) {
            if (!rSelfClose.test(val)) {
              tmp.push(val);
            }
          });
          summOpens = tmp;

          // strip close tags to just the tag name
          l = summCloses.length;

          for (i = 0; i < l; i++) {
            summCloses[i] = summCloses[i].replace(rCloseTag, '$1');
          }
          // tags that start in summary and end in detail need:
          // a). close tag at end of summary
          // b). open tag at beginning of detail
          $.each(summOpens, function (index, val) {
            var thisTagName = val.replace(rOpenTag, '$1');
            var closePosition = $.inArray(thisTagName, summCloses);

            if (closePosition === -1) {
              openTagsForDetails.push(val);
              closeTagsForsummaryText.push('</' + thisTagName + '>');
            } else {
              summCloses.splice(closePosition, 1);
            }
          });

          // reverse the order of the close tags for the summary so they line up right
          closeTagsForsummaryText.reverse();

          // create necessary summary and detail elements if they don't already exist
          if (!hasDetails) {
            // end script if there is no detail text or if detail has fewer words than widow option
            detailText = allHtml.slice(summaryText.length);
            detailTagless = $.trim(detailText.replace(rOpenCloseTag, ''));

            if (
              detailTagless === '' ||
              detailTagless.split(/\s+/).length < o.widow
            ) {
              return;
            }
            // otherwise, continue...
            lastCloseTag = closeTagsForsummaryText.pop() || '';
            summaryText += closeTagsForsummaryText.join('');
            detailText = openTagsForDetails.join('') + detailText;
          } else {
            // assume that even if there are details, we still need readMore/readLess/summary elements
            // (we already bailed out earlier when readMore el was found)
            // but we need to create els differently

            // remove the detail from the rest of the content
            detailText = $this.find(detailSelector).remove().html();

            // The summary is what's left
            summaryText = $this.html();

            // allHtml is the summary and detail combined (this is needed when content has block-level elements)
            allHtml = summaryText + detailText;

            lastCloseTag = '';
          }
          o.moreLabel = $this.find(o.moreSelector).length
            ? ''
            : buildMoreLabel(o, detailText);

          if (hasBlocks) {
            detailText = allHtml;
            // Fixes issue #89; Tested by 'split html escapes'
          } else if (summaryText.charAt(summaryText.length - 1) === '&') {
            strayChars = /^[#\w\d\\]+;/.exec(detailText);

            if (strayChars) {
              detailText = detailText.slice(strayChars[0].length);
              summaryText += strayChars[0];
            }
          }
          summaryText += lastCloseTag;

          // onSlice callback
          o.summary = summaryText;
          o.details = detailText;
          o.lastCloseTag = lastCloseTag;

          if (defined.onSlice) {
            // user can choose to return a modified options object
            // one last chance for user to change the options. sneaky, huh?
            // but could be tricky so use at your own risk.
            tmp = o.onSlice.call(thisEl, o);

            // so, if the returned value from the onSlice function is an object with a details property, we'll use that!
            o = tmp && tmp.details ? tmp : o;
          }

          // build the html with summary and detail and use it to replace old contents
          html = buildHTML(o, hasBlocks);

          $this.empty().append(html);

          // set up details and summary for expanding/collapsing
          $thisDetails = $this.find(detailSelector);
          $readMore = $this.find(o.moreSelector);

          // Hide details span using collapseEffect unless
          // expandEffect is NOT slideDown and collapseEffect IS slideUp.
          // The slideUp effect sets span's "default" display to
          // inline-block. This is necessary for slideDown, but
          // problematic for other "showing" animations.
          // Fixes #46
          if (
            (o.collapseEffect === 'slideUp' &&
              o.expandEffect !== 'slideDown') ||
            $this.is(':hidden')
          ) {
            $thisDetails.css({ display: 'none' });
          } else {
            $thisDetails[o.collapseEffect](0);
          }

          $summEl = $this.find('div.' + o.summaryClass);

          expand = function (event) {
            event.preventDefault();
            var exSpeed = event.startExpanded ? 0 : expandSpeed;
            $readMore.hide();
            $summEl.hide();

            if (defined.beforeExpand) {
              o.beforeExpand.call(thisEl);
            }

            $thisDetails
              .stop(false, true)
              [o.expandEffect](exSpeed, function () {
                $thisDetails.css({ zoom: '' });

                if (defined.afterExpand) {
                  o.afterExpand.call(thisEl);
                }
                delayCollapse(o, $thisDetails, thisEl);
              });
          };

          $readMore
            .find('a')
            .off('click.expander')
            .on('click.expander', expand);

          if (o.userCollapse && !$this.find(o.lessSelector).length) {
            $this
              .find(detailSelector)
              .append(
                '<span class="' +
                  o.lessClass +
                  '">' +
                  o.userCollapsePrefix +
                  '<a href="#" class="' +
                  o.lessLinkClass +
                  '">' +
                  o.userCollapseText +
                  '</a></span>'
              );
          }

          $this
            .find(o.lessSelector + ' a')
            .off('click.expander')
            .on('click.expander', function (event) {
              event.preventDefault();
              clearTimeout(delayedCollapse);
              var $detailsCollapsed = $(this).closest(detailSelector);
              reCollapse(o, $detailsCollapsed);

              if (defined.onCollapse) {
                o.onCollapse.call(thisEl, true);
              }
            });

          if (o.startExpanded) {
            expand({
              preventDefault: function () {},
              startExpanded: true,
            });
          }
        }); // this.each
      },
      destroy: function () {
        this.each(function () {
          var o, details;
          var $this = $(this);

          if (!$this.data('expanderInit')) {
            return;
          }

          o = $.extend({}, $this.data('expander') || {}, opts);
          details = $this.find('.' + o.detailClass).contents();

          $this.removeData('expanderInit');
          $this.removeData('expander');

          $this.find(o.moreSelector).remove();
          $this.find('.' + o.summaryClass).remove();
          $this
            .find('.' + o.detailClass)
            .after(details)
            .remove();
          $this.find(o.lessSelector).remove();
        });
      },
    };

    // run the methods (almost always "init")
    if (methods[meth]) {
      methods[meth].call(this);
    }

    // utility functions
    function buildHTML(o, blocks) {
      var el = 'span';
      var summary = o.summary;
      var closingTagParts = rLastCloseTag.exec(summary);
      var closingTag = closingTagParts ? closingTagParts[2].toLowerCase() : '';

      if (blocks) {
        el = 'div';

        // if summary ends with a close tag, tuck the moreLabel inside it
        if (closingTagParts && closingTag !== 'a' && !o.expandAfterSummary) {
          summary = summary.replace(rLastCloseTag, o.moreLabel + '$1');
        } else {
          // otherwise (e.g. if ends with self-closing tag) just add moreLabel after summary
          // fixes #19
          summary += o.moreLabel;
        }

        // and wrap it in a div
        summary = '<div class="' + o.summaryClass + '">' + summary + '</div>';
      } else {
        summary += o.moreLabel;
      }

      return [
        summary,

        // after summary, add an optional prefix. Default single space prevents last word of summary
        // and first word of detail from collapsing together into what looks like a single word.
        // (could also be done with CSS, but this feels more natural)
        // Prefix made optional to fix issue #106
        o.detailPrefix || '',
        '<',
        el + ' class="' + o.detailClass + '"',
        '>',
        o.details,
        '</' + el + '>',
      ].join('');
    }

    function buildMoreLabel(o, detailText) {
      var ret = '<span class="' + o.moreClass + '">' + o.expandPrefix;

      if (o.showWordCount) {
        o.wordCountText = o.wordCountText.replace(
          /\{\{count\}\}/,
          detailText
            .replace(rOpenCloseTag, '')
            .replace(/\&(?:amp|nbsp);/g, '')
            .replace(/(?:^\s+|\s+$)/, '')
            .match(/\w+/g).length
        );
      } else {
        o.wordCountText = '';
      }
      ret +=
        '<a href="#" class="' +
        o.moreLinkClass +
        '">' +
        o.expandText +
        o.wordCountText +
        '</a></span>';

      return ret;
    }

    function backup(txt, preserveWords) {
      if (txt.lastIndexOf('<') > txt.lastIndexOf('>')) {
        txt = txt.slice(0, txt.lastIndexOf('<'));
      }

      if (preserveWords) {
        txt = txt.replace(rAmpWordEnd, '');
      }

      return $.trim(txt);
    }

    function reCollapse(o, el) {
      el.stop(true, true)[o.collapseEffect](o.collapseSpeed, function () {
        var prevMore = el.prev('span.' + o.moreClass).show();

        if (!prevMore.length) {
          el.parent()
            .children('div.' + o.summaryClass)
            .show()
            .find('span.' + o.moreClass)
            .show();
        }

        if (o.afterCollapse) {
          o.afterCollapse.call(el);
        }
      });
    }

    function delayCollapse(option, $collapseEl, thisEl) {
      if (option.collapseTimer) {
        delayedCollapse = setTimeout(function () {
          reCollapse(option, $collapseEl);

          if ($.isFunction(option.onCollapse)) {
            option.onCollapse.call(thisEl, false);
          }
        }, option.collapseTimer);
      }
    }

    function changeSlicePoint(info) {
      // Create placeholder string text
      var sliceOnTemp = 'ExpandMoreHere374216623';

      // Replace sliceOn with placeholder unaffected by .text() cleaning
      // (in case sliceOn contains html)
      var summaryTextClean = info.summaryText.replace(
        info.sliceOn,
        sliceOnTemp
      );
      summaryTextClean = $('<div>' + summaryTextClean + '</div>').text();

      // Find true location of sliceOn placeholder
      var sliceOnIndexClean = summaryTextClean.indexOf(sliceOnTemp);

      // Store location of html version too
      var sliceOnIndexHtml = info.summaryText.indexOf(info.sliceOn);

      // Base condition off of true sliceOn location...
      if (sliceOnIndexClean !== -1 && sliceOnIndexClean < info.slicePoint) {
        // ...but keep html in summaryText
        info.summaryText = info.allHtml.slice(0, sliceOnIndexHtml);
      }

      return info;
    }

    return this;
  };

  // plugin defaults
  $.fn.expander.defaults = $.expander.defaults;
};

export default {
  name: 'Gas',
  components: { Comments, LeaderBoard, SelectTeam, SelectParticipantGroup },
  props: [
    'challenge',
    'participatingTeams',
    'participatingUserTeams',
    'isUserInChallenge',
    'isPreview',
  ],
  data() {
    return {
      selectedTeamPromiseResolve: null,
      showTeamSelect: false,
      selectedParticipantGroupResolve: null,
      showParticipantGroupSelect: false,
      selectedTeam: null,
      joinSelection: '0',
      hasChallengeStarted: false,
      section: 'home',
    };
  },
  computed: {
    requiredUtilitiesText() {
      const requiredUtilities = this.challenge.pelm_utilities.map(
        (o) => o.name
      );

      if (requiredUtilities.length == 1) {
        return requiredUtilities.toString();
      }

      const exceptLast = requiredUtilities.slice(
        0,
        requiredUtilities.length - 1
      );

      const lastItem = requiredUtilities.slice(-1).pop();

      return `${exceptLast.toString()} or ${lastItem}`;
    },
    averageSavingsRate() {
      let average = 0;
      let averageNum = 0;
      for (const team of this.participatingTeams) {
        if (team.usage_thm_relative > 0) {
          average += team.usage_thm_relative * 100;
          averageNum += 1;
        }
      }
      return averageNum > 0 ? Math.round(average / averageNum) : 0;
    },
    challengeUri() {
      if (process.client) {
        return process.env.AUTH_BASE_URL + this.$route.path;
      }
      return process.env.FE_ADDRESS + this.$route.path;
    },
    createdOn() {
      if (this.challenge.created_dtm) {
        return moment(this.challenge.created_dtm).format('MMMM D YYYY');
      }
      return '';
    },
    startDate() {
      return moment
        .tz(this.challenge.start_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('ddd. MMM. D, YYYY h:mmA z');
    },
    endDate() {
      return moment
        .tz(this.challenge.end_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('ddd. MMM. D, YYYY h:mmA z');
    },
    timeLeft() {
      const start = moment(this.challenge.start_date, 'YYYY-MM-DD hh:mm ZZ');
      const end = moment(this.challenge.end_date, 'YYYY-MM-DD hh:mm ZZ');
      const now = moment();
      const minute = 1000 * 60;
      const hour = minute * 60;
      const day = hour * 24;
      let timeLeft;

      if (now.isBefore(end)) {
        let difference;

        if (now.isBefore(start)) {
          difference = moment(start).diff(now);
        } else {
          difference = moment(end).diff(now);
          this.hasChallengeStarted = true;
        }
        const days = Math.floor(difference / day);
        if (days > 0) {
          timeLeft = days + ' days';
        } else {
          const hours = Math.floor(difference / hour);
          if (hours > 0) {
            timeLeft = hours + ' hours';
          } else {
            const minutes = Math.floor(difference / minute);
            timeLeft = minutes + ' minutes';
          }
        }
        if (now.isBetween(start, end)) {
          timeLeft = timeLeft + ' left!';
        } else if (now.isBefore(start)) {
          timeLeft = `Starts in ${timeLeft}!`;
        }
      } else {
        timeLeft = 'Challenge has ended!';
        this.hasChallengeStarted = true;
      }
      return timeLeft;
    },
  },
  //prettier-ignore
  async mounted() {
    await this.getUserPelmEnergyAccounts()
      this.challengeId = parseInt(this.$route.params.challenge);

      // eslint-disable-next-line nuxt/no-env-in-hooks
      if (process.client) {
        const fbShare = $('#fb-share');
        const twitterShare = $('#twitter');

        window.fbAsyncInit = function() {
          FB.init({
            appId            : '306413506865027',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v3.1'
          });
        };

        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        fbShare.click(function() {
          FB.ui(
            {
              method: 'share',
              href: window.location.href,
              mobile_iframe: true,
            },
            function(response) {}
          );
        });

        const self = this;

        this.$nextTick(() => {
          twitterShare.click(function() {
            const width  = 575;
            const height = 400;
            const left   = ($(window).width()  - width)  / 2;
            const top    = ($(window).height() - height) / 2;
            const url    = 'https://twitter.com/intent/tweet?text=' + escape('Join the ' + self.challenge.name + ' Challenge, Save Energy, Win Prizes ' + window.location.href);
            const opts   = 'status=1' +
              ',width='  + width  +
              ',height=' + height +
              ',top='    + top    +
              ',left='   + left;

            window.open(url, 'twitter', opts);
          });
        });

        if(this.isUserInChallenge && this.userPelmEnergyAccounts.length === 0) {
          $('#syncUtilityModal').modal('show');
        }
        this.legacyInit();
      }
      je($);


// you can override default options globally, so they apply to every .expander() call
//     $.expander.defaults.slicePoint = 120;

      $(document).ready(function() {
        // simple example, using all default options unless overridden globally
        $('p.expandable').expander({
          slicePoint:       310,  // default is 100
          expandText:       'Read more ∨', // default is 'read more'
          userCollapseText: 'Read less ∧', // default is 'read more'
          normalizeWhitespace: false,
        });

        // *** OR ***

        // override default options (also overrides global overrides)
        // $('div.expandable p').expander({
        //   slicePoint:       80,  // default is 100
        //   expandPrefix:     ' ', // default is '... '
        //   expandText:       '[...]', // default is 'read more'
        //   collapseTimer:    5000, // re-collapses after 5 seconds; default is 0, so no re-collapsing
        //   userCollapseText: '[^]'  // default is 'read less'
        // });

      });

      // require('jquery-expander')($);
    },
  methods: {
    async getUserPelmEnergyAccounts() {
      const res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      this.userPelmEnergyAccounts = res.items;
    },
    setSection(section) {
      this.section = section;
      if (section === 'discussion') {
        this.$store.commit(mutationTypes.CLEAR_DISCUSSION_NOTIFICATION);
      }
    },
    makeUrl(link) {
      if (!link.startsWith('http://') || !link.startsWith('https://')) {
        return 'http://' + link;
      }
      return link;
    },

    selectTeamId() {
      this.showTeamSelect = true;
      return new Promise((resolve, reject) => {
        this.selectedTeamPromiseResolve = resolve;
      });
    },

    selectParticipantGroup() {
      this.showParticipantGroupSelect = true;
      return new Promise((resolve, reject) => {
        this.selectedParticipantGroupResolve = resolve;
      });
    },

    async joinClick() {
      if (this.isPreview) {
        return;
      }

      if (this.$auth.loggedIn) {
        // $('#joinModal').modal('show');

        let commons = this.userPelmEnergyAccounts.filter((obj) =>
          this.challenge.pelm_utilities
            .map((o) => o.id)
            .includes(obj.utility_provider_id)
        );

        if (!commons.length) {
          this.$toast.error(
            `In order to join this challenge you must be a customer of : ${this.requiredUtilitiesText}`,
            { duration: 5000 }
          );
          return;
        }

        this.joinChallenge();
      } else {
        try {
          localStorage.setItem('joinChallengeId', this.challenge.id);
        } catch {}
        this.$router.push('/signup');
      }
    },
    legacyInit() {
      if (process.client) {
        this.LEADERBOARD_GROUP = 15;
        this.LEADERBOARD_BUFFER = 5;
        this.start = $('.main-content').attr('startTime');
        this.end = $('.main-content').attr('endTime');
        this.middleBox = $('#middle-box');
        this.leaderboardTable = $('#leaderboard tbody');
        this.leaderboardHeading = this.leaderboardTable.children().first();
        this.averageSavings = $('#average');
        this.viewMore = $('#view-more');
        this.alreadyInError = $('#already-in');
        this.fullError = $('#full');
        this.notAuthorizedError = $('#not-authorized');
        this.noAccountsError = $('#no-active-accounts');
        this.joinError = $('#join-error');
        // this.leaveChallenge = $('#leave-challenge');
        this.notEnrolledError = $('#not-enrolled');
        this.endedError = $('#ended');
        this.leaveError = $('#leave-error');
        this.errors = $('.error');
        this.fbShare = $('#fb-share');
        this.twitterShare = $('#twitter');
        this.challengeName = $('#challenge-name');

        this.errors.hide();
      }
    },
    async joinChallenge() {
      await this.$store.dispatch(actionTypes.GET_TEAMS);

      let teamId;
      let participantGroupId;

      const team = await this.selectTeamId();
      this.showTeamSelect = false;
      teamId = team.id;

      if (this.challenge.participant_group == 'Creator specified groups') {
        const participantGroup = await this.selectParticipantGroup();
        participantGroupId = participantGroup.id;
        this.showParticipantGroupSelect = false;
      }

      this.errors.hide();

      // if (!this.$store.state.user.isConnected) {
      //   this.noAccountsError.show();
      //   return;
      // }

      try {
        const url = '/challenges/' + this.challengeId + '/join';
        const data = {
          team_id: teamId,
          participant_group_id: participantGroupId,
        };

        const errorFunc = function (err, self) {
          const message = err.response.data.msg;
          console.log('message', message);
          switch (message) {
            case 'already in challenge':
              self.alreadyInError.show();
              break;
            case 'challenge is full':
              self.fullError.show();
              break;
            case 'challenge has not been authorized':
              self.notAuthorizedError.show();
              break;
            case 'Team has no meters':
              self.noAccountsError.show();
              break;
            default:
              self.joinError.show();
          }
        };

        this.$api
          .post(url, data)
          .then((res) => {
            $('#joinModal').modal('hide');
            location.reload();
          })
          .catch((error) => {
            console.log('joinChallenge error', error.response);
            errorFunc(error, this);
          });
      } catch (e) {
        console.log(e);
        this.noAccountsError.show();
      }
    },

    close() {
      window.close();
    },

    leaveChallenge() {
      const url = `/challenges/${this.challengeId}/leave`;
      const data = {
        team_id: this.participatingUserTeams[0],
      };
      this.$api.post(url, data).then(() => {
        $('#leaveModal').modal('hide');
        location.reload();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/challengeDetailElectricity';
</style>
