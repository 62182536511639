<template>
  <div>
    <BootstrapModal :showModal.sync="$alert.data.showModal">
      <template v-slot:body>
        <div>
          {{ $alert.data.message }}
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $alert.promiseResolve(false);
            $alert.data.showModal = false;
          "
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="
            $alert.promiseResolve(true);
            $alert.data.showModal = false;
          "
        >
          OK
        </button>
      </template>
    </BootstrapModal>
  </div>
</template>
<script>
import BootstrapModal from './BootstrapModal.vue';
export default {
  components: { BootstrapModal },
};
</script>