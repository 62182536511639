<template>
  <div>
    <div>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-card v-if="Object.keys(data).length > 0">
            <v-card-title>{{ `${data.pelm_utility_name}` }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>Pelm User Id</v-col>
                <v-col>{{ data.pelm_user_id }}</v-col>
              </v-row>
              <v-row>
                <v-col>User</v-col>
                <v-col>{{ data.user_fullname }}</v-col>
              </v-row>
              <v-row>
                <v-col>Is active</v-col>
                <v-col>{{ data.is_active }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/pelm-subscriptions/${this.$route.params.subscriptionId}`
      );
      this.data = res.data;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>