<template>
  <div id="challenge-page">
    <div
      id="joinModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="joinModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="joinTitle" class="modal-title">Join Challenge</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form id="join-form">
              <div class="form-group">
                <label for="join-team"
                  >Which team would you like to join the Challenge?</label
                >
                <select
                  id="join-team"
                  v-model="selectedTeam"
                  class="custom-select"
                  name="join-team"
                >
                  <!--                    <option disabled>Please select a team</option>-->
                  <option
                    v-for="(team, index) of myTeams"
                    :key="`joinTeam${index}`"
                    :value="team"
                  >
                    {{ team.name }}
                  </option>
                </select>
              </div>
              <button class="btn" type="button" @click="joinChallenge">
                JOIN
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      id="onboardingModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onboardingModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="onboardingTitle" class="modal-title">Join Challenge</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              You must ask your team leader to join the challenge or create your
              own team by connecting a utility account.
            </p>
            <nuxt-link class="btn" to="/syncdata"> Connect Utility </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div
      id="leaveModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="leaveModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalCenterTitle" class="modal-title">
              Leave Challenge
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p id="not-enrolled" class="error">
              You are not enrolled in this challenge
            </p>
            <p id="ended" class="error">This challenge has already ended</p>
            <p id="leave-error" class="error">A server error has occured</p>
            Are you sure that you want to leave this challenge?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn green" data-dismiss="modal">
              No
            </button>
            <button
              id="leave-challenge"
              type="button"
              class="btn btn-danger"
              @click="leaveChallenge"
            >
              Leave
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="incompatibleUtilityModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onboardingModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="incompatibleUtilityTitle" class="modal-title">Sorry</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              You can't join this challenge because it's for
              {{ utilityName }} customers only.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="syncUtilityModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onboardingModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="syncUtilityTitle" class="modal-title">
              Connect utility account
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Nice, you've joined the challenge. To fully participate you need
              to connect your utility account. Would you like to do that now?
            </p>
          </div>
          <div class="modal-footer">
            <nuxt-link
              to="/syncdata"
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
            >
              Yes
            </nuxt-link>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>

    <component
      v-bind:is="challengeType"
      :challenge="challenge"
      :participating-teams="participatingTeams"
      :participating-user-teams="participatingUserTeams"
      :is-user-in-challenge="isUserInChallenge"
      :is-preview="isPreview"
    />
  </div>
</template>

<script>
import Electricity from '@/components/challengeDetail/Electricity';
import Gas from '@/components/challengeDetail/Gas';
import ElectricityAndGas from '@/components/challengeDetail/ElectricityAndGas';
import { actionTypes, mutationTypes } from '@/store/types';

function fillEmptyChallengeFields(challenge) {
  challenge.name = challenge.name || '[Challenge name]';
  challenge.description = challenge.description || '[Description]';
  challenge.description_prizes = challenge.description_prizes || '[Prize description]';
  // challenge.description_eligibility =
  //   challenge.description_eligibility || `1) Must be a primary account holder of an active residential Pacific Gas & Electric (PG&E) electric account.
  //   2) Only participants who have been living (and currently are living) at the home address of the associated PG&E account for at least 1 year and therefore have at least 1 year’s worth of electricity data, are eligible to win the reward. Home address must be primary location of residence.
  //   3) Participants with less than 1 year’s worth of electricity data are still eligible to participate in the challenge, but will not be eligible to win the reward.`;
  challenge.description_individual_goal = challenge.description_individual_goal || '[Individual goal]';
  challenge.description_collective_goal = challenge.description_collective_goal || '[Collective goal]';
  challenge.description_rules_url = challenge.description_rules_url || '[Rules]';
}

export default {
  name: 'ChallengeDetails',
  components: { Electricity, Gas, ElectricityAndGas },

  async asyncData({ params, query, store, app, $auth, redirect, req }) {
    let password;
    // if (process.server) {
    //   password = req.cookies['auth.challengepass'];
    // }

    // if (process.client) {
    //   password = $auth.$storage.getCookie('challengepass');
    // }
    let challenge;
    try {
      challenge = await app.$repo.challenges.get(params.challenge);
      // if (!challenge.data.name && challenge.data.is_private) {
      //   if (password) {
      //     const result = await app.$repo.challenges.submitPassword(
      //       params.challenge,
      //       { password: password }
      //     );
      //     if (result.data.msg === 'ok') {
      //       challenge = result.data.challenge;
      //     } else {
      //       challenge.data['name'] = 'Private Challenge';
      //       return {
      //         challenge: challenge.data,
      //       };
      //     }
      //   } else {
      //     challenge.data['name'] = 'Private Challenge';
      //     return {
      //       challenge: challenge.data,
      //     };
      //   }
      // } else {
      challenge = challenge.data;
      // challenge.is_private = false;
      // }

      const [leaderBoard, _0] = await Promise.all([
        app.$repo.challenges.getLeaderBoard(params.challenge),
        store.dispatch(actionTypes.GET_COMMENTS, params.challenge),
      ]);

      let isUserInChallenge = false;
      const participatingUserTeams = [];
      if ($auth.loggedIn) {
        try {
          const [myChallenges, _0, _1] = await Promise.all([
            app.$api.get(`/challenges/mychallenges`),
            store.dispatch(actionTypes.GET_TEAMS),
            // store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
          ]);
          const myChallengesFlat = myChallenges.data[0]
            .concat(myChallenges.data[1])
            .concat(myChallenges.data[2]);

          for (const userChallenge of myChallengesFlat) {
            if (String(userChallenge.id) === params.challenge) {
              isUserInChallenge = true;
              break;
            }
          }

          // Find which of user's teams are participating in the challenge
          if (leaderBoard) {
            for (const leaderBoardTeam of leaderBoard.data) {
              for (const userTeam of store.state.user.teams) {
                if (leaderBoardTeam.team_id === userTeam.id) {
                  participatingUserTeams.push(userTeam.id);
                }
              }
            }
          }
        } catch (e) {
          // TODO ...
          console.log(e);
        }
      }

      const isPreview = Object.keys(query).includes('preview');
      if (isPreview) {
        fillEmptyChallengeFields(challenge);
      }

      let challengeType;
      switch (challenge.metric) {
        case 1:
          challengeType = 'electricity';
          break;
        case 2:
          challengeType = 'gas';
          break;
        case 3:
          challengeType = 'electricity-and-gas';
          break;
      }

      return {
        challenge: challenge,
        participatingTeams: leaderBoard ? leaderBoard.data : [],
        participatingUserTeams,
        isUserInChallenge,
        isPreview,
        challengeType: challengeType,
      };
    } catch (e) {
      // TODO ...
      console.log(e);
      redirect('/404');
    }
  },
  data() {
    return {
      challenge: {},
      selectedTeam: null,
      joinSelection: '0',
      participatingTeams: [],
      participatingUserTeams: [],
      hasChallengeStarted: false,
      section: 'home',
      password: '',
      unlocked: false,
      passwordError: false,
    };
  },
  computed: {
    utilityName() {
      switch (this.challenge.pelm_utility_id) {
        case 1:
          return 'PG&E';
        case 2:
          return 'SCE';
        case 57:
          return 'SDG&E';
        default:
          return '';
      }
    },
    myTeams() {
      if (this.$store.state.user.teams) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedTeam = this.$store.state.user.teams[0];
      }
      return this.$store.state.user.teams;
    },
  },
  mounted() {
    this.legacyInit();
  },
  methods: {
    async submitPassword(event) {
      if (event) {
        event.preventDefault();
      }
      this.passwordError = false;
      try {
        const result = await this.$repo.challenges.submitPassword(
          this.$route.params.challenge,
          { password: this.password }
        );
        if (result.data.msg === 'ok') {
          this.$auth.$storage.setCookie('challengepass', this.password);
          await this.loadChallenge(result.data.challenge);
          this.challenge = result.data.challenge;
        } else {
          this.passwordError = true;
        }
      } catch {
        // TODO
      }
    },

    async loadChallenge(challenge) {
      const [leaderBoard, _0] = await Promise.all([
        this.$repo.challenges.getLeaderBoard(challenge.id),
        this.$store.dispatch(actionTypes.GET_COMMENTS, challenge.id),
      ]);

      let isUserInChallenge = false;
      const participatingUserTeams = [];
      if (this.$auth.loggedIn) {
        try {
          const [myChallenges, _0, _1] = await Promise.all([
            this.$api.get(`/challenges/mychallenges`),
            this.$store.dispatch(actionTypes.GET_TEAMS),
            // this.$store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
          ]);
          const myChallengesFlat = myChallenges.data[0]
            .concat(myChallenges.data[1])
            .concat(myChallenges.data[2]);
          for (const userChallenge of myChallengesFlat) {
            if (String(userChallenge.id) === challengeId) {
              isUserInChallenge = true;
              break;
            }
          }

          // Find which of user's teams are participating in the challenge
          if (leaderBoard) {
            for (const leaderBoardTeam of leaderBoard.data) {
              for (const userTeam of store.state.user.teams) {
                if (leaderBoardTeam.team_id === userTeam.id) {
                  participatingUserTeams.push(userTeam.id);
                }
              }
            }
          }
        } catch (e) {
          // TODO ...
          console.log(e);
        }
      }

      const isPreview = Object.keys(this.$route.query).includes('preview');
      if (isPreview) {
        fillEmptyChallengeFields(challenge);
      }

      let challengeType;
      switch (challenge.metric) {
        case 1:
          challengeType = 'electricity';
          break;
        case 2:
          challengeType = 'gas';
          break;
        case 3:
          challengeType = 'electricity-and-gas';
          break;
      }

      this.participatingTeams = leaderBoard ? leaderBoard.data : [];
      this.participatingUserTeams = participatingUserTeams;
      this.isUserInChallenge = isUserInChallenge;
      this.isPreview = isPreview;
      this.challengeType = challengeType;
    },

    setSection(section) {
      this.section = section;
      if (section === 'discussion') {
        this.$store.commit(mutationTypes.CLEAR_DISCUSSION_NOTIFICATION);
      }
    },
    makeUrl(link) {
      if (!link.startsWith('http://') || !link.startsWith('https://')) {
        return 'http://' + link;
      }
      return link;
    },

    joinClick() {
      if (this.isPreview) {
        return;
      }

      if (this.$auth.loggedIn) {
        // $('#joinModal').modal('show');
        this.joinChallenge();
      } else {
        try {
          localStorage.setItem('joinChallengeId', this.challenge.id);
        } catch {}
        this.$router.push('/signup');
      }
    },
    legacyInit() {
      if (process.client) {
        this.LEADERBOARD_GROUP = 15;
        this.LEADERBOARD_BUFFER = 5;
        this.start = $('.main-content').attr('startTime');
        this.end = $('.main-content').attr('endTime');
        this.middleBox = $('#middle-box');
        this.leaderboardTable = $('#leaderboard tbody');
        this.leaderboardHeading = this.leaderboardTable.children().first();
        this.averageSavings = $('#average');
        this.viewMore = $('#view-more');
        this.alreadyInError = $('#already-in');
        this.fullError = $('#full');
        this.notAuthorizedError = $('#not-authorized');
        this.noAccountsError = $('#no-active-accounts');
        this.joinError = $('#join-error');
        // this.leaveChallenge = $('#leave-challenge');
        this.notEnrolledError = $('#not-enrolled');
        this.endedError = $('#ended');
        this.leaveError = $('#leave-error');
        this.errors = $('.error');
        this.fbShare = $('#fb-share');
        this.twitterShare = $('#twitter');
        this.challengeName = $('#challenge-name');

        this.errors.hide();
      }
    },
    async joinChallenge() {
      await this.$store.dispatch(actionTypes.GET_TEAMS);

      let teamId;
      if (this.$store.state.user.teams.length === 0) {
        const createTeamResult = await this.$repo.teams.create();
        teamId = createTeamResult.data.id;
      } else {
        teamId =
          this.$store.state.user.teams[this.$store.state.user.teams.length - 1]
            .id;
      }

      if (TEAMS) {
        $('#joinModal').modal('show');
        return;
      }

      this.errors.hide();

      // if (!this.$store.state.user.isConnected) {
      //   this.noAccountsError.show();
      //   return;
      // }

      try {
        const url = '/challenges/' + this.challenge.id + '/join';
        const data = {
          team_id: teamId,
        };

        const errorFunc = function (err, self) {
          const message = err.response.data.msg;
          console.log('message', message);
          switch (message) {
            case 'already in challenge':
              self.alreadyInError.show();
              break;
            case 'challenge is full':
              self.fullError.show();
              break;
            case 'challenge has not been authorized':
              self.notAuthorizedError.show();
              break;
            case 'Team has no meters':
              self.noAccountsError.show();
              break;
            default:
              self.joinError.show();
          }
        };

        this.$api
          .post(url, data)
          .then((res) => {
            $('#joinModal').modal('hide');
            location.reload();
          })
          .catch((error) => {
            console.log('joinChallenge error', error.response);
            errorFunc(error, this);
          });
      } catch (e) {
        console.log(e);
        this.noAccountsError.show();
      }
    },

    close() {
      window.close();
    },

    leaveChallenge() {
      const url = `/challenges/${this.challenge.id}/leave`;
      const data = {
        team_id: this.participatingUserTeams[0],
      };
      this.$api.post(url, data).then(() => {
        $('#leaveModal').modal('hide');
        location.reload();
      });
    },
  },
  head() {
    return {
      title: `${this.challenge.name} | MeterLeader`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `Join the ${this.challenge.name} Challenge`,
        },
        // TODO: fix
        // { hid: 'og:url', property: 'og:url', content: this.challengeUri },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.challenge.clean_description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.challenge.image_banner_url,
        },
        // TODO: fix
        { hid: 'og:image:width', property: 'og:image:width', content: '1110' },
        // { hid: 'og:image:height', property:"og:image:height", content: "400" },
      ],
    };
  },
};
</script>

<style>
</style>
