<template>
  <div id="join-box" class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <h2>Save energy and combat climate change!</h2>
      </div>
      <div class="row no-gutters" style="max-width: 580px; margin: auto">
        <div class="col">
          <router-link id="create-challenge" to="/signup">
            Get Started FREE
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinFooter',
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/joinFooter';
</style>
