<template>
  <div>
    <NuxtLink :to="`/admind/property/${slotParams.item.id}`">{{
      slotParams.item.id
    }}</NuxtLink>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
};
</script>