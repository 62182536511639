<template>
  <div id="how-it-works-page">
    <div id="how-it-works-box" class="container" style="max-width: 900px">
      <h1>Pricing</h1>
      <h4 class="pricing-text text-left">
        Organizations that are nonprofit, governmental, or educational
        institutions are especially encouraged to discuss their unique needs
        with us. We will strive to develop a price structure that fits your
        budget.
      </h4>
      <h4 class="contact-text">
        For more details about Base Features & Services please contact us at
        <a href="mailto:support@meterleader.com"
          ><u>support@meterleader.com</u></a
        >
      </h4>
      <table
        class="table table-bordered"
        style="max-width: 820px; margin: auto"
      >
        <thead>
          <tr>
            <th colspan="3">Base Features & Services</th>
          </tr>
          <tr>
            <th scope="col">Pricing</th>
            <th scope="col">Individual</th>
            <th scope="col">Organization</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>One Time Setup Fee</td>
            <td>NA</td>
            <td>$3000</td>
          </tr>
          <tr>
            <td>Annual Subscription</td>
            <td>NA</td>
            <td>$1.25/eligible employee/month</td>
          </tr>
          <tr>
            <td>Monthly Subscription</td>
            <td>Free</td>
            <td>$2/eligible employee/month (minimum 3 months)</td>
          </tr>

          <tr>
            <th colspan="3">Benefits for Organizations</th>
          </tr>
          <tr>
            <td>Create Challenges</td>
            <td>N/A</td>
            <td>
              Create customized challenges for your organization’s members to
              participate in.
            </td>
          </tr>
          <tr>
            <td>Challenge Reporting</td>
            <td>N/A</td>
            <td>
              Receive Aggregated Challenge Data Extract for ESG Reporting.
            </td>
          </tr>
          <tr>
            <td>Challenge Engagement</td>
            <td>N/A</td>
            <td>Virtual Kickoff Event</td>
          </tr>
          <tr>
            <td>Program Design & Support</td>
            <td>N/A</td>
            <td>
              Platform onboarding, Program launch guidance, and Regular progress checkins.
            </td>
          </tr>
          <tr>
            <th colspan="3">Benefits for Users</th>
          </tr>

          <tr>
            <td>Challenges</td>
            <td>
              Individuals are eligible to participate in available open
              challenges, but are NOT able to create their own challenges.
            </td>
            <td>
              Individuals are eligible to participate in closed organization sponsored challenges, but are NOT able to create their own challenges. Individuals are eligible to win organization sponsored challenge prizes.
            </td>
          </tr>
          <tr>
            <td>Challenge Engagement</td>
            <td>
              Receive 14 Email Modules as part of participation in a Challenge
              (Start, End, and 12 Energy Saving Modules).
            </td>
            <td>
              Receive 14 Email Modules as part of participation in a Challenge
              (Start, End, and 12 Energy Saving Modules).
            </td>
          </tr>

          <tr>
            <td>My Usage</td>
            <td>View hourly electric & gas usage data, plus hourly carbon emissions</td>
            <td>View hourly electric & gas usage data, plus hourly carbon emissions</td>
          </tr>
          <tr>
            <td>Energy Saving Actions</td>
            <td>View a list of energy saving actions</td>
            <td>View a list of energy saving actions</td>
          </tr>
          <tr>
            <td>My Account</td>
            <td>Add utility accounts to your properties</td>
            <td>Add utility accounts to your properties</td>
          </tr>

          <tr>
            <td>New Features</td>
            <td>N/A</td>
            <td>Coming Soon</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FourSteps from '@/components/FourSteps';

export default {
  name: 'HowItWorks',
  components: { FourSteps },
  head() {
    return {
      title: 'How It Works | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Participate in a MeterLeader energy saving Challenge in four easy steps: 1. Sync Utility Data, 2. Join A Challenge, 3. Save Energy, 4. Make An Impact.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/howItWorks';

.table-bordered {
  border: 1px solid black;

  table {
    line-height: 1.2;
  }

  th,
  td {
    border: 1px solid black;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    padding: 0.5rem 0.75rem;
  }

  td {
    font-weight: 300;
  }

  th {
    background-color: $green;
  }
}

.pricing-text {
  max-width: 820px;
  margin: auto auto 40px auto;
}

.contact-text {
  max-width: 650px;
  margin: auto auto 40px auto;
  font-weight: bold !important;
}
</style>
