<template>
  <div id="tips-page">
    <OnboardingReminder />
    <div id="tip-header" class="container" style="margin-top: 50px">
      <nuxt-link to="/actions"
        ><h6 id="back-button">< Back to Actions</h6></nuxt-link
      >
      <h1 id="tips-title">{{ tip.name }}</h1>
    </div>

    <div id="tip-description-container" class="container">
      <div id="tip-description" v-html="tip.description"></div>
      <div id="learn-more-container">
        <h5>Learn More</h5>
        <div v-for="more of tip.links" class="learn-more-content">
          <p class="learn-more-title">
            <a
              target="_blank"
              :href="Object.values(more)[0]"
              :title="Object.values(more)[0]"
            >
              {{ Object.keys(more)[0] }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingReminder from '@/components/OnboardingReminder';
export default {
  name: 'TipName',
  components: { OnboardingReminder },
  async asyncData({ app, params }) {
    if (params.tipName) {
      const tip = await app.$api.get(`/tips/name/${params.tipName}`);
      return {
        tip: tip.data,
      };
    }
  },
  data() {
    return {
      tip: {},
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/css/energySavingTips';
</style>
