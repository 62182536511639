<template>
  <div id="utility-content" class="content mx-3">
    <div class="d-flex align-items-center">
      <h3 class="font-weight-bold mt-3">Utilities</h3>
      <div :style="{ flexGrow: 1 }"></div>

      <button class="btn btn-c add" @click="addUtilityAccount">
        <span>Add Utility</span>
        <font-awesome-icon class="ml-2" icon="plus" size="md" />
      </button>
    </div>

    <div
      v-for="(account, index) in utilityAccounts"
      :key="`utilityCard-${index}`"
    >
      <div class="mb-4 card round">
        <div class="d-flex align-items-center p-3">
          <h5 class="font-weight-bold">{{ account.utility_provider }}</h5>
          <div class="h5 px-2">
            <span
              class="badge badge-pill"
              :class="{
                'badge-success': account.status === 'Active',
                'badge-danger': account.status !== 'Active',
              }"
              >{{ account.status }}</span
            >
          </div>
          <div :style="{ flexGrow: 1 }"></div>
        </div>
        <div
          v-for="(meter, meterIndex) in userMeters.filter(
            (o) => o.subscription_id === account.id
          )"
          :key="`meters-${meterIndex}`"
          class="d-flex align-items-center bg-grey p-2 c-space"
        >
          <div class="h6">
            <span v-if="meter.available_meter_types.includes('GAS')">🔥</span>
            <span v-if="meter.available_meter_types.includes('ELECTRIC')">⚡</span>
            ({{meter.meter_no}}), {{ meter.address }}
          </div>
<!--          <font-awesome-icon-->
<!--            v-if="meter.available_meter_types.includes('GAS')"-->
<!--            class="ml-5"-->
<!--            icon="fire"-->
<!--          />-->
<!--          <font-awesome-icon-->
<!--            v-if="meter.available_meter_types.includes('ELECTRIC')"-->
<!--            icon="bolt"-->
<!--            class="ml-3"-->
<!--          />-->
        </div>
        <div class="p-2 d-flex justify-content-around">
          <button
            class="btn btn-secondary btn-c"
            @click="$router.push(`/syncdata/${account.id}`)"
          >
            Reconnect
          </button>
          <button
            class="btn btn-secondary btn-c c-disconnect"
            data-toggle="modal"
            data-target="#delete-modal"
            @click="selectedUtilityAccount = account"
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>

    <h5 v-if="utilityAccounts.length <= 0">
      No utility accounts have yet been added
    </h5>

    <!--region Delete Utility Account Modal    -->
    <div
      id="delete-modal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Utility Account</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure that you want to delete this utility account?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              id="delete-utility"
              type="button"
              data-dismiss="modal"
              class="btn btn-danger"
              @click="deleteUtility(selectedUtilityAccount.id)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--endregion Delete Utility Account Modal    -->
    <BootstrapModal :showModal.sync="showCannotAddPelmSubscription">
      <template v-slot:body>
        <div>
          Sorry! <br />
          In order to connect your utility account you must be associated with
          an Organization who has purchased a MeterLeader Subscription. Please
          see pricing <a href="/pricing"></a>. To complete purchase, please send
          email to
          <a href="mailto: support@meterleader.com">support@meterleader.com</a>.
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="showCannotAddPelmSubscription = false"
        >
          Close
        </button>
      </template>
    </BootstrapModal>
  </div>
</template>

  <script>
import { DateTime } from 'luxon';
import { actionTypes } from '@/store/types';
import BootstrapModal from '~/components/BootstrapModal.vue';

export default {
  pageTitle: 'Settings',

  data() {
    return {
      utilityAccounts: [],
      userMeters: [],
      selectedUtilityAccount: null,
      showCannotAddPelmSubscription: false,
      canSubscribePelm: false,
    };
  },
  async mounted() {
    this.$store.dispatch(actionTypes.GET_USER_PROFILE);
    await this.loadUtilityAccounts();
    await this.getUserMeters();
    this.canSubscribePelm = this.$store.state.user.profile.can_subscribe_pelm;
  },
  methods: {
    addUtilityAccount() {
      if (!this.canSubscribePelm) {
        this.showCannotAddPelmSubscription = true;
        return;
      }
      this.$router.push('/syncdata');
    },
    async loadUtilityAccounts() {
      // this.$nuxt.$loading.start();
      let res = await this.$api.get('pelm/pelm-subscriptions');
      this.utilityAccounts = res.data.subscriptions.map((o) => {
        o.status = 'Active';
        return o;
      });
      // this.$nuxt.$loading.finish();
    },
    async getUserMeters() {
      // this.$nuxt.$loading.start();
      const res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      this.userMeters = res.items;
      // this.$nuxt.$loading.finish();
    },
    async deleteUtility(accountId) {
      this.$nuxt.$loading.start();
      await this.$api.delete(`pelm/pelm-subscriptions/${accountId}`);
      await this.loadUtilityAccounts();
      this.$nuxt.$loading.finish();
    },
  },
  components: { BootstrapModal },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~/assets/css/variables'

.round
  border-radius: 10px

.bg-grey
  background-color: $grey

a
  color: $accent
.btn-c
  font-weight: 500
    // color: #fff
  border-radius: 18px

  &.add
    // background-color: $green
    border-color: black

  &.delete
    background-color: #dc3545
    border-color: #dc3545

.c-space
  margin-bottom: 2px

.c-disconnect
  color: #808080
  background-color: #ffffff
</style>
