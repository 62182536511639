<template>
  <div id="house-content" class="content">
    <h2>Residence Details</h2>
    <p class="note">
      Your electric and gas utility account is attached to your specific home
      residence. Currently MeterLeader only supports one electric and gas
      utility account per user.
    </p>
    <p v-if="!$store.state.user.isConnected || !hasMeter">
      To fill out your home profile you must first connect your utility account.
      <nuxt-link to="/syncdata">Click here to connect!</nuxt-link>
    </p>
    <!--      <form method="POST" id="choose-residence">
        <label for="residence">Residence:</label>
        <select id="residence" name="residence">
        </select>
        <button id="choose-residence-button" type="submit" class="btn">Go</button>
      </form> -->
    <form
      v-if="$store.state.user.isConnected && hasMeter"
      id="house-settings-form"
      method="POST"
    >
      <p id="house-changed" class="success">
        House settings successfully saved
      </p>
      <p id="house-error" class="error">Server Error. Please try again.</p>
      <label for="homeType">Home Type</label><br />
      <select
        id="homeType"
        v-model.number="meter.properties.homeType"
        name="homeType"
        class="custom-select"
      >
        <option disabled selected>Please select Home Type</option>
        <option value="0">Single Family Home</option>
        <option value="2">Townhouse</option>
        <option value="4">Apartment</option>
        <option value="8">Condo</option>
        <option value="16">Duplex</option>
        <option value="32">Mobile Home</option>
        <option value="64">Other</option>
        <option value="128">Not Sure</option></select
      ><br />
      <label for="occupants">Number of Occupants</label><br />
      <input
        id="occupants"
        v-model.number="meter.properties.occupants"
        type="number"
        name="occupants"
      /><br />
      <label for="square-footage">Square Footage</label><br />
      <input
        id="square-footage"
        v-model.number="meter.properties.footage"
        type="number"
        name="footage"
      /><br />
      <label for="heatingType">Primary Heating Type</label><br />
      <select
        id="heatingType"
        v-model.number="meter.properties.heatingType"
        name="heatingType"
        class="custom-select"
      >
        <option disabled selected>Please select Heating Type</option>
        <option value="0">Natural Gas Furnace</option>
        <option value="2">Propane Furnace</option>
        <option value="4">Oil Furnace</option>
        <option value="8">Electric Furnace</option>
        <option value="16">Electric Heat Pump</option>
        <option value="32">Electric Baseboard Heating</option>
        <option value="64">Electric Space Heater</option>
        <option value="128">Boiler</option>
        <option value="256">Other</option>
        <option value="512">No Heating</option>
        <option value="1028">Not Sure</option></select
      ><br />
      <label id="acType" for="coolingType">Primary Cooling Type</label><br />
      <select
        id="coolingType"
        v-model.number="meter.properties.coolingType"
        name="coolingType"
        class="custom-select"
      >
        <option disabled selected>Please select Cooling Type</option>
        <option value="0">Central Cooling</option>
        <option value="2">Through Wall (Split System)</option>
        <option value="4">Window/Room Air Conditioner</option>
        <option value="8">Portable Air Conditioner</option>
        <option value="16">No AC</option>
        <option value="32">Not Sure</option></select
      ><br />
      <label for="poolSpa">Pool and/or Spa</label><br />
      <select
        id="poolSpa"
        v-model.number="meter.properties.hasPoolSpa"
        name="poolSpa"
        class="custom-select"
      >
        <option disabled selected>Please select one</option>
        <option value="0">Yes</option>
        <option value="2">No</option></select
      ><br />
      <button
        id="house-settings-save-changes-button"
        type="button"
        class="btn btn-green"
        @click="submit"
      >
        Save Changes
      </button>
    </form>

    <form v-else style="margin-top: 10px"></form>
  </div>
</template>

<script>
import { actionTypes } from '@/store/types';

const PROPERTIES = [
  'meter',
  'homeType',
  'occupants',
  'footage',
  'heatingType',
  'coolingType',
  'hasPoolSpa',
];

export default {
  name: 'MeterProfile',
  async asyncData({ app }) {
    let hasMeter = false;
    try {
      const result = await app.$repo.accounts.getAll();
      const account = result.data[result.data.length - 1];
      const meter = account.meters[account.meters.length - 1];
      if (meter) {
        return {
          meter,
          hasMeter: true,
        };
      }
    } catch (error) {}
    return {
      meter: {
        properties: {},
      },
      hasMeter: false,
    };
  },
  data() {
    return {
      meter: {
        properties: {},
      },
      hasMeter: false,
    };
  },
  methods: {
    async submit() {
      const properties = this.meter.properties;
      const data = {
        properties,
      };
      try {
        await this.$repo.accounts.updateMeter(this.meter.id, data);
        $('#house-changed').show();
      } catch (error) {
        // TODO: This errors out when a property isn't an Integer, however the error isn't caught here
        console.log('error', error.response);
      }
    },
  },
};
</script>

<style scoped lang="scss">
label {
  padding-top: 5px;
}

#house-settings-save-changes-button {
  margin-top: 15px;
}
</style>
