<template>
  <div class="container">
    <div
      class="my-3 p-3 d-flex align-items-center"
      :style="{ backgroundColor: '#f5f5f5' }"
    >
      <div class="h1 mb-0">My Meters</div>
      <div :style="{ flexGrow: 1 }"></div>
      <div>
        <button class="btn btn-primary">Manage Utility Accounts</button>
      </div>
    </div>
    <div class="d-flex">
      <div
        v-for="(meter, index) in meters"
        :key="`meter-${index}`"
        class="mx-2 card"
        style="width: 18rem"
      >
        <div class="card-body d-flex flex-column justify-content-between">
          <h5 class="card-title">Pacific Gas and Electric</h5>
          <p class="card-text">1 WARRIORS WAY SAN FRANCISCO CA 94158</p>
          <p>#5777345636</p>
        </div>
      </div>
      <div v-if="meters.length == 0" class="mx-2 card" style="width: 18rem">
        <div
          class="
            card-body
            d-flex
            flex-column
            justify-content-center
            align-items-center
          "
          :style="{ height: '12rem' }"
        >
          <h5 class="card-title">No meters to show.</h5>
          <button class="btn btn-primary">Add Utility Account</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  middleware: 'check_login',
  data() {
    return {
      meters: [1, 2, 3],
    };
  },
};
</script>