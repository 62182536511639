<template>
  <div id="home">
    <!--region Panel 1 -->
    <div id="lightning-box">
      <div class="container" style="height: 100%">
        <div class="row no-gutters" style="height: 100%">
          <div class="col align-self-center left">
            <h1>MeterLeader Pilot Opportunity</h1>
            <h5 id="banner-tagline">
              MeterLeader gamifies saving energy by using real-time utility data
              and behavioral science. We’re like a Fitbit challenge, but instead
              of steps we measure kWh, therm, and CO2 reductions.
            </h5>
            <div class="row no-gutters" style="max-width: 580px">
              <div class="col">
                <a
                  href="https://calendly.com/meterleader/nzandt"
                  target="_blank"
                  class="btn px-5 py-3 my-4 font-weight-bold"
                  style="background-color: #c200fb; color: #fff"
                  >Schedule a 15 min call</a
                >
              </div>
            </div>
          </div>
          <div class="col-5 align-self-center">
            <div style="object-fit: cover">
              <img
                id="lightning-img"
                style="height: 450px"
                src="~/assets/images/SampleMeterLeaderPilot.webp"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--endregion Panel 1 -->
    <!--region Panel 2: Explore Challenges -->
    <div class="container-fluid" style="background-color: #e8e8e8">
      <div id="description-box" class="container">
        <h4>
          Seeking Companies, Cities, or Property Management Groups located in
          CA, NY, MD, WA, IL, or FL with 100+ employees/residents to pilot a
          MeterLeader Energy Savings Challenge.
        </h4>
      </div>
    </div>
    <div class="container my-5 py-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-12 col-lg-7">
          <h2 class="font-weight-bold">Pilot Goals</h2>
          <h4>
            Run a successful 3 month long Energy Savings Challenge for Employees
            and/or Residents:
          </h4>
          <ul class="ml-4 mt-4">
            <li class="h4">With 50 - 100+ challenge participants</li>
            <li class="h4">Resulting in 5 - 10% in energy savings</li>
            <li class="h4">Resulting in participant engagement</li>
          </ul>
        </div>
        <div class="col-sm-12 col-lg-5 pt-sm-5 pt-lg-0 align-self-center">
          <img
            :style="{ width: '100%' }"
            src="~/assets/images/pilotIllustrationOne.svg"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="container">
        <div
          id="how-it-works-box"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <h2 class="mb-4 font-weight-bold">How MeterLeader Works</h2>
          <FourSteps class="mt-5" color="purple" counterBgColor="#C200FB" />
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h2 class="py-3 font-weight-bold">Pilot Benefits</h2>
        <ol
          :style="{
            minWidth: '100px',
            maxWidth: '1000px',
            listStyleType: 'none',
          }"
        >
          <li class="h4 py-2">
            1. Help your employees/residents reduce their utility bills while
            working from home.
          </li>
          <li class="h4 py-2">
            2. Increase employee/resident satisfaction through engagement on
            climate change.
          </li>
          <li class="h4 py-2">
            3. Track measure and report emissions from home based
            emplovees/residents.
          </li>
          <li class="h4 py-2">
            4. Help your state meet its ambitious residential energy & climate
            goals.
          </li>
        </ol>
      </div>
    </div>
    <div class="pb-5">
      <div class="container">
        <div class="row text-center justify-content-center mb-5">
          <div class="col-xl-6 col-lg-8">
            <h2 class="font-weight-bold">Pilot Timeline</h2>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2003"
                >
                  <div class="inner-circle"></div>
                  <p class="h4 font-weight-bold mt-3 mb-1">Month 1</p>
                  <p class="h5 mb-0 mb-lg-0">Build & Launch Challenge</p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2004"
                >
                  <div class="inner-circle"></div>
                  <p class="h4 font-weight-bold mt-3 mb-1">Month 2</p>
                  <p class="h5 mb-0 mb-lg-0">
                    Challenge Starts - Kickoff Event
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2005"
                >
                  <div class="inner-circle"></div>
                  <p class="h4 font-weight-bold mt-3 mb-1">Month 3</p>
                  <p class="h5 mb-0 mb-lg-0">
                    12 Email Modules & Slack Facilitation
                  </p>
                </div>
              </div>
              <div class="timeline-step">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2010"
                >
                  <div class="inner-circle"></div>
                  <p class="h4 font-weight-bold mt-3 mb-1">Month 4</p>
                  <p class="h5 mb-0 mb-lg-0">Challenge Ends - Awards Prizes</p>
                </div>
              </div>
              <div class="timeline-step mb-0">
                <div
                  class="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2020"
                >
                  <div class="inner-circle"></div>
                  <p class="h4 font-weight-bold mt-3 mb-1">Month 5</p>
                  <p class="h5 mb-0 mb-lg-0">Data Extract & Case Study</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #1ad596" class="py-5">
      <div class="container">
        <div class="d-flex flex-column align-items-center">
          <h2 class="py-3" style="color: #fff; text-align: center">
            Become a Pilot Partner
          </h2>
          <a
            href="https://calendly.com/meterleader/nzandt"
            target="_blank"
            class="btn px-5 py-3 my-4 font-weight-bold"
            style="background-color: #fff; color: #000"
            >Schedule a 15 min call</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FourSteps from '@/components/FourSteps';
import JoinFooter from '@/components/JoinFooter';
import Challenge from '@/components/Challenge';
import { actionTypes } from '@/store/types';

import UpOpt from '@/assets/images/up-opt.svg?inline';
import DownOpt from '@/assets/images/down-opt.svg?inline';

export default {
  name: 'Home',
  components: {
    Challenge,
    JoinFooter,
    FourSteps,
    UpOpt,
    DownOpt,
  },

  async asyncData({ store, app }) {
    try {
      await store.dispatch(actionTypes.GET_CHALLENGES);
    } catch (error) {
      console.log('error', error.response);
    }
  },
  data() {
    return {};
  },
  computed: {
    challenges() {
      if (process.client) {
        if (this.$store.state.challenges.length > 0) {
          this.$nextTick(function () {
            $('.challenge-homepage').hover(
              function () {
                $(this).find('.overlay').css('display', 'block');
              },
              function () {
                $(this).find('.overlay').css('display', 'none');
              }
            );
          });
          // TODO: do this properly
          return this.$store.state.challenges[1]
            .concat(this.$store.state.challenges[2])
            .slice(0, 3);
        }
      }
      return [];
    },
  },
  head() {
    return {
      title: 'Pilot | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'http://s3.amazonaws.com/meterleader-assets/fbimage.png',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '876',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '451',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/homepage';
@import '~@/assets/css/howItWorks';

.four-steps {
  margin: 0;

  .row {
    padding: 0;
    margin: 0;
  }

  .text {
    h4 {
      font-family: $header_font;
      font-weight: 500;
      font-size: 1.2rem;
    }

    p {
      font-family: $primary_font;
      font-weight: 400;
    }
  }

  .text {
    text-align: left;
  }

  .icon {
    height: 50px;
    padding-bottom: 10px;
  }

  .number {
    margin-top: 48px;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    p {
      font-family: $primary_font;
      width: 50%;
      margin: auto;
      font-size: 15pt;
      font-weight: bold;
      color: white;
    }
  }

  .number-container {
    padding: 0;
  }
}

.as-featured-in {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;

  h2 {
    padding-bottom: 40px;
  }
}

#homepage-how-it-works-box {
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 0;
  position: relative;

  h2 {
    padding-bottom: 40px;
  }

  .number {
    background-color: $orange;
  }

  #learn-more {
    width: 100%;
    text-align: right;
    padding-bottom: 40px;
    padding-top: 70px;
    padding-right: 40px;

    a {
      color: black;
      display: inline-block;
      font-size: 25px;
    }

    a:hover {
      text-decoration: none;
    }

    span {
      font-size: 22pt;
    }
  }
}

@media only screen and (max-width: 768px) {
  .small-margin-top {
    margin-top: 30px;
  }
}

.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: '';
    display: block;
    border-top: 0.25rem dotted #c200fb;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: '';
    display: block;
    border-top: 0.25rem dotted #c200fb;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: 0.3125rem;
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #c200fb;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: '';
  background-color: #c200fb;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: 0.5;
}
</style>
