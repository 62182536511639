<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-12 mt-3">
        <div class="login p-4">
          <h1 class="font-weight-bold mb-1">Sign Up</h1>
          <small class="text-tip"
            >Already have a MeterLeader account?
            <a href="/login">Login</a>
          </small>

          <div v-if="typeof errorMessage === Array">
            <p class="error" v-for="item in errorMessage" :key="item">
              {{ item }}
            </p>
          </div>
          <div v-else>
            <p class="error mb-0" v-if="errorMessage">{{ errorMessage }}</p>
          </div>

          <div class="form-field mt-3">
            <label class="label">First Name</label><br />
            <input class="px-3" type="text" v-model="firstname" required />
          </div>
          <div class="form-field mt-3">
            <label class="label">Last Name</label><br />
            <input class="px-3" type="text" v-model="lastname" required />
          </div>
          <div class="form-field mt-3">
            <label class="label">Email</label><br />
            <input class="px-3" type="text" v-model="email" required />
          </div>

          <div class="form-field mt-3">
            <label class="label">Password</label><br />
            <input class="px-3" type="password" v-model="password" required />
          </div>

          <div class="form-field mt-3">
            <label class="label">Corporate Code (Optional)</label><br />
            <input class="px-3" type="text" v-model="corporate_code" />
          </div>

          <button class="accent-bg mb-3 mt-3">
            <p class="mb-0" @click="signUp">Sign Up</p>
          </button>
          <small class="text-tip"
          >By creating an account you are accepting our
            <a href="/terms-conditions">Terms and Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>
          </small>

          <div class="d-flex justify-content-center">
            <hr class="flex-grow-1" />
            <p class="pt-1 px-2">OR</p>
            <hr class="flex-grow-1" />
          </div>

          <div>
            <button
              class="fb-bg text-white d-flex align-items-center mb-3"
              @click="facebookSignup"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="mx-3"
              >
                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
              <p class="mb-0 text-center w-100 neg-margin">
                Signup with Facebook
              </p>
            </button>

            <div
              id="appleid-signin"
              class="signin-button"
              data-mode="center-align"
              data-color="black"
              data-border="false"
              data-border-radius="40"
              data-type="sign-up"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'Signup',
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      corporate_code: '',
      errorMessage: '',
    };
  },
  beforeCreate() {
    if (this.$auth.loggedIn) {
      return this.$router.push('/my-usage');
    }
  },
  methods: {
    facebookSignup() {
      this.$auth.$storage.setUniversal('retryCount', 0);
      window.location.href = process.env.API_ADDRESS + '/facebook/signup';
    },
    signUp() {
      const data = {
        email: this.email.trim(),
        password: this.password.trim(),
        firstname: this.firstname.trim(),
        lastname: this.lastname.trim(),
        corporate_code: this.corporate_code.trim(),
      };

      this.$api
        .post('/user/create_user', data)
        .then(() => {
          this.$router.replace('/signup/confirm');
        })
        .catch((error) => {
          switch (error.response.data.msg) {
            case 'weak_password':
              this.errorMessage = error.response.data.warning;
              break;
            case 'bad_email':
              this.errorMessage = 'Not a valid email address';
              break;
            case 'bad_corporate_code':
              this.errorMessage = 'Invalid corporate code';
              break;
            case 'user_exists':
              this.errorMessage = 'A user for that email already exists.';
              break;

            default:
              this.errorMessage = 'Server Error';
              break;
          }
        });
    },
  },
  head() {
    return {
      title: 'Sign up',
      meta: [
        {name: 'appleid-signin-client-id', content: process.env.APPLE_SIGNIN_CLIENT_ID},
        {name: 'appleid-signin-scope', content: 'name email'},
        {name: 'appleid-signin-redirect-uri', content: process.env.APPLEID_SIGNIN_REDIRECT_URI},
        {name: 'appleid-signin-state', content: 'mlapsu'},
        {name: 'appleid-signin-nonce', content: ''},
        {name: 'appleid-signin-use-popup', content: false},
      ],
      script: [
        {
          src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', body: true
        }
      ]
    };
  },
};
</script>
  <style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.error
  color: red

hr
  border-top: 1px solid black

a
  color: $accent

.accent-bg
  background-color: $accent

.fb-bg
  background-color: #3B579D

.form-field

  > .label
    margin-bottom: 0px
    font-size: 0.9rem
    font-weight: bold
    padding-bottom: 5px
  > input
    width: 100%
    height: 3rem
    border-radius: 10px !important

.login
  border-radius: 18px
  border: 2px solid $grey

.text-tip
  color: color.scale(#000, $lightness: 50%)
  font-size: 0.95rem

button
  width: 100%
  height: 3.3rem
  border-radius: 40px

  p
    font-weight: bold
    font-size: 1.09rem
  > .neg-margin
    margin-left: -62px
  > svg
    height: 30px
    width: 30px
    fill: white
</style>
<style>
.signin-button {
  width: 100%;
  height: 3.3rem;
}
.signin-button > div {
  max-width: 100% !important;
}
</style>
