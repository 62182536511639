<template>
  <div id="tips-page">
    <OnboardingReminder />
    <div id="energy-saving-tips-title" class="container">
      <h1>Energy Saving Actions</h1>
    </div>

    <div id="tips-top" class="row container d-none d-md-flex">
      <div id="tips-description" class="col-md-8 col-12">
        <p>
          Browse through our collection of energy saving actions and learning
          resources, to find actions that are best suited for your household and
          budget. Then during a Challenge implement some of these actions in
          order to save energy.
          <b
            >Consult a professional before making any equipment
            installations.</b
          >
        </p>
      </div>
      <div id="stats" class="col-md-4 col-12">
        <p>
          <strong>Average US Home Energy End Uses</strong><br />
          41% Space Heating<br />
          35% Appliances, Electronics, Lighting<br />
          18% Water Heating<br />
          6% Air Conditioning
        </p>
      </div>
    </div>

    <div id="tips-list" class="row container">
      <div id="tip-filter" class="col-lg-2 col-12">
        <h4 id="by-type">By Type</h4>
        <ul>
          <li id="0" class="active">All actions (<span />)</li>
          <li id="1">Lighting (<span />)</li>
          <li id="2">Appliances (<span />)</li>
          <li id="3">Electronics (<span />)</li>
          <li id="4">Heating (<span />)</li>
          <li id="5">Cooling (<span />)</li>
          <li id="6">Heating & Cooling (<span />)</li>
          <li id="7">Hot Water (<span />)</li>
          <li id="8">Pool (<span />)</li>
          <li id="9">Other (<span />)</li>
        </ul>
      </div>
      <div class="col-lg-10 col-12">
        <div id="tips-cards" class="row" />
        <div id="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingReminder from '@/components/OnboardingReminder';

export default {
  name: 'Actions',
  components: { OnboardingReminder },
  middleware: ['auth'],
  mounted() {
    if (process.client) {
      require('paginationjs');

      const filters = $('#tip-filter li');
      const paginationContainer = $('#pagination');
      const dataContainer = $('#tips-cards');

      const updatePagination = function (filter) {
        paginationContainer.pagination({
          dataSource: categories[filter].tips,
          prevText: 'Prev',
          nextText: 'Next',
          callback(data, pagination) {
            dataContainer.html(data);
          },
          className: 'custom-paginationjs',
        });
      };

      const categories = {
        0: { name: 'all-tips', tips: [] },
        1: { image: '/assets/images/lighting.png', name: 'lighting', tips: [] },
        2: {
          image: '/assets/images/appliances.png',
          name: 'appliances',
          tips: [],
        },
        3: {
          image: '/assets/images/electronics.png',
          name: 'electronics',
          tips: [],
        },
        4: { image: '/assets/images/heating.png', name: 'heating', tips: [] },
        5: { image: '/assets/images/cooling.png', name: 'cooling', tips: [] },
        6: {
          image: '/assets/images/heatingAndCooling.png',
          name: 'heating-and-cooling',
          tips: [],
        },
        7: {
          image: '/assets/images/hotWater.png',
          name: 'hot-water',
          tips: [],
        },
        8: { image: '/assets/images/pool.png', name: 'pool', tips: [] },
        9: { image: '/assets/images/other.png', name: 'other', tips: [] },
      };

      this.$api.get('/tips/?show_all=true').then(function (res) {
        console.log(res);

        for (let i = 0; i < res.data.length; i++) {
          const tip = res.data[i];

          const tiphtml =
            '<div class="col-md-6 col-12 tip-outer ' +
            tip.category +
            '">\
            <a class="row tip" href="/actions/' +
            tip.slug +
            '">\
              <div class="col-3 tip-content image">\
                <img src="' +
            categories[tip.category].image +
            '">\
              </div>\
              <div class="col-9 tip-content">\
                <h6>' +
            tip.name +
            '</h6>\
                <p>View Action</p>\
              </div>\
            </a>\
          </div>';
          categories[tip.category].tips.push(tiphtml);
          categories[0].tips.push(tiphtml);
        }

        for (const type in categories) {
          $('#' + type + ' > span').append(categories[type].tips.length);
        }
        updatePagination(0);
      });

      filters.click(function () {
        const filter = parseInt(this.id);
        filters.removeClass('active');
        this.classList.add('active');
        dataContainer.empty();
        updatePagination(filter);
      });
    }
  },
  head() {
    return {
      title: 'Energy Saving Actions | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Browse through MeterLeader’s catalog of energy saving tips and learning resources. During the challenge implement these tips in order to save energy and improve your challenge ranking.',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/css/energySavingTips';
</style>
