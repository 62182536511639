<template>
  <div>
    <div id="toggle-container">
      <h3>
        <span
          id="four-steps-creators-toggle"
          style="text-decoration: underline"
          class="toggle"
          >Organizers</span
        >
        &nbsp; &nbsp;
        <span id="four-steps-participants-toggle" class="toggle"
          >Participants</span
        >
      </h3>
    </div>
    <div id="four-steps-creators-div" style="display: block">
      <div class="container-fluid row four-steps">
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>1</p>
              </div>
            </div>
            <div class="container col">
              <img class="icon" :src="`/assets/images/${color}Notepad.png`" />
              <div class="text">
                <h4>Design Challenge</h4>
                <p>
                  Give your challenge a name, set energy reduction goals, and
                  the types of rewards.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>2</p>
              </div>
            </div>
            <div class="container col">
              <img
                class="icon"
                :src="'/assets/images/' + color + 'Group2.png'"
              />
              <div class="text">
                <h4>Invite Participants</h4>
                <p>
                  Recruit challenge participants by sharing the challenge page
                  over social media and email.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>3</p>
              </div>
            </div>
            <div class="container col">
              <img
                class="icon"
                :src="'/assets/images/' + color + 'Chart.png'"
              />
              <div class="text">
                <h4>Monitor Savings</h4>
                <p>
                  Monitor the progress of your challenge over its duration, and
                  engage with participants.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>4</p>
              </div>
            </div>
            <div class="container col">
              <img class="icon" :src="'/assets/images/' + color + 'Gift.png'" />
              <div class="text">
                <h4>Reward Winners</h4>
                <p>
                  At the end of the challenge reward your winners with a prize
                  and recognition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="four-steps-participants-div" style="display: none">
      <div class="container-fluid row four-steps">
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>1</p>
              </div>
            </div>
            <div class="container col">
              <img
                class="icon"
                :src="'/assets/images/' + color + 'Meter.png'"
              />
              <div class="text">
                <h4>Sync Data</h4>
                <p>
                  Sync your energy meter data automatically from your online
                  utility account.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>2</p>
              </div>
            </div>
            <div class="container col">
              <img
                class="icon"
                :src="'/assets/images/' + color + 'Group.png'"
              />
              <div class="text">
                <h4>Join Challenge</h4>
                <p>
                  Choose one or as many challenges as are available to you to
                  participate in.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>3</p>
              </div>
            </div>
            <div class="container col">
              <img class="icon" :src="'/assets/images/' + color + 'Bolt.png'" />
              <div class="text">
                <h4>Save Energy</h4>
                <p>
                  Follow our energy saving tips to start saving energy as
                  compared to your previous year's baseline.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid col-12 col-sm-6 col-xl-3">
          <div class="container-fluid row">
            <div class="container col-auto number-container">
              <div class="number" :style="counterStyle">
                <p>4</p>
              </div>
            </div>
            <div class="container col">
              <img
                class="icon"
                :src="'/assets/images/' + color + 'Reward.png'"
              />
              <div class="text">
                <h4>Win Prizes</h4>
                <p>
                  Over the duration of the competition check your ranking to see
                  if you qualify to win a prize.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourSteps',
  props: {
    color: String,
    counterBgColor: {
      type: String,
      required: false,
    },
  },
  mounted() {
    $('#four-steps-creators-toggle').click(function () {
      $('#four-steps-creators-div').css('display', 'block');
      $('#four-steps-participants-div').css('display', 'none');
      $('#four-steps-creators-toggle').css('text-decoration', 'underline');
      $('#four-steps-participants-toggle').css('text-decoration', 'none');
    });

    $('#four-steps-participants-toggle').click(function () {
      $('#four-steps-creators-div').css('display', 'none');
      $('#four-steps-participants-div').css('display', 'block');
      $('#four-steps-creators-toggle').css('text-decoration', 'none');
      $('#four-steps-participants-toggle').css('text-decoration', 'underline');
    });
  },
  computed: {
    counterStyle() {
      return {
        backgroundColor: this.counterBgColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/fourSteps';

#homepage-how-it-works-box {
  background-color: $green;
  color: white;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 0;
  position: relative;

  h2 {
    padding-bottom: 40px;
  }

  .number {
    background-color: $purple;
  }

  #learn-more {
    width: 100%;
    text-align: right;
    padding-bottom: 40px;
    padding-top: 70px;
    padding-right: 40px;

    a {
      color: white;
      display: inline-block;
      font-size: 25px;
    }

    a:hover {
      text-decoration: none;
    }

    span {
      font-size: 22pt;
    }
  }
}
</style>
