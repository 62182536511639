<template>
  <div class="container">
    <h2 class="d-none d-md-flex font-weight-bold">
      {{ $titleBar.data.title }}
    </h2>
    <div class="row">
      <div class="col-12 col-md-3 mt-2">
        <div>
          <PillTabsVue
            class="d-md-none"
            :items="tabItems"
            :selected.sync="selectedTab"
          ></PillTabsVue>
          <SidebarTabsVue
            class="d-none d-md-block"
            :items="tabItems"
            :selected.sync="selectedTab"
          ></SidebarTabsVue>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div class="setting-block">
          <PropertiesSettingVue v-if="selectedTab == 'Properties'" />
          <UtilitiesSettingVue v-if="selectedTab == 'Utilities'" />
          <AccountSettingVue v-if="selectedTab == 'Account'" />
          <NotificationSettingVue v-if="selectedTab == 'Notifications'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PillTabsVue from '~/components/PillTabs.vue';
import SidebarTabsVue from '~/components/SidebarTabs.vue';
import AccountSettingVue from '~/components/user_settings/AccountSetting.vue';
import NotificationSettingVue from '~/components/user_settings/NotificationSetting.vue';
import PropertiesSettingVue from '~/components/user_settings/PropertiesSetting.vue';
import UtilitiesSettingVue from '~/components/user_settings/UtilitiesSetting.vue';

export default {
  pageTitle: 'Settings',
  backButtonRoute: '/settings',
  middleware: ['auth'],
  components: {
    PillTabsVue,
    SidebarTabsVue,
    PropertiesSettingVue,
    UtilitiesSettingVue,
    AccountSettingVue,
    NotificationSettingVue,
  },
  data() {
    return {
      selectedTab: this.$route.query.tab ? this.$route.query.tab : 'Account',
      tabItems: ['Account', 'Utilities', 'Properties', 'Notifications'],
    };
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.setting-block
  // margin-top: 1rem !important
  // border-radius: 10px !important
  // height: 300px
    // margin-top: 1rem !important
  // padding: 2px
  // background-color: $grey
  // border: 2px solid $grey
</style>
