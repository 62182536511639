<template>
  <div class="container">
<!--    <h3 style="padding-top: 72px">Loading...</h3>-->
  </div>
</template>

<script>
export default {
  name: 'Apple',
  mounted() {
    if (this.$route.query.status === 'success') {
      this.processAppleLogin();
    } else {
      return this.$router.replace(`/login?error=${this.$route.query.status}`);
    }

  },
  methods: {
    async processAppleLogin() {
      try {
        const url = `${process.env.AUTH_BASE_URL}/api/apple?uid=${this.$route.query.uid}`;
        this.$auth.$storage.setUniversal('strategy', 'local');
        const result = await this.$axios.get(url);

        if (result.data.msg === 'ok') {
          this.$auth.$storage.setUniversal('user', result.data.user, true);
          window.location.href = '/my-usage';
        } else {
          return this.$router.replace(`/login?error=${result.data.msg}`);
        }
      } catch (error) {
        console.log('apple error', error);
      }
    },
  },
};
</script>
