<template>
  <div class="mb-1 mt-1">
    <v-row no-gutters align="center" class="mb-4">
      <v-col cols="9" md="7" lg="4">
        <v-text-field
          label="Search properties"
          append-icon="mdi-magnify"
          color="green"
          rounded
          solo
          hide-details
          v-model="filterFields.address_includes"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="5" lg="8" align="start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              rounded
              v-bind="attrs"
              v-on="on"
              @click="open = !open"
              class="ml-2"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
          <span>Toggle filter options</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card v-if="open" class="mt-2">
        <v-card-title>Filter By</v-card-title>
        <v-card-text>
          <v-responsive max-width="300" height="50">
            <UserSelect
              :value="filterFields.user_id"
              @onSelect="$event = filterFields.user_id = $event"
            ></UserSelect>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>
<script>
import UserSelect from '@/components/admind/UserSelect.vue';
export default {
  props: ['filterParams'],
  components: { UserSelect },
  data() {
    console.log(this.filterParams)
    return {
      open: false,
      filterFields: this.filterParams,
    };
  },
  watch: {
    filterFields: {
      handler: function (newVal) {
        this.$emit('update:filterParams', newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
</style>