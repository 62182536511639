<template>
  <div class="mt-3" v-if="Object.keys(metrics).length">
    <v-row>
      <v-col cols="12" md="3" sm="12">
        <v-card class="pa-5" rounded height="100%">
          <div class="d-flex justify-center align-center fill-height">
            <v-icon class="mx-2" size="60">mdi-account-multiple-outline</v-icon>

            <div class="d-flex flex-column mx-2">
              <div class="subtitle-2 grey--text text--darken-1">
                Total Users
              </div>
              <div class="h2 font-weight-regular">
                {{ metrics.total_users }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="pa-5" rounded height="100%">
          <div class="d-flex justify-center">
            <v-icon class="mx-2" size="60">mdi-account-box-multiple</v-icon>

            <div class="d-flex flex-column mx-2">
              <div class="subtitle-2 grey--text text--darken-1">
                Total utilities
              </div>
              <div class="h2 font-weight-regular">
                {{ metrics.total_utility_accounts }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="pa-5" rounded height="100%">
          <div class="d-flex justify-center">
            <v-icon class="mx-2" size="60">mdi-clipboard-list</v-icon>

            <div class="d-flex flex-column mx-2">
              <div class="subtitle-2 grey--text text--darken-1">
                Total challenges
              </div>
              <div class="h2 font-weight-regular">
                {{ metrics.total_challenges }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="pa-5" rounded height="100%">
          <div class="d-flex justify-center">
            <v-icon class="mx-2" size="60">mdi-account-group-outline</v-icon>

            <div class="d-flex flex-column mx-2">
              <div class="subtitle-2 grey--text text--darken-1">
                Total participants
              </div>
              <div class="h2 font-weight-regular">
                {{ metrics.total_participants }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="pa-5" rounded height="100%">
          <div class="d-flex justify-center">
            <v-icon class="mx-2" size="60">mdi-flash</v-icon>

            <div class="d-flex flex-column mx-2">
              <div class="subtitle-2 grey--text text--darken-1">
                Total meters
              </div>
              <div class="h2 font-weight-regular">
                {{ metrics.total_energy_accounts }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="3" sm="12">
        <v-card>
          <v-card-subtitle>METER SYNCED LAST HOURS</v-card-subtitle>
          <v-card-text>
            <div
              class="d-flex align-center"
              v-for="(key, index) in Object.keys(getMeterSyncDates())"
              :key="`last-sync-date-${index}`"
            >
              <div class="h4" :style="{ minWidth: '45px' }">
                <a v-if="getMeterSyncDates()[key][0]"
                  href="#"
                  @click.prevent="
                    $router.push({
                      name: 'admind-pelm_energy_accounts',
                      query: {
                        sync_date_from: getMeterSyncDates()[key][0],
                        sync_date_to: getMeterSyncDates()[key][1],
                      },
                    })
                  "
                  >{{ metrics[key] }}
                </a>
                <a v-else
                   href="#"
                   @click.prevent="
                    $router.push({
                      name: 'admind-pelm_energy_accounts',
                      query: {
                        sync_date_to: getMeterSyncDates()[key][1],
                      },
                    })
                  "
                >{{ metrics[key] }}
                </a>
              </div>
              <small>{{ $options.lastSyncLabels[index] }}</small>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" sm="12">
        <v-card>
          <v-card-subtitle>TOTAL SAVINGS</v-card-subtitle>
          <v-card-text>
            <div class="d-flex align-center">
              <div class="h4" :style="{ minWidth: '50px' }">
                {{ metrics.total_kwh_savings | round }}
              </div>
              <small>kwh</small>
            </div>
            <div class="d-flex align-center">
              <div class="h4" :style="{ minWidth: '50px' }">
                {{ metrics.total_thm_savings | round }}
              </div>
              <small>therm</small>
            </div>
            <div class="d-flex align-center">
              <div class="h4" :style="{ minWidth: '50px' }">
                {{ metrics.total_co2_savings | round }}
              </div>
              <small>co2</small>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
export default {
  lastSyncLabels: [
    '<1d (0-24 hours)',
    '<2d (24 -48h)',
    '<3d (48 - 72h)',
    '<4d (72 - 96h)',
    '<1w (4- 7d)',
    '<2w (8 - 14d)',
    '<3w (15 - 21d)',
    '<1m (22 -28d)',
    '<2m (29 - 60 d)',
    '<3m (61- 90d)',
    '>3m (more than 90d)',
  ],
  layout: 'admin_dashboard',
  data() {
    return {
      metrics: {},
    };
  },
  methods: {
    async getData() {
      let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let metrics = await this.$api.$get(
        `admind/dashboard-metrics?user_tz=${localTimeZone}`
      );
      this.metrics = metrics;
    },
    getMeterSyncDates() {
      let now = DateTime.now();
      let yesterday = now.plus({ days: -1 });
      let day_before_yesterday = yesterday.plus({ days: -1 });
      let three_days_ago = day_before_yesterday.plus({ days: -1 });


      let today = [now, now];
      let today_1 = [yesterday, yesterday];
      let today_2 = [day_before_yesterday, day_before_yesterday];
      let today_3 = [three_days_ago, three_days_ago];
      let day4_to_7 = [now.plus({ days: -7+1 }), now.plus({ days: -4 })];
      let day8_to_14 = [now.plus({ days: -14+1 }), now.plus({ days: -8+1 })];
      let day15_to_21 = [now.plus({ days: -21+1 }), now.plus({ days: -15+1 })];
      let day22_to_28 = [now.plus({ days: -28+1 }), now.plus({ days: -22+1 })];
      let day29_to_60 = [now.plus({ days: -60+1 }), now.plus({ days: -29+1 })];
      let day61_to_90 = [now.plus({ days: -90+1 }), now.plus({ days: -61+1 })];

      let before_90_days = now.plus({ days: -91+1 });

      return {
        meters_updated_today: today.map((o) => o.toISODate()),
        meters_updated_yesterday: today_1.map((o) => o.toISODate()),
        meters_updated_day_before_yesterday: today_2.map((o) => o.toISODate()),
        meters_updated_three_days_ago: today_3.map((o) => o.toISODate()),
        day4_to_7: day4_to_7.map((o) => o.toISODate()),
        day8_to_14: day8_to_14.map((o) => o.toISODate()),

        day15_to_21: day15_to_21.map((o) => o.toISODate()),
        day22_to_28: day22_to_28.map((o) => o.toISODate()),
        day29_to_60: day29_to_60.map((o) => o.toISODate()),
        day61_to_90: day61_to_90.map((o) => o.toISODate()),

        meters_updated_before_90_days: ['', before_90_days.toISODate()],
      };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.flex-item-basis-full {
  flex-basis: 100%;
}
</style>
