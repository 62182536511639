<template>
  <div id="home">
    <!--region Panel 1 -->
    <div id="lightning-box">
      <div class="container" style="height: 100%">
        <div class="row no-gutters" style="height: 100%">
          <div class="col align-self-center left">
            <h1>Save Energy ⚡ <br />Fight Climate Change</h1>
            <h5 id="banner-tagline">
              Engage your employees or tenants in reducing carbon emissions at
              home. MeterLeader is integrated with energy data from 12 utilities
              in 10 states.
            </h5>
            <div class="row no-gutters" style="max-width: 580px">
              <div class="col">
                <nuxt-link id="create-challenge" to="/signup">
                  Get Started FREE
                </nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-5 pl-lg-3 align-self-center">
            <img
              id="lightning-img"
              src="~/assets/images/SampleMeterLeaderChallenge.webp"
            />
          </div>
        </div>
      </div>
    </div>
    <!--endregion Panel 1 -->
    <!--region Panel 2: Explore Challenges -->
    <div class="container-fluid" style="background-color: #e8e8e8">
      <div id="description-box" class="container">
        <h4>
          Electricity and heating are responsible for 1/3 of global carbon
          emissions. We can change that!
        </h4>
      </div>
    </div>

    <!--endregion Panel 2: Explore Challenges -->
    <!--region Panel 3: How It Works -->
    <div id="homepage-how-it-works-box" class="container-fluid">
      <div class="container">
        <h2>How It Works</h2>
        <div id="four-steps-participants-div">
          <div class="container-fluid row four-steps">
            <div class="container-fluid col-12 col-sm-6 col-xl-3">
              <div class="container-fluid row">
                <div class="container col-auto number-container">
                  <div class="number">
                    <p>1</p>
                  </div>
                </div>
                <div class="container col">
                  <img class="icon" :src="'/assets/images/orangeMeter.png'" />
                  <div class="text">
                    <h4>Sync Data</h4>
                    <p>
                      Sync your electricity and natural gas meter data
                      automatically from your online utility account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid col-12 col-sm-6 col-xl-3">
              <div class="container-fluid row">
                <div class="container col-auto number-container">
                  <div class="number">
                    <p>2</p>
                  </div>
                </div>
                <div class="container col">
                  <img class="icon" :src="'/assets/images/orangeBolt.png'" />
                  <div class="text">
                    <h4>Join Challenge</h4>
                    <p>
                      Engage in fun social experiences to see who is the biggest
                      at-home energy saver.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid col-12 col-sm-6 col-xl-3">
              <div class="container-fluid row">
                <div class="container col-auto number-container">
                  <div class="number">
                    <p>3</p>
                  </div>
                </div>
                <div class="container col">
                  <img class="icon" :src="'/assets/images/orangeGroup.png'" />
                  <div class="text">
                    <h4>Save Energy</h4>
                    <p>
                      Energy tips help achieve measurable savings you can
                      actually see via the real-time usage graph.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-fluid col-12 col-sm-6 col-xl-3">
              <div class="container-fluid row">
                <div class="container col-auto number-container">
                  <div class="number">
                    <p>4</p>
                  </div>
                </div>
                <div class="container col">
                  <img class="icon" :src="'/assets/images/orangeReward.png'" />
                  <div class="text">
                    <h4>Win Prizes</h4>
                    <p>
                      Households that save the most, win the most. Challenge
                      winners qualify for cool prizes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="learn-more">
          <nuxt-link to="/how-it-works">Learn More <span>></span></nuxt-link>
        </div>
      </div>
    </div>
    <!--endregion 3: How It Works -->
    <div class="container-fluid" style="background-color: #1ad596">
      <div id="explore-box" class="container">
        <h2>Explore Challenges</h2>
        <div id="challenges" class="container-fluid row">
          <Challenge
            v-for="challenge in challenges"
            :key="'challenge' + challenge.id"
            :challenge="challenge"
            :on-homepage="true"
          />
        </div>
        <!--        <div class="view-all">-->
        <!--          <nuxt-link to="/explore-challenges">View All <span>></span></nuxt-link>-->
        <!--        </div>-->
      </div>
    </div>
    <!--region As Featured In -->
    <div class="container as-featured-in">
      <h2>As Featured In</h2>
      <div class="row">
        <div class="col-md-4">
          <a
            href="https://laincubator.org/laci-welcomes-10-promising-cleantech-startups-for-its-fall-2021-incubation-cohort/"
          >
            <img style="max-height: 100px" src="~/assets/images/laciLogo.png" />
          </a>
        </div>
        <div class="col-md-4">
          <a
            href="https://www.techstars.com/newsroom/announcing-the-stanley-techstars-2022-class"
          >
            <img
              style="max-height: 100px"
              src="~/assets/images/stanley-techstars-accelerator.png"
            />
          </a>
        </div>
        <div class="col-md-4">
          <a href="https://www.netzeroaccelerator.org/nza-22">
            <img style="max-height: 100px" src="~/assets/images/net_zero.png" />
          </a>
        </div>
      </div>
    </div>
    <!--endregion -->
    <!--region Panel 5: Why Use MeterLeader -->
    <div id="reason-box" class="container-fluid">
      <div class="container">
        <h2>Why Use MeterLeader?</h2>
        <div class="reason row">
          <div class="reason-image col-2">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10">
            Less electricity usage = savings on your utility bill!
          </h4>
        </div>
        <div class="reason row">
          <div class="reason-image col-2">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10">
            Make positive environmental impacts that are close to home
          </h4>
        </div>
        <div class="reason row">
          <div class="reason-image col-2">
            <img src="~/assets/images/checkmark.png" />
          </div>
          <h4 class="col-10">
            Have fun participating in our community driven energy saving
            challenges
          </h4>
        </div>
      </div>
    </div>
    <!--endregion Panel 5: Why Use MeterLeader -->
    <!--region Panel 6: Why Save Energy -->
    <div id="why-save-energy-box" class="container">
      <h2>Why Save Energy?</h2>
      <div class="container-fluid row">
        <div class="col-12 col-md-4 reason">
          <h1>$2,060</h1>
          <h4>/year</h4>
          <p>
            You can save upwards of 30% on your utility bills by adopting energy
            saving habits and making affordable energy upgrades in your home.
          </p>
        </div>
        <div class="col-12 col-md-4 reason">
          <h1>230,000</h1>
          <h4>deaths/year</h4>
          <p>
            Reducing your electricity usage can help combat negative effects of
            air pollution from power plants that contribute to respiratory
            diseases.
          </p>
        </div>
        <div class="col-12 col-md-4 reason">
          <h1>16.49</h1>
          <h4>metric tons CO<span>2</span></h4>
          <p>
            Combat climate change by taking small tangible steps to reduce your
            carbon footprint.
          </p>
        </div>
      </div>
    </div>
    <!--endregion Panel 6: Why Save Energy -->
    <JoinFooter />
  </div>
</template>

<script>
import FourSteps from '@/components/FourSteps';
import JoinFooter from '@/components/JoinFooter';
import Challenge from '@/components/Challenge';
import { actionTypes } from '@/store/types';

export default {
  name: 'Home',
  components: {
    Challenge,
    JoinFooter,
    FourSteps,
  },

  async asyncData({ store, app }) {
    try {
      await store.dispatch(actionTypes.GET_CHALLENGES);
    } catch (error) {
      console.log('error', error.response);
    }
  },
  data() {
    return {};
  },
  computed: {
    challenges() {
      if (process.client) {
        if (this.$store.state.challenges.length > 0) {
          this.$nextTick(function () {
            $('.challenge-homepage').hover(
              function () {
                $(this).find('.overlay').css('display', 'block');
              },
              function () {
                $(this).find('.overlay').css('display', 'none');
              }
            );
          });
          // TODO: do this properly
          return this.$store.state.challenges[1]
            .concat(this.$store.state.challenges[2])
            .slice(0, 3);
        }
      }
      return [];
    },
  },
  head() {
    return {
      title: "Let's Save Energy Together | MeterLeader",
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'http://s3.amazonaws.com/meterleader-assets/fbimage.png',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '876',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '451',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/homepage';

.four-steps {
  margin: 0;

  .row {
    padding: 0;
    margin: 0;
  }

  .text {
    h4 {
      font-family: $header_font;
      font-weight: 500;
      font-size: 1.2rem;
    }

    p {
      font-family: $primary_font;
      font-weight: 400;
    }
  }

  .text {
    text-align: left;
  }

  .icon {
    height: 50px;
    padding-bottom: 10px;
  }

  .number {
    margin-top: 48px;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    p {
      font-family: $primary_font;
      width: 50%;
      margin: auto;
      font-size: 15pt;
      font-weight: bold;
      color: white;
    }
  }

  .number-container {
    padding: 0;
  }
}

.as-featured-in {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;

  h2 {
    padding-bottom: 40px;
  }
}

#homepage-how-it-works-box {
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 0;
  position: relative;

  h2 {
    padding-bottom: 40px;
  }

  .number {
    background-color: $orange;
  }

  #learn-more {
    width: 100%;
    text-align: right;
    padding-bottom: 40px;
    padding-top: 70px;
    padding-right: 40px;

    a {
      color: black;
      display: inline-block;
      font-size: 25px;
    }

    a:hover {
      text-decoration: none;
    }

    span {
      font-size: 22pt;
    }
  }
}

@media only screen and (max-width: 768px) {
  .small-margin-top {
    margin-top: 30px;
  }
}
</style>
