import { render, staticRenderFns } from "./NewUngroupedChallengeLeaderboard.vue?vue&type=template&id=61789151&scoped=true&"
import script from "./NewUngroupedChallengeLeaderboard.vue?vue&type=script&lang=js&"
export * from "./NewUngroupedChallengeLeaderboard.vue?vue&type=script&lang=js&"
import style0 from "./NewUngroupedChallengeLeaderboard.vue?vue&type=style&index=0&id=61789151&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61789151",
  null
  
)

export default component.exports