<template>
  <div class="mheader">
    <div :style="{ position: 'relative' }" class="px-3 py-1">
      <h5 class="mb-0 pl-2 pb-1 pt-3 text-center w-100">
        {{ $titleBar.data.title }}
      </h5>
      <div :style="{ position: 'absolute', top: '4px' }">
        <div class="d-flex align-items-center">
          <font-awesome-icon
            v-if="$titleBar.data.backButtonRoute"
            class="icon h2 mb-0"
            icon="angle-left"
            @click="$router.back()"
          />
          <div class="flex-grow-1"></div>
          <component
            v-if="$titleBar.data.rightComponent"
            :is="$titleBar.data.rightComponent"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.icon
  // background-color: $grey
  padding: 5px 13px 5px 13px
  // border-radius: 100px

.logo
  height: 50px
  weight: 50px

.mheader
  position: sticky
  top: 0
  z-index: 10
  border-bottom: 2px solid $grey
  background-color: white
</style>
