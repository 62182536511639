<template>
  <div id="action-page">
    <OnboardingReminder v-if="$auth.loggedIn" />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 d-none d-md-flex">
          <h2 class="mb-0 font-weight-bold">{{ $titleBar.data.title }}</h2>
        </div>
      </div>
      <div class="d-flex my-3 categories">
        <div
          v-for="(category, categoryIndex) in categories"
          :key="`category-${categoryIndex}`"
          class="cpill"
          :class="{ selected: categoryIndex == selectedTab }"
          @click="selectedTab = categoryIndex"
        >
          {{ category.name }}
        </div>
      </div>
      <div class="d-flex action-group justify-content-center">
        <div
          class="d-flex action align-items-center"
          v-for="(action, actionIndex) in filteredAction"
          :key="`action-${actionIndex}`"
        >
          <div class="detail d-flex flex-column">
            <div>
              <h4>{{ action.name }}</h4>
            </div>
            <div class="flex-grow-1"></div>
            <div>
              <button
                class="action-btn rounded"
                @click="$router.push(`/actions/${action.slug}`)"
              >
                View Action
              </button>
            </div>
          </div>
          <br />
          <div class="img">
            <img :src="categories[action.category].image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Actions',
  pageTitle: 'Actions',
  middleware: ['auth'],
  data() {
    return {
      selectedTab: 0,
      categories: [
        { name: 'All Actions', image: '' },
        {
          name: 'Lighting',

          image: '/assets/images/lighting.png',
        },
        {
          name: 'Appliances',
          image: '/assets/images/lighting.png',
        },
        {
          image: '/assets/images/electronics.png',

          name: 'Electronics',
        },
        {
          image: '/assets/images/heating.png',

          name: 'Heating',
        },

        {
          image: '/assets/images/cooling.png',

          name: 'Cooling',
        },

        {
          image: '/assets/images/heatingAndCooling.png',

          name: 'Heating and Cooling',
        },

        {
          image: '/assets/images/hotWater.png',

          name: 'Hot Water',
        },
        { image: '/assets/images/pool.png', name: 'Pool' },
        { image: '/assets/images/other.png', name: 'Other' },
      ],
      actions: [],
    };
  },
  async mounted() {
    await this.getAllActions();
  },
  methods: {
    async getAllActions() {
      let res = await this.$api.get('/tips/?show_all=true');
      this.actions = res.data;
    },
  },
  computed: {
    filteredAction() {
      if (this.selectedTab == 0) {
        return this.actions;
      }
      return this.actions.filter((o) => o.category == this.selectedTab);
    },
  },
  head() {
    return {
      title: 'Energy Saving Actions | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Browse through MeterLeader’s catalog of energy saving tips and learning resources. During the challenge implement these tips in order to save energy and improve your challenge ranking.',
        },
      ],
    };
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.categories
  overflow-x: auto

.categories::-webkit-scrollbar
  height: 2px

/* Hide scrollbar for Chrome, Safari and Opera */
.categories::-webkit-scrollbar
  display: none

/* Hide scrollbar for IE, Edge and Firefox */
.categories
  -ms-overflow-style: none  /* IE and Edge */
  scrollbar-width: none  /* Firefox */

.ctabs
  border-radius: 18px !important
  margin-top: 1rem !important
  padding: 4px
  background-color: $grey

.ctab-item
  width: 100%
  border-radius: 18px !important
  text-align: center
  &.selected
    background-color: white

.cpill
  white-space: nowrap
  border: 1px solid $grey
  padding: 5px 10px 5px 10px
  border-radius: 18px !important
  text-align: center
  font-size: 0.8rem
  margin-right: 10px

  &.selected
    background-color: $grey
    font-weight: bold

.rounded
  border-radius: 18px !important

.action-btn
  padding: 5px 10px 5px 10px
  text-align: center
  border: 2px solid black

.action-group
  flex-wrap: wrap

.action
  background-color: $grey
  padding: 10px
  margin: 13px

  border-radius: 18px

  > .detail
    margin: 8px 14px 8px 14px
    width: 12rem
    > .img
      padding: 10px
      border-radius: 15px
      background-color: white

      > img
        height: 100px
        width: 100px
</style>
