<template>
  <div id="account-content" class="content">
    <h3 class="font-weight-bold pl-4">Account Settings</h3>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="pl-4 pr-4">
          <h5 class="font-weight-bold">Change Name</h5>
          <form id="change-name-form">
            <p id="name-changed" class="success">Name successfully changed</p>
            <p id="name-error" class="error">Server Error. Please try again.</p>
            <p id="invalid-name" class="error">
              First name must be at least 2 characters long.
            </p>

            <div class="form-field">
              <label class="label" for="firstName">Full Name</label><br />

              <div class="d-md-flex">
                <input
                  id="firstName"
                  class="mr-md-3 p-1 round w-100"
                  v-model="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  required
                />

                <div class="mt-2"></div>

                <input
                  id="lastName"
                  class="p-1 round w-100"
                  v-model="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>

            <br />
            <button
              id="save-changes-button"
              type="button"
              class="btn btn-green w-100"
              @click="saveName"
            >
              Save Changes
            </button>
          </form>
        </div>
        <div class="pl-4 pr-4">
          <h5 class="font-weight-bold mt-4">
            Affiliated Organization (Optional)
          </h5>
          <form id="change-organization-form">
            <p id="organization-changed" class="success">
              Organization successfully changed
            </p>
            <p id="organization-error" class="error">
              Server Error. Please try again.
            </p>
            <p id="invalid-organization" class="error">
              Invalid corporate code
            </p>

            <div class="d-flex">
              <h5>Current Organization:</h5>
              <h5 class="pl-2">
                {{ currentOrganization || 'Not associated yet' }}
              </h5>
            </div>

            <div class="form-field">
              <label class="label" for="corporateCode">Corporate Code</label
              ><br />
              <input
                id="corporateCode"
                class="p-1 round w-100"
                v-model="corporateCode"
                name="corporateCode"
                type="text"
                placeholder="Corporate Code"
                required
              />
            </div>

            <br />
            <button
              id="save-changes-button1"
              type="button"
              class="btn btn-green w-100"
              @click="updateOrganization"
            >
              Update Corporate Code
            </button>
          </form>
        </div>
        <div class="pl-4 pr-4">
          <h5 class="font-weight-bold mt-4">Request Email Change</h5>
          <form id="change-email-form">
            <p id="email-requested" class="success">
              Email change successfully requested. A verification email has been
              sent to your new email.
              <a href="/send-verification">Resend Verification Email</a>
            </p>
            <p id="email-error" class="error">
              Server Error. Please try again.
            </p>
            <p id="same-email" class="error">
              Email entered is the same as current email.
            </p>
            <p id="email-used" class="error">
              This email address is already in use.
            </p>
            <p id="bad-login" class="error">Incorrect password.</p>
            <p id="social-account" class="error">
              This account is connected to Facebook. Email must be changed
              through Facebook.
            </p>
            <div class="form-field">
              <label class="label" for="currentPassword">Current Email</label
              ><br />
              <input
                id="currentPassword"
                class="p-1 round w-100"
                name="currentPassword"
                type="password"
                placeholder="Current Email"
                required
              />
            </div>
            <br />
            <div class="form-field">
              <label class="label" for="email">New Email</label><br />
              <input
                id="email"
                class="p-1 round w-100"
                name="email"
                type="email"
                required
                placeholder="New Email"
              />
            </div>
            <br />
            <button
              id="change-email-button"
              type="button"
              class="btn btn-green w-100"
              @click="changeEmail"
            >
              Change Email
            </button>
          </form>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="pl-4 pr-4 pb-4">
          <h5 class="font-weight-bold mt-3">Change Password</h5>
          <form id="change-password-form">
            <p id="password-changed" class="success">
              Password successfully changed
            </p>
            <p id="server-error" class="error">
              Server Error. Please try again.
            </p>
            <p id="wrong-pass" class="error">Old password is incorrect.</p>
            <p id="pass-reset" class="error">
              This account is currently already in the password reset process.
            </p>
            <p id="social-account-pass" class="error">
              This account is connected to Facebook. Password must be changed
              through Facebook.
            </p>
            <ul id="password-error">
              <li id="password-length">
                Password must be at least 8 characters long
              </li>
              <li id="password-case">
                Password must contain an upper case character
              </li>
              <li id="password-lower">
                Password must contain a lower case character
              </li>
              <li id="password-num">Password must contain a number</li>
            </ul>

            <div class="form-field">
              <label class="label" for="oldPassword">Old Password</label><br />
              <input
                id="oldPassword"
                class="p-1 round w-100"
                name="oldPassword"
                type="password"
                placeholder="Old Password"
                required
              />
            </div>
            <br />
            <div class="form-field">
              <label class="label" for="newPassword">New Password</label><br />
              <input
                id="newPassword"
                class="p-1 round w-100"
                name="newPassword"
                type="password"
                placeholder="New Password"
                required
              />
            </div>
            <br />
            <button
              id="change-password-button"
              class="btn btn-green w-100"
              type="button"
              @click="changePassword"
            >
              Change Password
            </button>
            <br />
          </form>
          <h5 class="font-weight-bold mt-3 delete-account">Delete Account</h5>
          <button
            id="delete-account-button"
            class="btn btn-danger w-100 delete-account-button"
            data-toggle="modal"
            data-target="#deleteAccount"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
    <div
      id="deleteAccount"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteAccount"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalCenterTitle" class="modal-title">
              Delete Account
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete your account? All of your utilities and account information will be deleted.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn green" data-dismiss="modal">
              No
            </button>
            <button
              id="leave-challenge"
              type="button"
              class="btn btn-danger"
              @click="deleteAccount"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { actionTypes } from '@/store/types';

export default {
  pageTitle: 'Settings',
  name: 'Account',
  //   async asyncData({ store }) {
  //     await store.dispatch(actionTypes.GET_USER_PROFILE);
  //     return {
  //       firstName: store.state.user.profile.firstname,
  //       lastName: store.state.user.profile.lastname,
  //       currentOrganization: store.state.user.profile.organization_name,
  //     };
  //   },
  data() {
    return {
      currentOrganization: null,
      firstName: '',
      lastName: '',
      currentPassword: '',
      newPassword: '',
      newEmail: '',
      corporateCode: '',
    };
  },
  async mounted() {
    await this.$store.dispatch(actionTypes.GET_USER_PROFILE);
    this.firstName = this.$store.state.user.profile.firstname;
    this.lastName = this.$store.state.user.profile.lastname;
    this.currentOrganization = this.$store.state.user.profile.organization_name;

    const organizationError = $('#organization-error');
    const organizationSuccess = $('#organization-changed');
    const invalidOrganization = $('#invalid-organization');

    organizationError.hide();
    organizationSuccess.hide();
    invalidOrganization.hide();

    const nameError = $('#name-error');
    const nameSuccess = $('#name-changed');
    const invalidName = $('#invalid-name');

    invalidName.hide();
    nameSuccess.hide();
    nameError.hide();

    const email = $('#email');
    const currentPassword = $('#currentPassword');
    const sameEmail = $('#same-email');
    const emailUsed = $('#email-used');
    const badLogin = $('#bad-login');
    const socialAccount = $('#social-account');
    const emailError = $('#email-error');
    const emailSuccess = $('#email-requested');

    sameEmail.hide();
    emailUsed.hide();
    badLogin.hide();
    socialAccount.hide();
    emailSuccess.hide();
    emailError.hide();

    const wrongPass = $('#wrong-pass');
    const passReset = $('#pass-reset');
    const socialAccountPass = $('#social-account-pass');
    const passwordError = $('#password-error');
    const passwordLength = $('#password-length');
    const passwordCase = $('#password-case');
    const passwordLower = $('#password-lower');
    const passwordNum = $('#password-num');
    const oldPassword = $('#oldPassword');
    const newPassword = $('#newPassword');
    const passSuccess = $('#password-changed');
    const passError = $('#server-error');

    passwordError.hide();
    wrongPass.hide();
    passReset.hide();
    socialAccountPass.hide();
    passSuccess.hide();
    passError.hide();
  },
  methods: {
    deleteAccount() {
      this.$nuxt.$loading.start();
      this.$api
        .delete('/user/delete_user')
        .then(() => {
          $('#deleteAccount').modal('hide');
          this.$auth.$storage.removeUniversal('user');
          this.$auth.logout();
        })
        .catch((error) => {
          const errorMessage = error.response.data.msg;
          this.$nuxt.$loading.finish();
        });
      this.$nuxt.$loading.finish();
    },
    updateOrganization() {
      this.$nuxt.$loading.start();
      const organizationError = $('#oragnization-error');
      const organizationSuccess = $('#organization-changed');
      const invalidOrganization = $('#invalid-organization');

      organizationError.hide();
      organizationSuccess.hide();
      invalidOrganization.hide();

      this.$repo.user
        .update({
          corporate_code: this.corporateCode.trim(),
        })
        .then((data) => {
          organizationSuccess.show();
          this.$nuxt.$loading.finish();
          this.currentOrganization = data.data.profile.organization_name;
          this.corporateCode = '';
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              invalidOrganization.show();
              break;
            default:
              organizationError.show();
          }
          this.$nuxt.$loading.finish();
        });
    },
    saveName() {
      this.$nuxt.$loading.start();
      const nameError = $('#name-error');
      const nameSuccess = $('#name-changed');
      const invalidName = $('#invalid-name');

      invalidName.hide();
      nameSuccess.hide();
      nameError.hide();

      this.$repo.user
        .changeName({
          firstname: this.firstName.trim(),
          lastname: this.lastName.trim(),
        })
        .then(() => {
          nameSuccess.show();
          this.$nuxt.$loading.finish();
        })
        .catch((error) => {
          switch (error.status) {
            case 400:
              invalidName.show();
              break;
            default:
              nameError.show();
          }
          this.$nuxt.$loading.finish();
        });
    },
    changeEmail() {
      this.$nuxt.$loading.start();
      const email = $('#email');
      const currentPassword = $('#currentPassword');
      const sameEmail = $('#same-email');
      const emailUsed = $('#email-used');
      const badLogin = $('#bad-login');
      const socialAccount = $('#social-account');
      const emailError = $('#email-error');
      const emailSuccess = $('#email-requested');

      sameEmail.hide();
      emailUsed.hide();
      badLogin.hide();
      socialAccount.hide();
      emailSuccess.hide();
      emailError.hide();

      const data = {
        newemail: email.val().trim(),
        password: currentPassword.val().trim(),
      };

      this.$api
        .post('/user/change_email_request', data)
        .then(() => {
          emailSuccess.show();
          this.$nuxt.$loading.finish();
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              switch (error.response.data.msg) {
                case 'email_unchanged':
                  sameEmail.show();
                  break;
                case 'email_already_in_use':
                  emailUsed.show();
              }
              break;
            case 401:
              badLogin.show();
              break;
            case 403:
              socialAccount.show();
              break;
            default:
              emailError.show();
          }
          this.$nuxt.$loading.finish();
        });
    },
    changePassword() {
      this.$nuxt.$loading.start();
      const wrongPass = $('#wrong-pass');
      const passReset = $('#pass-reset');
      const socialAccountPass = $('#social-account-pass');
      const passwordError = $('#password-error');
      const passwordLength = $('#password-length');
      const passwordCase = $('#password-case');
      const passwordLower = $('#password-lower');
      const passwordNum = $('#password-num');
      const oldPassword = $('#oldPassword');
      const newPassword = $('#newPassword');
      const passSuccess = $('#password-changed');
      const passError = $('#server-error');

      passwordError.hide();
      wrongPass.hide();
      passReset.hide();
      socialAccountPass.hide();
      passSuccess.hide();
      passError.hide();

      const data = {
        oldpassword: oldPassword.val().trim(),
        newpassword: newPassword.val().trim(),
      };

      this.$api
        .post('/user/change_password', data)
        .then(() => {
          passSuccess.show();
          oldPassword.val('');
          newPassword.val('');
          this.$nuxt.$loading.finish();
        })
        .catch((error) => {
          const errorMessage = error.response.data.msg;
          switch (errorMessage) {
            case 'weak_password':
              const warnings = error.response.data.warning;
              passwordError.show();
              oldPassword.val('');
              newPassword.val('');
              for (let i = 0; i < warnings.length; i++) {
                switch (warnings[i]) {
                  case 'Password must be at least 8 characters long':
                    passwordLength.addClass('bold');
                    break;
                  case 'Password must contain an upper case character':
                    passwordCase.addClass('bold');
                    break;
                  case 'Password must contain a lower case character':
                    passwordLower.addClass('bold');
                    break;
                  case 'Password must contain a number':
                    passwordNum.addClass('bold');
                    break;
                }
              }
              break;
            case 'bad_login':
              oldPassword.val('');
              wrongPass.show();
              console.log('bad login');
              break;
            case 'password has been reset':
              passReset.show();
              break;
            case 'social_account':
              socialAccountPass.show();
              break;
            default:
              passError.show();
          }
          this.$nuxt.$loading.finish();
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '~@/assets/css/accountSettings.scss'
// @impor

.round
  border-radius: 10px !important
.section
  padding: 10px
  background-color: $grey
  border-radius: 18px

.form-field

  > .label
    margin-bottom: 0px
    font-size: 0.9rem
    font-weight: bold
    padding-bottom: 5px
    input
      width: 100%
      height: 3rem
      border-radius: 10px !important
      padding: 0px 5px 0px 5px

button
  border-radius: 15px

.delete-account
  margin-top: 10%

.delete-account-button
  background-color: #ffffff
  border-color: #dc3545
  color: #dc3545
</style>
