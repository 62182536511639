<template>
  <div id="login-page">
    <div class="main-content">
      <div class="login-page">
        <div id="message"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmEmail',
  mounted() {
    if (!this.$route.query.token) {
      $('#message').append(
        '<h2>Please click on the link in the confirmation email</h2>'
      );
      return;
    }

    this.$nextTick(() => {
      this.$nuxt.$loading.start();
    });

    this.$api
      .post('/user/verify_email', { token: this.$route.query.token })
      .then((result) => {
        this.$nuxt.$loading.finish();
        $('#message').append(
          '<h1>Success</h1><p>Your email has been confirmed.<br/>We are now taking you to your energy savings challenge page.</p>'
        );
        let redirectUrl = '/challenges';
        if (result.data.reditect_to_challenge) {
          redirectUrl += '/' + result.data.reditect_to_challenge
        }
        setTimeout(function() {
          window.location.href = redirectUrl;
        }, 2000)
      })
      .catch((error) => {
        this.$nuxt.$loading.finish();
        let message;
        switch (error.response.data.msg) {
          case 'new_email_mismatch':
            message =
              '<h1>Error</h1><p>This email does not match the newest email address change request. <a href="/signup/send-verification">Resend verification email</a></p>';
            break;
          case 'email_already_confirmed':
            message =
              '<h1>Error</h1><p>This email has already been confirmed. <a href="/signup/send-verification">Resend verification email</a></p>';
            break;
          case 'user_not_found':
            message =
              '<h1>Error</h1><p>User does not exist. <a href="/signup/send-verification">Resend verification email</a></p>';
            break;
          case 'bad_token':
            message =
              '<h1>Error</h1><p>Invalid token. <a href="/signup/send-verification">Resend verification email</a></p>';
            break;
          default:
            message = '<h1>Server Error</h1>';
        }
        $('#message').append(message);
      });
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';
</style>
