<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          :label="label || 'User'"
          :value="selectedUser ? selectedUser.full_name : null"
          :append-icon="selectedUser ? 'mdi-close' : null"
          v-bind="attrs"
          v-on="on"
          @click:append="
            () => {
              selectedUser = null;
              $emit('onSelect', null);
            }
          "
        ></v-text-field>
      </template>
      <DynamicSelect
        :endpoint="'admind/users'"
        :labelKey="'full_name'"
        @onSelect="
          ($event) => {
            $emit('onSelect', $event.id);
            selectedUser = $event;
          }
        "
      ></DynamicSelect>
    </v-menu>
  </div>
</template>
<script>
import DynamicSelect from '@/components/admind/DynamicSelect.vue';

export default {
  props: ['value', 'label'],
  components: { DynamicSelect },
  data() {
    return {
      selectedUser: null,
    };
  },
  watch: {
    value: {
      handler: async function (val) {
        if (val && !this.selectedUser) {
          const res = await this.$api.get(`admind/users/${val}`);
          this.selectedUser = res.data;
        }
      },
      immediate: true,
    },
  },
};
</script>