<template>
  <m-loader :loading="pageLoading">
    <div>
      <m-loader :loading="metersLoading">
        <div class="">
          <small>
            Move your unassigned utility account meter(s) to an existing
            property or create a new property by clicking the “Move To” button
            next to the unassigned meter.
          </small>
        </div>
        <br/>
        <h3 class="font-weight-bold">Properties</h3>
        <div
          v-for="(item, index) in userTeams"
          :key="`prop-${index}`"
          class="card round mb-2"
        >
          <div class="d-flex p-3">
            <div>
              <h5 class="font-weight-bold mb-0">{{ item.name }}</h5>
              <small>{{ item.home_type }}</small>
            </div>

            <div :style="{ flexGrow: 1 }"></div>
            <a
              class="font-weight-bold"
              @click="
                teamFormData = { ...item };
                showModal = true;
              "
            >
              Edit
            </a>
          </div>
          <div class="m-0 row p-3 p-section">
            <div></div>
            <div class="col-md-12">
              <div>
                {{
                  `
                  ${item.number_of_occupants ? item.number_of_occupants + ' occupants,' : ''}
                  ${item.square_footage ? item.square_footage + ' square feet,' : ''}
                  ${item.primary_heating_type ? item.primary_heating_type + ',' : ''}
                  ${item.primary_cooling_type ? item.primary_cooling_type + ',' : ''}
                  ${item.has_pool_or_spa ? 'Has pool or spa,' : 'No pool or spa,'}
                  ${item.rooftop_solar ? 'Has rooftop solar,' : 'No rooftop solar,'}
                  ${item.home_battery_storage ? 'Has battery storage,' : 'No battery storage,'}
                  ${item.no_of_electric_vehicles > 0 ? item.no_of_electric_vehicles + ' Electric vehicles,' : 'No Electric vehicles,'}
                  ${item.no_of_gas_vehicles > 0 ? item.no_of_gas_vehicles + ' Gas vehicles,' : 'No Gas vehicles,'}
                  ${item.no_of_electric_powered_tools > 0 ? item.no_of_electric_powered_tools + ' Electric power tools,' : 'No Electric power tools,'}
                  ${item.no_of_gas_powered_tools > 0 ? item.no_of_gas_powered_tools + ' Gas power tools' : 'No Gas power tools'}
                `
                }}
              </div>
            </div>
          </div>

          <div class="bg-gray">
            <p v-if="!groupedEnerygyAccounts[item.id]" class="p-2">
              No Utility Accounts have been added to this property.
            </p>

            <div v-else>
              <div
                v-for="(account, gIndex) in groupedEnerygyAccounts[item.id]"
                :key="`angacc-${gIndex}`"
                class="p-2 bg-grey c-space"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <span v-if="account.available_meter_types.includes('GAS')">🔥</span>
                    <span v-if="account.available_meter_types.includes('ELECTRIC')">⚡</span>
                    <span>{{ account.utility_provider_name }} ({{ account.meter_no }}),</span>
                    <span>{{ account.address }}</span>
                  </div>
                  <div class="flex-grow-1"></div>
                  <div>
                    <button
                      @click="moveMeter(null, account.id, item.id)"
                      class="btn btn-secondary btn-sm cbtn bt-same-size"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="" v-if="userTeams.length <= 0">
          No properties have been created
        </p>
      </m-loader>

      <div class="mt-4">
        <h3 class="font-weight-bold">Unassigned Utility Meters</h3>
        <m-loader :loading="metersLoading">
          <p v-if="!groupedEnerygyAccounts['unassigned']" class="">No unassigned utility meters</p>
          <div v-else>
            <div
              v-for="(account, guIndex) in groupedEnerygyAccounts['unassigned']"
              :key="`ngacc-${guIndex}`"
              class="bg-grey round p-2 c-space"
            >
              <div class="d-flex align-items-center">
                <div>
                  <span v-if="account.available_meter_types.includes('GAS')">🔥</span>
                  <span v-if="account.available_meter_types.includes('ELECTRIC')">⚡</span>
                  <span>{{ account.utility_provider_name }} ({{ account.meter_no }}),</span>
                  <span>{{ account.address }}</span>
                </div>
                <div class="flex-grow-1"></div>
                <div>
                  <span class="dropdown">
                    <button
                      class="btn btn-secondary btn-sm dropdown-toggle cbtn bt-same-size"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Move to
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        v-for="(team, pidx) in userTeams"
                        :key="`mov-pro-${pidx}`"
                        class="dropdown-item"
                        href="#"
                        @click="moveMeter(team.id, account.id, null)"
                      >{{ team.name }}</a
                      >
                      <div class="dropdown-item">
                        <a href="#" @click="addAndMove(account.id)"
                        >Add New Property</a
                        >
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </m-loader>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    {{ teamFormData.id ? 'Edit' : 'Add' }} Property
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showModal = false"
                    >&times;</span
                    >
                  </button>
                </div>
                <div
                  class="modal-body overflow-auto"
                  :style="{ height: '80vh' }"
                >
                  <form @submit.prevent="teamSubmit">
                    <div class="form-group">
                      <label for="formGroupExampleInput">Property Name *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="team-name"
                        placeholder="Property Name"
                        required
                        v-model="teamFormData.name"
                      />
                    </div>

                    <div class="form-group">
                      <label for="homeType">Home Type</label>
                      <select
                        class="form-control"
                        id="homeType"
                        v-model="teamFormData.home_type"
                      >
                        <option disabled selected>
                          Please select Home Type
                        </option>
                        <option value="Single Family Home">
                          Single Family Home
                        </option>
                        <option value="Townhouse">Townhouse</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Condo">Condo</option>
                        <option value="Duplex">Duplex</option>
                        <option value="Mobile Home">Mobile Home</option>
                        <option value="Other">Other</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="homeType">Home Ownership</label>
                      <select
                        class="form-control"
                        id="homeOwnershio"
                        v-model="teamFormData.home_ownership"
                      >
                        <option disabled selected>
                          Please select Home Ownership
                        </option>
                        <option value="Renter">I am a renter.</option>
                        <option value="Home Owner">I am a home owner.</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="numberOfOccupants">Number of Occupants</label>
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="numberOfOccupants"
                        aria-describedby="numberOfOccupants"
                        placeholder="Enter number of occupants"
                        v-model="teamFormData.number_of_occupants"
                      />
                    </div>

                    <div class="form-group">
                      <label for="squareFootage">Square Footage</label>
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="squareFootage"
                        aria-describedby="squareFootage"
                        placeholder="Enter square footage"
                        v-model="teamFormData.square_footage"
                      />
                    </div>

                    <div class="form-group">
                      <label for="primaryHeatingType"
                      >Primary Heating Type</label
                      >
                      <select
                        class="form-control"
                        id="primaryHeatingType"
                        v-model="teamFormData.primary_heating_type"
                      >
                        <option disabled selected>
                          Please select Heating Type
                        </option>
                        <option value="Natural Gas Furnace">
                          Natural Gas Furnace
                        </option>
                        <option value="Propane Furnace">Propane Furnace</option>
                        <option value="Oil Furnace">Oil Furnace</option>
                        <option value="Electric Furnace">
                          Electric Furnace
                        </option>
                        <option value="Electric Heat Pump">
                          Electric Heat Pump
                        </option>
                        <option value="Electric Baseboard Heating">
                          Electric Baseboard Heating
                        </option>
                        <option value="Electric Space Heater">
                          Electric Space Heater
                        </option>
                        <option value="Boiler">Boiler</option>
                        <option value="Other">Other</option>
                        <option value="No Heating">No Heating</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="primaryCoolingType"
                      >Primary Cooling Type</label
                      >
                      <select
                        class="form-control"
                        id="primaryCoolingType"
                        v-model="teamFormData.primary_cooling_type"
                      >
                        <option disabled selected>
                          Please select Cooling Type
                        </option>
                        <option value="Central Cooling">Central Cooling</option>
                        <option value="Through Wall (Split System)">
                          Through Wall (Split System)
                        </option>
                        <option value="Window/Room Air Conditioner">
                          Window/Room Air Conditioner
                        </option>
                        <option value="Portable Air Conditioner">
                          Portable Air Conditioner
                        </option>
                        <option value="No AC">No AC</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="poolAndSpa">Has Pool or Spa</label>
                      <select
                        class="form-control"
                        id="poolAndSpa"
                        v-model="teamFormData.has_pool_or_spa"
                      >
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="rooftopSolar">Rooftop Solar</label>
                      <select
                        class="form-control"
                        id="rooftopSolar"
                        v-model="teamFormData.rooftop_solar"
                      >
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="homeBatteryStorage"
                      >Home Battery Storage</label
                      >
                      <select
                        class="form-control"
                        id="homeBatteryStorage"
                        v-model="teamFormData.home_battery_storage"
                      >
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="numberOfElectricVehicles"
                      >Number of Electric Vehicles</label
                      >
                      <input
                        type="number"
                        min="0"
                        max="10"
                        class="form-control"
                        id="numberOfElectricVehicles"
                        aria-describedby="numberOfElectricVehicles"
                        placeholder="Enter number of electric vehicles"
                        v-model="teamFormData.no_of_electric_vehicles"
                      />
                    </div>

                    <div class="form-group">
                      <label for="numberOfGasVehicles"
                      >Number of Gas Vehicles</label
                      >
                      <input
                        type="number"
                        min="0"
                        max="10"
                        class="form-control"
                        id="numberOfGasVehicles"
                        aria-describedby="numberOfGasVehicles"
                        placeholder="Enter number of gas vehicles"
                        v-model="teamFormData.no_of_gas_vehicles"
                      />
                    </div>

                    <div class="form-group">
                      <label for="numberOfElectricPoweredTools"
                      >Number of Electric Powered Tools</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="numberOfElectricPoweredTools"
                        aria-describedby="numberOfElectricPoweredTools"
                        placeholder="Enter number of electric powered tools"
                        v-model="teamFormData.no_of_electric_powered_tools"
                      />
                    </div>
                    <div class="form-group">
                      <label for="numberOfGasPoweredTools"
                      >Number of Gas Powered Tools</label
                      >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        id="numberOfGasPoweredTools"
                        aria-describedby="numberOfGasPoweredTools"
                        placeholder="Enter number of gas powered tools"
                        v-model="teamFormData.no_of_gas_powered_tools"
                      />
                    </div>

                    <input
                      :style="{ width: '100%' }"
                      type="submit"
                      :value="teamFormData.id ? 'Update' : 'Save'"
                      class="btn btn-primary"
                    />
                    <button
                      v-if="teamFormData.id"
                      :style="{ width: '100%' }"
                      class="btn btn-danger mt-1"
                      @click.prevent="deleteTeam(teamFormData.id)"
                    >
                      Delete
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </m-loader>
</template>
<script>
export default {
  pageTitle: 'Settings',
  data() {
    return {
      userPelmEnergyAccounts: [],
      userTeams: [],
      showModal: false,
      teamFormData: {},
      teamPostCreateEnergyAccount: null,
      teamsLoading: false,
      metersLoading: false,
      pageLoading: false,
    };
  },
  async mounted() {
    await this.getUserMeters();
    await this.getUserTeams();
    // this.pageLoading = false;
  },
  computed: {
    groupedEnerygyAccounts() {
      const groupedAccounts = {};

      this.userPelmEnergyAccounts.forEach((obj) => {
        if (obj.team_id) {
          if (!Array.isArray(groupedAccounts[obj.team_id])) {
            groupedAccounts[obj.team_id] = [];
          }
          groupedAccounts[obj.team_id].push(obj);
        } else {
          if (!Array.isArray(groupedAccounts['unassigned'])) {
            groupedAccounts['unassigned'] = [];
          }
          groupedAccounts['unassigned'].push(obj);
        }
      });
      return groupedAccounts;
    },
  },
  methods: {
    async deleteTeam(team_id) {
      this.$nuxt.$loading.start();
      await this.$api.delete(`team/${team_id}`);

      const teamObj = this.userTeams.find((o) => {
        return parseInt(o.id) == parseInt(team_id);
      });

      const teamObjIndex = this.userTeams.indexOf(teamObj);

      this.userTeams.splice(teamObjIndex, 1);
      this.showModal = false;
      await this.getUserMeters();
      this.$nuxt.$loading.finish();
    },
    async addAndMove(energy_account_id) {
      this.showModal = true;
      this.teamPostCreateEnergyAccount = energy_account_id;
    },
    async moveMeter(team_id, energy_account_id, removingFromTeamId) {
      // this.$nuxt.$loading.start();
      if (
        !team_id &&
        this.groupedEnerygyAccounts[removingFromTeamId].length == 1
      ) {
        let val = await this.$alert.init(
          'If you remove the meter it will delete the property. Are you sure you want to do this?'
        );

        if (!val) {
          return;
        }
      }

      try {
        let pelmMeterRes = await this.$api.patch(
          `pelm/pelm-energy-accounts/${energy_account_id}`,
          {team_id: team_id}
        );

        if (
          !team_id &&
          this.groupedEnerygyAccounts[removingFromTeamId].length == 1
        ) {
          const teamObj = this.userTeams.find((o) => {
            return parseInt(o.id) == parseInt(removingFromTeamId);
          });

          const teamObjIndex = this.userTeams.indexOf(teamObj);

          this.userTeams.splice(teamObjIndex, 1);

          await this.getUserMeters();
        }
      } catch (error) {
        console.error(error.response.data.msg);
        this.$toast.error(error.response.data.msg, {duration: 5000});
      }

      await this.getUserMeters();
      // this.$nuxt.$loading.finish();
    },
    async getUserMeters() {
      // this.metersLoading = true;
      // this.$nuxt.$loading.start();
      const res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      // this.metersLoading = false;
      this.userPelmEnergyAccounts = res.items;
      // this.$nuxt.$loading.finish();
    },

    async getUserTeams() {
      // this.teamsLoading = true;
      // this.$nuxt.$loading.start();
      const res = await this.$api.$get('team/');
      // this.teamsLoading = false;
      this.userTeams = res;
      // this.$nuxt.$loading.finish();
    },
    async teamSubmit() {
      if (this.teamFormData.id) {
        const res = await this.$api.put(
          `team/${this.teamFormData.id}`,
          this.teamFormData
        );

        const item = this.userTeams.find((obj) => {
          obj.id == this.teamFormData.id;
        });

        const index = this.userTeams.indexOf(item);

        this.userTeams.splice(index, 1, this.teamFormData);
      } else {
        const res = await this.$api.post('team/', this.teamFormData);
        this.userTeams.push({...this.teamFormData, id: res.data.id});

        if (this.teamPostCreateEnergyAccount) {
          await this.moveMeter(
            res.data.id,
            this.teamPostCreateEnergyAccount,
            null
          );
          this.teamPostCreateEnergyAccount = null;
        }
      }

      this.showModal = false;
      this.teamFormData = {};
    },
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.round
  border-radius: 10px

.bg-grey
  background-color: $grey

a
  color: $accent

.modal-mask
  position: fixed
  z-index: 9998
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: table
  transition: opacity 0.3s ease

.modal-wrapper
  display: table-cell
  vertical-align: middle

.cbtn
  border-radius: 18px

.p-section
  border-top: 1px solid rgba(0, 0, 0, 0.125)

.c-space
  margin-bottom: 2px

.bt-same-size
  width: 85px

.dropdown-menu
  left: -50px !important
  z-index: 1050
</style>
