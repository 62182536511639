<template>
  <div id="my-usage-page">
    <OnboardingReminder v-if="$auth.loggedIn" />
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6 d-none d-md-flex">
          <h2 class="font-weight-bold page-title mb-0">
            {{ $titleBar.data.title }}
          </h2>
        </div>
        <div class="col-12 col-md-6">
          <select style="border-radius: 20px !important;"
            class="custom-select rounded"
            v-model="filterParams.energy_account_id"
          >
            <option disabled selected :value="null">Select Utility</option>
            <optgroup
              v-for="group in Object.keys(addressMeters)"
              :key="group"
              :label="group"
            >
              <option
                v-for="meter in addressMeters[group]"
                :key="`${meter.id}pelm-meter`"
                :value="meter.id"
              >
                <div>
                  {{ meter.label }}
                </div>
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 py-1">
          <div class="d-flex">
            <div style="border-radius: 20px !important;"
              class="text-center period-option rounded"
              :class="{ selected: selectedPeriod == 'daily' }"
              @click="selectedPeriod = 'daily'"
            >
              D
            </div>
            <div style="border-radius: 20px !important;"
              class="text-center period-option rounded"
              :class="{ selected: selectedPeriod == 'weekly' }"
              @click="selectedPeriod = 'weekly'"
            >
              W
            </div>
            <div style="border-radius: 20px !important;"
              class="text-center period-option rounded"
              :class="{ selected: selectedPeriod == 'monthly' }"
              @click="selectedPeriod = 'monthly'"
            >
              M
            </div>

            <div style="border-radius: 20px !important;"
              class="text-center period-option rounded"
              :class="{ selected: selectedPeriod == 'yearly' }"
              @click="selectedPeriod = 'yearly'"
            >
              Y
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div style="border-radius: 20px !important;"
            class="
            d-flex
            align-items-center
            justify-content-center
            period-label
            rounded
            py-2
            range-display
          "
          >
            <font-awesome-icon
              class="pr-2 pl-1 h5 mb-0"
              icon="chevron-left"
              @click="setRangeFilter('left')"
            />
            <div class="text-center h5 mb-0">
              {{ periodsConfig[this.selectedPeriod].displayLabel }}
            </div>
            <font-awesome-icon
              class="pl-2 pr-1 h5 mb-0"
              icon="chevron-right"
              @click="setRangeFilter('right')"
            />
          </div>
        </div>
      </div>
      <div class="row summary rounded no-gutters">
        <div class="col-12">
          <div class="col-12" v-if="!Object.keys(addressMeters).length">
            <small>Connect your utility account to see your data.</small>
          </div>
          <div
            class="d-flex align-items-center"
            v-if="!!selectedMeterLatestDailySummary.electric"
          >
            <p class="h2 pr-3">⚡</p>
            <p class="h2 pr-2 font-weight-bold">
              {{ selectedMeterLatestDailySummary.electric.toFixed(2) }}
            </p>
            <sub><small class="val-label">Electricity (kWh)</small></sub>
          </div>
          <div
            class="d-flex align-items-center"
            v-if="!!selectedMeterLatestDailySummary.gas"
          >
            <p class="h2 pr-3">🔥</p>
            <p class="h2 pr-2 font-weight-bold">
              {{ selectedMeterLatestDailySummary.gas.toFixed(2) }}
            </p>
            <sub><small class="val-label">Gas (therm)</small></sub>
          </div>
          <div
            class="d-flex align-items-center"
            v-if="!!selectedMeterLatestDailySummary.total_ghg_emission"
          >
            <p class="h2 pr-3">💨</p>
            <p class="h2 pr-2 font-weight-bold">
              {{ selectedMeterLatestDailySummary.total_ghg_emission.toFixed(2) }}
            </p>
            <sub><small class="val-label">CO2 (lbs)</small></sub>
          </div>
          <div
            class="d-flex align-items-center"
            v-if="
            selectedMeterLatestDailySummary.total_billing_amount !== undefined
          "
          >
            <p class="h2 pr-3">💰</p>
            <p class="h2 pr-2 font-weight-bold">
              {{
                selectedMeterLatestDailySummary.total_billing_amount.toFixed(2)
              }}
            </p>
          </div>
          <div class="no-data" v-if="Object.keys(addressMeters).length && !selectedMeterLatestDailySummary.electric && !selectedMeterLatestDailySummary.gas">
            <small>No data is yet available for this time period.</small>
          </div>
        </div>
      </div>

      <div
        class="d-flex type-filter rounded justify-content-around"
        v-if="Object.keys(addressMeters).length"
      >
        <div
          class="text-center meter-type-filter"
          v-show="selectedMeterSupportedTypes.includes('ELECTRIC')"
          :class="{
          selected:
            filterParams.usage_unit == 'kwh' && !filterParams.show_emission,
        }"
          @click="
          filterParams.usage_unit = 'kwh';
          filterParams.show_emission = false;
        "
        >
          Electricity (kWh)
        </div>
        <div
          class="text-center meter-type-filter"
          v-show="selectedMeterSupportedTypes.includes('GAS')"
          :class="{
          selected:
            filterParams.usage_unit == 'therm' && !filterParams.show_emission,
        }"
          @click="
          filterParams.usage_unit = 'therm';
          filterParams.show_emission = false;
        "
        >
          Gas (therm)
        </div>
        <div
          class="text-center meter-type-filter"
          v-show="selectedMeterSupportedTypes.includes('ELECTRIC')"
          :class="{
          selected:
            filterParams.usage_unit == 'kwh' && filterParams.show_emission,
        }"
          @click="
          filterParams.usage_unit = 'kwh';
          filterParams.show_emission = true;
        "
        >
          CO<sub>2</sub> Electric (lbs)
        </div>
        <div
          class="text-center meter-type-filter"
          v-show="selectedMeterSupportedTypes.includes('GAS')"
          :class="{
          selected:
            filterParams.usage_unit == 'therm' && filterParams.show_emission,
        }"
          @click="
          filterParams.usage_unit = 'therm';
          filterParams.show_emission = true;
        "
        >
          CO<sub>2</sub>Gas (lbs)
        </div>
      </div>

      <UserUsageChart
        class="pt-3"
        :dataSet="periodsConfig[this.selectedPeriod].dataSet"
        :totalSamplesCount="periodsConfig[this.selectedPeriod].totalSamplesCount"
      />
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import {
  DemiDecadePeriodFilterParam,
  YearlyPeriodFilterParam,
  DailyPeriodFilterParam,
  WeeklyPeriodFilterParam,
  MonthlyPeriodFilterParam,
  BillingPeriodFilterParam,
  unitToTypeMapping,
} from '@/javascripts/myUsageDataSourcer';

import UserUsageChart from '@/components/UserUsageChart.vue';
export default {
  ssr: false,
  pageTitle: '🏠 Energy',
  name: 'Usage',
  components: { UserUsageChart },
  middleware: ['auth'],
  periodDefaultFilterParams: {},
  constants: {
    meterTypeUsageUnit: {
      ELECTRIC: 'kwh',
      GAS: 'therm',
    },
  },
  data() {
    return {
      selectedMeterLatestDailySummary: {},
      filterParams: {
        usage_unit: 'kwh',
        show_emission: false,
        energy_account_id: null,
      },
      selectedPeriod: 'daily',
      userPelmEnergyAccounts: [],
      periodsConfig: {
        daily: new DailyPeriodFilterParam(),
        weekly: new WeeklyPeriodFilterParam(),
        monthly: new MonthlyPeriodFilterParam(),
        billing: new BillingPeriodFilterParam(),
        yearly: new YearlyPeriodFilterParam(),
        demiDecade: new DemiDecadePeriodFilterParam(),
      },
      graphData: {
        labels: [],
        data: [],
      },
    };
  },
  watch: {
    'filterParams.usage_unit': {
      handler: function () {
        this.$nuxt.$loading.start();
        // this.periodsConfig = {
        //   daily: new DailyPeriodFilterParam(),
        //   weekly: new WeeklyPeriodFilterParam(),
        //   monthly: new MonthlyPeriodFilterParam(),
        //   billing: new BillingPeriodFilterParam(),
        //   yearly: new YearlyPeriodFilterParam(),
        //   demiDecade: new DemiDecadePeriodFilterParam(),
        // };
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
        this.$nuxt.$loading.finish();
      },
    },
    'filterParams.show_emission': {
      handler: function () {
        this.$nuxt.$loading.start();
        // this.periodsConfig = {
        //   daily: new DailyPeriodFilterParam(),
        //   weekly: new WeeklyPeriodFilterParam(),
        //   monthly: new MonthlyPeriodFilterParam(),
        //   billing: new BillingPeriodFilterParam(),
        //   yearly: new YearlyPeriodFilterParam(),
        //   demiDecade: new DemiDecadePeriodFilterParam(),
        // };
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
        this.$nuxt.$loading.finish();
      },
    },
    'filterParams.energy_account_id': {
      handler: function () {
        this.$nuxt.$loading.start();
        this.periodsConfig = {
          daily: new DailyPeriodFilterParam(),
          weekly: new WeeklyPeriodFilterParam(),
          monthly: new MonthlyPeriodFilterParam(),
          billing: new BillingPeriodFilterParam(),
          yearly: new YearlyPeriodFilterParam(),
          demiDecade: new DemiDecadePeriodFilterParam(),
        };
        if (this.selectedMeterSupportedTypes.length > 0) {
          let usage_unit =
            this.$options.constants.meterTypeUsageUnit[
              this.selectedMeterSupportedTypes[0]
            ];
          this.filterParams.usage_unit = usage_unit;
        }
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
        this.$nuxt.$loading.finish();
      },
    },
    selectedPeriod(newVal) {
      this.$nuxt.$loading.start();
      this.periodsConfig[newVal].fetchData(this, this.filterParams);
      this.$nuxt.$loading.finish();
    },
  },
  async mounted() {
    await this.getUserMeters();
    if (this.filterParams.energy_account_id) {
      this.periodsConfig[this.selectedPeriod].fetchData(this, this.filterParams);
    }
  },
  filters: {
    toHTTP(value) {
      let dt = DateTime.now();
      if (value) {
        dt = DateTime.fromISO(value);
      }
      return dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
  },
  methods: {
    getGraphLabel() {
      if (
        this.filterParams.usage_unit == 'kwh' &&
        this.filterParams.show_emission
      ) {
        return 'lb_co2e';
      }
      if (
        this.filterParams.usage_unit == 'therm' &&
        this.filterParams.show_emission
      ) {
        return 'lb_co2e';
      }

      if (
        this.filterParams.usage_unit == 'kwh' &&
        !this.filterParams.show_emission
      ) {
        return 'kwh';
      }
      if (
        this.filterParams.usage_unit == 'therm' &&
        !this.filterParams.show_emission
      ) {
        return 'therm';
      }
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    async getUserLatestDailyUsageSummary(from_date, to_date, billing_head) {
      this.$nuxt.$loading.start();
      let meterId = this.filterParams.energy_account_id;
      try {
        let res = await this.$api.$get(
          `pelm/user-latest-daily-usage-summary/${meterId}`,
          {
            params: {
              from_date: from_date,
              to_date: to_date,
              billing_head: billing_head,
            },
          }
        );
        console.log(res);
        this.selectedMeterLatestDailySummary = res;
        this.$nuxt.$loading.finish();
      } catch (error) {
        console.error(error);
        this.$nuxt.$loading.finish();
      }
    },
    async getUserMeters() {
      let res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      this.userPelmEnergyAccounts = res.items.filter((o) => {
        return (
          o.last_sync_date_gas ||
          o.last_sync_date_electric ||
          o.start_sync_date_gas ||
          o.start_sync_date_electric
        );
      });
      if (this.userPelmEnergyAccounts.length > 0) {
        this.filterParams.energy_account_id = this.userPelmEnergyAccounts[0].id;
      }
    },
    async setRangeFilter(direction) {
      this.$nuxt.$loading.start();
      if (direction == 'left') {
        this.periodsConfig[this.selectedPeriod].previous();
      }
      if (direction == 'right') {
        this.periodsConfig[this.selectedPeriod].next();
      }

      this.periodsConfig[this.selectedPeriod].fetchData(
        this,
        this.filterParams
      );
      this.$nuxt.$loading.finish();
    },
  },
  computed: {
    addressMeters() {
      return this.groupBy(this.userPelmEnergyAccounts, 'address');
    },
    selectedMeterSupportedTypes() {
      this.userPelmEnergyAccounts;
      let meterTypes = [];
      if (this.filterParams.energy_account_id) {
        const meter = this.userPelmEnergyAccounts.find(
          (o) => o.id === this.filterParams.energy_account_id
        );
        meterTypes = [...meter.available_meter_types];
      }
      return meterTypes;
    },
    selectedMeter() {
      const meter = this.userPelmEnergyAccounts.find(
        (o) => o.id === this.filterParams.energy_account_id
      );
      return meter;
    },
  },
  head() {
    return {
      title: 'My Usage | Meterleader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'View your utility data.',
        },
      ],
    };
  },
};
</script>
<style lang="sass">
@import '~@/assets/css/_variables.scss'
.rounded
  border-radius: 20px !important

.period-option
  margin: 2px 5px 2px 5px
  padding: 10px 5px 10px 5px !important
  border: 2px solid $grey
  // min-width: 3rem
  // max-width: 3rem
  font-weight: 400
  flex-grow: 1
  flex-basis: 0
  min-width: 0
  &.selected
    border-color: $accent
    color: $accent
    font-weight: 800

.period-label
  padding: 20px
  font-size: 1.8rem !important
  background-color: $grey
  width: auto

.val-label
  color: $dark-grey

.bg-white
  background-color: white

.summary
  border: 2px solid $grey
  margin-top: 1rem !important
  padding: .2rem 1rem 0 1rem !important
  // background-color: $grey

.type-filter
  margin-top: 1rem !important
  padding: 4px
  background-color: $grey

.meter-type-filter
  width: 100%
  border-radius: 18px !important
  &.selected
    background-color: white

.no-data
  text-align: center
  padding-bottom: 2px
</style>
