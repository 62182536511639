<template>
  <div id="account-content" class="content">
    <h2>Change Name</h2>
    <form id="change-name-form">
      <p id="name-changed" class="success">Name successfully changed</p>
      <p id="name-error" class="error">Server Error. Please try again.</p>
      <p id="invalid-name" class="error">
        First name must be at least 2 characters long.
      </p>
      <label for="firstName">Full Name</label><br />
      <input
        id="firstName"
        v-model="firstName"
        name="firstName"
        type="text"
        placeholder="First Name"
        required
      />
      <input
        id="lastName"
        v-model="lastName"
        name="lastName"
        type="text"
        placeholder="Last Name"
        required
      /><br />
      <button
        id="save-changes-button"
        type="button"
        class="btn btn-green"
        @click="saveName"
      >
        Save Changes
      </button>
    </form>

    <h2>Affiliated Organization (Optional)</h2>
    <form id="change-organization-form">
      <p id="organization-changed" class="success">
        Organization successfully changed
      </p>
      <p id="organization-error" class="error">
        Server Error. Please try again.
      </p>
      <p id="invalid-organization" class="error">Invalid corporate code</p>

      <div class="d-flex">
        <h5>Current Organization:</h5>
        <h5 class="pl-2">{{ currentOrganization || 'Not associated yet' }}</h5>
      </div>
      <br />
      <label for="corporateCode">Corporate Code</label><br />
      <input
        id="corporateCode"
        v-model="corporateCode"
        name="corporateCode"
        type="text"
        placeholder="Corporate Code"
        required
      />
      <br />
      <button
        id="save-changes-button"
        type="button"
        class="btn btn-green"
        @click="updateOrganization"
      >
        Update Corporate Code
      </button>
    </form>
    <h2>Request Email Change</h2>
    <form id="change-email-form">
      <p id="email-requested" class="success">
        Email change successfully requested. A verification email has been sent
        to your new email.
        <a href="/send-verification">Resend Verification Email</a>
      </p>
      <p id="email-error" class="error">Server Error. Please try again.</p>
      <p id="same-email" class="error">
        Email entered is the same as current email.
      </p>
      <p id="email-used" class="error">This email address is already in use.</p>
      <p id="bad-login" class="error">Incorrect password.</p>
      <p id="social-account" class="error">
        This account is connected to Facebook. Email must be changed through
        Facebook.
      </p>
      <label for="currentPassword">Current Email</label><br />
      <input
        id="currentPassword"
        name="currentPassword"
        type="password"
        required
      /><br />
      <label for="email">New Email</label><br />
      <input id="email" name="email" type="email" required /><br />
      <button
        id="change-email-button"
        type="button"
        class="btn btn-green"
        @click="changeEmail"
      >
        Change Email
      </button>
    </form>
    <h2>Change Password</h2>
    <form id="change-password-form">
      <p id="password-changed" class="success">Password successfully changed</p>
      <p id="server-error" class="error">Server Error. Please try again.</p>
      <p id="wrong-pass" class="error">Old password is incorrect.</p>
      <p id="pass-reset" class="error">
        This account is currently already in the password reset process.
      </p>
      <p id="social-account-pass" class="error">
        This account is connected to Facebook. Password must be changed through
        Facebook.
      </p>
      <ul id="password-error">
        <li id="password-length">
          Password must be at least 8 characters long
        </li>
        <li id="password-case">
          Password must contain an upper case character
        </li>
        <li id="password-lower">
          Password must contain a lower case character
        </li>
        <li id="password-num">Password must contain a number</li>
      </ul>
      <label for="oldPassword">Old Password</label><br />
      <input
        id="oldPassword"
        name="oldPassword"
        type="password"
        required
      /><br />
      <label for="newPassword">New Password</label><br />
      <input
        id="newPassword"
        name="newPassword"
        type="password"
        required
      /><br />
      <button
        id="change-password-button"
        class="btn btn-green"
        type="button"
        @click="changePassword"
      >
        Change Password
      </button>
      <br />
    </form>
  </div>
</template>

<script>
import { actionTypes } from '@/store/types';

export default {
  name: 'Account',
  async asyncData({ store }) {
    await store.dispatch(actionTypes.GET_USER_PROFILE);
    return {
      firstName: store.state.user.profile.firstname,
      lastName: store.state.user.profile.lastname,
      currentOrganization: store.state.user.profile.organization_name,
    };
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      currentPassword: '',
      newPassword: '',
      newEmail: '',
      corporateCode: '',
    };
  },
  methods: {
    updateOrganization() {
      const organizationError = $('#oragnization-error');
      const organizationSuccess = $('#organization-changed');
      const invalidOrganization = $('#invalid-organization');

      organizationError.hide();
      organizationSuccess.hide();
      invalidOrganization.hide();

      this.$repo.user
        .update({
          corporate_code: this.corporateCode.trim(),
        })
        .then((data) => {
          organizationSuccess.show();

          this.currentOrganization = data.data.profile.organization_name;
          this.corporateCode = '';
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              invalidOrganization.show();
              break;
            default:
              organizationError.show();
          }
        });
    },
    saveName() {
      const nameError = $('#name-error');
      const nameSuccess = $('#name-changed');
      const invalidName = $('#invalid-name');

      invalidName.hide();
      nameSuccess.hide();
      nameError.hide();

      this.$repo.user
        .changeName({
          firstname: this.firstName.trim(),
          lastname: this.lastName.trim(),
        })
        .then(() => {
          nameSuccess.show();
        })
        .catch((error) => {
          switch (error.status) {
            case 400:
              invalidName.show();
              break;
            default:
              nameError.show();
          }
        });
    },
    changeEmail() {
      const email = $('#email');
      const currentPassword = $('#currentPassword');
      const sameEmail = $('#same-email');
      const emailUsed = $('#email-used');
      const badLogin = $('#bad-login');
      const socialAccount = $('#social-account');
      const emailError = $('#email-error');
      const emailSuccess = $('#email-requested');

      sameEmail.hide();
      emailUsed.hide();
      badLogin.hide();
      socialAccount.hide();
      emailSuccess.hide();
      emailError.hide();

      const data = {
        newemail: email.val().trim(),
        password: currentPassword.val().trim(),
      };

      this.$api
        .post('/user/change_email_request', data)
        .then(() => {
          emailSuccess.show();
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              switch (error.response.data.msg) {
                case 'email_unchanged':
                  sameEmail.show();
                  break;
                case 'email_already_in_use':
                  emailUsed.show();
              }
              break;
            case 401:
              badLogin.show();
              break;
            case 403:
              socialAccount.show();
              break;
            default:
              emailError.show();
          }
        });
    },
    changePassword() {
      const wrongPass = $('#wrong-pass');
      const passReset = $('#pass-reset');
      const socialAccountPass = $('#social-account-pass');
      const passwordError = $('#password-error');
      const passwordLength = $('#password-length');
      const passwordCase = $('#password-case');
      const passwordLower = $('#password-lower');
      const passwordNum = $('#password-num');
      const oldPassword = $('#oldPassword');
      const newPassword = $('#newPassword');
      const passSuccess = $('#password-changed');
      const passError = $('#server-error');

      passwordError.hide();
      wrongPass.hide();
      passReset.hide();
      socialAccountPass.hide();
      passSuccess.hide();
      passError.hide();

      const data = {
        oldpassword: oldPassword.val().trim(),
        newpassword: newPassword.val().trim(),
      };

      this.$api
        .post('/user/change_password', data)
        .then(() => {
          passSuccess.show();
          oldPassword.val('');
          newPassword.val('');
        })
        .catch((error) => {
          const errorMessage = error.response.data.msg;
          switch (errorMessage) {
            case 'weak_password':
              const warnings = error.response.data.warning;
              passwordError.show();
              oldPassword.val('');
              newPassword.val('');
              for (let i = 0; i < warnings.length; i++) {
                switch (warnings[i]) {
                  case 'Password must be at least 8 characters long':
                    passwordLength.addClass('bold');
                    break;
                  case 'Password must contain an upper case character':
                    passwordCase.addClass('bold');
                    break;
                  case 'Password must contain a lower case character':
                    passwordLower.addClass('bold');
                    break;
                  case 'Password must contain a number':
                    passwordNum.addClass('bold');
                    break;
                }
              }
              break;
            case 'bad_login':
              oldPassword.val('');
              wrongPass.show();
              console.log('bad login');
              break;
            case 'password has been reset':
              passReset.show();
              break;
            case 'social_account':
              socialAccountPass.show();
              break;
            default:
              passError.show();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/accountSettings.scss';
</style>
