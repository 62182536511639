<template>
  <div class="container">
<!--    <h3 style="padding-top: 72px">Loading...</h3>-->
  </div>
</template>

<script>
export default {
  name: 'Facebook',
  async mounted() {
    if (process.client) {
      this.$nextTick(() => {
        this.$nuxt.$loading.start();
      });
      await this.processFacebookLogin();
    }
  },
  methods: {
    async processFacebookLogin() {
      try {
        const url = `${process.env.AUTH_BASE_URL}/api/facebook?task_id=${this.$route.query.task_id}`;
        this.$auth.$storage.setUniversal('strategy', 'local');
        if (this.$route.query.status === 'pending') {
          const result = await this.$axios.get(url);

          if (result.data.msg === 'ok') {
            this.$auth.$storage.setUniversal('user', result.data.user, true);
            // console.log('facebook login ok');
            window.location.href = '/my-usage';
          } else {
            try {
              const retryCount = this.$auth.$storage.getUniversal('retryCount');
              if (retryCount < 2) {
                this.$auth.$storage.setUniversal('retryCount', retryCount + 1);
                window.location.href =
                  process.env.API_ADDRESS + '/facebook/login';
              }
            } catch {}

            // return this.$router.replace(`/login?error=${result.data.msg}`);
            return this.$router.replace(`/login?error=access_denied`);

          }
        } else {
          return this.$router.replace(
            '/login?error=' + this.$route.query.status
          );
        }
      } catch (error) {
        console.log('facebook error', error);
      }
    },
  },
};
</script>
