<template>
  <nav class="custom-nav navbar fixed-bottom navbar-light m-0 pt-3"
  >
    <div
      :style="{ width: '100%', height: '100%' }"
      class="d-flex justify-content-around"
    >
      <div
        :style="{ width: '100%' }"
        :class="{ selected: $route.path === '/my-usage' }"
        class="
          custom-nav-item
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        @click="$router.push('/my-usage')"
      >
        <font-awesome-icon icon="bolt" size="2x" />
        <small>Energy</small>
      </div>
      <div
        :style="{ width: '100%' }"
        :class="{ selected: $route.path === '/challenges' }"
        class="
          custom-nav-item
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        @click="$router.push('/challenges')"
      >
        <font-awesome-icon icon="trophy" size="2x" />
        <small>Challenges</small>
      </div>
      <div
        :style="{ width: '100%' }"
        :class="{ selected: $route.path === '/actions' }"
        class="
          custom-nav-item
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        @click="$router.push('/actions')"
      >
        <font-awesome-icon icon="check-circle" size="2x" />
        <small>Actions</small>
      </div>
      <div
        :style="{ width: '100%' }"
        :class="{
          selected: $route.path === '/settings',
        }"
        class="
          custom-nav-item
          d-flex
          flex-column
          justify-content-center
          align-items-center
          border-right
        "
        @click="$router.push('/settings')"
      >
        <font-awesome-icon icon="cog" size="2x" />
        <small>Settings</small>
      </div>
    </div>
  </nav>
</template>
<script>
export default {};
</script>
<style lang="sass" scoped>
@import '~@/assets/css/_variables.scss'
small
  font-size: 0.7rem
.custom-nav
  background-color: $light-grey
  overflow: hidden

.custom-nav-item
  color: $medium-grey
  &.selected
    color: $primary
</style>
