<template>
  <div id="login-page">
    <h1>Log in to MeterLeader</h1>
    <p>
      Don't have a MeterLeader account?
      <nuxt-link to="/signup">Sign Up</nuxt-link>
    </p>
    <div class="login-box">
      <h3>Account login</h3>
      <p id="facebook-error" class="error">{{ errorMessage }}</p>
      <div class="login-facebook-button" @click="facebookLogin">
        <img src="~/assets/images/blueFacebookLogo.png" />
        <h4>Continue with Facebook</h4>
      </div>
      <form v-on:submit.prevent="login" class="login-form">
        <p id="or">
          or
        </p>
        <p id="incorrect-login" class="error">
          Email and/or password is incorrect.
        </p>
        <p id="awaiting-password-reset" class="error">
          This account has been locked an needs a password reset. Please click
          <nuxt-link to="/reset_password">here</nuxt-link>
          to reset your password.
        </p>
        <p id="server-error" class="error">
          A server error has occurred.
        </p>
        <p id="verification-needed" class="error">
          The email address for this account has not yet been verified.
          <nuxt-link to="/signup/send-verification"
            >Resend verification email</nuxt-link
          >
        </p>
        <input
          v-model="email"
          class="email"
          type="email"
          name="email"
          placeholder="Email address"
          required
        /><br />
        <input
          v-model="password"
          class="password"
          type="password"
          name="password"
          placeholder="Password"
          required
        /><br />
        <div class="remember">
          <input
            id="remember-me"
            v-model="rememberMe"
            class="remember-me"
            type="checkbox"
            name="rememberMe"
            value="remember"
          />
          <label for="remember-me">Remember Me</label><br />
        </div>
        <input
          class="login-button"
          type="submit"
          value="Log in"
        />
      </form>
      <div id="bottom-login">
        <nuxt-link id="forgot" to="/reset_password">Forgot password?</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  middleware: ['auth'],
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: 'test',
    };
  },
  mounted() {
    this.incorrectError = $('#incorrect-login');
    this.emailError = $('#verification-needed');
    this.serverError = $('#server-error');
    this.passwordResetError = $('#awaiting-password-reset');
    this.passwordInput = $('.password');

    // encodeURIComponent
    // console.log(this.$route.query.hasOwnProperty('redirect'))
    // console.log(this.$route.query)


    // TODO: log error
    switch (this.$route.query.error) {
      case 'temporarily_unavailable':
      case 'server_error':
        this.errorMessage =
          'Something went wrong with connecting to Facebook. Please try again.';
        break;
      case 'access_denied':
        this.errorMessage = 'Facebook login declined.';
        break;
      case 'timeout':
      case 'our_bad':
        this.errorMessage =
          'Something went wrong on our end. Please try again.';
        break;
      default:
        this.errorMessage = '';
    }

    if (this.errorMessage !== '') {
      $('#facebook-error').show();
    }
  },
  methods: {
    facebookLogin() {
      this.$auth.$storage.setUniversal('retryCount', 0);
      window.location.href = process.env.API_ADDRESS + '/facebook/login';
    },
    async login() {
      this.incorrectError.hide();
      this.emailError.hide();
      this.serverError.hide();
      this.passwordResetError.hide();
      if (DEMO) {
        console.log('login demo');
        await this.$auth.loginWith('local', {
          data: {
            email: this.email.trim(),
            password: this.password.trim(),
            remember_me: this.rememberMe,
          },
        });
        console.log('login success');
        // this.$router.push('/challenges');
      } else {
        try {
          try {
            const redirectUrl = this.$auth.$storage.getUniversal('redirect');
            if (!redirectUrl) {
              this.$auth.$storage.setUniversal('redirect', '/challenges');
            }
          } catch {}

          const result = await this.$auth.loginWith('local',
            {
              data:
                {
                  email: this.email.trim(),
                  password: this.password.trim(),
                  remember_me: this.rememberMe,
                }
            });
          // console.log(this.$route.query)
          if (this.$route.query.hasOwnProperty('redirect')) {
            window.location.href = this.$route.query['redirect'];
          }
        } catch (error) {
          if (error.response) {
            switch (error.response.data.msg) {
              case 'bad_login':
                this.incorrectError.show();
                this.passwordInput.val('');
                break;
              case 'waiting for password reset':
                this.passwordResetError.show();
                this.passwordInput.val('');
                break;
              case 'email confirmation required':
                $('.login-form').trigger('reset');
                this.emailError.show();
                break;
              default:
                this.serverError.show();
            }
          } else {
            this.serverError.show();
          }
        }
      }
    },
  },
  head() {
    return {
      title: 'Login',
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/login';

#facebook-error {
  padding-top: 15px;
}
</style>
