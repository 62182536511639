<template>
  <div :style="{ backgroundColor: '#fff', minHeight: '90vh' }">
    <div class="progress" :style="{ backgroundColor: '#fff', height: '5px' }">
      <div
        class="progress-bar accent-bg"
        :style="{ width: `${(currentStep * 100) / totalSteps}%` }"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div :class="containerClass()">
      <div class="">
        <div v-if="currentStep === 1">
          <h1 class="my-5 text-center">Utility Registration</h1>
          <div class="d-flex justify-content-center text-center">
            <div
              :style="{ width: '450px', backgroundColor: '#fafafa' }"
              class="p-4 rounded"
            >
              <p id="utility-message" class="description">
                Connect your electric and/or gas utility account to your
                MeterLeader account.
                <a href="/faqs#1" target="_blank">Questions about this step?</a>
              </p>
              <button
                class="add-utility-button p-3 mt-3 rounded"
                @click="initPelm(pelmUserId)"
              >
                Add Utility Account
              </button>
            </div>
          </div>
          <div id="myModal" class="modal" :class="modalControl">
            <!-- Modal content -->
            <div class="modal-content">
              <div><span class="close" @click="hideModal">&times;</span></div>
              <div v-if="pelm_connect" class="connect-form">
                <h4>Connect Your Utility Account</h4>
                <p class="description">MeterLeader lets you securely connect your utility account in seconds and doesn’t sell any of your personal information.</p>
                <form id="pelm-connect" @submit.prevent="handleSubmit">
                  <label class="label">Select Your Utility</label><br />
                  <select class="form-control" v-model="form.utility_id" v-on:change="utilityChange" required>
                    <option disabled value="">Please select your utility</option>
                    <option v-for="utilitie in this.utilities" :value="utilitie.id">{{ utilitie.name }}</option>
                  </select>
                  <label class="label spacer-top">{{ this.utility_account_label }}</label><br />
                  <input class="form-control" v-model="form.username" type="text" placeholder="Utility username"
                         required>
                  <div v-if="password_field">
                    <label class="label spacer-top">Utility Account Password</label><br />
                    <input class="form-control" v-model="form.password" type="password" placeholder="Utility password"
                           required>
                  </div>
                  <div v-else>
                    <small class="text-tip">*For Ameren your account data is tied to the employee name that you signed with for the Ameren Home Energy Challenge Pilot.</small>
                  </div>
                  <button class="form-control submit spacer-top-submit" type="submit">Submit</button>
                  <small class="text-tip"
                  >By clicking submit, you agree to MeterLeader’s
                    <nuxt-link to="/terms-conditions"> Terms & Conditions </nuxt-link> and <nuxt-link to="/privacy-policy"> Privacy Policy </nuxt-link>
                  </small>
                </form>
                <div class="errorDiv"><p :class="validation">{{ this.errorMsg }}</p></div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 2 && initial && !addAnotherUtility"
          class="d-flex justify-content-center"
        >
          <div
            :style="{ width: '450px', /*backgroundColor: '#fafafa'*/ }"
            class="py-4 px-2 rounded"
          >
            <h1>Home Information</h1>
            <hr/>
            <br/>
            <form @submit.prevent="teamSubmit">
              <div class="form-group">
                <label class="label">Property Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="team-name"
                  placeholder="Property Name *"
                  required
                  v-model="teamFormData.name"
                />
              </div>
              <div class="form-group">
                <label class="label">Home Type</label>
                <select
                  class="form-control"
                  id="homeType"
                  v-model="teamFormData.home_type"
                >
                  <option :value="undefined" disabled selected>
                    Please select Home Type
                  </option>
                  <option value="Single Family Home">Single Family Home</option>
                  <option value="Townhouse">Townhouse</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Condo">Condo</option>
                  <option value="Duplex">Duplex</option>
                  <option value="Mobile Home">Mobile Home</option>
                  <option value="Other">Other</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>
              <div class="form-group">
                <label class="label">Home Ownership</label>
                <select
                  class="form-control"
                  id="homeOwnershio"
                  v-model="teamFormData.home_ownership"
                  placeholder="Home Ownership"
                >
                  <option :value="undefined" disabled selected>
                    Please select Home Ownership
                  </option>
                  <option value="Renter">I am a renter.</option>
                  <option value="Home Owner">I am a home owner.</option>
                </select>
              </div>
              <div class="form-group">
                <label class="label"># of Occupants</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="numberOfOccupants"
                  aria-describedby="numberOfOccupants"
                  placeholder="Enter number of occupants"
                  v-model="teamFormData.number_of_occupants"
                />
              </div>

              <div class="form-group">
                <label class="label">Square Footage</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  id="squareFootage"
                  aria-describedby="squareFootage"
                  placeholder="Enter square footage"
                  v-model="teamFormData.square_footage"
                />
              </div>

              <div class="form-group">
                <label class="label">Primary Heating Type</label>
                <select
                  class="form-control"
                  id="primaryHeatingType"
                  v-model="teamFormData.primary_heating_type"
                >
                  <option :value="undefined" disabled selected>
                    Please select Heating Type
                  </option>
                  <option value="Natural Gas Furnace">
                    Natural Gas Furnace
                  </option>
                  <option value="Propane Furnace">Propane Furnace</option>
                  <option value="Oil Furnace">Oil Furnace</option>
                  <option value="Electric Furnace">Electric Furnace</option>
                  <option value="Electric Heat Pump">Electric Heat Pump</option>
                  <option value="Electric Baseboard Heating">
                    Electric Baseboard Heating
                  </option>
                  <option value="Electric Space Heater">
                    Electric Space Heater
                  </option>
                  <option value="Boiler">Boiler</option>
                  <option value="Other">Other</option>
                  <option value="No Heating">No Heating</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>
              <div class="form-group">
                <label class="label">Primary Cooling Type</label>
                <select
                  class="form-control"
                  id="primaryCoolingType"
                  v-model="teamFormData.primary_cooling_type"
                >
                  <option :value="undefined" disabled selected>
                    Please select Cooling Type
                  </option>
                  <option value="Central Cooling">Central Cooling</option>
                  <option value="Through Wall (Split System)">
                    Through Wall (Split System)
                  </option>
                  <option value="Window/Room Air Conditioner">
                    Window/Room Air Conditioner
                  </option>
                  <option value="Portable Air Conditioner">
                    Portable Air Conditioner
                  </option>
                  <option value="No AC">No AC</option>
                  <option value="Not Sure">Not Sure</option>
                </select>
              </div>

              <div class="form-group">
                <label class="label">Pool or Spa?</label>
                <select
                  class="form-control"
                  id="poolAndSpa"
                  v-model="teamFormData.has_pool_or_spa"
                >
                  <option :value="undefined" disabled selected>
                    Has pool or spa
                  </option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label class="label">Rooftop Solar?</label>
                <select
                  class="form-control"
                  id="rooftopSolar"
                  v-model="teamFormData.rooftop_solar"
                >
                  <option :value="undefined" disabled selected>
                    Has rooftop solar
                  </option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label class="label">Home Battery Storage?</label>
                <select
                  class="form-control"
                  id="homeBatteryStorage"
                  v-model="teamFormData.home_battery_storage"
                >
                  <option :value="undefined" disabled selected>
                    Has home battery storage
                  </option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>

              <div class="form-group">
                <label class="label"># of Electric Vehicles</label>
                <input
                  type="number"
                  min="0"
                  max="10"
                  class="form-control"
                  id="numberOfElectricVehicles"
                  aria-describedby="numberOfElectricVehicles"
                  placeholder="Enter number of electric vehicles"
                  v-model="teamFormData.no_of_electric_vehicles"
                />
              </div>

              <div class="form-group">
                <label class="label"># of Gas Vehicles</label>
                <input
                  type="number"
                  min="0"
                  max="10"
                  class="form-control"
                  id="numberOfGasVehicles"
                  aria-describedby="numberOfGasVehicles"
                  placeholder="Enter number of gas vehicles"
                  v-model="teamFormData.no_of_gas_vehicles"
                />
              </div>
              <div class="form-group">
                <label class="label"># of Electric Powered Tools</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Enter number of electric powered tools"
                  v-model="teamFormData.no_of_electric_powered_tools"
                />
              </div>
              <div class="form-group">
                <label class="label"># of Gas Powered Tools</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Enter number of gas powered tools"
                  v-model="teamFormData.no_of_gas_powered_tools"
                />
              </div>

              <button
                type="submit"
                class="add-utility-button p-3 mt-3 rounded w-100"
              >
                Next
              </button>
            </form>
          </div>
        </div>
        <div v-else-if="(currentStep === 2 && !initial) || currentStep === 3">
          <h1 class="text-center pb-4">Connect Other Utility Account</h1>
          <div class="d-flex justify-content-center text-center">
            <div
              :style="{ width: '450px', backgroundColor: '#fafafa' }"
              class="p-4 rounded"
            >
              <p id="utility-message" class="description">
                Do you have another utility account?
                <a href="/faqs#1" target="_blank">Questions about this step?</a>
              </p>
              <button
                class="add-utility-button p-3 mt-3 rounded w-100"
                @click="
                  addAnotherUtility = true;
                  currentStep = 1;
                  totalSteps = 3;
                  initial = false;
                  initPelm(pelmUserId);
                "
              >
                Connect Other Utility
              </button>
              <br/>
              <button
                class="add-utility-button p-3 mt-4 rounded w-100"
                @click="currentStep = 4"
              >
                Done
              </button>
            </div>
          </div>
        </div>
        <div
          v-else-if="(currentStep === 3 && !initial) || currentStep === 4"
          class="d-flex justify-content-center text-center"
        >
          <div
            :style="{ width: '450px', /*backgroundColor: '#fafafa'*/ }"
            class="p-4 mt-5"
          >
            <template v-if="initial">
              <h1 class="c-final-title">Congratulations!</h1>
              <br/>
              <p class="c-final-content">
                Congratulations your utility has been added. Please note, it may
                take up to 20 minutes for your energy data to be displayed.

                <br/><br/>Now go join an available challenge. Or if you need
                to - go add a second utility account to your property.
              </p>

              <button
                type="button"
                class="btn p-3 w-100 accent-bg rounded font-weight-bold mt-3"
                @click="$router.push({ path: '/challenges' })"
              >
                Join A Challenge
              </button>
            </template>
            <template v-else>
              <h1 class="c-final-title">Congratulations!</h1>
              <br/>
              <p class="c-final-content">
                Congratulations your utility has been added. Please note, it may
                take up to 20 minutes for your energy data to be displayed.

                <br/><br/>Now make sure it is associated with an existing
                property or create a new property.
              </p>

              <button
                type="button"
                class="btn p-3 w-100 accent-bg rounded font-weight-bold mt-3"
                @click="$router.push({ path: '/account-settings?tab=Properties' })"
              >
                Go to My Properties
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    subscriptionIdProp: {type: String, required: false},
    initial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      addAnotherUtility: false,
      totalSteps: this.initial ? 4 : 3,
      currentStep: 1,
      pelmSdkLoaded: false,
      pelmUserId: null,
      subscriptionId: null,
      defaultPelmEnergyAccount: {},
      teamFormData: {},
      initialDataSynced: false,
      pelm_connect: false,
      form: {
        utility_id: "",
        username: "",
        password: "",
      },
      utilities: [],
      errorMsg: '',
      validation: 'error',
      modalControl: 'hide',
      password_field: true,
      utility_account_label: 'Utility Account Username',
      pelm_url: 'https://pmigration-wtvnys3zja-wl.a.run.app',
      // pelm_url: 'http://127.0.0.1:8000'
    };
  },
  async mounted() {
    await this.getSupportedUtilities();
  },
  watch: {
    async currentStep(newVal) {
      // if (newVal === 2) {
      if ((this.initial && newVal === 2) || (!this.initial && newVal === 4)) {
        let res = await this.$api.$get('pelm/sync-user-pelm-data');

        let intervalId;

        intervalId = setInterval(async () => {
          let pollEventState = await this.$api.get(
            `poll-events/${res.poll_event_id}`
          );

          if (!pollEventState.data.data) {
            return;
          }
          this.initialDataSynced = true;
          this.$nuxt.$loading.finish();
          clearInterval(intervalId);
          await this.$api.delete(`poll-events/${res.poll_event_id}`);
        }, 5000);
      }
      window.scrollTo(0, 0);
    },
  },
  methods: {
    utilityChange() {
      this.password_field = ![4, 6].includes(this.form.utility_id);
      if ([4, 6].includes(this.form.utility_id)) {
        this.form.password = 'Ameren';
        this.utility_account_label = 'Utility Account First & Last Name';
      } else {
        this.form.password = '';
        this.utility_account_label = 'Utility Account Username';
      }
    },
    hideModal() {
      this.modalControl = 'hide';
      this.pelm_connect = false;
    },
    clearForm() {
      this.form.username = '';
      this.form.password = '';
      this.form.utility_id = '';
      this.errorMsg = '';
    },
    handleSubmit: async function () {
      this.$nuxt.$loading.start();
      const formData = {};

      for (let [key, value] of Object.entries(this.form)) {
        formData[key] = value;
      }

      try {
        const valid_acc = await axios.post(this.pelm_url + '/verify-credentials', formData);
        if (valid_acc.data.is_successful) {
          this.validation = 'success';
          this.errorMsg = 'Credentials approved, please wait ...';

          const pelm = await axios.post(this.pelm_url + '/create-user', formData);
          this.pelmUserId = pelm.data.user_id;
          let res;

          try {
            res = await this.$api.post('pelm/pelm-subscriptions', {
              authorization_code: '',
              utility_id: formData['utility_id'],
              pelm_user_id: this.pelmUserId
            });
          } catch (err) {
            this.$nuxt.$loading.finish();
            this.validation = 'error';
            this.errorMsg = 'Utility provider is duplicate or not supported by meterleader.';
          }

          let intervalId;

          intervalId = setInterval(async () => {
            let pollEventState = await this.$api.get(
              `poll-events/${res.data.poll_event_id}`
            );

            if (!pollEventState.data.data) {
              return;
            }

            if (pollEventState.data.data.error) {
              this.validation = 'error';
              this.errorMsg = 'This utility account is already registered with current user.';
              this.$nuxt.$loading.finish();
              clearInterval(intervalId);
              await this.$api.delete(`poll-events/${res.data.poll_event_id}`);
              this.currentStep = 1;
              return;
            }

            this.$nuxt.$loading.finish();
            this.clearForm();

            if (this.initial) {
              this.defaultPelmEnergyAccount =
                pollEventState.data.data.energy_accounts[0];
              if (this.defaultPelmEnergyAccount.team_id) {
                this.currentStep = 3;
              } else {
                this.currentStep = 2;
              }
            } else {
              this.currentStep = 4;
            }

            clearInterval(intervalId);
            await this.$api.delete(`poll-events/${res.data.poll_event_id}`);
          }, 5000);

        }
      } catch (e) {
        this.validation = 'error';
        this.errorMsg = 'Your provided credentials are invalid.';
        this.$nuxt.$loading.finish();
      }
    },
    async getSupportedUtilities() {
      try {
        let res = await this.$api.$get(
          `pelm/supported-utilities`
        );
        this.utilities = res.results;
      } catch (error) {
        console.error(error);
      }
    },
    containerClass() {
      return this.currentStep === 4 ? 'container main' : 'container';
    },
    directToMyUsage() {
      if (!this.initialDataSynced) {
        this.$nuxt.$loading.start();
      }

      this.$router.push({path: '/challenges'});
    },
    async teamSubmit() {
      this.$nuxt.$loading.start();

      if (!this.defaultPelmEnergyAccount.team_id) {
        let teamRes = await this.$api.post('team/', {
          ...this.teamFormData,
        });

        let pelmMeterRes = await this.$api.patch(
          `pelm/pelm-energy-accounts/${this.defaultPelmEnergyAccount.id}`,
          {team_id: teamRes.data.id}
        );
      } else {
        // TODO dispatch a message
      }

      this.currentStep = 3;
      this.$nuxt.$loading.finish();
    },
    initPelm(pelmUserId) {
      this.modalControl = 'show';
      this.pelm_connect = true;
    },
  },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.accent-bg
  background-color: $accent

.rounded
  border-radius: 18px !important

.stepper-counter
  border-radius: 50%
  width: 32px
  height: 32px
  text-align: center
  color: #fff

.disable-stepper
  background-color: gray

.active-stepper
  background-color: #1ad596

.add-utility-button
  background-color: #1ad596
  font-weight: 700
  box-shadow: 0 8px 8px -4px #b1a9a9

input, select
  width: 100%
  height: 3rem
  border-radius: 10px !important

.main
  background: linear-gradient(162.75deg, #1AD596 14.92%, #C200FB 112.62%)
  height: 89.4vh
  width: 100%
  max-width: 100%
  //position: absolute

.c-final-title
  color: #ffffff
  //font-size: 1.75rem
  font-weight: 600
  font-family: Raleway
  line-height: 29px
  letter-spacing: 0em
  text-align: left

.c-final-content
  font-family: Raleway
  font-size: 1rem
  font-weight: 600
  line-height: 14px
  letter-spacing: 0em
  text-align: left

.errorDiv
  //text-align: center
  margin-top: 1%

.error
  color: #ff0000

.success
  color: #008000

.modal
  position: fixed
  z-index: 2100
  padding-top: 68px
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: scroll
  background-color: rgb(0, 0, 0)
  background-color: rgba(0, 0, 0, 0.4)

.modal-content
  background-color: #fefefe
  margin: auto
  padding: 20px
  border: 1px solid #888
  width: 30%

.close
  color: #aaaaaa
  float: right
  font-size: 28px
  font-weight: bold
  cursor: pointer

.close:hover,
.close:focus
  color: #000
  text-decoration: none
  cursor: pointer

.show
  display: block

.hide
  display: none

#pelm-connect
  margin-top: 3%
//padding: 2%

.submit
  background-color: #000000
  color: #ffffff
  font-weight: bolder
  height: 50px

.label
  margin-bottom: 0px
  font-size: 1rem
  font-weight: bold
  padding-bottom: 5px

@media (max-width: $mob-width-1)
  .modal-content
    width: 95%
    height: 600px
    overflow-y: scroll
  .main
    height: 105vh

@media (max-width: $mob-width-2)
  .modal-content
    width: 95%
    height: 600px
    overflow-y: scroll
  .main
    height: 105vh

.spacer-top
  margin-top: 10px

.spacer-top-submit
  margin-top: 15px
</style>
