<template>
  <div>
    <div>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-card v-if="Object.keys(data).length > 0">
            <v-card-title>{{ `${data.name}` }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>Subscription Plan</v-col>
                <v-col>{{ data.subscription_plan_typ || '-' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Admin</v-col>
                <v-col>{{ data.admin_name || '-' }}</v-col>
              </v-row>
              <v-row>
                <v-col>Corporate code</v-col>
                <v-col>{{ data.corporate_code }}</v-col>
              </v-row>
              <v-row>
                <v-col>Members count</v-col>
                <v-col>{{ data.members_count }}</v-col>
              </v-row>
              <v-row>
                <v-col>Pelm Subscription Cap</v-col>
                <v-col>{{ data.maximum_pelm_subscription_allowed }}</v-col>
              </v-row>
              <v-row>
                <v-col>Subscription Plan Expiry Datetime</v-col>
                <v-col>{{
                  data.subscription_paln_expiry_datetime | formatDate
                }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="blue accent-1"
                    @click="
                      $router.push({
                        name: 'admind-organizations-organizationId-update',
                        params: { organizationId: data.id },
                      })
                    "
                    >Update</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  data() {
    return {
      loading: false,
      data: {},
    };
  },

  methods: {
    async getData() {
      let res = await this.$api.get(
        `admind/organizations/${this.$route.params.organizationId}`
      );
      this.data = res.data;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>