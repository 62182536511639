<template>
  <div>
    <ChallengeMobileVue
      :challenge="challenge"
      :participating-teams="participatingTeams"
      :participating-user-teams="participatingUserTeams"
      :is-user-in-challenge="isUserInChallenge"
      :is-preview="isPreview"
      class="d-lg-none"
    />
    <ChallengeDesktopVue
      :challenge="challenge"
      :participating-teams="participatingTeams"
      :participating-user-teams="participatingUserTeams"
      :is-user-in-challenge="isUserInChallenge"
      :is-preview="isPreview"
      class="d-none d-lg-block"
    />
    <div
      id="leaveModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="leaveModal"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog
          modal-dialog-scrollable
          modal-dialog
          modal-dialog-scrollable-centered
        "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalCenterTitle" class="modal-title">
              Leave Challenge
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p id="not-enrolled" class="error">
              You are not enrolled in this challenge
            </p>
            <p id="ended" class="error">This challenge has already ended</p>
            <p id="leave-error" class="error">A server error has occured</p>
            Are you sure that you want to leave this challenge?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn green" data-dismiss="modal">
              No
            </button>
            <button
              id="leave-challenge"
              type="button"
              class="btn btn-danger"
              @click="leaveChallenge"
            >
              Leave
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChallengeDesktopVue from '~/components/challengeDetail/ChallengeDesktop.vue';
import ChallengeMobileVue from '~/components/challengeDetail/ChallengeMobile.vue';
import { actionTypes, mutationTypes } from '@/store/types';
import moment from "moment-timezone";

function fillEmptyChallengeFields(challenge) {
  challenge.name = challenge.name || '[Challenge name]';
  challenge.description = challenge.description || '[Description]';
  challenge.description_prizes = challenge.description_prizes || '[Prize description]';
  // challenge.description_eligibility =
  //   challenge.description_eligibility || `1) Must be a primary account holder of an active residential Pacific Gas & Electric (PG&E) electric account.
  //   2) Only participants who have been living (and currently are living) at the home address of the associated PG&E account for at least 1 year and therefore have at least 1 year’s worth of electricity data, are eligible to win the reward. Home address must be primary location of residence.
  //   3) Participants with less than 1 year’s worth of electricity data are still eligible to participate in the challenge, but will not be eligible to win the reward.`;
  challenge.description_individual_goal = challenge.description_individual_goal || '[Individual goal]';
  challenge.description_collective_goal = challenge.description_collective_goal || '[Collective goal]';
  challenge.description_rules_url = challenge.description_rules_url || '[Rules]';
}
export default {
  backButtonRoute: '/challenges',
  components: { ChallengeDesktopVue, ChallengeMobileVue },
  async asyncData({ params, query, store, app, $auth, redirect, req }) {
    let challenge;
    try {
      challenge = await app.$repo.challenges.get(params.challenge);

      challenge = challenge.data;

      const [leaderBoard, _0] = await Promise.all([
        app.$repo.challenges.getLeaderBoard(params.challenge),
        store.dispatch(actionTypes.GET_COMMENTS, params.challenge),
      ]);

      let isUserInChallenge = false;
      const participatingUserTeams = [];
      if ($auth.loggedIn) {
        try {
          const [myChallenges, _0, _1] = await Promise.all([
            app.$api.get(`/challenges/mychallenges`),
            store.dispatch(actionTypes.GET_TEAMS),
            // store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
          ]);
          const myChallengesFlat = myChallenges.data[0]
            .concat(myChallenges.data[1])
            .concat(myChallenges.data[2]);

          for (const userChallenge of myChallengesFlat) {
            if (String(userChallenge.id) === params.challenge) {
              isUserInChallenge = true;
              break;
            }
          }

          // Find which of user's teams are participating in the challenge
          if (leaderBoard) {
            for (const leaderBoardTeam of leaderBoard.data) {
              for (const userTeam of store.state.user.teams) {
                if (leaderBoardTeam.team_id === userTeam.id) {
                  participatingUserTeams.push(userTeam.id);
                }
              }
            }
          }
        } catch (e) {
          // TODO ...
          console.log(e);
        }
      }

      const isPreview = Object.keys(query).includes('preview');
      if (isPreview) {
        fillEmptyChallengeFields(challenge);
      }

      let challengeType;
      switch (challenge.metric) {
        case 1:
          challengeType = 'electricity';
          break;
        case 2:
          challengeType = 'gas';
          break;
        case 3:
          challengeType = 'electricity-and-gas';
          break;
      }

      return {
        challenge: challenge,
        participatingTeams: leaderBoard ? leaderBoard.data : [],
        participatingUserTeams,
        isUserInChallenge,
        isPreview,
        challengeType: challengeType,
      };
    } catch (e) {
      // TODO ...
      console.log(e);
      redirect('/404');
    }
  },
  methods: {
    leaveChallenge() {
      const url = `/challenges/${this.challenge.id}/leave`;
      const data = {
        team_id: this.participatingUserTeams[0],
      };
      this.$api.post(url, data).then(() => {
        $('#leaveModal').modal('hide');
        location.reload();
      });
    },
  },
  computed: {
    startDate() {
      return moment
        .tz(this.challenge.start_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('MMM. D, YYYY. h:mmA z');
    },
    endDate() {
      return moment
        .tz(this.challenge.end_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('MMM. D, YYYY. h:mmA z');
    }
  },
};
</script>
