<template>
  <div id="debugMenu">
    <button type="button" @click="createTestChallenge">
      Create Test Challenge
    </button>
    <button type="button" @click="createTestAccount">
      Create Test Account
    </button>
    <button type="button" @click="disbandAllTeams">
      Disband All Teams
    </button>
    <button type="button" @click="refresh">
      Refresh
    </button>
    <p>{{ result }}</p>
  </div>
</template>

<script>
import { actionTypes } from '@/store/types';

export default {
  name: 'Debug',
  data() {
    return {
      result: '',
    };
  },
  methods: {
    refresh() {
      setTimeout(() => {
        this.$store.dispatch(actionTypes.GET_CHALLENGES);
        this.$store.dispatch(actionTypes.GET_TEAMS);
        this.$store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS);
      }, 200);
    },
    createTestChallenge() {
      this.result = '';
      this.$api
        .get('/testing/challenges/create_test_challenge')
        .then((result) => {
          this.result = result.data;
          this.refresh();
        })
        .catch((error) => {
          this.result = error;
        });
    },
    createTestAccount() {
      this.result = '';
      this.$api
        .get('/testing/accounts/create_test_account')
        .then((result) => {
          this.result = result.data;
          this.refresh();
        })
        .catch((error) => {
          this.result = error;
        });
    },
    disbandAllTeams() {
      this.$api.get('/team/').then((result) => {
        result.data.forEach((team) => {
          this.$api.delete('/team/' + team.id);
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
#debugMenu {
  background-color: #0f6674;
  width: 20px;
  height: 20px;

  button,
  p {
    display: none;
  }
}

#debugMenu:hover {
  width: 200px;
  height: 200px;
  button,
  p {
    display: block;
  }
}
</style>
