<template>
  <v-app id="inspire">
    <v-navigation-drawer
      color="grey lighten-4"
      v-model="drawer"
      class="elevation-3"
      app
    >
      <v-sheet class="pa-4">
        <v-avatar class="mb-4" color="grey darken-1" size="64">
          <img id="profile-pic" :src="userData.photo_url" />
        </v-avatar>

        <div class="h5">{{ navBarName }}</div>
        <div class="body-2">Administrator</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list rounded>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="goto(item.routeName)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $options.appBarTitles[$route.name]
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs"
          v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      </template>
      <v-list>
       <v-list-item @click="syncUserContacts">
        <v-list-item-title>
          Sync user contacts
        </v-list-item-title>
       </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-2">
      <v-container :fluid="true">
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from 'vuex';

export default {
  middleware: 'admin_dashboard',
  appBarTitles: {
    admind: 'Meterleader Dashboard',
    'admind-users': 'Users',
    'admind-users-userId': 'User details',
    'admind-challenges': 'Challenges',
    'admind-challenges-challengeId': 'Challenge details',
    'admind-utility_providers': 'Utility Providers',
    'admind-utility_providers-utilId': 'Utility providers details',
    'admind-utility_accounts': 'Utility Accounts',
    'admind-utility_accounts-accountId': 'Utility Account details',
    'admind-property': 'Property',
    'admind-property-propertyId': 'Property details',
    'admind-background_tasks': 'Background Jobs',
    'admind-organizations': 'Organizations',
    'admind-organizations-create': 'Create Organizations',
  },
  data: () => ({
    drawer: null,
    selectedItem: 1,
    items: [
      { text: 'Dashboard', icon: 'mdi-view-dashboard', routeName: 'admind' },
      {
        text: 'Users',
        icon: 'mdi-account-supervisor',
        routeName: 'admind-users',
      },
      {
        text: 'Organizations',
        icon: 'mdi-domain',
        routeName: 'admind-organizations',
      },
      {
        text: 'Challenges',
        icon: 'mdi-clipboard-list',
        routeName: 'admind-challenges',
      },
      {
        text: 'Utility Providers',
        icon: 'mdi-speedometer-slow',
        routeName: 'admind-utility_providers',
      },

      {
        text: 'Pelm Subscriptions',
        icon: 'mdi-sim',
        routeName: 'admind-pelm_subscriptions',
      },

      {
        text: 'Pelm Energy Accounts',
        icon: 'mdi-lightning-bolt-circle',
        routeName: 'admind-pelm_energy_accounts',
      },
      {
        text: 'Properties',
        icon: 'mdi-home-city',
        routeName: 'admind-teams',
      },

      {
        text: 'Background Jobs',
        icon: 'mdi-animation',
        routeName: 'admind-background_tasks',
      },
    ],
  }),
  computed: mapState({
    userData: (state) => state.auth.user,
    navBarName(state) {
      return `${state.auth.user.firstname} ${state.auth.user.lastname}`;
    },
  }),
  mounted() {
    let routes = this.items.map((obj) => obj.routeName);
    this.selectedItem = routes.indexOf(this.$route.name);
  },
  methods: {
    async syncUserContacts(){
      try{
        await this.$api.$get('admind/sync-system-contacts');
        this.$toast.success(
              'Contacts syncronization queued successfully.',
              { duration: 5000 }
            );
      }catch(err){
        this.$toast.error(
              'Something went wrong.',
              { duration: 5000 }
            );
      }
    },
    goto(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>