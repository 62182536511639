var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5 col-12 mt-3"},[_c('div',{staticClass:"login p-4"},[_c('h1',{staticClass:"font-weight-bold mb-1"},[_vm._v("Sign Up")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('div',[_c('button',{staticClass:"fb-bg text-white d-flex align-items-center mb-3"},[_c('svg',{staticClass:"mx-3",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}},[_c('path',{attrs:{"d":"M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"}})]),_vm._v(" "),_c('p',{staticClass:"mb-0 text-center w-100 neg-margin"},[_vm._v("\n              Continue with facebook\n            ")])]),_vm._v(" "),_vm._m(6)]),_vm._v(" "),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"text-tip"},[_vm._v("Already have a Meterleader account? "),_c('a',{attrs:{"href":"#"}},[_vm._v("Login")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field mt-3"},[_c('label',{staticClass:"label"},[_vm._v("Display Name")]),_c('br'),_vm._v(" "),_c('input',{staticClass:"px-3",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field mt-3"},[_c('label',{staticClass:"label"},[_vm._v("Email")]),_c('br'),_vm._v(" "),_c('input',{staticClass:"px-3",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field mt-3"},[_c('label',{staticClass:"label"},[_vm._v("Password")]),_c('br'),_vm._v(" "),_c('input',{staticClass:"px-3",attrs:{"type":"password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field mt-3"},[_c('label',{staticClass:"label"},[_vm._v("Corporate Code (Optional)")]),_c('br'),_vm._v(" "),_c('input',{staticClass:"px-3",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('hr',{staticClass:"flex-grow-1"}),_vm._v(" "),_c('p',{staticClass:"pt-1 px-2"},[_vm._v("OR")]),_vm._v(" "),_c('hr',{staticClass:"flex-grow-1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"accent-bg mb-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("Login")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"text-tip"},[_vm._v("By creating an account you are accepting our\n          "),_c('a',{attrs:{"href":"#"}},[_vm._v("Terms and Conditions and Privacy Policy")])])
}]

export { render, staticRenderFns }