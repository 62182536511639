<template>
  <div id="team-content" class="content">
    <h2>Team Settings</h2>
    <p id="no-residences" style="margin-bottom: 10px">
      All the occupants in a home/building impact its energy use. To create a
      team you must first connect your utility account to MeterLeader!
      <nuxt-link to="/syncdata"> Click here to connect! </nuxt-link>
      If you are not an account holder ask the account holder to invite you to
      their team.
    </p>
    <form id="choose-team">
      <div class="form-row">
        <div class="form-group col">
          <label for="team">Select Team:</label>
          <select
            id="team"
            v-model="selectedTeam"
            class="form-control"
            name="team"
          >
            <!-- TODO: Investigate possible bug on iOS that prevents first item from being selected -->
            <option
              v-if="teams.length === 0"
              value="noTeamAvailable"
              selected
              disabled
            >
              No team available
            </option>
            <option v-for="team in teams" :id="'team-' + team.id" :value="team">
              {{ team.name }}
            </option>
          </select>
        </div>
        <div v-if="hasUtilityAccount" class="col align-self-center">
          <button
            type="button"
            class="btn btn-green"
            data-toggle="modal"
            data-target="#createTeamModal"
          >
            Create a Team
          </button>
        </div>
      </div>
    </form>

    <template v-if="selectedTeamDetails != null">
      <div id="team-settings-main">
        <div class="section">
          <h5>Team Meters</h5>
          <template v-for="meter in selectedTeamDetails.meters">
            <div class="row" data-meter="">
              <div class="col-3">
                <p>{{ meter.name.length > 0 ? meter.name : 'unnamed' }}</p>
              </div>
              <div v-if="isUserTeamLeader" class="col-6">
                <a
                  class="remove-utility leader red"
                  data-meter=""
                  @click="removeMeter(meter.id)"
                  >Remove</a
                >
              </div>
            </div>
          </template>

          <template v-if="isUserTeamLeader">
            <p id="team-utility-error" style="color: red; padding-top: 10px" />
            <a
              class="add-utility leader"
              data-toggle="modal"
              data-target="#editMetersModal"
            >
              <font-awesome-icon icon="wrench" size="xs" /> Edit meters
            </a>
          </template>
        </div>

        <form v-if="isUserTeamLeader" id="team-name-form" class="section">
          <h5 style="margin-top: 10px">Property Name</h5>
          <div class="form-group">
            <input
              id="team-name-input"
              v-model="teamNameInput"
              type="text"
              name="teamName"
            />
          </div>
          <button type="button" class="btn btn-green" @click="saveTeamName">
            Save Changes
          </button>
        </form>

        <div id="team-members-form" class="section">
          <h5 style="margin-top: 10px">Team Members</h5>
          <table id="team-members-list" class="table">
            <tbody>
              <tr v-for="(member, index) in selectedTeamDetails.members">
                <td class="team-member">
                  {{ index + 1 }}. <img :src="member.photo_url" />{{
                    member.full_name
                  }}
                </td>
                <td style="vertical-align: middle">
                  <button
                    v-if="
                      isUserTeamLeader &&
                      selectedTeamDetails.leader.user_id !== member.user_id
                    "
                    type="button"
                    class="btn btn-green"
                    @click="boot(member.user_id)"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <form v-if="isUserTeamLeader" id="team-invite-form" class="section">
          <h5 style="margin-top: 10px">Invite New Team Members</h5>
          <div class="form-group">
            <input
              id="team-invitee"
              v-model="inviteEmailInput"
              type="text"
              name="teamInvitee"
            />
          </div>
          <p id="invite-result" :class="inviteStatus">
            {{ inviteResultMessage }}
          </p>
          <button type="button" class="btn btn-green" @click="invite">
            Send
          </button>
        </form>

        <a
          v-if="isUserTeamLeader"
          id="disband-team"
          class="disband-team leader red"
          data-toggle="modal"
          data-target="#disbandModal"
          >Disband Team</a
        >
        <a
          v-else
          class="leave-team member red"
          data-toggle="modal"
          data-target="#disbandModal"
          >Leave Team</a
        >
      </div>
    </template>

    <!--region Modals -->
    <dialog-modal
      id="disbandModal"
      title="Disband Team?"
      :message="
        getDefault(
          selectedTeam,
          'name',
          'Are you sure you want to disband @@@ ?'
        )
      "
      confirm-button-label="Disband"
      @on-confirm="disbandTeam"
    />
    <dialog-modal
      id="disbandModal"
      title="Leave Team?"
      :message="
        getDefault(
          selectedTeam,
          'name',
          'Are you sure you want to disband @@@ ?'
        )
      "
      confirm-button-label="Leave"
      @on-confirm="leaveTeam"
    />
    <CreateTeam v-if="hasUtilityAccount" @team-created="onTeamCreated" />
    <EditMeters
      v-if="isUserTeamLeader"
      :selected-team-details="selectedTeamDetails"
      @meters-updated="onMetersUpdated"
    />
    <!--endregion Modals -->
  </div>
</template>

<script>
import DialogModal from '@/components/DialogModal';
import CreateTeam from '@/components/CreateTeam';
import { actionTypes } from '@/store/types';
import EditMeters from '@/components/EditMeters';

export default {
  name: 'Team',
  components: { EditMeters, CreateTeam, DialogModal },
  async asyncData({ store }) {
    await Promise.all([
      store.dispatch(actionTypes.GET_TEAMS),
      store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
    ]);
  },
  data() {
    return {
      teamNameInput: '',
      inviteEmailInput: '',
      inviteResultMessage: '',
      didInviteSucceed: true,
      selectedTeam: 'noTeamAvailable',
      selectedTeamDetails: null,
    };
  },
  computed: {
    teams() {
      return this.$store.state.user.teams;
    },
    utilityAccounts() {
      return this.$store.state.user.utilityAccounts;
    },
    hasUtilityAccount() {
      return this.utilityAccounts.length > 0;
    },
    inviteStatus() {
      return {
        'invite-success': this.didInviteSucceed,
        'invite-fail': !this.didInviteSucceed,
      };
    },
    isUserTeamLeader() {
      return (
        this.selectedTeamDetails != null &&
        this.selectedTeamDetails.leader.user_id === this.$auth.user.user_id
      );
    },
  },
  watch: {
    selectedTeam(selection) {
      if (selection != null) {
        this.$nuxt.$loading.start();
        this.getTeamDetails(selection.id);
      } else {
        this.selectedTeamDetails = null;
      }
    },
  },
  methods: {
    async onMetersUpdated() {
      await this.$store.dispatch(actionTypes.GET_TEAMS);
      this.getTeamDetails(this.selectedTeamDetails.id);
    },
    async onTeamCreated() {
      this.$nuxt.$loading.start();
      await this.$store.dispatch(actionTypes.GET_TEAMS);
      this.selectedTeam = this.teams[this.teams.length - 1];
      this.$nuxt.$loading.finish();
    },

    refresh() {
      // TODO: remove setTimeouts
      setTimeout(() => {
        this.$store.dispatch(actionTypes.GET_TEAMS);
        this.$store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS);
      }, 200);

      setTimeout(() => {
        if (this.teams.length > 0) {
          this.selectedTeam = this.teams[this.teams.length - 1];
        }
      }, 500);
    },

    getDefault(o, path, str) {
      if (o) {
        return str.replace('@@@', o[path]);
      }
      return 'loading';
    },

    removeMeter(meterId) {
      this.$repo.teams
        .removeMeter(this.selectedTeamDetails.id, meterId)
        .then((result) => {
          this.selectedTeamDetails.meters =
            this.selectedTeamDetails.meters.filter(
              (meter) => meter.id !== meterId
            );
        })
        .catch((error) => {
          $('#team-utility-error').text(error.response.data.msg);
        });
    },
    boot(userId) {
      const url = '/team/' + this.selectedTeamDetails.id + '/boot/' + userId;
      this.$api
        .get(url)
        .then((result) => {
          this.selectedTeamDetails.members =
            this.selectedTeamDetails.members.filter(
              (member) => member.user_id !== userId
            );
        })
        .catch((error) => {
          // TODO
        });
    },
    invite() {
      this.$repo.teams
        .invite(this.selectedTeamDetails.id, { emails: this.inviteEmailInput })
        .then((result) => {
          this.inviteResultMessage = `Invited ${this.inviteEmailInput} to join ${this.selectedTeamDetails.name}`;
          this.didInviteSucceed = true;
        })
        .catch((error) => {
          this.inviteResultMessage = error.response.data.msg;
          this.didInviteSucceed = false;
        });
    },
    async saveTeamName() {
      try {
        await this.$repo.teams.update(this.selectedTeamDetails.id, {
          name: this.teamNameInput,
        });
        this.refresh();
      } catch (error) {
        // TODO ...
      }
    },
    async disbandTeam() {
      await this.$repo.teams.disband(this.selectedTeamDetails.id);
      await this.$store.dispatch(actionTypes.GET_TEAMS);
      this.selectedTeam = null;
    },
    leaveTeam() {
      this.$repo.teams
        .leave(this.selectedTeamDetails.id)
        .then((result) => {
          this.selectedTeam = null;
        })
        .catch((error) => {
          // TODO
        });
    },
    getTeamDetails(teamId) {
      this.$repo.teams
        .get(teamId)
        .then((result) => {
          this.selectedTeamDetails = result.data;
          this.teamNameInput = result.data.name;
          this.$nuxt.$loading.finish();
        })
        .catch((error) => {
          // TODO
        });
    },
  },
};
</script>

<style scoped lang="scss">
.remove-utility {
  color: red;
  cursor: pointer;
}

.section {
  border: 0px;

  .btn {
    margin: 0 !important;
  }
}

.invite-success {
  padding-bottom: 15px !important;
}

.invite-fail {
  color: red;
  padding-bottom: 15px !important;
}

.disband-team,
.leave-team,
.remove-utility,
.add-utility {
  cursor: pointer;
}

.disband-team,
.leave-team {
  margin-top: 20px;
}

.team-member img {
  padding-left: 10px;
  padding-right: 10px;
}

table {
  max-width: 450px;
}
</style>
