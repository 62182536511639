<template>
  <div v-if="show">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-body m-4">
                <div class="text-center">
                  <h3 class="py-4">Which Property is joining the Challenge?</h3>
                  <h5 class="pt-2">
                    By joining this Challenge you are agreeing to MeterLeader's
                    <a href="#" @click.prevent="$router.push('/terms-conditions')">Terms of Services</a>
                    and
                    <a href="#" @click.prevent="$router.push('/privacy-policy')">Privacy Policy</a>, as well as to the specific
<!--                    <a href="#" @click.prevent="$emit('showRules')"-->
<!--                      >Challenge Rules</a-->
<!--                    >.-->
                    <a href="#" @click.prevent="$router.push('/challenges/rules/'+challenge.id)">Challenge Rules</a>.
                  </h5>

                  <h5 class="pb-2">
                    Have Questions? Check out MeterLeader's
                    <nuxt-link to="/faqs"> FAQs </nuxt-link>.
                  </h5>

                  <div class="form-group">
                    <select
                      class="form-control-lg p-2 mb-2 custom-select"
                      v-model="selectedTeam"
                      :style="{ width: '100%' }"
                    >
                      <option :value="null" selected>Select property</option>
                      <option
                        v-for="(team, index) in teams"
                        :key="`team-${index}`"
                        :value="team"
                      >
                        {{ team.name }}
                      </option>
                    </select>
                  </div>

                  <h5 class="text-left py-2">
                    Check to make sure your property is associated with the
                    correct electric and gas meters.<span>
                      <nuxt-link to="/account-settings?tab=Properties">
                        Manage properties
                      </nuxt-link>
                    </span>
                  </h5>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="$emit('selectedTeam', selectedTeam)"
                    :disabled="!selectedTeam"
                  >
                    Join Challenge
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="show = false"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { actionTypes } from '@/store/types';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    challenge: ''
  },
  data() {
    return {
      show: this.showModal,
      selectedTeam: null,
    };
  },
  watch: {
    showModal(val) {
      this.show = val;
    },
    show(val) {
      this.$emit('update:showModal', val);
    },
  },

  async mounted() {
    if (this.$auth.loggedIn) {
      await this.$store.dispatch(actionTypes.GET_TEAMS);
    }
  },

  computed: {
    ...mapState({
      teams: (state) => state.user.teams,
    }),
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.item {
  cursor: pointer;
  padding: 5px;
}

.item:hover {
  background-color: rgb(241, 241, 241);
}
</style>
