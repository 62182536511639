<template>
  <div>
    <v-chip>{{
      $options.statusMapping[`${slotParams.item.status}_`]
    }}</v-chip>
  </div>
</template>
<script>
export default {
  statusMapping: {
    '0_': 'Draft',
    '1_': 'Submitted',
    '2_': 'Returned',
    '3_': 'Approved',
    '4_': 'Launched',
    '5_': 'Started',
    '6_': 'Ended',
    '7_': 'Closed',
    '-1_': 'Removed',
  },
  props: ['slotParams'],
};
</script>