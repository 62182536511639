<template>
  <div id="faq-page">
    <div id="faq-box" class="container">
      <h1>Frequently Asked Questions</h1>

      <h4>
        We love hearing from our community! Here are some of the top questions
        we get and their answers. Still have a question? Reach out to us at
        <a href="mailto:support@meterleader.com">support@meterleader.com</a>.
      </h4>
    </div>
    <div id="faq-questions-container" class="container">
      <div v-for="(faq, sectionIndex) in faqs" class="container-fluid">
        <h3>{{ faq.title }}</h3>
        <ul>
          <li v-for="(item, itemIndex) in faq.content">
            <a
              class="accordian-toggle"
              data-toggle="collapse"
              :href="'#' + splittedTitle[sectionIndex] + itemIndex"
              role="button"
              aria-expanded="false"
              aria-controls=""
              ><font-awesome-icon icon="chevron-right" /><span
                v-html="item.question"
              ></span
            ></a>
            <div
              :id="splittedTitle[sectionIndex] + itemIndex"
              class="answer-container collapse"
            >
              <p v-html="item.answer" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  backButtonRoute: '/settings',
  data() {
    return {
      splittedTitle: [],
      faqs: [
        {
          title: 'Basics',
          content: [
            {
              question: 'Which Utilities does MeterLeader support?',
              answer: `
                    <p>MeterLeader supports the integration of electric and gas data from the following utilities:<br>
                    <strong>SCE and SoCalGas</strong></p>
                    `
            },

            {
              question: 'How does MeterLeader help users save energy?',
              answer:
                'MeterLeader helps you save energy in a number of different ways. MeterLeader Challenges motivate you to save energy because you are able to see your energy saving efforts as part of a group effort. Saving energy together with peers is motivating and empowering because you get to see the larger collective impact you are making. MeterLeader also gives you the information necessary to take action and save energy. You can easily view your daily energy, and browse through our collection of energy saving actions that include links to additional resources.',
            },
          ],
        },
        {
          title: 'Security',
          content: [
            {
              question:
                'How does MeterLeader access my utility account meter data?',
              answer: `
              <ul>
                <li>MeterLeader uses industry-standard security measures to collect and store utility account credentials and energy use data.</li>
                <li>Plaintext passwords are never stored or seen by human eyes.</li>
                <li>Utility account credentials are only used to retrieve electricity/gas consumption data, billing data, and other metadata about utility accounts (they are not used for any other purpose).</li>
                <li>MeterLeader generates a session token with credentials to access the internal APIs of utilities.</li>
                <li>These techniques used for data retrieval are the same as any other universal API company like Plaid, for example.</li>
                <li>MeterLeader processes your energy use data in 3 ways: 1) We display your usage values in your private My Usage page. 2) MeterLeader displays your energy use data in a public facing leaderboard as percentage values (% change in usage during challenge period as compared to usage during the same time period in the year prior) and as aggregated values (total combined savings values of all challenge participants). 3) If you choose to join a Challenge that is sponsored by a customer Organization we provide an anonymized data extract of the sponsored challenge results back to the customer Organization at the end of a Challenge.</li>
                <li>MeterLeader never sells data to third parties.</li>
                <li>For more details please review MeterLeader’s <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-conditions">Terms & Conditions</a></li>
              </ul>
              `,
            },
            {
              question:
                'Why do I need to disable MFA in order to connect my Utility Account to MeterLeader?',
              answer: `
              <p>Depending on the utility, Multi-Factor Authentication (MFA) or 2-Factor Authentication (2FA) cannot be on in order for MeterLeader to successfully pull your utility data. If you want to continuously see up to date and accurate energy usage data on the MeterLeader platform then you need to have MFA/2FA turned off for the duration of the time that you use MeterLeader. Remember, even with MFA/2FA turned off, your utility account is still password protected.</p>
              <p>If you want to still use MeterLeader while having your MFA/2FA on then the only option is to temporarily turn off your MFA to sync your utility account and join a challenge, and then turn your MFA back on. Then periodically throughout the challenge duration and at the end of the challenge you’d need to reconnect your utility account so that your utility data populates, and then you can turn your MFA back on. With this option, during the challenge you won’t see your daily progress towards energy savings goals.</p>
              `,
            },
            {
              question: 'Why does MeterLeader want access to my meter data?',
              answer:
                "MeterLeader believes you can't reduce what you don't measure. <a href=\"https://psmag.com/environment/mission-compostable\">Scientific studies</a> show that our values don't necessarily line up with our actions, no matter how good our intentions are. This is why, unlike other applications that rely on self reported data or pledges, MeterLeader uses real meter data to measure your progress toward reducing your energy usage. After all, the data does not lie.",
            },
            {
              question: 'Can other people see my energy usage data?',
              answer:
                "No, no one can see your exact electric usage data except yourself. If you are participating in a public facing Challenge, the only thing other people can see is the percent change in your energy usage during the Challenge period as compared to your previous year's usage during the same time period. This percent change does not reveal how much energy you actually used. <br/> In addition, your actual reduction in kWh and therm usage is integrated into the collective aggregate group's total kilowatt-hour (kWh) energy savings.",
            },
          ],
        },
        {
          title: 'Signing Up',
          content: [
            {
              question:
                'How do I connect my utility account to my MeterLeader account? ',
              answer: `
                <p>Watch this <a @click.prevent href="https://www.youtube.com/watch?v=_3RR9-iAIwI&t=27s">How To Video</a> or follow the instructions below:</p>
                <ol>
                  <li>Connect your utility account by going to your Account Settings and navigating to the Utility Accounts Page. Once you are there, click the Add Utility Account button</li>
                  <li>Click Connect your Utility Account, and then Select which Utility provider you are connecting. Input your username and password for your online Utility account. If you do not have an online utility account, please create one first with your utility.</li>
                  <li>Once you complete connecting your utility account make sure that the correct utility account is associated with the correct property by going to your Account Settings - Properties page. You will see the utility account meters you just added listed in the “Unassigned Meters” category.</li>
                  <li>If you have meters listed in the “Unassigned Meters” category, click on the “Actions” button and either assign them to an existing property or create a new property to assign them to.</li>
                </ol>

                <div>It is important to make sure that the correct utility meters are associated with the correct properties, because when you join a Challenge you will need to indicate which property is participating in the Challenge. </div>
                <div>Once you connect your utility account, MeterLeader can access 4 years worth of your historical electricity and gas usage data. This data will be updated on a daily basis and will be displayed on your My Usage page, and will also be integrated into any Challenges that you participate in.</div>
                `,
            },

            {
              question:
                'What if I have two a different provider for my electricity vs. gas going to my home?',
              answer: `
                <div>We built MeterLeader to account for these situations. In this case, you just need to go to your Account Settings - Utilities page and click on the “Add Utility” button. Go through the “Add Utility” flow twice to add both of your accounts.</div>
                <div>Once both utility accounts have been added, go to your Account Settings - Properties page. There you will see the meters from these utility accounts listed in the “Unassigned Meters” section. For each of the unassigned meters, click on the “Actions” button and assign them to an existing property or create a new property to assign them to. You cannot have more than 2 meters associated with a residential property.</div>
                <div>It is important to make sure that the correct utility meters are associated with the correct properties, because when you join a Challenge you will need to indicate which property is participating in the Challenge.</div>
              `,
            },

            {
              question:
                'How do I know what my primary heating and cooling type is?',
              answer:
                'You can learn about the different types of heating equipment <a href="https://smarterhouse.org/heating-systems/types-heating-systems" target="_blank">here</a> and cooling equipment <a href="https://smarterhouse.org/cooling-systems/types-cooling-systems" target="_blank">here</a>. Based on these descriptions you should be able to identify what type of equipment you have.<br/>If you are still having trouble determining what type of heating system and fuel type you use, google the model number and brand name of your heating and cooling equipment.',
            },
            {
              question: "How do I know what my home's square footage is?",
              answer:
                'Usually the builder, when they build the home, submits the square footage info to the city or county, and then this data is recorded in county records. You can find this information on websites like <a href="https://www.zillow.com/how-much-is-my-home-worth/">Zillow</a>, or you can <a href="https://www.zillow.com/blog/how-to-measure-your-homes-square-footage-92175/">calculate</a> your home\'s square footage.',
            },
            {
              question:
                'Can more than one person from my household participate in a Challenge?',
              answer:
                'No. At this time, MeterLeader can support only one user per utility account, so that means that only the primary utility account holder is able to sign up to participate in a Challenge. However, we encourage the the primary account holder who signs up with MeterLeader to involve everyone in their household by regularly showing them the Challenge page, forwarding them Challenge activity emails, and encouraging them to implement energy saving actions.',
            },
          ],
        },
        {
          title: 'Participating in a Challenge',
          content: [
            {
              question: 'How many people can participate in a Challenge?',
              answer:
                'We are currently limiting our Challenges to 300 participants. Unfortunately, if there are already 200 participants, you will have to wait for a new challenge to join. We are working hard to soon allow for a larger number of Challenges and participants.',
            },
            {
              question:
                'Can I join Challenges even after they have already started?',
              answer:
                'Yes, you can join a Challenge at any point during the Challenge duration period. However, it is ideal for a participant to join ahead of the start date so that they have time to plan for the energy saving actions they intend on implementing during the Challenge duration period.',
            },
            {
              question:
                'What is the difference between the individual goal and the collective goal?',
              answer:
                'The individual goal is the energy savings goal set for every participant to aim to achieve. The collective goal is the energy savings goal set for the group to achieve together. Some Challenges may offer rewards if collective goals are met.',
            },
            {
              question:
                'How can I get my friends, family, coworkers to participate in a Challenge?',
              answer:
                'The best way to get friends, family, and co-workers to participate in a MeterLeader Challenge is by sharing it through social media, email, and word of mouth. A MeterLeader Challenge is a great way to start a conversation around saving energy with your community. The more people who are participating in a Challenge the bigger collective impact we can make.',
            },
            {
              question:
                'How is my energy savings calculated and displayed in the Challenge?',
              answer: `
                <div>MeterLeader calculates energy savings as the percent change in your electricity and gas usage during the challenge period as compared to your electricity and gas usage in the previous year during the same time period.</div>
                <div>In the Challenge leaderboard these percent changes are displayed next to your username. If for whatever reason you use more energy during the Challenge period as compared to your usage in the previous year, your percent change will be displayed as 0%.</div>
                <div>On the right of the Challenge page, are aggregate group statistics including Total Number of Participants, Electricity Savings (kWh), Natural Gas Savings (therms), and CO2 Reductions (lb). Electricity Savings (kWh) and Natural Gas Savings (therms) are the aggregation of all participants' kWh and therm energy reductions.These Energy Savings are updated daily.</div>
                <div>At this time Electricity Savings (kWh) and Natural Gas Savings (therms) are not weather normalized. We hope to incorporate weather normalization in the near future.</div>
                `,
            },
            {
              question:
                'How do you calculate CO2 emissions from my energy usage?',
              answer: `
              <div>CO2 reductions are the pounds of CO2 avoided due to the amount of electricity and natural gas saved during the Challenge period. </div>
              <div>For all California Utilities integrated into the MeterLeader platform including PG&E, SCE, SoCalGas, LADWP, SDG&E, we calculate hourly CO2 emissions based on hourly CAISO data. </div>
              <div>For all other utilities integrated into the MeterLeader platform including ConEd, ComEd, PSE, BG&E, Georgia Power, and FPL, we estimate CO2 reductions by multiplying the aggregate kWh energy savings by the EPA defined e-grid values and by multiplying the aggregate therm energy savings by 11.7 pounds/therm.</div>
              `,
            },
            {
              question: 'How do I join a Teams Challenge?',
              answer: `
              <div>If an organization has created a teams challenge, you join the Challenge by taking the following steps: </div>
              <ol>
                <li>Select which property you want to participate in the Challenge.</li>
                <li>Select which team you are part of from the dropdown menu.</li>
              </ol>
              <div>If you accidentally join the Challenge under the wrong team, you must remove yourself from the Challenge and then rejoin the challenge.</div>
              `,
            },
            {
              question:
                'What happens if I increase my energy usage during the Challenge period?',
              answer:
                'Because Challenges are focused on energy savings we do not display your usage if you have in fact used more energy during the Challenge period. In such cases, we assign you 0% energy savings, as opposed to showing a negative percent values.',
            },
            {
              question: 'What happens at the end of the challenge?',
              answer:
                'Once a challenge has ended you will be notified by email. However final Challenge results will not be announced until several weeks after the Challenge has ended. This is because we need additional time for any straggling bits of data to be received.<br/>If a Challenge offers a reward for individual and collective goals, you will be notified by email if the reward has been achieved.',
            },
          ],
        },
        {
          title: 'Saving Energy',
          content: [
            {
              question: 'What is a kWh and therm?',
              answer: `
                <div>kWh stands for kilowatt-hour. It is a measure of how much energy you're using and also the metric by which utility companies charge us. A kWh is equivalent to one kilowatt (1 kW) of power expended for one hour (1 h) of time.</div>
                <div>Therm is the unit of measurement for your natural gas use over time. One therm is equal to 100,000 BTUs (British Thermal Unit) – which is the quantity of heat that’s needed to raise the temperature of one pound of water by one degree Fahrenheit.</div>
              `,
            },
            {
              question: 'How do I reduce my energy use?',
              answer:
                'MeterLeader provides you with many energy savings actions you can take to reduce your energy consumption. Take the time to review some or all of the energy saving actions, as well as the links to additional resources. Then choose the actions that are most appropriate for your household. As you implement these energy saving actions, your energy usage will decrease.',
            },
            {
              question: 'What if I am already as energy efficient as I can be?',
              answer:
                'While the vast majority of people can at least adopt a few new ways to save energy, there are a small minority of people who may already be as energy efficient as they possibly can be. For these people, we still encourage you to participate in Challenges. But you can make an even bigger indirect impact by inviting friends, family, and co-workers to join a Challenge and save energy. You can also take the time to help your friends and family save energy by showing them the actions that you implemented to become energy efficient.',
            },
            {
              question:
                'Why is my 2022 energy use so much higher or lower than my 2021 baseline energy use?',
              answer:
                'Your energy use may fluctuate for a variety of different reasons, some of which is in your control and some of which you may have less control over. For example, energy use can drastically change due to using more or less heating or air conditioning, changes in how people use energy consuming devices, change in the number of people living in the house, purchase of new or more energy using devices, etc. The best way to know for certain what might be causing such significant swings in usage is to schedule a Home Energy Audit with an energy professional.',
            },
            {
              question:
                'Which energy saving actions should I implement and how?',
              answer:
                'MeterLeader provides a large collection of energy saving actions that all include a list of more detailed resources for further reading. After reading through the actions, you should have a sense of which ones are most appropriate and applicable to your household and budget.<br/>In general, we recommend that you first start implementing the no cost and low cost energy saving actions that typically are applicable to anyone. Then we recommend that you read carefully though the energy saving actions associated with greater investment, and visit the provided website links. For any large investment, we recommend you consult a licensed professional.',
            },
          ],
        },
        {
          title: 'Account Settings',
          content: [
            {
              question: 'How do I change my notification settings?',
              answer:
                'MeterLeader sends two types of notifications - its monthly email newsletter and email notifications associated with the Challenges you are participating in. If you want to change which notifications you receive go to your Account Settings and go to the Account page. There you can indicate which notifications you would like to receive.',
            },
            {
              question: 'Can I connect more than one utility account?',
              answer:
                'Yes. You can connect as many utility accounts as you would like. However you have to make sure that all your utility account meters are associated with the correct properties. So after you connect all your utility accounts, go to Account Settings - Properties page, and make sure that each meter is assigned to the correct property. ',
            },
          ],
        },
        {
          title: 'Community',
          content: [
            {
              question:
                'How can I be an active part of the MeterLeader Community?',
              answer: `
                <div>MeterLeader relies on its Community of users to achieve energy savings and to help us continue to grow and improve our product offerings. If you want to be an active part of the MeterLeader community take the following actions: </div>
                <ol>
                  <li>Participate in MeterLeader Challenges</li>
                  <li>Invite your friends, family, and coworkers to join Challenges through social media</li>
                  <li>Follow MeterLeader on Facebook, Twitter, and Instagram.</li>
                  <li>Join MeterLeader’s Slack Community</li>
                  <li>In our Slack Community tell us how you would like to see MeterLeader improve. We love hearing your ideas and feedback, and we welcome any ways in which you would like to contribute to our mission.</li>
                </ol>
                `,
            },
            {
              question: "How can I help expand MeterLeader's reach?",
              answer: `
                If your Utility is not currently supported by MeterLeader send us an email at <a href="mailto:support@meterleader.com">support@meterleader.com</a> to tell us what utility we should add next. We will do our best to connect to your Utility in the near future. The more users that tell us they want a certain Utility to be added, the more we will prioritize adding that particular Utility. Help us grow our collective energy saving impacts by inviting friends, family, and co-workers to participate in MeterLeader Challenges.
                `,
            },
          ],
        },
        {
          title: 'Companies',
          content: [
            {
              question:
                'How do I set up a MeterLeader program for my employees?',
              answer: `
              Send us an email at <a href="mailto:support@meterleader.com">support@meterleader.com</a> and we will develop a customized program for your needs. Take a look at our <a href="https://www.meterleader.com/pricing">pricing here</a>.
              `,
            },
            {
              question:
                'How do my employees register under my corporate account?',
              answer: `
              Once you have paid for your MeterLeader subscription, your company will receive a unique corporate code. You will ask employees to input that corporate code after they sign up for MeterLeader. To input the code all they have to do is go into their Account Settings - Account Page, and underneath the “Change Affiliated Organization” section they will input the unique corporate code provided.
              `,
            },
          ],
        },
      ],
    };
  },
  created() {
    for (const faq of this.faqs) {
      this.splittedTitle.push(faq.title.split(' ')[0].toLowerCase());
    }
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      const answerContainer = $('.answer-container');
      answerContainer.click(function () {});
      answerContainer.on('show.bs.collapse', function () {
        $(this).prev().find('svg').removeClass('rot-neg-90').addClass('rot-90');
      });

      answerContainer.on('hide.bs.collapse', function () {
        $(this).prev().find('svg').removeClass('rot-90').addClass('rot-neg-90');
      });
    }
  },
  head() {
    return {
      title: 'FAQs | MeterLeader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'FAQs. How does MeterLeader access my utility account meter data? Why does MeterLeader want access to my meter data? How does MeterLeader help users save energy?',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/faq';
.rot-90 {
  /*-webkit-animation: spin 0.4s forwards;*/
  /*-moz-animation: spin 0.4s forwards;*/
  animation: spin 0.4s forwards;
}

.rot-neg-90 {
  /*-webkit-animation: spin 0.4s backwards;*/
  /*-moz-animation: spin 0.4s backwards;*/
  animation: spin 0.4s backwards;
}

/*@-moz-keyframes spin { 100% { -moz-transform: rotate(90deg); } }*/
/*@-webkit-keyframes spin { 100% { -webkit-transform: rotate(90deg); } }*/
@keyframes spin {
  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

/*@-moz-keyframes neg-spin { 100% { -moz-transform: rotate(90deg); } }*/
/*@-webkit-keyframes neg-spin { 100% { -webkit-transform: rotate(90deg); } }*/
/*@keyframes neg-spin { 100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); } }*/
//.supported-utilities table, th, td {
//  border: 1px solid black;
//  border-collapse: collapse;
//}
.supported-utilities {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.supported-utilities td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
