<template>
  <div class="leaderboard px-md-5 pb-4">
<!--    <h3 class="py-3">Leaderboard</h3>-->
    <div class="group-label d-flex align-items-center">
      <div style="flex: 6">Participants</div>
      <div style="flex: 2">Electricity</div>
      <div style="flex: 2">Gas</div>
      <div style="flex: 2">CO2</div>
      <div style="flex: 1"></div>
    </div>
    <div class="participant-group">
      <div
        v-if="participatingTeams.length <= 0"
        class="group-detail d-flex align-items-center text-center"
      >
        <p class="c-empty">No participants have yet joined the challenge</p>
      </div>
      <div
        v-else
        class="group-detail d-flex align-items-center"
        v-for="(item, index) in participatingTeams"
        :key="`lb-${index}`"
      >
        <div class="group-rank text-left" style="flex: 1">{{ index + 1 }}</div>
        <div style="flex: 5">
          <img
            class="avatar"
            :src="
              item.image_url ||
              `https://ui-avatars.com/api/?name=${item.user_name}`
            "
          />
          {{ item.user_name }}
        </div>
        <div style="flex: 2">{{ item.usage_kwh_relative | stat }}</div>
        <div style="flex: 2">{{ item.usage_thm_relative | stat }}</div>
        <div style="flex: 2" class="group-co2">
          {{ item.co2_emissions_relative | stat }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['participatingTeams', 'hasChallengeStarted', 'challengeMetric'],
  filters: {
    stat(value) {
      if (value) {
        let result = Math.round((value + Number.EPSILON) * 100);
        if (result < 0) {
          result = 0;
        }
        return `${result}%`;
      } else {
        return '-';
      }
    },
  },
  mounted() {
    if (process.client) {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    }
  },
};
</script>
<style scoped lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/variables.scss'

.avatar
  width: 100%
  height: 50px
  width: 50px
  border-radius: 50px
  margin-right: 5px

.leaderboard
  margin-top: 15px
  // padding: 30px
  background-color: #fff
  border-radius: 10px

.group-rank
  font-size: 1.5rem
  color: $accent

.group-label
  > div
    font-size: 0.7rem
    color: $medium-grey

.participant-group
  background-color: $light-grey

  border-radius: 10px
  margin-top: 5px

  > .participant-detail
    border-top: 0.3rem solid #fff
    padding: 15px
    > .group-co2
      background-color: color.scale($accent, $lightness: 60%)
      text-align: center
      padding: 15px 8px 15px 8px
      border-radius: 15px

    > .group-detail
      padding: 15px
      > div
        font-weight: 600
        > .group-co2
          background-color: $accent
          text-align: center
          padding: 15px 8px 15px 8px
          border-radius: 15px

.c-empty
  padding: 2% 2% 2% 5%
  margin-bottom: 0 !important
</style>
