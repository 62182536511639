<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="masquerade" class="ml-2">
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-btn>
      </template>
      <span>Masquerade</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ['slotParams'],
  methods: {
    async masquerade() {
      await this.$api.get(`admin/masquerade/${this.slotParams.item.id}`);
      //   let user = await this.$auth.fetchUserOnce();
      //   this.$auth.setUser(user);
      this.$router.push('/');
    },
  },
};
</script>