<template>
  <div>
    <div class="row no-gutters">
      <img :src="comment.user.photo_url" class="commenter-image"/>
      <p style="line-height: 1; margin: 10px; font-weight: bold">{{ comment.user.firstname }}</p>
    </div>
    <p style="line-height: 1; margin-top: -5px; margin-left: 50px; margin-bottom: 0" class="comment-message">{{ comment.message }}</p>
    <div style="margin-top: 5px">
      <p v-if="$auth.loggedIn" class="reply" @click="showReply = true">Reply</p>
<!--      <p v-if="$auth.loggedIn && $store.state.auth.user.admin" class="reply" style="color: red;" @click="showDeleteModal">Delete</p>-->
    </div>
    <form class="row row justify-content-around" style="margin-left: 50px; margin-bottom: 10px;" v-show="showReply">
      <textarea type="text" class="textarea form-control col-10 mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="" v-model="message"></textarea>
      <button type="button" class="btn btn-primary align-self-center mb-2" style="height: 38px; max-width: 60px;" @click="reply(comment.id)">Post</button>
    </form>
    <Comment
      style="margin-left: 50px; margin-top: 15px;"
      v-for="comment in $store.state.comments.comments.filter(c => c.parent_id == comment.id)"
      :comment="comment"
      :key="comment.id"
      :challenge-id="challengeId"
      :level="1"
    />


  </div>
</template>

<script>
  import {actionTypes} from "@/store/types";

  export default {
    name: "Comment",
    props: ['comment', 'level', 'challengeId'],
    data() {
      return {
        message: '',
        showReply: false
      }
    },
    mounted() {
      $('.textarea').on("input", function () {
        $(this).css("height", ""); //reset the height
        $(this).css("height", Math.min(jQuery(this).prop('scrollHeight'), 800) + "px");
      });
    },
    methods: {
      showDeleteModal() {
        $('#delete-comment-modal').modal('show');
      },
      async deleteComment() {

      },
      async reply(parentId) {
        if (this.message.length === 0) {
          return;
        }
        await this.$api.post(`/challenges/${this.challengeId}/comments`, {message: this.message, parent_id: parentId});
        this.showReply = false;
        await this.$store.dispatch(actionTypes.GET_COMMENTS, this.challengeId);
      }
    }
  }
</script>

<style scoped lang="scss">
  .commenter-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;

  img {
    height: 100%;
  }
  }
  .reply {
    display: inline;
    margin-left: 50px;
    cursor: pointer;
    color: #007bff;
  }
</style>
