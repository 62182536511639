<template>
  <div>
    <v-card :loading="loading" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="green accent-1"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-text>
        <form @submit.prevent="$emit('update', formData)">
          <v-text-field
            v-model="formData.name"
            label="Organization name"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.corporate_code"
            label="Corporate code"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.maximum_pelm_subscription_allowed"
            label="Pelm subscription cap"
          ></v-text-field>

          <UserSelect
            label="Organization admin"
            class="pr-2"
            :value="formData.admin_id"
            @onSelect="formData.admin_id = $event"
          ></UserSelect>

          <v-select
            class="pt-4"
            :items="['Annual', 'Monthly']"
            label="Subscription type"
            v-model="formData.subscription_plan_type"
          ></v-select>

          <DatetimePicker
            label="Subscription Plan Expiry Datetime"
            v-model="formData.subscription_paln_expiry_datetime"
          >
          </DatetimePicker>

          <v-btn color="green accent-2" class="mr-4" @click="submit">
            {{ formData.id ? 'Update' : 'Create' }}
          </v-btn>
          <button ref="submit" style="display: none">Submit</button>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import UserSelect from '../UserSelect.vue';
import DatetimePicker from '@/components/admind/commons/DatetimePicker.vue';

export default {
  components: {
    UserSelect,
    DatetimePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    initial: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {},
    };
  },

  methods: {
    submit() {
      this.$refs.submit.click();
    },
  },

  watch: {
    initial(newVal) {
      this.formData = { ...newVal };
    },
  },
};
</script>