<template>
  <div>
    <template>
      <v-data-table
        :headers="headers"
        :items="filteredResults"
        disable-pagination
        hide-default-footer
        class="elevation-1"
        :loading="loading"
      ></v-data-table>
    </template>
  </div>
</template>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  props: {
    taskStage: {
      type: Number,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
  },
  data() {
    return {
      loading: false,
      results: [],
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Start time',
          align: 'center',
          sortable: false,
          value: 'time_start',
        },
        {
          text: 'Acknowledged',
          align: 'center',
          sortable: false,
          value: 'acknowledged',
        },
        {
          text: 'ETA',
          align: 'right',
          sortable: false,
          value: 'eta',
        },
        {
          text: 'Status',
          align: 'right',
          sortable: false,
          value: 'status',
        },
      ],
    };
  },
  computed: {
    filteredResults() {
      let tabOptions = ['all', 'active', 'reserved', 'scheduled'];
      let filterParam = tabOptions[this.taskStage];
      if (filterParam !== 'all') {
        return this.results.filter((obj) => {
          return obj.status == filterParam;
        });
      }
      return this.results;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        let data = await this.$api.$get('admind/background-tasks');
        this.results = data;
      } catch (err) {
        console.error(err);
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>