<template>
  <div id="settings-page">
    <OnboardingReminder />
    <div id="page" class="container">
      <h1>Settings</h1>
      <div id="account-settings-filter" class="container-fluid">
        <ul>
          <li class="activeFilter">
            <nuxt-link to="/account-settings"> Account </nuxt-link>
          </li>
          <li class="activeFilter">
            <nuxt-link to="/account-settings/notifications">
              Notifications
            </nuxt-link>
          </li>
          <li class="activeFilter">
            <nuxt-link to="/account-settings/utility">
              Utility Accounts
            </nuxt-link>
          </li>
          <li :class="['activeFilter', isTeamsEnabled ? '' : 'right-filter']">
            <nuxt-link to="/account-settings/meter"> Properties </nuxt-link>
          </li>
          <li v-if="isTeamsEnabled" class="activeFilter right-filter">
            <nuxt-link to="/account-settings/team"> Team Settings </nuxt-link>
          </li>
        </ul>
      </div>
      <div id="account-content">
        <nuxt-child />
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingReminder from '@/components/OnboardingReminder';
import { actionTypes } from '@/store/types';

export default {
  name: 'AccountSettings',
  middleware: ['auth'],
  components: { OnboardingReminder },
  async fetch({ store }) {
    await Promise.all([
      store.dispatch(actionTypes.GET_TEAMS),
      // store.dispatch(actionTypes.GET_UTILITY_ACCOUNTS),
      store.dispatch(actionTypes.GET_USER_PROFILE),
    ]);
  },
  computed: {
    isTeamsEnabled() {
      return TEAMS;
    },
  },
  mounted() {
    if (this.$route.name === 'account-settings') {
      this.$router.push('account');
    }
  },
  head() {
    return {
      title: 'Account Settings | MeterLeader',
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/accountSettings.scss';
</style>
