<template>
  <div class="root">
    <div class="container d-flex">
      <div class="d-none d-lg-block" style="padding-top: 6px;">
        <div class="d-flex align-items-center">
          <font-awesome-icon
            v-if="$titleBar.data.backButtonRoute"
            class="icon h2 mb-0"
            icon="angle-left"
            @click="$router.back()"
          />
          <div class="flex-grow-1"></div>
        </div>
      </div>
      <div class="column1">
        <img class="challenge-image" :src="challenge.image_banner_url" />
        <h3 class="py-4">{{ challenge.name }}</h3>
        <div class="challenge-details">
          <div class="d-flex align-items-center">
            <h3>Details</h3>
            <div :style="{ flexGrow: 1 }"></div>
            <button class="rules-btn" @click="$router.push('/challenges/rules/'+challenge.id)">
              <font-awesome-icon class="pr-2" icon="scroll" size="sm" />Rules
            </button>
          </div>
          <div class="mt-4">
            <b>Starts:</b> {{ startDate }}
          </div>
          <div class="mb-4">
            <b>Ends:</b> {{ endDate }}
          </div>
          <div>{{ timeLeft }}</div>

          <div class="mb-4">
            {{ challenge.description }}
          </div>
          <div class="organizer d-flex">
            <img class="avatar mt-3" :src="challenge.image_creator_url" />
            <div class="ml-3">
              <small class="font-weight-bold primary-text">Organizer</small>
              <p class="font-weight-bold mb-0">{{ challenge.sponsor_name }}</p>
              <small class="grey-text"
                >Created on {{ challenge.created_dtm | formatDateOnly }}</small
              >
              <br />
              <br />
              <u
                ><a
                  v-if="challenge.sponsor_website"
                  :href="makeUrl(challenge.sponsor_website)"
                  class="link"
                  >Link 1</a
                ></u
              >
              <br />
              <u
                ><a
                  v-if="challenge.sponsor_facebook"
                  :href="makeUrl(challenge.sponsor_facebook)"
                  class="link"
                  >Link 2</a
                ></u
              >
              <br />
              <u
                ><a
                  v-if="challenge.sponsor_twitter"
                  :href="makeUrl(challenge.sponsor_twitter)"
                  class="link"
                  >Link 3</a
                ></u
              >
            </div>
          </div>
        </div>
      </div>
      <div class="column2">
        <div class="challenge-actions">
          <div class="d-flex">
            <div>
              <p class="mb-1 font-weight-bold">
                {{ Math.round(challenge.savings_co2 || 0) }}
                <span><small class="font-weight-leight">lbs</small></span>
              </p>
            </div>
            <div class="flex-grow-1"></div>
            <div>
              <p class="mb-1 font-weight-light">
                {{
                  Math.round((Math.round(challenge.savings_co2 || 0) * 100) /
                  challenge.savings_goal)
                }}% of {{ challenge.savings_goal }} lbs CO<sub>2</sub> goal
              </p>
            </div>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${
                (Math.round(challenge.savings_co2 || 0) * 100) /
                challenge.savings_goal
              }%`"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="text-center">
            <button
              v-if="!isUserInChallenge"
              @click="joinClick"
              class="join-challenge-btn"
            >
              Join Challenge
            </button>
            <button
              v-else-if="$auth.loggedIn"
              data-toggle="modal"
              data-target="#leaveModal"
              class="join-challenge-btn participating-btn"
            >
              <img src="~/assets/images/whiteCheckmark.png"> <span class="participating">Participating</span>
            </button>
          </div>
<!--          <div class="d-flex justify-content-around">-->
<!--            <button class="share-btn">Copy Link</button>-->
<!--            <button class="share-btn">Share</button>-->
<!--          </div>-->
          <div class="challenge-stats d-flex justify-content-around">
            <div class="text-center">
              <div>👤</div>
              <div class="font-weight-bold">
                {{ challenge.current_participants }}
              </div>
              <div><p class="vs-text grey-text">Participants</p></div>
            </div>
            <div class="text-center">
              <div>⚡</div>
              <div class="font-weight-bold">
                {{ Math.ceil(challenge.savings_kwh) || '-' }}
              </div>
              <div><p class="vs-text grey-text">Electricity (kWh)</p></div>
            </div>
            <div class="text-center">
              <div>🔥</div>
              <div class="font-weight-bold">
                {{ Math.ceil(challenge.savings_thm) || '-' }}
              </div>
              <div><p class="vs-text grey-text">Gas (therm)</p></div>
            </div>
            <div class="text-center">
              <div>☁️</div>
              <div class="font-weight-bold">
                {{ Math.ceil(challenge.savings_co2) || '-' }}
              </div>
              <div><p class="vs-text grey-text">CO2 (lbs)</p></div>
            </div>
          </div>

          <div class="mx-3 mt-3">
            <p :style="{ fontSize: '0.72rem' }">
              *Data will be displayed once there are at least 5 eligible
              participants. <a class="link" href="#">Learn More</a>
            </p>
          </div>
        </div>
        <ChallengeLeaderBoard
          :participating-teams="participatingTeams"
          :has-challenge-started="hasChallengeStarted"
          :challenge-metric="challenge.metric"
        />
      </div>

      <SelectTeam
        :challenge="this.challenge"
        :showModal.sync="showTeamSelect"
        @selectedTeam="($event) => selectedTeamPromiseResolve($event)"
        @showRules="showRules"
      />
      <SelectParticipantGroup
        :showModal.sync="showParticipantGroupSelect"
        :challengeId="$route.params.challenge"
        @selectedParticipantGroup="
          ($event) => selectedParticipantGroupResolve($event)
        "
      />
    </div>
  </div>
</template>
  <script>
import challengeMixin from '@/mixins/challenge';
import ChallengeLeaderBoard from '../NewChallengeLeaderBoard.vue';
export default {
  backButtonRoute: '/challenges',
  mixins: [challengeMixin],
  data() {
    return {};
  },
  components: { ChallengeLeaderBoard },
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.root
  background: color.scale($grey, $lightness: 70%)

.vs-text
  font-size: 0.7rem

.primary-text
  color: $accent

.grey-text
  color: $medium-grey

.rules-btn
  background-color: $light-grey
  padding: 5px
  border-radius: 5px

.join-challenge-btn
  padding: 15px 80px 15px 80px
  width: 100%
  font-weight: 500
  color: #fff
  background: linear-gradient(to right, $accent, $primary)
  border-radius: 50px
  margin: 10px 0px 10px 0px

.share-btn
  width: 100%
  color: $accent
  padding: 5px 10px 5px 10px
  border: 1.2px solid $accent
  border-radius: 50px
  margin: 5px

.avatar
  width: 100%
  height: 40px
  width: 40px
  border-radius: 50px
  margin: 5px

.challenge-image
  height: 200px
  width: 100%
  object-fit: cover

.challenge-details
  border-radius: 10px
  background-color: #fff
  padding: 30px

.column1
  flex: 1
  margin: 10px

.column2
  flex: 1
  margin: 10px

.challenge-actions
  padding: 30px
  background-color: #fff
  border-radius: 10px
  > .progress

    margin-bottom: 10px
    height: 27px
    border-radius: 18px
    > .progress-bar
      background-color: $accent
      border-radius: 18px

.link
  color: $primary

.challenge-stats
  padding-top: 20px

.leaderboard
  margin-top: 15px
  padding: 30px
  background-color: #fff
  border-radius: 10px

.participating-btn
  width: 100%
  background: #1AD596
  padding: 5px 40px 5px 40px
  > img
    width: 8%
</style>
