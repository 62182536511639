<template>
  <div id="usage-page">
    <OnboardingReminder />

    <div id="usage-title" class="container">
      <h1>My Usage</h1>
    </div>

    <div class="container">
      <div id="usage-top" class="row d-none d-md-flex">
        <div id="usage-description" class="col-md-8 col-12">
          <p>
            The below graph displays 4 years worth of your home's electricity,
            gas, and CO2 output by hour, week, month and year. Please note that
            your usage data may be 24-48 hours behind the current date. And if
            you are a customer of a utility without smart meters (LADWP) your
            usage data may be 4-6 weeks behind the current date, and your
            historical hourly and daily usage is based on your billing period
            usage divided evenly by the number of days in the billing period.
            CO2 values for CA utilities are based on interval CAISO data, while
            CO2 values for all other utilities are calculated by multiplying
            usage by EPA e-grid values.
          </p>
        </div>
        <div id="stats" class="col-md-4 col-12">
          <p>
            <strong>Average Home Energy Use</strong><br />
            897 kWh/month - Avg. Home in US<br />
            557 kWh/month - Avg. Home in CA<br />
            316 kWh/month - Avg. Home in SF
          </p>
        </div>
      </div>

      <div
        style="width: 30rem"
        id="select-meter-filter"
        class="mb-4 form-group d-lg-none"
      >
        <select
          id="meterSelect"
          v-model="filterParams.energy_account_id"
          class="custom-select"
          placeholder="Please select a meter"
        >
          <option disabled selected :value="null">Please select a meter</option>

          <optgroup
            v-for="group in Object.keys(addressMeters)"
            :key="group"
            :label="group"
          >
            <option
              v-for="meter in addressMeters[group]"
              :key="`${meter.id}pelm-meter`"
              :value="meter.id"
            >
              <div>
                {{ meter.label }}
              </div>
            </option>
          </optgroup>
        </select>
      </div>

      <div class="d-flex align-items-center">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div
              class="px-3 py-2 border h6"
              :class="{ 'selected-item': selectedPeriod == 'daily' }"
              @click="selectedPeriod = 'daily'"
            >
              Hourly
            </div>
            <div
              class="px-3 py-2 border h6"
              :class="{ 'selected-item': selectedPeriod == 'weekly' }"
              @click="selectedPeriod = 'weekly'"
            >
              Daily (by Week)
            </div>
            <div
              class="px-3 py-2 border h6"
              :class="{ 'selected-item': selectedPeriod == 'monthly' }"
              @click="selectedPeriod = 'monthly'"
            >
              Daily (by Month)
            </div>
            <div
              class="px-3 py-2 border h6"
              :class="{ 'selected-item': selectedPeriod == 'yearly' }"
              @click="selectedPeriod = 'yearly'"
            >
              Monthly
            </div>
            <div
              class="px-3 py-2 border h6"
              :class="{ 'selected-item': selectedPeriod == 'demiDecade' }"
              @click="selectedPeriod = 'demiDecade'"
            >
              Yearly
            </div>
          </div>

          <div class="d-flex">
            <div
              v-if="selectedMeterSupportedTypes.includes('ELECTRIC')"
              class="px-3 py-2 border h6"
              :class="{
                'selected-item':
                  filterParams.usage_unit == 'kwh' &&
                  !filterParams.show_emission,
              }"
              @click="
                filterParams.usage_unit = 'kwh';
                filterParams.show_emission = false;
              "
            >
              ELECTRIC
            </div>
            <div
              v-if="selectedMeterSupportedTypes.includes('GAS')"
              class="px-3 py-2 border h6"
              :class="{
                'selected-item':
                  filterParams.usage_unit == 'therm' &&
                  !filterParams.show_emission,
              }"
              @click="
                filterParams.usage_unit = 'therm';
                filterParams.show_emission = false;
              "
            >
              GAS
            </div>
            <div
              v-if="selectedMeterSupportedTypes.includes('ELECTRIC')"
              class="px-3 py-2 border h6"
              :class="{
                'selected-item':
                  filterParams.usage_unit == 'kwh' &&
                  filterParams.show_emission,
              }"
              @click="
                filterParams.usage_unit = 'kwh';
                filterParams.show_emission = true;
              "
            >
              CO2 ELECTRIC
            </div>
            <div
              v-if="selectedMeterSupportedTypes.includes('GAS')"
              class="px-3 py-2 border h6"
              :class="{
                'selected-item':
                  filterParams.usage_unit == 'therm' &&
                  filterParams.show_emission,
              }"
              @click="
                filterParams.usage_unit = 'therm';
                filterParams.show_emission = true;
              "
            >
              CO2 GAS
            </div>
          </div>
        </div>
        <div :style="{ flexGrow: 1 }"></div>
        <div class="d-none d-lg-block">
          <div id="select-meter-filter" class="form-group">
            <select
              id="meterSelect"
              v-model="filterParams.energy_account_id"
              class="custom-select"
              placeholder="Please select a meter"
            >
              <option disabled selected :value="null">
                Please select a meter
              </option>

              <optgroup
                v-for="group in Object.keys(addressMeters)"
                :key="group"
                :label="group"
              >
                <option
                  v-for="meter in addressMeters[group]"
                  :key="`${meter.id}pelm-meter`"
                  :value="meter.id"
                >
                  <div>
                    {{ meter.label }}
                  </div>
                </option>
              </optgroup>
            </select>
          </div>
          <div class="d-flex align-items-center">
            <font-awesome-icon
              id="left-arrow"
              icon="chevron-left"
              :class="['arrow', false ? 'disabled' : '']"
              @click="setRangeFilter('left')"
            />
            <div class="px-2">
              {{ periodsConfig[this.selectedPeriod].displayLabel }}
            </div>
            <font-awesome-icon
              id="right-arrow"
              icon="chevron-right"
              :class="['arrow', false ? 'disabled' : '']"
              @click="setRangeFilter('right')"
            />
          </div>
        </div>
      </div>

      <div class="my-3 d-flex d-lg-none align-items-center">
        <font-awesome-icon
          id="left-arrow"
          icon="chevron-left"
          :class="['arrow', false ? 'disabled' : '']"
          @click="setRangeFilter('left')"
        />
        <div class="px-2">
          {{ periodsConfig[this.selectedPeriod].displayLabel }}
        </div>
        <font-awesome-icon
          id="right-arrow"
          icon="chevron-right"
          :class="['arrow', false ? 'disabled' : '']"
          @click="setRangeFilter('right')"
        />
      </div>

      <UserUsageChart
        :dataSet="periodsConfig[this.selectedPeriod].dataSet"
        :totalSamplesCount="
          periodsConfig[this.selectedPeriod].totalSamplesCount
        "
      />
    </div>
  </div>
</template>

<script>
import OnboardingReminder from '../components/OnboardingReminder';
import { actionTypes } from '@/store/types';
import { DateTime } from 'luxon';
import Chart from 'chart.js';
import UserUsageChart from '@/components/UserUsageChart.vue';

// TODO monthly december should be pushed towards end
// TODO monthly yearly and weekly forward button not working
// TODO while switching period getting weired
// TODO months data are inverted (done)

let unitToTypeMapping = {
  therm: 'gas',
  kwh: 'electric',
};

class DemiDecadePeriodFilterParam {
  constructor() {
    this.url = 'pelm/daily-user-graph-data';

    let today = DateTime.now().toObject();

    this.from_year = today.year - 5;
    this.to_year = today.year;
    this.from_month = 1;
    this.to_month = 12;

    this.dataSet = {
      labels: [],
      datasets: [
        {
          label: 'N/A',
          data: [],
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get totalSamplesCount() {
    if (!this.vm) {
      return 1;
    }

    let start = DateTime.fromISO(
      this.vm.selectedMeter[
        `start_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let end = DateTime.fromISO(
      this.vm.selectedMeter[
        `last_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let dataStartYear = this.from_year;
    let dataEndYear = this.to_year;

    if (start.year > this.from_year) {
      dataStartYear = start.year;
    }

    if (end.year < this.to_year) {
      dataEndYear = end.year;
    }

    return dataEndYear - dataStartYear + 1;
  }

  processData(res, vm) {
    let labels = [];
    let data = [];

    let start = DateTime.local(this.from_year, this.from_month, 1);
    let end = DateTime.local(this.to_year, this.to_month, 1);

    while (start.ts <= end.ts) {
      let key = start.toFormat('yyyy');

      let value = 0;

      for (let keyIndex in res) {
        if (keyIndex.startsWith(key)) {
          value += res[keyIndex];
        }
      }

      labels.push(start.toFormat('yyyy').toUpperCase());
      data.push(value.toFixed(2) || 0);

      start = start.plus({ months: 12 });
    }

    this.dataSet = {
      labels: labels,
      datasets: [
        {
          label: vm.getGraphLabel(),
          data: data,
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get displayLabel() {
    return `${this.from_year} - ${this.to_year}`;
  }

  changeInitialContext(vm) {
    if (vm.selectedMeter && vm.filterParams.usage_unit) {
      let today = DateTime.fromISO(
        vm.selectedMeter[
          `last_sync_date_${unitToTypeMapping[vm.filterParams.usage_unit]}`
        ]
      );
      this.from_year = today.year - 5;
      this.to_year = today.year;
      this.from_month = 1;
      this.to_month = 12;
    }
  }

  async fetchData(vm, filterParams) {
    this.vm = vm;
    this.changeInitialContext(vm);
    let fromDate = DateTime.local(this.from_year, this.from_month, 1);
    let firstDayLastMonth = DateTime.local(this.to_year, this.to_month, 1);
    let toDate = firstDayLastMonth.plus({
      days: firstDayLastMonth.daysInMonth - 1,
    });

    let data = await vm.$api.$get(this.url, {
      params: {
        ...filterParams,
        from_date: fromDate.toFormat('yyyy-MM-dd'),
        to_date: toDate.toFormat('yyyy-MM-dd'),
      },
    });

    this.processData(data, vm);
  }

  get disableRight() {
    let today = DateTime.now().toObject();

    return this.to_year == today.year;
  }

  next() {
    if (!this.disableRight) {
      this.from_year = this.from_year + 5;
      this.to_year = this.to_year + 5;
    }
  }
  previous() {
    this.from_year = this.from_year - 5;
    this.to_year = this.to_year - 5;
  }
}

class YearlyPeriodFilterParam {
  constructor() {
    this.url = 'pelm/daily-user-graph-data';
    this.lastSyncDateIsSet = false;

    let today = DateTime.now().toObject();

    this.from_year = today.year;
    this.to_year = today.year;
    this.from_month = 1;
    this.to_month = 12;

    this.dataSet = {
      labels: [],
      datasets: [
        {
          label: 'N/A',
          data: [],
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  processData(res, vm) {
    let labels = [];
    let data = [];

    let start = DateTime.local(this.from_year, this.from_month, 1);
    let end = DateTime.local(this.to_year, this.to_month, 1);

    while (start.ts <= end.ts) {
      let key = start.toFormat('yyyy-MM');

      let value = 0;

      for (let keyIndex in res) {
        if (keyIndex.startsWith(key)) {
          value += res[keyIndex];
        }
      }

      labels.push(start.toFormat('LLL').toUpperCase());
      data.push(value.toFixed(2) || 0);

      start = start.plus({ days: start.daysInMonth });
    }

    this.dataSet = {
      labels: labels,
      datasets: [
        {
          label: vm.getGraphLabel(),
          data: data,
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get displayLabel() {
    return `${this.from_year}`;
  }

  get totalSamplesCount() {
    if (!this.vm) {
      return 1;
    }

    let meterDataStart = DateTime.fromISO(
      this.vm.selectedMeter[
        `start_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    ).set({ day: 1 });

    let meterDataEnd = DateTime.fromISO(
      this.vm.selectedMeter[
        `last_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    ).set({ day: 1 });

    let start = DateTime.local(this.from_year, this.from_month, 1);
    let end = DateTime.local(this.to_year, this.to_month, 1);

    if (meterDataStart > start) {
      start = meterDataStart;
    }

    if (meterDataEnd < end) {
      end = meterDataEnd;
    }

    return Math.ceil(end.diff(start, 'months').months) + 1;
  }

  changeInitialContext(vm) {
    this.lastSyncDateIsSet = true;
    if (vm.selectedMeter && vm.filterParams.usage_unit) {
      let today = DateTime.fromISO(
        vm.selectedMeter[
          `last_sync_date_${unitToTypeMapping[vm.filterParams.usage_unit]}`
        ]
      );
      this.from_year = today.year;
      this.to_year = today.year;
      this.from_month = 1;
      this.to_month = 12;
    }
  }

  async fetchData(vm, filterParams) {
    this.vm = vm;
    if (!this.lastSyncDateIsSet) {
      this.changeInitialContext(vm);
    }
    let fromDate = DateTime.local(this.from_year, this.from_month, 1);
    let firstDayLastMonth = DateTime.local(this.to_year, this.to_month, 1);
    let toDate = firstDayLastMonth.plus({
      days: firstDayLastMonth.daysInMonth - 1,
    });

    let data = await vm.$api.$get(this.url, {
      params: {
        ...filterParams,
        from_date: fromDate.toFormat('yyyy-MM-dd'),
        to_date: toDate.toFormat('yyyy-MM-dd'),
      },
    });

    this.processData(data, vm);
  }

  get disableRight() {
    let today = DateTime.now().toObject();

    return this.from_year == today.year;
  }

  next() {
    if (!this.disableRight) {
      this.from_year = this.from_year + 1;
      this.to_year = this.to_year + 1;
    }
  }
  previous() {
    this.from_year = this.from_year - 1;
    this.to_year = this.to_year - 1;
  }
}

class DailyPeriodFilterParam {
  constructor() {
    this.url = 'pelm/hourly-user-graph-data';
    this.lastSyncDateIsSet = false;

    this.currentDateTime = DateTime.now();
    this.usage_date = this.currentDateTime.toFormat('yyyy-MM-dd');
    this.dataSet = {
      labels: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
      datasets: [
        {
          label: 'N/A',
          data: [],
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get displayLabel() {
    return this.currentDateTime.toLocaleString(DateTime.DATE_MED);
  }

  get totalSamplesCount() {
    return 24;
  }

  changeInitialContext(vm) {
    this.lastSyncDateIsSet = true;
    if (vm.selectedMeter && vm.filterParams.usage_unit) {
      let today = DateTime.fromISO(
        vm.selectedMeter[
          `last_sync_date_${unitToTypeMapping[vm.filterParams.usage_unit]}`
        ]
      );
      this.currentDateTime = today;
      this.usage_date = this.currentDateTime.toFormat('yyyy-MM-dd');
    }
  }

  async fetchData(vm, filterParams) {
    if (!this.lastSyncDateIsSet) {
      this.changeInitialContext(vm);
    }
    let res = await vm.$api.get(this.url, {
      params: {
        ...filterParams,
        usage_date: this.usage_date,
      },
    });

    let hourlyAgrregatedValues = [];

    let graphValues = Object.values(res.data);

    if (graphValues.length == 24 * 4) {
      let startIndex = 0;
      while (startIndex < graphValues.length) {
        hourlyAgrregatedValues.push(
          graphValues
            .slice(startIndex, startIndex + 4)
            .reduce((a, b) => a + b, 0)
        );
        startIndex += 4;
      }

      if (hourlyAgrregatedValues.length > 0) {
        console.log(hourlyAgrregatedValues);
        graphValues = hourlyAgrregatedValues;
      }
    }

    this.dataSet = {
      labels: this.dataSet.labels,
      datasets: [
        {
          label: vm.getGraphLabel(),
          data: graphValues.map((o) => o.toFixed(2)),
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get disableRight() {
    return (
      DateTime.now().toFormat('yyyy-MM-dd') ==
      this.currentDateTime.toFormat('yyyy-MM-dd')
    );
  }

  next() {
    if (!this.disableRight) {
      this.currentDateTime = this.currentDateTime.plus({ days: 1 });
      this.usage_date = this.currentDateTime.toFormat('yyyy-MM-dd');
    }
  }
  previous() {
    this.currentDateTime = this.currentDateTime.plus({ days: -1 });
    this.usage_date = this.currentDateTime.toFormat('yyyy-MM-dd');
  }
}

class WeeklyPeriodFilterParam {
  constructor() {
    this.url = 'pelm/daily-user-graph-data';
    this.lastSyncDateIsSet = false;
    let today = DateTime.now();
    this.from_date = today.startOf('week');
    this.to_date = this.from_date.plus({ days: 6 });
    this.dataSet = {
      labels: [],
      datasets: [
        {
          label: 'N/A',
          data: [],
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get displayLabel() {
    return `${this.from_date.toLocaleString(
      DateTime.DATE_MED
    )} - ${this.to_date.toLocaleString(DateTime.DATE_MED)}`;
  }

  processData(res, vm) {
    let labels = [];
    let data = [];

    let start = this.from_date;

    let end = this.to_date;

    while (start.ts <= end.ts) {
      let key = start.toFormat('yyyy-MM-dd');

      let value = res[key] || 0;

      labels.push(start.toFormat('EEE').toUpperCase());
      data.push(value.toFixed(2) || 0);

      start = start.plus({ days: 1 });
    }

    this.dataSet = {
      labels: labels,
      datasets: [
        {
          label: vm.getGraphLabel(),
          data: data,
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  changeInitialContext(vm) {
    this.lastSyncDateIsSet = true;
    if (vm.selectedMeter && vm.filterParams.usage_unit) {
      let today = DateTime.fromISO(
        vm.selectedMeter[
          `last_sync_date_${unitToTypeMapping[vm.filterParams.usage_unit]}`
        ]
      );
      this.from_date = today.startOf('week');
      this.to_date = this.from_date.plus({ days: 6 });
    }
  }

  get totalSamplesCount() {
    if (!this.vm) {
      return 1;
    }

    let meterDataStart = DateTime.fromISO(
      this.vm.selectedMeter[
        `start_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let meterDataEnd = DateTime.fromISO(
      this.vm.selectedMeter[
        `last_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let start = this.from_date;
    let end = this.to_date;

    if (meterDataStart > start) {
      start = meterDataStart;
    }

    if (meterDataEnd < end) {
      end = meterDataEnd;
    }

    return Math.ceil(end.diff(start, 'days').days) + 1;
  }

  async fetchData(vm, filterParams) {
    this.vm = vm;
    if (!this.lastSyncDateIsSet) {
      this.changeInitialContext(vm);
    }
    let data = await vm.$api.$get(this.url, {
      params: {
        ...filterParams,
        from_date: this.from_date.toFormat('yyyy-MM-dd'),
        to_date: this.to_date.toFormat('yyyy-MM-dd'),
      },
    });

    this.processData(data, vm);
  }

  get disableRight() {
    return DateTime.now().endOf('week').ts == this.to_date.ts;
  }

  next() {
    if (!this.disableRight) {
      this.from_date = this.from_date.plus({ days: 7 });
      this.to_date = this.to_date.plus({ days: 7 });
    }
  }

  previous() {
    this.from_date = this.from_date.plus({ days: -7 });
    this.to_date = this.to_date.plus({ days: -7 });
  }
}

class MonthlyPeriodFilterParam {
  constructor() {
    this.url = 'pelm/daily-user-graph-data';
    this.lastSyncDateIsSet = false;
    let today = DateTime.now();
    this.from_date = today.startOf('month');
    this.to_date = this.from_date.plus({
      days: this.from_date.daysInMonth - 1,
    });

    this.dataSet = {
      labels: [],
      datasets: [
        {
          label: 'N/A',
          data: [],
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  get displayLabel() {
    return `${this.from_date.toLocaleString(
      DateTime.DATE_MED
    )} - ${this.to_date.toLocaleString(DateTime.DATE_MED)}`;
  }

  processData(res, vm) {
    let labels = [];
    let data = [];

    let start = this.from_date;

    let end = this.to_date;

    while (start.ts <= end.ts) {
      let key = start.toFormat('yyyy-MM-dd');

      let value = res[key] || 0;

      labels.push(start.toFormat('dd').toUpperCase());
      data.push(value.toFixed(2) || 0);

      start = start.plus({ days: 1 });
    }

    this.dataSet = {
      labels: labels,
      datasets: [
        {
          label: vm.getGraphLabel(),
          data: data,
          backgroundColor: '#C200FB',
          borderColor: '#C200FB',
          borderWidth: 1,
        },
      ],
    };
  }

  changeInitialContext(vm) {
    this.lastSyncDateIsSet = true;
    if (vm.selectedMeter && vm.filterParams.usage_unit) {
      let today = DateTime.fromISO(
        vm.selectedMeter[
          `last_sync_date_${unitToTypeMapping[vm.filterParams.usage_unit]}`
        ]
      );
      this.from_date = today.startOf('month');
      this.to_date = this.from_date.plus({
        days: this.from_date.daysInMonth - 1,
      });
    }
  }

  get totalSamplesCount() {
    if (!this.vm) {
      return 1;
    }

    let meterDataStart = DateTime.fromISO(
      this.vm.selectedMeter[
        `start_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let meterDataEnd = DateTime.fromISO(
      this.vm.selectedMeter[
        `last_sync_date_${unitToTypeMapping[this.vm.filterParams.usage_unit]}`
      ]
    );

    let start = this.from_date;
    let end = this.to_date;

    if (meterDataStart > start) {
      start = meterDataStart;
    }

    if (meterDataEnd < end) {
      end = meterDataEnd;
    }

    return Math.ceil(end.diff(start, 'days').days) + 1;
  }

  async fetchData(vm, filterParams) {
    if (!this.lastSyncDateIsSet) {
      this.changeInitialContext(vm);
    }
    this.vm = vm;
    let data = await vm.$api.$get(this.url, {
      params: {
        ...filterParams,
        from_date: this.from_date.toFormat('yyyy-MM-dd'),
        to_date: this.to_date.toFormat('yyyy-MM-dd'),
      },
    });

    this.processData(data, vm);
  }

  get disableRight() {
    return (
      DateTime.now().endOf('month').toFormat('yyyy_MM') ==
      this.to_date.toFormat('yyyy_MM')
    );
  }

  next() {
    if (!this.disableRight) {
      this.from_date = this.from_date.plus({
        days: this.from_date.daysInMonth,
      });
      this.to_date = this.to_date.plus({ days: this.from_date.daysInMonth });
    }
  }

  previous() {
    let lastMonthDate = this.from_date.plus({ days: -1 });
    this.from_date = lastMonthDate.startOf('month');
    this.to_date = this.from_date.plus({
      days: this.from_date.daysInMonth - 1,
    });
  }
}

export default {
  name: 'Usage',
  components: { OnboardingReminder, UserUsageChart },
  middleware: ['auth'],
  periodDefaultFilterParams: {},
  constants: {
    meterTypeUsageUnit: {
      ELECTRIC: 'kwh',
      GAS: 'therm',
    },
  },
  data() {
    return {
      filterParams: {
        usage_unit: 'kwh',
        show_emission: false,
        energy_account_id: null,
      },
      selectedPeriod: 'monthly',
      userPelmEnergyAccounts: [],
      periodsConfig: {
        daily: new DailyPeriodFilterParam(),
        weekly: new WeeklyPeriodFilterParam(),
        monthly: new MonthlyPeriodFilterParam(),
        yearly: new YearlyPeriodFilterParam(),
        demiDecade: new DemiDecadePeriodFilterParam(),
      },
      graphData: {
        labels: [],
        data: [],
      },
    };
  },
  watch: {
    'filterParams.usage_unit': {
      handler: function () {
        this.periodsConfig = {
          daily: new DailyPeriodFilterParam(),
          weekly: new WeeklyPeriodFilterParam(),
          monthly: new MonthlyPeriodFilterParam(),
          yearly: new YearlyPeriodFilterParam(),
          demiDecade: new DemiDecadePeriodFilterParam(),
        };
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
      },
    },
    'filterParams.show_emission': {
      handler: function () {
        this.periodsConfig = {
          daily: new DailyPeriodFilterParam(),
          weekly: new WeeklyPeriodFilterParam(),
          monthly: new MonthlyPeriodFilterParam(),
          yearly: new YearlyPeriodFilterParam(),
          demiDecade: new DemiDecadePeriodFilterParam(),
        };
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
      },
    },
    'filterParams.energy_account_id': {
      handler: function () {
        this.periodsConfig = {
          daily: new DailyPeriodFilterParam(),
          weekly: new WeeklyPeriodFilterParam(),
          monthly: new MonthlyPeriodFilterParam(),
          yearly: new YearlyPeriodFilterParam(),
          demiDecade: new DemiDecadePeriodFilterParam(),
        };
        if (this.selectedMeterSupportedTypes.length > 0) {
          let usage_unit =
            this.$options.constants.meterTypeUsageUnit[
              this.selectedMeterSupportedTypes[0]
            ];
          this.filterParams.usage_unit = usage_unit;
        }
        this.periodsConfig[this.selectedPeriod].fetchData(
          this,
          this.filterParams
        );
      },
    },
    selectedPeriod(newVal) {
      this.periodsConfig[newVal].fetchData(this, this.filterParams);
    },
  },
  async mounted() {
    await this.getUserMeters();
    this.periodsConfig[this.selectedPeriod].fetchData(this, this.filterParams);
  },
  methods: {
    getGraphLabel() {
      if (
        this.filterParams.usage_unit == 'kwh' &&
        this.filterParams.show_emission
      ) {
        return 'lb_co2e';
      }
      if (
        this.filterParams.usage_unit == 'therm' &&
        this.filterParams.show_emission
      ) {
        return 'lb_co2e';
      }

      if (
        this.filterParams.usage_unit == 'kwh' &&
        !this.filterParams.show_emission
      ) {
        return 'kwh';
      }
      if (
        this.filterParams.usage_unit == 'therm' &&
        !this.filterParams.show_emission
      ) {
        return 'therm';
      }
    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    async getUserMeters() {
      let res = await this.$api.$get('pelm/user-pelm-energy-accounts');
      this.userPelmEnergyAccounts = res.items.filter((o) => {
        return (
          o.last_sync_date_gas ||
          o.last_sync_date_electric ||
          o.start_sync_date_gas ||
          o.start_sync_date_electric
        );
      });
      if (this.userPelmEnergyAccounts.length > 0) {
        this.filterParams.energy_account_id = this.userPelmEnergyAccounts[0].id;
      }
    },
    async setRangeFilter(direction) {
      if (direction == 'left') {
        this.periodsConfig[this.selectedPeriod].previous();
      }
      if (direction == 'right') {
        this.periodsConfig[this.selectedPeriod].next();
      }

      this.periodsConfig[this.selectedPeriod].fetchData(
        this,
        this.filterParams
      );
    },
  },
  computed: {
    addressMeters() {
      return this.groupBy(this.userPelmEnergyAccounts, 'address');
    },
    selectedMeterSupportedTypes() {
      this.userPelmEnergyAccounts;
      let meterTypes = [];
      if (this.filterParams.energy_account_id) {
        const meter = this.userPelmEnergyAccounts.find(
          (o) => o.id === this.filterParams.energy_account_id
        );
        meterTypes = [...meter.available_meter_types];
      }
      return meterTypes;
    },
    selectedMeter() {
      const meter = this.userPelmEnergyAccounts.find(
        (o) => o.id === this.filterParams.energy_account_id
      );
      return meter;
    },
  },
  head() {
    return {
      title: 'My Usage | Meterleader',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'View your utility data.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/usage';
@import '~@/assets/css/exploreChallenges';

.selected-item {
  background-color: #eaa000;
}
</style>
