<template>
  <div class="container d-flex">
    <!--    <h2 class="d-md-flex font-weight-bold">-->
    <!--      {{ $titleBar.data.title }}-->
    <!--    </h2>-->
    <div class="row">
      <div class="tc-div">
        <div class="d-none d-lg-block">
          <div class="d-flex align-items-center">
            <font-awesome-icon
              class="icon h2 mb-0"
              icon="angle-left"
              @click="$router.back()"
            />
            <div class="flex-grow-1"></div>
          </div>
        </div>
        <div class="d-lg-none" v-if="!$auth.loggedIn">
          <div class="d-flex align-items-center">
            <font-awesome-icon
              class="icon h2 mb-0"
              icon="angle-left"
              @click="$router.back()"
            />
            <div class="flex-grow-1"></div>
          </div>
        </div>
        <div id="terms-conditions" class="fit-obj">
          <h3 class="title text-center">METERLEADER<br><br>
            WEBSITE TERMS OF SERVICE</h3>

          <p class="context mt-5">Last Updated: May 8, 2023</p>
          <p class="context">These MeterLeader Website Terms of Service (“<strong>Terms of Service</strong>”) apply to your access to and
            use of any websites, mobile applications, and other online products and services that are
            provided by MeterLeader Inc. (“<strong>MeterLeader</strong>,” “<strong>we</strong>,” or “<strong>us</strong>”) and link to these Terms of Service,
            in addition to any third-party web platforms or downloadable software applications which you use
            to access such websites or services provided by us (collectively, our “<strong>Services</strong>,” or, our “<strong>Site</strong>”).
            These Terms do not alter in any way the terms or conditions of any other agreement you may have
            with us or our subsidiaries or affiliates, for products, services or otherwise.</p>
          <p class="content">By using or browsing the Site or accessing any information while using or browsing the Site, you
            acknowledge that you have read, understood and agree to be bound by these Terms of Service
            and our Privacy Policy (found at <a href="https://s3.amazonaws.com/meterleader-assets/meterleaderprivacy.pdf">https://s3.amazonaws.com/meterleader-assets/meterleaderprivacy.pdf</a> ), which is incorporated into these Terms of Service by reference, and all applicable
            laws and regulations.</p>
          <p class="content">We reserve the right to change or modify these Terms of Service at any time and in our sole
            discretion. If we make changes to these Terms of Service, we will provide notice of such changes,
            such as by posting a notice on our Site and/or updating the “Last Updated” date above. Your
            continued use of our Services following the posting of changes or modifications will confirm your
            acceptance of such changes or modifications. If you do not agree to the amended Terms of
            Service, you must stop using our Services.</p>
          <p class="content">It is your responsibility to review these Terms of Service periodically, and if at any time you find
            these Terms of Service unacceptable, you must immediately leave the Site and cease all use of the
            Site and our Services.</p>

          <p class="content insider">1. <strong class="title">OUR ENERGY-SAVING CHALLENGES</strong>. MeterLeader provides services that are designed to
            crowdsource and aggregate measured energy reductions through online challenge events
            called “<strong>Challenges</strong>” on the Site. Challenges may be hosted by MeterLeader (in which case
            they are referred to as “<strong>Public Challenges</strong>” herein), or they may be offered to select users of
            the Site who meet certain eligibility criteria set forth by a third-party host of a Challenge
            (referred to as “<strong>Private Challenges</strong>”).</p>

          <p class="content bolder">Collection of Utility Data and Challenge Data</p>
          <p class="content">In connection with a Challenge, the Site will monitor your energy and/or water consumption by
            accessing and analyzing your household location and electricity, natural gas, and/or water data on
            file with your Utility, your utility bill amounts due and paid, and any other information from your
            Utility that may be necessary to provide our Services (collectively, your “<strong>Utility Data</strong>”). For
            purposes of these Terms of Service, “<strong>Utility</strong>” means either a regulated electric, natural gas, or
            water generation, transmission or distribution company, from which you receive electricity, natural
            gas or water. Your Utility Data is not shared with any third-parties or made public, except as
            provided herein. If you choose to participate in a Challenge, the Site will collect and track
            percentage change in kilowatt-hour (kWh) electricity, natural gas (therm), carbon emissions (CO2e
            pounds), and/or water (gallons) consumption during the Challenge period, as compared to that
            same time period during the previous year, and average and aggregate utility data reductions
            achieved by all participants in the Challenge (collectively, “<strong>Challenge Data</strong>”). Such Challenge Data
            may be displayed publicly on the applicable Challenge page. Some Utility Data and/or Challenge
            Data presented through the Site may be estimates based on available information, and may not
            reflect actual figures. By creating an Account and participating in a Challenge, you agree to the
            Site’s collection of Utility Data and derivation of Challenge Data therefrom, as described herein.</p>
          <p class="content bolder">Sharing & Retention of Utility Data and Challenge Data</p>
          <p class="content">When you connect your utility account (“<strong>Utility Account</strong>”) to your MeterLeader Account, your
            individual Utility Data is always viewable only by you, unless you choose to participate in a
            Challenge. When you participate in a Challenge, you agree to allow MeterLeader to list your
            Account name on Leaderboards or other features of the Site where your Utility Data is shared with
            other Users who are also participating in that Challenge. If you join a Public Challenge, you also
            agree to allow MeterLeader to share your MeterLeader Account name and Utility Data with other
            Users who visit the Public Challenge page URL.</p>
          <p class="content">In addition, when your Utility Data is used to generate Challenge Data, that Challenge Data is
            distributed or made public to MeterLeader, the host of a Challenge, other Users and Visitors.
            Challenge Data is shared solely in an aggregated, anonymized format, as described below.</p>
          <p class="content">In the case of a Public Challenge, your Utility Data is used to generate Challenge Data, which is
            shared with MeterLeader. You agree that MeterLeader may, in its discretion, publish such public
            Challenge Data to the public portions of the Site available to both Visitors and Users. MeterLeader
            may choose to retain Challenge Data from Public Challenges for the purposes described in these
            Terms of Service, for such period as may be set forth in our Privacy Policy or as permissible by
            law.</p>
          <p class="content">In the case of a Private Challenge, your Utility Data is used to generate Challenge Data, which is
            shared with MeterLeader and with the host of such Private Challenge. You may request that
            MeterLeader delete Challenge Data from Private Challenges after the completion of the Private
            Challenge. By participating in a Private Challenge, you agree that MeterLeader may share your
            Utility Data with the host of the Private Challenge. You also understand and agree that the host of
            the Private Challenge may provide written notice under any applicable Challenge Rules (as
            defined below) of additional circumstances under which they may use your Utility Data to derive
            Challenge Data in connection with a given Private Challenge, and how such host may use or
            disclose Challenge Data associated with that Private Challenge to their subsidiaries, parents,
            affiliates, licensors, or other third-parties. Unless you communicate otherwise to the host of a
            Private Challenge, by participating in a Private Challenge, you agree to be bound by such
            additional written terms regarding the use and disclosure of Utility Data and Challenge Data by the
            Private Challenge host.</p>
          <p class="content bolder">BY PARTICIPATING IN A CHALLENGE, YOU AGREE TO PUBLICLY DISCLOSE YOUR
            UTILITY DATA FOR THE PURPOSES SET FORTH IN THESE TERMS OF SERVICE. IF YOU DO
            NOT AGREE TO HOW YOUR UTILITY DATA IS COLLECTED AND PROCESSED AS
            DESCRIBED HEREIN, YOUR CHOICE IS NOT TO PARTICIPATE IN A CHALLENGE OR
            OTHERWISE USE THE SITE.</p>

          <p class="content insider">2. <strong class="title">CHALLENGE TERMS</strong>. Your participation in a given Challenge offered through the Site is
            entirely personal, non-commercial, and voluntary. You specifically acknowledge and agree that
            your participation in a Challenge is not compelled or coerced by any third-party, and is outside
            the scope of any special relationship (including any applicable employment relationship) which
            may exist between you and MeterLeader, the host of a Private Challenge, or any other thirdparty. Accordingly, neither MeterLeader nor the host of any Private Challenge has any fiduciary
            or other obligation to you in connection with your participation in a Challenge, except as may
            be voluntarily agreed to pursuant to these Terms of Service. You further acknowledge and
            agree that, for purposes of your participation in a Challenge, MeterLeader is not a processor of
            any personal information belonging to any organization to which you may belong, for instance
            your employer</p>

          <p class="content">All Challenges can be accessed through your Account (discussed below). Public Challenges are
            offered to users of the Site who have successfully registered and maintain an Account on the Site,
            at MeterLeader’s discretion. Private Challenges are offered to you via your Account if you qualify
            for participation in an applicable Private Challenge, based on eligibility criteria established in
            writing and provided to you on the Site by the host of the Private Challenge.</p>
          <p class="content">All Challenges can be accessed through your Account (discussed below). Public Challenges are
            offered to users of the Site who have successfully registered and maintain an Account on the Site,
            at MeterLeader’s discretion. Private Challenges are offered to you via your Account if you qualify
            for participation in an applicable Private Challenge, based on eligibility criteria established in
            writing and provided to you on the Site by the host of the Private Challenge.</p>
          <p class="content">MeterLeader does not control the terms of participation in Private Challenges, and cannot be held
            liable for any damages which may arise from your ability or inability to participate in a given Private
            Challenge. When you join a Public Challenge, you make an offer to MeterLeader to join the Public
            Challenge on the terms set forth in the applicable Public Challenge page; when you join a Private
            Challenge, you make an offer to the host of the Private Challenge on the terms set forth in the
            applicable Private Challenge page (collectively, such additional terms and conditions of your
            participation in a Challenge are referred to herein as the “<strong>Challenge Rules</strong>”; any such Challenge
            Rules that may be communicated to you as described herein are incorporated into these Terms of
            Service by this reference). MeterLeader or the applicable Challenge Host may accept or deny your
            offer to join the Challenge at any time in their sole discretion by providing written notice to you
            through the Site.</p>

          <p class="content insider">3. <strong class="title">ACCESSING THE SITE; ACCOUNT SECURITY</strong>. You must be at least 18 years of age to use
            our Services or access the Site. We reserve the right to withdraw or amend this Site, and any
            service or material we provide on the Site, in our sole discretion without notice. We will not be
            liable if for any reason all or any part of the Site is unavailable at any time or for any period.
            From time to time, we may restrict access to some parts of the Site, or the entire Site, to Users.
            In connection with your use of the Site, you are responsible for making all arrangements
            necessary for you to have access to the Site; and ensuring that all persons who access the
            Site through your internet connection are aware of these Terms of Service and comply with
            them.</p>

          <p class="content">We provide access to our Site to the following groups:</p>
          <p class="content insider-div">a. “<strong>Visitors</strong>” are people that want to explore our Site and the services we provide. No login is
            required for Visitors. Visitors can: (i) view and access all publicly available features and
            functionality on the Site; (ii) make purchases from us on the Site; (iii) subscribe to our
            communications, alerts and other notifications; and (iv) e-mail us.</p>
          <p class="content insider-div">b. “<strong>Utility Connectors</strong>” are people that provide their Utility Account Credentials (as defined
            below) to MeterLeader, and that, at MeterLeader’s sole discretion, may be compensated
            for doing so, in order to enable MeterLeader to integrate with new a Utility.</p>
          <p class="content insider-div">c. “<strong>Users</strong>” can do everything Visitors can, as well as: (i) access non-public features and
            functionality available only through our “<strong>Account</strong>” login features; (ii) create, access,
            manage, and update their own personal accounts on the Site; and (iii) access proprietary,
            downloadable and non-downloadable content developed by us in connection with
            Challenges and other Services we provide (“<strong>User Content</strong>”).</p>
          <p class="content">To access the Site or User Content as a User, you may be asked to provide certain registration
            details or other information to create an Account, including your Utility Account Credentials. For
            purposes of these Terms of Service, “<strong>Utility Account Credentials</strong>” means the username,
            password, account number, or other credentials to access your Utility Account. It is a condition of
            your use of the Site that all the information you provide through your Account or otherwise on the
            Site is correct, current, and complete. You agree that all information you provide to register with
            this Site or otherwise, including through the use of any interactive features on the Site, is governed
            by our Privacy Policy, and you consent to all actions we take with respect to your information
            consistent with our Privacy Policy.</p>
          <p class="content">By submitting information requested in connection with your Account, providing your Utility
            Account Credentials, connecting your Utility Data to your Account, or providing other information
            in connection with the Site (together, your “<strong>Account Information</strong>”), you agree to provide
            MeterLeader a royalty-free, sublicensable, transferrable, worldwide license to your Account
            Information for the purpose of providing the Services to you, in accordance with these Terms of
            Service and our Privacy Policy. This license can be revoked by you at any time by deleting your
            Account, which will automatically disconnect your Utility Data from the Site and all Account
            Information (other than Challenge Data, which will be anonymized) will be deleted.</p>
          <p class="content">If you choose, or are provided with, a username, password, or any other piece of information as
            part of our security procedures, you must treat such information as confidential, and you must not
            disclose it to any other person or entity. You also acknowledge that your Account is personal to
            you and agree not to provide any other person with access to this Site or portions of it using your
            username, password, or other security information. You agree to notify us immediately of any
            unauthorized access to or use of your username or password or any other breach of security. You
            also agree to ensure that you exit from your Account at the end of each session. You should use
            particular caution when accessing your Account from a public or shared computer so that others
            are not able to view or record your password or other personal information.</p>
          <p class="content">We have the right to disable any username, password, or other identifier used to access the Site,
            whether chosen by you or provided by us, at any time, including if, in our opinion, you have
            violated any provision of these Terms of Service.</p>

          <p class="content insider">4. <strong class="title">UTILITY ACCOUNT ACCESS</strong>. You authorize MeterLeader to retrieve, store, and use, on your
            behalf as your agent, your Utility Data and your Utility Account Credentials in order to provide
            our Services, including Utility Data provided to us after you create your Account. To allow
            MeterLeader to retrieve and use your Utility Data, you understand that you must provide
            MeterLeader with your Utility Account Credentials. You represent, warrant, acknowledge, and
            agree that you are solely responsible for the accuracy of your Utility Account Credentials. If
            multi-factor authentication is active on your Utility Account, you may need to deactivate it in
            order to use the Services. MeterLeader is not responsible or liable in any way for any
            unauthorized access to your Utility Account, Utility Account Credentials, Utility Data, or other
            any information in your Utility Account if you choose to deactivate multi-factor authentication
            for your Utility Account. You agree to notify MeterLeader of any changes to your Utility Data or
            Utility Account Credentials. By providing MeterLeader with your Utility Account Credentials,
            you certify that you or another member of your household or business owns the Utility
            Account(s) associated with your home or business and that you are authorized to share your
            Utility Data and Utility Account Credentials with MeterLeader. You acknowledge and agree that
            when MeterLeader is accessing and retrieving your Utility Data from third-party sites,
            MeterLeader is acting as your agent, and not as the agent for or on behalf of the third-party.
            You further acknowledge and agree that MeterLeader may analyze your Utility Data as set
            forth in the Privacy Policy.</p>

          <p class="content insider">5. <strong class="title">IDENTITY VERIFICATION</strong>. You authorize MeterLeader, directly or through third-parties, to
            make any inquiries we consider necessary to verify your identity. This may include asking you
            for further information, requiring you to take steps to confirm ownership of your email address
            or financial instruments, and verifying your information against third-party databases or
            through other sources. If you cannot verify your identity, MeterLeader reserves the right to
            deny you use of the Services, including Challenges.</p>

          <p class="content insider">6. <strong class="title">YOUR RELATIONSHIP WITH YOUR UTILITY</strong>. MeterLeader does not supply, transmit, or
            distribute electricity, natural gas, or water and is not affiliated or endorsed by any Utility. By
            using the Services, you acknowledge and agree that MeterLeader is acting only as an
            intermediary and is not a party to any transaction between you and your Utility. Accordingly,
            MeterLeader assumes no responsibility and will have no liability of any kind whatsoever in
            respect to your dealings with your Utility, including with regard to electric, natural gas, or water
            delivery, payments, notices, and the proper and timely delivery of goods or services by your
            Utility. MeterLeader in no way endorses, recommends, and/or bears any responsibility or
            liability for any products, services, statements, made by your Utility. Statements and opinions
            of your Utility are not representative of MeterLeader or its business partners. MeterLeader is
            not liable for the accuracy of your Utility billing or use statement. Any discrepancies or
            disputes regarding the accuracy of your such statement must be addressed with your Utility
            directly. MeterLeader assumes no responsibility, and will not be liable, for your failure to take
            any action or inaction with respect to your Utility Account(s) or your failure to receive any
            information with respect to any Utility Account(s). You agree to hold MeterLeader harmless
            should your Utility fail to deliver your Utility statement(s). You are responsible for ensuring
            timely payment of all Utility statements. These Terms of Service do not alter any liability or
            obligations that currently exist between you and your Utility. You agree that your relationship
            with your Utility is governed solely by the agreement(s) between you and your Utility.</p>

          <p class="content insider">7. <strong class="title">METERLEADER UTILITY CONNECTION SITE</strong>. By submitting information to us as a Utility
            Connector and providing your Utility Account Credentials, you agree to provide MeterLeader a
            royalty-free, sublicensable, transferrable, worldwide license to your Utility Account Credentials
            solely for the purpose of integrating new Utilities with the Services, in accordance with these
            Terms and our Privacy Policy. Any consideration provided to you for becoming a Utility
            Connector is at MeterLeader’s sole discretion. MeterLeader has no liability for any receipt or
            nonreceipt of any compensation or other consideration, including if you provide incorrect or
            invalid information to MeterLeader.</p>

          <p class="content insider">8. <strong class="title">USER FEES</strong>. Prices for individuals and organizations that use MeterLeader’s Services are
            posted on the Site. MeterLeader reserves the right to charge a fee for User access to
            Challenges or other portions of the Services. By creating an Account, you agree to pay
            MeterLeader any fee stated on the Site for all periods during which your Account is active.
            Participation fees are determined separately for each Challenge, and are subject to change. All
            payments must be made on the Site prior to connecting your Utility Data, and within 15 days of
            the end of the most recent period for which your Account was active for recurring payments.
            MeterLeader reserves the right to change User fees or institute new fees at any time, in each
            case upon reasonable notice of such change posted on the Site or communicated to you
            directly in writing. In the event of such change, no refund of User fees already paid will be
            given. If you breach the Terms of Service and MeterLeader subsequently terminates your
            access to the Site as provided herein, you are not entitled to any refund of any payments
            made to MeterLeader prior to such termination.</p>

          <p class="content">Your access to portions of the Site which require payment of a User fee begins as of the first date
            on which you successfully create an Account and connect your Utility Data, and pay such User
            fee. Your access continues monthly unless canceled or terminated earlier as set out herein. You
            may cancel your Account at any time; however, no refund of any User fees paid will be given.
            MeterLeader may cancel your membership and terminate your Account at any time, provided that
            if feasible MeterLeader will use reasonable efforts to provide you prior notice; however, no refund
            of any User fees paid will be given. The rights, duties and obligations of you and MeterLeader that
            by their nature continue and survive will survive any expiration or termination of such access.</p>
          <p class="content">From time to time, MeterLeader may offer promotional rates, discounts, or waivers of otherwise
            applicable User fees. In the event of such promotion, MeterLeader will provide notice of the
            promotion on the Site or to you directly in writing.</p>

          <p class="content insider">9. <strong class="title">INTELLECTUAL PROPERTY RIGHTS</strong>. Our Services, the Site, and their entire contents
            (including any third-party content accessible by you), features, and functionality (including all
            information, software, text, displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by us, or our licensors and are protected under both U.S. and
            foreign laws. Except as explicitly stated in these Terms of Service, all rights in and to our
            Services are reserved by us or our licensors. Subject to your compliance with these Terms, you
            are granted a limited, nonexclusive, nontransferable, non-sublicensable, revocable license to
            access and use the Services for non-commercial purposes and to download and print
            materials from the Services for the purpose of viewing, reading and retaining such materials for
            reference. Any other access, use, copying, distribution, retransmission or modification of our
            Services, without our prior written permission, is strictly prohibited and will terminate the
            license granted herein and violate our intellectual property rights. In addition, you may not sell,
            redistribute, or reproduce, decompile, reverse-engineer, disassemble, or otherwise convert the
            Site or any content on the site to a human-perceivable form without our prior written consent.</p>

          <p class="content bolder">Trademarks</p>
          <p class="content">METERLEADER, our company name, and all related names, logos, product and service names,
            designs, and slogans are our company and licensors’ trademarks. You cannot use such marks
            without our prior written permission. All other names, logos, product and service names, designs,
            and slogans on this Site are the trademarks of their respective owners.</p>

          <p class="content insider">10. <strong class="title">USE OF THE SITE</strong>. You may use the Site and our Services only for lawful purposes and in
            accordance with these Terms of Service. You specifically agree not to use the Site:</p>

          <p class="content insider-div">a. In any way that violates any applicable federal, state, local, or international law or
            regulation (including any laws regarding the export of data or software to and from the US
            or other countries);</p>
          <p class="content insider-div">b. In contravention of any website terms of service or end-user license agreement entered
            into between you and any third-party in connection with your access to any services
            provided by us, or in violation of any copyright or other intellectual property right of any
            third-party in connection with your access to any services provided by us; or</p>
          <p class="content insider-div">c. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
            Site, or which, as determined by us, may harm MeterLeader or users of the Site, or
            expose them to liability</p>

          <p class="content">You are also prohibited from violating or attempting to violate any security features of the Site,
            including:</p>
          <p class="content insider-div">i. Accessing content or data not intended for you, or logging onto a server or account that
            you are not authorized to access;</p>
          <p class="content insider-div">ii. Attempting to probe, scan, or test the vulnerability of the Service, the Site, or any
            associated system or network, or to breach security or authentication measures without
            proper authorization;</p>
          <p class="content insider-div">iii. Interfering or attempting to interfere with service to any user, host, or network, including
            by means of submitting a virus to the Site or Service, overloading, “flooding,”
            “spamming,” “mail bombing,” or “crashing;”</p>
          <p class="content insider-div">iv. Using the Site or Service to send unsolicited e-mail, including promotions, or
            advertisements for products or services;</p>
          <p class="content insider-div">v. Forging any TCP/IP packet header or any part of the header information in any e-mail or in
            any posting using the Site; or</p>
          <p class="content insider-div">vi. Attempting to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or
            attempt to reduce to a human-perceivable form any of the source code used by
            MeterLeader in providing the Site.</p>

          <p class="content">Any violation of system or network security may subject you to civil and/or criminal liability</p>

          <p class="content insider">11. <strong class="title">USER GENERATED CONTENT</strong>. The Site may contain message boards, chat rooms, personal
            web pages or profiles, newsletters, forums, bulletin boards, and other interactive features that
            allow users to post, submit, publish, display, or transmit Utility Data, Challenge Data, or other
            content or materials (collectively, “<strong>User Generated Content</strong>”) to us or to other Users
            (collectively, “<strong>Posting</strong>” on the Site). By Posting on the Site, you:</p>

          <p class="content insider-div">a. Grant MeterLeader, its affiliates, officers, directors, employees, consultants, agents, and
            representatives a license to use your User Generated Content in connection with the
            operation of the business of MeterLeader, its affiliates, officers, directors, employees,
            consultants, agents, and representatives, including a right to copy, distribute, transmit,
            publicly display, publicly perform, reproduce, edit, translate, and reformat your User
            Generated Content;</p>
          <p class="content insider-div">b. Agree that MeterLeader may publish or otherwise disclose your MeterLeader Account
            name and the city identified in your Utility Account in connection with your User
            Generated Content; and</p>
          <p class="content insider-div">c. Warrant and represent that you own the rights to your User Generated Content or are
            otherwise authorized to post, distribute, display, perform, transmit, or otherwise distribute
            your User Generated Content.</p>

          <p class="content">You will not be compensated for Posting any User Generated Content.</p>
          <p class="content">The Site may also contain links to other sites (“Third-Party Links”), including links to third-party
            social media platforms via which you may share User Generated Content with third-parties using
            platforms available to you via such Third-Party Links. If you click on a Third-Party Link, you will be
            directed to that site. Note that these external sites are not operated by us. Therefore, we strongly
            advise you to review the privacy policy of these sites. We have no control over and assume no
            responsibility for the content, privacy policies, or practices of any third-party sites or services
            presented to you via the Site.</p>

          <p class="content insider">12. <strong class="title">INAPPROPRIATE CONTENT</strong>. In connection with your use of the Site, you agree not to upload,
            download, display, perform, transmit, or otherwise distribute any User Generated Content or
            third-party content that:</p>

          <p class="content insider-div">a. Is libelous, defamatory, obscene, pornographic, abusive, or threatening;</p>
          <p class="content insider-div">b. Creates a false identity for the purpose of misleading others;</p>
          <p class="content insider-div">c. Advocates or encourages conduct that could constitute a criminal offense, give rise to
            civil liability, or otherwise violate any applicable local, state, national, or foreign law or
            regulation;</p>
          <p class="content insider-div">d. Contains viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or similar
            software; or</p>
          <p class="content insider-div">e. Uses any part of the Site for surveys, contests, pyramid schemes, chain letters, junk mail,
            spam, or unsolicited messages.</p>

          <p class="content">MeterLeader reserves the right to terminate your use of the Site and Services, and your receipt,
            transmission, or other distribution of any such material using the Service, and, if applicable, to
            delete any such material from its servers. MeterLeader intends to cooperate fully with any law
            enforcement officials or agencies in the investigation of any violation of these Terms of Service or
            of any applicable laws.</p>

          <p class="content insider">13. <strong class="title">ALLEGED COPYRIGHT INFRINGEMENT</strong>. MeterLeader has in place certain legally
            mandated procedures regarding allegations of copyright infringement occurring on the Site.
            We have adopted a policy that provides for the immediate suspension and/or termination of
            any User who is found to have infringed the rights of MeterLeader or a third-party, or
            otherwise violated any intellectual property laws or regulations. Our policy is to investigate
            any allegations of copyright infringement brought to our attention.</p>

          <p class="content">If you have evidence, know, or have a good faith belief that your rights or the rights of a third-party
            have been violated and you want us to delete, edit, or disable the material in question, you must
            provide us with all of the following information:</p>
          <p class="content insider-div">a. A physical or electronic signature of a person authorized to act on behalf of the owner of
            the exclusive right that is allegedly infringed;</p>
          <p class="content insider-div">b. Identification of the copyrighted work claimed to have been infringed, or, if multiple
            copyrighted works are covered by a single notification, a representative list of such works;</p>
          <p class="content insider-div">c. Identification of the material that is claimed to be infringed or to be the subject of
            infringing activity and that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit MeterLeader to locate the material;</p>
          <p class="content insider-div">d. Information reasonably sufficient to permit MeterLeader to contact you, such as an
            address, telephone number, and if available, an electronic mail address at which you may
            be contacted;</p>
          <p class="content insider-div">e. A statement that you have a good faith belief that use of the material in the manner
            complained of is not authorized by the copyright owner, its agent, or the law; and</p>
          <p class="content insider-div">f. A statement that the information in the notification is accurate, and under penalty of
            perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
            allegedly infringed.</p>

          <p class="content">For this notification to be effective, you must provide it to our designated agent either by email or
            first-class mail at:</p>

          <p class="content insider"><br>MeterLeader Inc.<br>
            Attn: Legal<br>
            6942 Lydia Dr<br>
            Huntington Beach, CA 92647<br>
            support@meterleader.com</p>

          <p class="content insider">14. <strong class="title">THIRD-PARTY CONTENT & SERVICES</strong>. We may provide information about third-party
            products, services, activities or events on our Services, or we may allow third-parties to make
            their content and information available on our Services (collectively, “<strong>Third-Party Content</strong>”)
            as a service to those interested in this information. Your dealings or correspondence with
            third-parties and your use of or interaction with any Third-Party Content are solely between
            you and the third-party. We do not control, endorse or adopt any Third-Party Content and
            make no representation or warranties of any kind regarding Third-Party Content, and your
            access to and use of such Third-Party Content is at your own risk.</p>

          <p class="content">In addition, we may use certain third-party service(s), including those offered by Stripe, Google,
            and Apple (each, a “Third-Party Service”) to process payments, and make our Services available
            to you on certain devices, or otherwise. These Third-Party Services are made available on the Site
            via integrated applications owned and operated by the provider of each Third-Party Service, not
            us.</p>
          <p class="content">To learn more about Stripe, please visit: <a href="https://stripe.com/legal">https://stripe.com/legal</a></p>
          <p class="content">To learn more about Apple’s App Store services, please visit: <a href="https://www.apple.com/legal/privacy/
            data/en/app-store/">https://www.apple.com/legal/privacy/
            data/en/app-store/</a></p>
          <p class="content">To learn more about Google’s services, please visit: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> and
            <a href="www.google.com/analytics">www.google.com/analytics</a></p>

          <p class="content insider">15. <strong class="title">TERMINATION</strong>. We reserve the right to terminate your use of the Site in our sole discretion.
            To ensure that we provide a high-quality experience for you and for other users of the Site,
            you agree that we (or our representatives) may access your Account and records on a
            case-by-case basis to investigate complaints or allegations of abuse, infringement of thirdparty rights, or other unauthorized uses of the Site. We reserve the right not to disclose the
            existence or occurrence of such an investigation unless required by law, but we also
            reserve the right to terminate your Account or your access to the Site immediately, with or
            without notice, if we believe that you have violated any of the Terms of Service, furnished
            us with false or misleading information, or interfered with use of the Site by others.</p>

          <p class="content">16. <strong class="title">WE HEREBY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES</strong>.</p>

          <p class="content">WE ARE MAKING THE SITE AND OUR SERVICES AVAILABLE “AS IS” WITHOUT WARRANTY OF
            ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR
            INABILITY TO USE, THE SITE, INCLUDING ANY PRODUCT OR SERVICE YOU MAY ACCESS AS
            A RESULT OF YOUR USE OF THE SITE.</p>
          <p class="content">TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL
            WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE AND OUR SERVICES,
            INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NONINFRINGEMENT. METERLEADER DOES NOT WARRANT THAT THE SITE
            WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE WILL BE
            UNINTERRUPTED OR ERROR-FREE.</p>
          <p class="content">WE DO NOT ENDORSE ANY ENTITY, PRODUCT OR SERVICE MENTIONED IN ANY CONTENT
            AVAILABLE ON THE SITE.</p>

          <p class="content">17. <strong class="title">OUR LIABILITY TO YOU IS LIMITED</strong>.</p>

          <p class="content">TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE BE LIABLE FOR
            DAMAGES OF ANY KIND (INCLUDING SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
            DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF
            THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH:</p>

          <p class="content insider-div">A) YOUR USE OF THE SITE OR ANY OTHER MATERIALS, INFORMATION, OR SERVICES
            PROVIDED TO YOU BY US,</p>
          <p class="content insider-div">B) YOUR INTERACTION WITH ANY OTHER PERSONS WITH WHOM YOU MAY
            COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE,</p>
          <p class="content insider-div">C) ANY PERSONAL INJURY, PROPERTY DAMAGE, OR OTHER HARM OR LOSS TO YOU
            OR ANY THIRD-PARTY RESULTING FROM OR ARISING OUT OF YOUR USE OF THE
            SERVICES PROVIDED BY US OR THROUGH THE SITE.</p>

          <p class="">THIS LIMITATION SHALL APPLY REGARDLESS OF WHETHER THE DAMAGES ARISE OUT OF
            BREACH OF CONTRACT, TORT, PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY OR
            FORM OF ACTION.</p>
          <p class="content">FURTHER, YOU RELEASE US FROM ALL LIABILITY TO YOU AND YOUR PRINCIPALS,
            EMPLOYEES, AGENTS, REPRESENTATIVES, GUARDIANS, SUCCESSORS, ASSIGNS, HEIRS,
            CHILDREN, AND NEXT OF KIN FOR ALL LIABILITY, CLAIMS, DAMAGE, OR DEMANDS FOR
            PERSONAL INJURY, DEATH, OR PROPERTY DAMAGE, ARISING FROM OR RELATED TO YOUR
            USE OF THE SITE. THIS RELEASE INCLUDES ANY PERSONAL INJURY, DEATH, OR PROPERTY
            DAMAGE CAUSED BY THE ACTIVE OR PASSIVE NEGLIGENCE OF METERLEADER OR ANY OF
            OUR PRINCIPALS, EMPLOYEES, AGENTS, REPRESENTATIVES, OR INDEPENDENT
            CONTRACTORS. YOU BEAR SOLE RESPONSIBILITY FOR ANY LOSS.</p>
          <p class="content">IN NO EVENT SHALL WE BE LIABLE TO YOU FOR DAMAGES OF ANY KIND ARISING OUT OF
            OR IN CONNECTION WITH ACTS OR OMISSIONS OF ANY THIRD-PARTY, INCLUDING THE
            ACTIVE OR PASSIVE NEGLIGENCE OF THAT PARTY, OR THAT PARTY’S VIOLATION OF A
            STATUTE.</p>
          <p class="content">IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542,
            WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
            DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
            WITH THE DEBTOR.”</p>
          <p class="content">YOU AND WE AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN
            THESE TERMS OF SERVICE ARE MATERIAL, BARGAINED-FOR BASES OF THIS AGREEMENT,
            AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN DETERMINING THE CONSIDERATION
            TO BE GIVEN BY EACH PARTY UNDER THIS AGREEMENT AND IN THE DECISION BY EACH
            PARTY TO ENTER INTO THIS AGREEMENT. YOU AND METERLEADER AGREE THAT THE
            WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF SERVICE
            ARE FAIR AND REASONABLE.</p>

          <p class="content insider">18. <strong class="title">INDEMNITY</strong>. You agree to indemnify us for certain of your acts and omissions. You agree to
            indemnify, defend, and hold us and our affiliates, officers, directors, employees, consultants,
            agents, and representatives harmless from any and all third-party claims, losses, liability,
            damages, and/or costs (including reasonable attorney fees and costs) directly or indirectly
            arising from your access to or use of the Site, including your negligent use of the Site; your
            violation of these Terms of Service; your violation of the representations, warranties, or
            covenants set forth in these Terms of Service; any misrepresentations, defective products, or
            breach of any warranties or agreements made by you in connection with your use of the Site;
            or your infringement, or infringement by any other user of your Account, of any intellectual
            property or other right of any person or entity. We will notify you promptly of any such claim,
            loss, liability, or demand, and will provide you with reasonable assistance, at your expense, in
            defending any such claim, loss, liability, damage, or cost.</p>

          <p class="content insider">19. <strong class="title">BINDING ARBITRATION; CLASS ACTION WAIVER</strong>. If you have any dispute with us, you
            agree that before taking any formal action, you will contact us at MeterLeader Inc., Attn:
            Legal, 6942 Lydia Dr, Huntington Beach, CA 92647 and at support@meterleader.com, and
            provide a brief, written description of the dispute and your contact information (including your
            MeterLeader Account name, if your dispute relates to an Account). Except for intellectual
            property and small claims court claims, the parties agree to use their best efforts to settle any
            dispute, claim, question, or disagreement directly through consultation and good faith
            negotiations shall be a condition to either party initiating a lawsuit or arbitration</p>

          <p class="content">If a dispute arises from or relates to these Terms of Service or the breach thereof, including the
            determination of the scope or applicability of this agreement to arbitrate, and if the dispute cannot
            be settled through direct discussions as outlined above, then the remaining dispute shall be
            settled by binding arbitration administered by an arbitrator through a reputable arbitration
            association such as the American Arbitration Association. All disputes shall be mediated and
            arbitrated in Los Angeles County, California, and judgment on the award rendered by the
            arbitrator(s) may be entered in any court having jurisdiction thereof. The parties may not engage in
            class or representative arbitration.</p>

          <p class="content">You and we agree that the arbitration shall be conducted in the party’s respective individual
            capacities only and not as a class action or other representative action, and the parties expressly
            waive their right to file a class action or seek relief on a class basis. <strong>YOU AND WE AGREE THAT
              EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
              CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING</strong>. If any court or arbitrator determines that the class action
            waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can
            proceed on a class basis, then the arbitration provisions set forth above shall be deemed null and
            void in their entirety and the parties shall be deemed to have not agreed to arbitrate disputes.</p>

          <p class="content">You have the right to opt out and not be bound by the arbitration and class action waiver
            provisions set forth above by sending written notice of your decision to opt out to MeterLeader
            Inc., Attn: Legal, 6942 Lydia Dr, Huntington Beach, CA 92647 and at support@meterleader.com.
            The notice must be sent within 30 days of (a) the most recent Effective Date of these Terms of
            Service as set forth above; or (b) the first date that you used the Services that contained any
            versions of the Terms of Service that included this version of the mandatory arbitration and class
            action waiver, whichever is later. Otherwise, you shall be bound to arbitrate disputes in
            accordance with the terms of this Section 19.</p>
          <p class="content">If you opt out of these arbitration provisions, MeterLeader also will not be bound by them. If we
            change this Section 19 after you have accepted these Terms of Service (or accepted any
            subsequent changes to these Terms of Service), you may reject any such change by sending us
            written notice within 30 days of the date such change became effective, as indicated in the
            Effective Date above or in the date of our email to you notifying you of such change. By rejecting
            any change, you are agreeing that you will arbitrate any dispute between you and us in
            accordance with the provisions of this section as of the date you first accepted these Terms of
            Service (or accepted any subsequent changes to these Terms of Service).</p>
          <p class="content">YOU UNDERSTAND AND AGREE THAT THE ABOVE DISPUTE PROCEDURES SHALL BE YOUR
            SOLE REMEDY IN THE EVENT OF DISPUTE BETWEEN YOU AND METERLEADER REGARDING
            ANY ASPECT OF THE SERVICE AND THAT YOU ARE WAIVING YOUR RIGHT TO LEAD OR
            PARTICIPATE IN A LAWSUIT INVOLVING OTHER PERSONS, SUCH AS A CLASS ACTION.</p>

          <p class="content insider">20. <strong class="title">SEVERABILITY; WAIVER</strong>. If, for whatever reason, a court of competent jurisdiction finds any
            term or condition in these Terms of Service to be unenforceable, all other terms and
            conditions will remain unaffected and in full force and effect. No waiver of any breach of any
            provision of these Terms of Service shall constitute a waiver of any prior, concurrent, or
            subsequent breach of the same or any other provisions hereof, and no waiver shall be
            effective unless made in writing and signed by an authorized representative of the waiving
            party.</p>

          <p class="content insider">21. <strong class="title">UNITED STATES USE ONLY</strong>. The Site is controlled and operated by MeterLeader from its
            offices in the State of California. MeterLeader makes no representation that any of the
            materials or the services to which You have been given access are available or appropriate
            for use in locations outside of the United States. Your use of or access to the Site should not
            be construed as MeterLeader’s purposefully availing itself of the benefits or privilege of doing
            business in any state or jurisdiction other than California.</p>

          <p class="content insider">22. <strong class="title">ENTIRE AGREEMENT</strong>. These Terms of Service, and all agreements incorporated by
            reference, constitute the final, complete, and exclusive statement of the terms of the
            agreement between you and MeterLeader pertaining to the subject matter of these Terms of
            Service, and supersedes all other prior or contemporaneous oral or written understandings
            and agreements between you and MeterLeader.</p>

          <p class="content insider">23. <strong class="title">SUCCESSORS</strong>. This Agreement shall inure to the benefit of and be binding on the heirs, legal
            representatives, successors, and permitted assigns of the parties.</p>

          <p class="content insider">24. <strong class="title">ACKNOWLEDGEMENT</strong>. BY USING THE SERVICE OR ACCESSING THE SITE, YOU
            ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE
            BOUND BY THEM.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  pageTitle: 'Terms & Conditions',
  backButtonRoute: '/account-settings',
  components: {
  },
  data() {
    return {
    };
  }
};
</script>
<style lang="sass" scoped>
@use "sass:color"
@import '~@/assets/css/_variables.scss'

.tc-div
  width: 100%
  margin-top: 2%
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #212529
  text-align: left
  background-color: #fff

.fit-obj
  width: 90%
  height: 99.87%
  margin: 0 auto

.content
  width: 100%
  float: left

.title
  font-size: 18px
  font-weight: bold

.bolder
  font-weight: bold

.insider
  padding-left: 1.2em
  text-indent: -1.2em

.insider-div
  width: 98%
  float: right
  padding-left: 1.2em
  text-indent: -1.2em

#terms-conditions
  padding: 3% 3% 0 3%
</style>
