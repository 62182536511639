<template>
  <div class="challenge-outer container col-12 col-md-6 col-lg-4">
    <div :class="getChallengeClass">
      <div v-if="isUserChallenge && challenge.status < 4" class="manage-header">
        <nuxt-link :to="`/challenges/create/?id=${challenge.id}`">
          <font-awesome-icon icon="cog" size="xs" style="margin-right: 5px" />
          Manage
        </nuxt-link>
      </div>
      <nuxt-link :to="`/challenges/${challenge.id}`">
        <div class="overlay">
          <h4>View Challenge</h4>
        </div>
      </nuxt-link>
      <img
        class="challenge-img"
        :src="challenge.image_tile_url || challenge.image_banner_url"
        alt="Please upload a banner image"
      />
      <p>Starts: {{ startDate }}</p>
      <p>Ends: {{ endDate }}</p>
      <h4>{{ challenge.name }}</h4>
      <!-- <p v-if="challenge.utility_id === 1">(PG&E customers only)</p>
      <p v-if="challenge.utility_id === 3">(SCE customers only)</p>
      <p v-if="challenge.utility_id === 4">(SDG&E customers only)</p> -->
      <div class="container-fluid row bottom">
        <div class="logo-container col-auto">
          <img class="sponsor-img" :src="challenge.image_creator_url" />
        </div>
        <div class="col-auto sponsor">
          <p>
            Sponsored by: <span>{{ challenge.sponsor_name }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment-timezone');
export default {
  name: 'Challenge',
  props: {
    challenge: Object,
    isUserChallenge: {
      type: Boolean,
      default: false,
    },
    onHomepage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    startDate() {
      return moment
        .tz(this.challenge.start_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('MMM. D, YYYY. h:mmA z');
    },
    endDate() {
      return moment
        .tz(this.challenge.end_date, 'YYYY-MM-DD hh:mm ZZ', moment.tz.guess())
        .format('MMM. D, YYYY. h:mmA z');
    },
    getChallengeClass() {
      if (this.onHomepage) {
        return 'challenge-homepage';
      }
      return this.isUserChallenge && this.challenge.status < 4
        ? 'user-challenge'
        : 'challenge';
    },
  },
};
</script>

<style scoped lang="scss">
/*@import '~@/assets/css/homepage';*/
@import '~@/assets/css/variables';

.challenge-outer {
  text-align: left;
  margin-top: -20px;

  .challenge {
    background-color: $blue;
    padding: 0;
    border-radius: 18px;
    margin: 0 15px 15px 15px;
    color: white;
    padding: 40px 0;
    position: relative;
    height: calc(100% - 40px);
    cursor: pointer;

    .overlay {
      position: absolute;
      border-radius: 18px;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      display: none;
      z-index: 2;
      cursor: pointer;
      text-align: center;

      h4 {
        padding-top: 120px;
      }
    }

    p {
      font-size: 10pt;
      margin-bottom: 0;
      font-weight: thin;
      padding: 0 30px;

      /*span {*/
      /*  font-weight: bold;*/
      /*}*/
    }

    h4 {
      padding: 20px 30px;
      font-size: 1.1rem;
      height: 70px;
      font-weight: bold;
    }

    .challenge-img {
      height: 175px;
      width: 100%;
      padding-bottom: 20px;
    }

    .bottom {
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;

      .logo-container {
        width: 35px;
        height: 35px;
        background-color: white;
        border-radius: 50%;
        padding: 0;
        margin-left: 15px;
        overflow: hidden;

        .sponsor-img {
          width: 100%;
        }
      }

      .sponsor {
        padding: 8px 0;

        p {
          padding-left: 10px;
        }
      }
    }
  }

  .challenge-homepage {
    background-color: white;
    padding: 0;
    margin: 15px;
    color: black;
    padding: 40px 0;
    position: relative;
    height: calc(100% - 40px);
    cursor: pointer;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      display: none;
      z-index: 2;
      cursor: pointer;
      text-align: center;

      h4 {
        padding-top: 120px;
      }
    }

    p {
      font-size: 10pt;
      margin-bottom: 0;
      font-weight: thin;
      padding: 0 30px;

      /*span {*/
      /*  font-weight: bold;*/
      /*}*/
    }

    h4 {
      padding: 20px 30px;
      font-size: 1.1rem;
      height: 70px;
      font-weight: bold;
    }

    .challenge-img {
      height: 175px;
      width: 100%;
      padding-bottom: 20px;
    }

    .bottom {
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;

      .logo-container {
        width: 35px;
        height: 35px;
        background-color: white;
        border-radius: 50%;
        padding: 0;
        margin-left: 15px;
        overflow: hidden;

        .sponsor-img {
          width: 100%;
        }
      }

      .sponsor {
        padding: 8px 0;

        p {
          padding-left: 10px;
        }
      }
    }
  }

  .manage-header {
    border: 1px solid black;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom: none;
    background-color: white;
    color: black;
    width: 100%;
    /*text-align: center;*/
    /*vertical-align: middle;*/
    /*margin: auto;*/
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin: 0 15px 0 15px;*/
    height: 40px;
  }

  .user-challenge {
    background-color: $blue;
    border-radius: 18px;
    /*padding: 0;*/
    margin: 15px;
    /*margin: 0 15px 15px 15px;*/

    color: white;
    padding: 0 0 40px 0;
    position: relative;
    height: calc(100% - 40px);
    cursor: pointer;

    .overlay {
      position: absolute;
      border-radius: 18px;
      width: 100%;
      /*height: 100%;*/
      height: calc(100% - 40px);

      /*top: 0;*/
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      display: none;
      z-index: 2;
      cursor: pointer;
      text-align: center;

      h4 {
        padding-top: 120px;
      }
    }

    p {
      font-size: 10pt;
      margin-bottom: 0;
      font-weight: thin;
      padding: 0 30px;

      /*span {*/
      /*  font-weight: bold;*/
      /*}*/
    }

    h4 {
      padding: 20px 30px;
      font-size: 1.1rem;
      height: 70px;
      font-weight: bold;
    }

    .challenge-img {
      height: 175px;
      width: 100%;
      padding-bottom: 20px;
    }

    .bottom {
      margin-top: 20px;
      margin-left: 0px;
      margin-right: 0px;

      .logo-container {
        width: 35px;
        height: 35px;
        background-color: white;
        border-radius: 50%;
        padding: 0;
        margin-left: 15px;
        overflow: hidden;

        .sponsor-img {
          width: 100%;
        }
      }

      .sponsor {
        padding: 8px 0;

        p {
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
